(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('saml-login', {
            parent: 'app',
            url: '/saml/:key',
            data: {
                authorities: [],
                loginState: true,
            },
            onEnter: ['$state', '$stateParams', '$timeout', '$base64', 'AuthServerProvider', 'Principal', function($state, $stateParams, $timeout, $base64, AuthServerProvider, Principal) {

                var token = $base64.decode($stateParams.key);

                AuthServerProvider.loginWithToken(token, true);
                Principal.identity(true).then(function() {
                    $timeout(function() {
                        Principal.hasAuthority('ROLE_ADMIN')
                        .then(function(assigned) {
                            if (assigned)
                                    $state.go('sysadmin-home')
                                else
                                    $state.go('home');
                        });
                    });
                });
            }],
        });
    }
})();
