(function () {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider.state('login', {
            parent: 'app',
            url: '/login',
            data: {
                authorities: [],
                loginState: true,
            },
            onEnter: ['$rootScope', '$state', '$window', 'AuthServerProvider', function($rootScope, $state, $window, AuthServerProvider) {

                // redirect to proper login for current organization
                AuthServerProvider.getAuthenticationMethod()
                .then(function(method) {
                    // SquirrelBox authentication
                    if (method.authenticationType == 'SB' || !method.disableSquirrelboxAuthentication) {
                        if ($rootScope.transitioning)
                            $rootScope.nextState = 'sb-login';
                        else
                            $state.go('sb-login');
                    }

                    // SAML authentication
                    else {
                        $state.go('saml-login-landing');
                    }
                });
            }],
        });
    }
})();
