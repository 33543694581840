(function() {
    'use strict';

    var PASSWORD_MASK_TEXT =  "**********";

    angular
        .module('squirrelboxApp')
        .controller('DataCenterFlowController', DataCenterFlowController);

    DataCenterFlowController.$inject = ['$state', '$stateParams', '$q', '$scope', '$timeout', 'GlobalModal', 'ExternalEntity', 'DataCenterAdmin', 'DataCenterFlow'];

    function DataCenterFlowController ($state, $stateParams, $q, $scope, $timeout, GlobalModal, ExternalEntity, DataCenterAdmin, DataCenterFlow) {
        var vm = this;

        vm.DATA_SIZE_REGEX = "(\\d+(?:\\.\\d+)?)\\s*(B|KB|MB|GB|TB)";
        vm.TIME_DURATION_REGEX = "([\\d.]+)\\s*(ns|nano|nanos|nanosecond|nanoseconds|ms|milli|millis|millisecond|milliseconds|s|sec|secs|second|seconds|m|min|mins|minute|minutes|h|hr|hrs|hour|hours|d|day|days|w|wk|wks|week|weeks)";
        vm.INTEGER_REGEX = "[0-9]+";

        vm.externalEntityAutoComplete = externalEntityAutoComplete;
        vm.externalEntityAutoCompleteBlur = externalEntityAutoCompleteBlur;
        vm.externalEntityAutoCompleteChange = externalEntityAutoCompleteChange;
        vm.cifsCredsAutoComplete = cifsCredsAutoComplete;
        vm.cifsCredsAutoCompleteBlur = cifsCredsAutoCompleteBlur;
        vm.cifsCredsAutoCompleteChange = cifsCredsAutoCompleteChange;
        vm.sftpClientCredsAutoComplete = sftpClientCredsAutoComplete;
        vm.sftpClientCredsAutoCompleteBlur = sftpClientCredsAutoCompleteBlur;
        vm.sftpClientCredsAutoCompleteChange = sftpClientCredsAutoCompleteChange;
        vm.sftpHostCredsAutoComplete = sftpHostCredsAutoComplete;
        vm.sftpHostCredsAutoCompleteBlur = sftpHostCredsAutoCompleteBlur;
        vm.sftpHostCredsAutoCompleteChange = sftpHostCredsAutoCompleteChange;
        vm.s3CredsAutoComplete = s3CredsAutoComplete;
        vm.s3CredsAutoCompleteBlur = s3CredsAutoCompleteBlur;
        vm.s3CredsAutoCompleteChange = s3CredsAutoCompleteChange;
        vm.flowPgpDecryptKeyAutoCompleteBlur = flowPgpDecryptKeyAutoCompleteBlur;
        vm.flowPgpDecryptKeyAutoCompleteChange = flowPgpDecryptKeyAutoCompleteChange;
        vm.onSystemTabClick = onSystemTabClick;
        vm.navFlows = navFlows;
        vm.onDirectionChange = onDirectionChange;
        vm.selectSystemType = selectSystemType;
        vm.saveFlow = saveFlow;
        vm.showCifsAdvanced = showCifsAdvanced;
        vm.hideCifsAdvanced = hideCifsAdvanced;
        vm.showSftpClientAdvanced = showSftpClientAdvanced;
        vm.hideSftpClientAdvanced = hideSftpClientAdvanced;
        vm.showTargetSftpClientAdvanced = showTargetSftpClientAdvanced;
        vm.hideTargetSftpClientAdvanced = hideTargetSftpClientAdvanced;
        vm.showSourceS3Advanced = showSourceS3Advanced;
        vm.hideSourceS3Advanced = hideSourceS3Advanced;
        vm.showTargetS3Advanced = showTargetS3Advanced;
        vm.hideTargetS3Advanced = hideTargetS3Advanced;
        vm.addTag = addTag;
        vm.removeTag = removeTag;
        vm.getTagKeys = getTagKeys;
        vm.addDeleteTag = addDeleteTag
        vm.removeDeleteTag = removeDeleteTag;

        function updateAvailableSystemTypes(direction) {
            if (direction === 'INBOUND') {
                vm.flow.sourceSystem.availableSystemTypes = [
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
                vm.flow.targetSystem.availableSystemTypes = [
                    { value: 'CIFS', label: 'CIFS' },
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
            }
            else if (direction === 'OUTBOUND') {
                vm.flow.sourceSystem.availableSystemTypes = [
                    { value: 'CIFS', label: 'CIFS' },
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
                vm.flow.targetSystem.availableSystemTypes = [
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
            }
            else { // internal
                vm.flow.sourceSystem.availableSystemTypes = [
                    { value: 'CIFS', label: 'CIFS' },
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
                vm.flow.targetSystem.availableSystemTypes = [
                    { value: 'CIFS', label: 'CIFS' },
                    { value: 'SFTP_CLIENT', label: 'SFTP Client' },
                    { value: 'SFTP_HOST', label: 'SFTP Host' },
                    { value: 'S3', label: 'S3' },
                ]
            }
        }

        function onDirectionChange(direction) {
            var systems = ['sourceSystem', 'targetSystem'];
            systems.forEach(function(systemType) {
                var system = vm.flow[systemType];
                system.externalEntityId = null;
                system.externalEntity = null;
                system.cifs.credentials.id = null;
                system.cifs.credentials.selection = null;
                system.cifs.credentials.sharedCredsName = null;
                system.sftpClient.credentials.id = null;
                system.sftpClient.credentials.selection = null;
                system.sftpClient.credentials.sharedCredsName = null;
                system.sftpHost.credentials.id = null;
                system.sftpHost.credentials.selection = null;
                system.sftpHost.credentials.sharedCredsName = null;
            });

            updateAvailableSystemTypes(direction);

            if (direction === 'INBOUND') {
                vm.flow.pgpEncrypt = false;

                vm.flow.sourceSystem.internalSystem = false;
                selectSystemType('sourceSystem', 'SFTP_CLIENT')
                vm.flow.sourceSystem.sftpClient.credentials.shared = false;
                vm.flow.sourceSystem.sftpHost.credentials.shared = false;
                vm.flow.sourceSystem.s3.credentials.shared = false;
                vm.flow.sourceSystem.sftpClient.credentials.allowShared = false;

                vm.flow.targetSystem.internalSystem = true;
                selectSystemType('targetSystem', 'CIFS');
                vm.flow.targetSystem.cifs.credentials.shared = true;
                vm.flow.targetSystem.sftpClient.credentials.shared = true;
                vm.flow.targetSystem.sftpHost.credentials.shared = true;
                vm.flow.targetSystem.s3.credentials.shared = true;
                vm.flow.targetSystem.sftpClient.credentials.allowShared = true;

            }
            else if (direction === 'OUTBOUND') {
                vm.flow.pgpDecrypt = false;

                vm.flow.sourceSystem.internalSystem = true;
                selectSystemType('sourceSystem', 'CIFS')
                vm.flow.sourceSystem.cifs.credentials.shared = true;
                vm.flow.sourceSystem.sftpClient.credentials.shared = true;
                vm.flow.sourceSystem.sftpHost.credentials.shared = true;
                vm.flow.sourceSystem.s3.credentials.shared = true;
                vm.flow.sourceSystem.sftpClient.credentials.allowShared = true;

                vm.flow.targetSystem.internalSystem = false;
                selectSystemType('targetSystem', 'SFTP_CLIENT');
                vm.flow.targetSystem.sftpClient.credentials.shared = false;
                vm.flow.targetSystem.sftpHost.credentials.shared = false;
                vm.flow.targetSystem.s3.credentials.shared = false;
                vm.flow.targetSystem.sftpClient.credentials.allowShared = false;
            }
            else { // internal
                vm.flow.pgpEncrypt = false;
                vm.flow.pgpDecrypt = false;

                vm.flow.sourceSystem.internalSystem = true;
                selectSystemType('sourceSystem', 'CIFS')
                vm.flow.sourceSystem.cifs.credentials.shared = true;
                vm.flow.sourceSystem.sftpClient.credentials.shared = true;
                vm.flow.sourceSystem.sftpHost.credentials.shared = true;
                vm.flow.sourceSystem.s3.credentials.shared = true;
                vm.flow.sourceSystem.sftpClient.credentials.allowShared = true;

                vm.flow.targetSystem.internalSystem = true;
                selectSystemType('targetSystem', 'CIFS');
                vm.flow.targetSystem.cifs.credentials.shared = true;
                vm.flow.targetSystem.sftpClient.credentials.shared = true;
                vm.flow.targetSystem.sftpHost.credentials.shared = true;
                vm.flow.targetSystem.s3.credentials.shared = true;
                vm.flow.targetSystem.sftpClient.credentials.allowShared = true;
            }
        }

        function selectSystemType(system, type) {
            for (var i=0; i<vm.flow[system].availableSystemTypes.length; i++) {
                var option = vm.flow[system].availableSystemTypes[i];
                if (option.value === type) {
                    vm.flow[system].systemType = type;
                    vm.flow[system].systemTypeSelection = option;
                }
            }
        }

        function loadSystemData(system, emptySystem) {
            var result = {
                label: system.label,
                internalSystem: system.internalSystem,
                systemType: system.systemType,
                externalEntityId: system.externalEntityId,
                externalEntity: system.externalEntity,
                technicalFirstName: system.technicalFirstName,
                technicalLastName: system.technicalLastName,
                technicalPhone: system.technicalPhone,
                technicalEmail: system.technicalEmail,
                contactFirstName: system.contactFirstName,
                contactLastName: system.contactLastName,
                contactPhone: system.contactPhone,
                contactEmail: system.contactEmail,
                pickupTrackingStrategy: system.pickupTrackingStrategy || 'FILENAME',
                maxRetries: system.maxRetries || 3,
                retryWaitDuration: system.retryWaitDuration || '30 min',
            }

            if (result.systemType === 'CIFS') {
                result.cifs = {
                    hostname: system.cifs.hostname,
                    shareName: system.cifs.shareName,
                    folderPath: system.cifs.folderPath,
                    sourceRecursive: system.cifs.sourceRecursive || false,
                    sourceFileRegexPattern: system.cifs.sourceFileRegexPattern,
                    sourceFolderRegexPattern: system.cifs.sourceFolderRegexPattern,
                    sourceFilePickupCompleteStrategy: system.cifs.sourceFilePickupCompleteStrategy || 'NOTHING',
                    sourceFilePickupMoveConflictStrategy: system.cifs.sourceFilePickupMoveConflictStrategy || 'REPLACE',
                    sourceIgnoreHiddenFiles: system.cifs.sourceIgnoreHiddenFiles !== false, // default true
                    sourceMoveShareName: system.cifs.sourceMoveShareName,
                    sourceMoveFolderPath: system.cifs.sourceMoveFolderPath,
                    targetDeliveryConflictStrategy: system.cifs.targetDeliveryConflictStrategy || 'REPLACE',
                    targetCreateMissingDirectories: system.cifs.targetCreateMissingDirectories || false,
                    targetMaximumFileCount: system.cifs.targetMaximumFileCount,
                    targetUpdateModifiedTime: system.cifs.targetUpdateModifiedTime || false,
                    sourceMinSize: system.cifs.sourceMinSize || '0 B',
                    sourceMaxSize: system.cifs.sourceMaxSize,
                    sourceMinAge: system.cifs.sourceMinAge || '1 min',
                    sourceMaxAge: system.cifs.sourceMaxAge,
                    targetFilename: system.cifs.targetFilename,
                    credentials: {
                        shared: system.cifs.credentials.shared,
                        id: system.cifs.credentials.id,
                        sharedCredsName: system.cifs.credentials.sharedCredsName,
                        domain: system.cifs.credentials.domain,
                        username: system.cifs.credentials.username,
                        password: system.cifs.credentials.passwordUsed ? PASSWORD_MASK_TEXT : "",
                    }
                }
            }
            else if (result.systemType === 'SFTP_CLIENT') {
                result.sftpClient = {
                    hostname: system.sftpClient.hostname,
                    port: system.sftpClient.port,
                    folderPath: system.sftpClient.folderPath,
                    sourceRecursive: system.sftpClient.sourceRecursive || false,
                    sourceFileRegexPattern: system.sftpClient.sourceFileRegexPattern,
                    sourceFolderRegexPattern: system.sftpClient.sourceFolderRegexPattern,
                    sourceFilePickupCompleteStrategy: system.sftpClient.sourceFilePickupCompleteStrategy || 'NOTHING',
                    sourceFilePickupMoveConflictStrategy: system.sftpClient.sourceFilePickupMoveConflictStrategy || 'REPLACE',
                    sourceIgnoreDottedFiles: system.sftpClient.sourceIgnoreDottedFiles !== false, // default true
                    sourceMoveFolderPath: system.sftpClient.sourceMoveFolderPath,
                    sourceSystemTimestampPrecision: system.sftpClient.sourceSystemTimestampPrecision || 'AUTO',
                    connectionTimeout: system.sftpClient.connectionTimeout || '60 sec',
                    dataTimeout: system.sftpClient.dataTimeout || '60 sec',
                    targetDeliveryConflictStrategy: system.sftpClient.targetDeliveryConflictStrategy || 'REPLACE',
                    targetCreateMissingDirectories: system.sftpClient.targetCreateMissingDirectories || false,
                    targetMaximumFileCount: system.sftpClient.targetMaximumFileCount,
                    targetUpdateModifiedTime: system.sftpClient.targetUpdateModifiedTime || false,
                    targetPermissions: system.sftpClient.targetPermissions,
                    targetOwner: system.sftpClient.targetOwner,
                    targetGroup: system.sftpClient.targetGroup,
                    targetBatchSize: system.sftpClient.targetBatchSize,
                    targetUseDotFileRename: system.sftpClient.targetUseDotFileRename || false,
                    targetTempFilename: system.sftpClient.targetTempFilename,
                    hostKeys: system.sftpClient.hostKeys,
                    useCompression: system.sftpClient.useCompression || false,
                    sourceMinSize: system.sftpClient.sourceMinSize || '0 B',
                    sourceMaxSize: system.sftpClient.sourceMaxSize,
                    sourceMinAge: system.sftpClient.sourceMinAge || '1 min',
                    sourceMaxAge: system.sftpClient.sourceMaxAge,
                    targetFilename: system.sftpClient.targetFilename,
                    credentials: {
                        shared: system.sftpClient.credentials.shared,
                        id: system.sftpClient.credentials.id,
                        sharedCredsName: system.sftpClient.credentials.sharedCredsName,
                        username: system.sftpClient.credentials.username,
                        password: system.sftpClient.credentials.password,
                        privateKey: system.sftpClient.credentials.privateKeyUsed ? PASSWORD_MASK_TEXT : "",
                        privateKeyPassword: null,
                    }
                }
            }
            else if (result.systemType === 'SFTP_CLIENT') {
                result.sftpHost = {
                    folderName: system.sftpHost.folderName,
                    credentials: {
                        shared: system.sftpHost.credentials.shared,
                        id: system.sftpHost.credentials.id,
                        sharedCredsName: system.sftpHost.credentials.sharedCredsName,
                        username: system.sftpHost.credentials.username,
                        password: system.sftpHost.credentials.password,
                        publicKey: system.sftpHost.credentials.publicKey,
                    }
                }
            }
            else if (result.systemType === 'S3') {
                result.s3 = {
                    bucket: system.s3.bucket,
                    region: system.s3.region || 'us-east-1',
                    communicationsTimeout: system.s3.communicationsTimeout || '60 sec',
                    prefix: system.s3.prefix,
                    sourceDelimiter: system.s3.sourceDelimiter,
                    sourceMinSize: system.s3.sourceMinSize || '0 B',
                    sourceMaxSize: system.s3.sourceMaxSize,
                    sourceMinAge: system.s3.sourceMinAge || '1 min',
                    sourceMaxAge: system.s3.sourceMaxAge,
                    sourceRecursive: system.s3.sourceRecursive || false,
                    sourceKeyRegexPattern: system.s3.sourceKeyRegexPattern,
                    sourcePrefixRegexPattern: system.s3.sourcePrefixRegexPattern,
                    sourceFilePickupCompleteStrategy: system.s3.sourceFilePickupCompleteStrategy || 'NOTHING',
                    sourceMovePrefix: system.s3.sourceMovePrefix,
                    getTags: system.s3.getTags,
                    ignoreTags: system.s3.ignoreTags,
                    putTags: system.s3.putTags,
                    deleteTags: system.s3.deleteTags.sort(),
                    storageClass: system.s3.storageClass || 'STANDARD',
                    targetObjectName: system.s3.targetObjectName,
                    multipartThreshold: system.s3.multipartThreshold || '5 GB',
                    multipartPartSize: system.s3.multipartPartSize || '5 GB',
                    targetDeliveryConflictStrategy: system.s3.targetDeliveryConflictStrategy || 'REPLACE',
                    credentials: {
                        shared: system.s3.credentials.shared,
                        id: system.s3.credentials.id,
                        accessKey: system.s3.accessKey,
                        secretKey: system.s3.secretKeyUsed ? PASSWORD_MASK_TEXT : "",
                    }
                }
            }

            result.cifs = result.cifs || emptySystem.cifs;
            result.sftpClient = result.sftpClient || emptySystem.sftpClient;
            result.sftpHost = result.sftpHost || emptySystem.sftpHost;
            result.s3 = result.s3 || emptySystem.s3;

            return result;
        }

        function createEmptyFlow() {
            vm.flow = {
                pgpEncrypt: false,
                pgpDecrypt: false,
                direction: 'OUTBOUND',
                status: 'PENDING',
                sharedPgpDecryptKey: false,
                sourceSystem: {
                    internalSystem: true,
                    systemType: 'CIFS',
                    pickupTrackingStrategy: 'FILENAME',
                    maxRetries: 3,
                    retryWaitDuration: '30 min',
                    cifs: {
                        sourceRecursive: false,
                        sourceFilePickupCompleteStrategy: 'NOTHING',
                        sourceFilePickupMoveConflictStrategy: 'REPLACE',
                        sourceIgnoreHiddenFiles: true,
                        sourceMinSize: '0 B',
                        sourceMinAge: '1 min',
                        credentials: {
                            shared: true,
                            allowShared: true
                        }
                    },
                    sftpClient: {
                        sourceRecursive: false,
                        sourceFilePickupCompleteStrategy: 'NOTHING',
                        sourceFilePickupMoveConflictStrategy: 'REPLACE',
                        sourceIgnoreDottedFiles: true,
                        sourceSystemTimestampPrecision: 'AUTO',
                        connectionTimeout: '60 sec',
                        dataTimeout: '60 sec',
                        useCompression: false,
                        sourceMinSize: '0 B',
                        sourceMinAge: '1 min',
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                    sftpHost: {
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                    s3: {
                        communicationsTimeout: '60 sec',
                        sourceRecursive: false,
                        sourceFilePickupCompleteStrategy: 'NOTHING',
                        sourceMinSize: '0 B',
                        sourceMinAge: '1 min',
                        region: 'us-east-1',
                        storageClass: 'STANDARD',
                        multipartThreshold: '5 GB',
                        multipartPartSize: '5 GB',
                        getTags: {},
                        ignoreTags: {},
                        putTags: {},
                        deleteTags: [],
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                },
                targetSystem: {
                    internalSystem: false,
                    systemType: 'SFTP_CLIENT',
                    cifs: {
                        sourceRecursive: false,
                        targetDeliveryConflictStrategy: 'REPLACE',
                        targetCreateMissingDirectories: false,
                        targetUpdateModifiedTime: false,
                        credentials: {
                            shared: true,
                            allowShared: true
                        }
                    },
                    sftpClient: {
                        sourceRecursive: false,
                        targetDeliveryConflictStrategy: 'REPLACE',
                        targetCreateMissingDirectories: false,
                        targetUpdateModifiedTime: false,
                        targetUseDotFileRename: false,
                        useCompression: false,
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                    sftpHost: {
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                    s3: {
                        communicationsTimeout: '60 sec',
                        region: 'us-east-1',
                        storageClass: 'STANDARD',
                        multipartThreshold: '5 GB',
                        multipartPartSize: '5 GB',
                        targetDeliveryConflictStrategy: 'REPLACE',
                        getTags: {},
                        ignoreTags: {},
                        putTags: {},
                        deleteTags: [],
                        credentials: {
                            shared: false,
                            allowShared: true
                        }
                    },
                },
            }
        }

        // helper function to load form data for editing
        function editFlow(flow) {
            if (vm.flowForm)
                vm.flowForm.$setPristine();

            createEmptyFlow();

            var result = {
                id: flow.id,
                label: flow.label,
                direction: flow.direction,
                status: flow.status,
                transferFrequency: flow.transferFrequency,
                originPointOfData: flow.originPointOfData,
                notes: flow.notes,
                pgpEncrypt: flow.pgpEncrypt,
                pgpEncryptPublicKey: flow.pgpEncryptPublicKeyUsed ? PASSWORD_MASK_TEXT : "",
                pgpEncryptRecipient: flow.pgpEncryptRecipient,
                pgpDecrypt: flow.pgpDecrypt,
                sharedPgpDecryptKey: flow.pgpDecryptKeys && flow.pgpDecryptKeys.shared ? true : false,
                flowPgpDecryptKeyId: flow.pgpDecryptKeys && flow.pgpDecryptKeys.id,
                sharedPgpDecryptKeyValue: flow.pgpDecryptKeys && flow.pgpDecryptKeys.sharedKeyName,
                pgpDecryptPrivateKey: flow.pgpDecryptKeys && flow.pgpDecryptKeys.privateKeyUsed ? PASSWORD_MASK_TEXT : "",
                pgpDecryptPrivateKeyPassword: null,
                pgpDecryptPublicKey: flow.pgpDecryptKeys && flow.pgpDecryptKeys.publicKey,
            }

            result.sourceSystem = loadSystemData(flow.sourceSystem, vm.flow.sourceSystem);
            result.targetSystem = loadSystemData(flow.targetSystem, vm.flow.targetSystem);

            vm.flow = result;

            // initialize direction change values
            updateAvailableSystemTypes(vm.flow.direction);

            // select the system type
            var systems = ['sourceSystem', 'targetSystem'];
            systems.forEach(function(system) {
                for (var i=0; i<vm.flow[system].availableSystemTypes.length; i++) {
                    var type = vm.flow[system].availableSystemTypes[i];
                    if (type.value === vm.flow[system].systemType) {
                        vm.flow[system].systemTypeSelection = type;
                        break;
                    }
                }
            });
        }
        function externalEntityAutoComplete(text, systemType) {
            return {
                minimumChars: 1,
                pagingEnabled: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    ExternalEntity.queryForAutocomplete({name: searchText, page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['name']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.name,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.name'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm.flow[systemType].externalEntitySelection = selection.item;
                        vm.flow[systemType].externalEntityId = selection.item.id;
                    }
                }
            }
        }
        function externalEntityAutoCompleteBlur(systemType) {
            if (vm.flow[systemType].externalEntitySelection) {
                vm.flow[systemType].externalEntityId = vm.flow[systemType].externalEntitySelection.id;
            }
            else if (vm.flowForm[systemType + 'ExternalEntity'].$dirty) {
                vm.flow[systemType].externalEntity = "";
                vm.flow[systemType].externalEntityId = null;
            }
        }
        function externalEntityAutoCompleteChange(systemType) {
            vm.flowForm[systemType + 'ExternalEntity'].$setDirty();
            vm.flow[systemType].externalEntityId = null;
        }

        function cifsCredsAutoComplete(text, systemType) {
            return {
                minimumChars: 0,
                pagingEnabled: true,
                activateOnFocus: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    DataCenterAdmin.queryReusuableCifsCredentialsForAutocomplete({label: searchText, page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['label']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.label,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.label'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm.flow[systemType].cifs.credentials.selection = selection.item;
                        vm.flow[systemType].cifs.credentials.id = selection.item.id;
                    }
                }
            }
        }
        function cifsCredsAutoCompleteBlur(systemType) {
            if (vm.flow[systemType].cifs.credentials.selection)
                vm.flow[systemType].cifs.credentials.id = vm.flow[systemType].cifs.credentials.selection.id;
            else if (vm.flowForm[systemType + 'CifsCreds'].$dirty) {
                vm.flow[systemType].cifs.credentials.sharedCredsName = "";
                vm.flow[systemType].cifs.credentials.id = null;
            }
        }
        function cifsCredsAutoCompleteChange(systemType) {
            vm.flowForm[systemType + 'CifsCreds'].$setDirty();
            vm.flow[systemType].cifs.credentials.id = null;
        }


        function sftpClientCredsAutoComplete(text, systemType) {
            return {
                minimumChars: 0,
                pagingEnabled: true,
                activateOnFocus: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    DataCenterAdmin.queryReusuableSftpClientCredentialsForAutocomplete({label: searchText, page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['label']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.label,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.label'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm.flow[systemType].sftpClient.credentials.selection = selection.item;
                        vm.flow[systemType].sftpClient.credentials.id = selection.item.id;
                    }
                }
            }
        }
        function sftpClientCredsAutoCompleteBlur(systemType) {
            if (vm.flow[systemType].sftpClient.credentials.selection)
                vm.flow[systemType].sftpClient.credentials.id = vm.flow[systemType].sftpClient.credentials.selection.id;
            else if (vm.flowForm[systemType + 'SftpClientCreds'].$dirty) {
                vm.flow[systemType].sftpClient.credentials.sharedCredsName = "";
                vm.flow[systemType].sftpClient.credentials.id = null;
            }
        }
        function sftpClientCredsAutoCompleteChange(systemType) {
            vm.flowForm[systemType + 'SftpClientCreds'].$setDirty();
            vm.flow[systemType].sftpClient.credentials.id = null;
        }


        function sftpHostCredsAutoComplete(text, systemType) {
            return {
                minimumChars: 0,
                pagingEnabled: true,
                activateOnFocus: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    DataCenterAdmin.queryReusuableSftpHostCredentialsForAutocomplete({label: searchText, externalEntityId: vm.flow[systemType].externalEntityId,
                            page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['label']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.label,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.label'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm.flow[systemType].sftpHost.credentials.selection = selection.item;
                        vm.flow[systemType].sftpHost.credentials.id = selection.item.id;
                    }
                }
            }
        }
        function sftpHostCredsAutoCompleteBlur(systemType) {
            if (vm.flow[systemType].sftpHost.credentials.selection)
                vm.flow[systemType].sftpHost.credentials.id = vm.flow[systemType].sftpHost.credentials.selection.id;
            else if (vm.flowForm[systemType + 'SftpHostCreds'].$dirty) {
                vm.flow[systemType].sftpHost.credentials.sharedCredsName = "";
                vm.flow[systemType].sftpHost.credentials.id = null;
            }
        }
        function sftpHostCredsAutoCompleteChange(systemType) {
            vm.flowForm[systemType + 'SftpHostCreds'].$setDirty();
            vm.flow[systemType].sftpHost.credentials.id = null;
        }


        function s3CredsAutoComplete(text, systemType) {
            return {
                minimumChars: 0,
                pagingEnabled: true,
                activateOnFocus: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    DataCenterAdmin.queryReusuableS3CredentialsForAutocomplete({label: searchText, externalEntityId: vm.flow[systemType].externalEntityId,
                            page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['label']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.label,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.label'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm.flow[systemType].s3.credentials.selection = selection.item;
                        vm.flow[systemType].s3.credentials.id = selection.item.id;
                    }
                }
            }
        }
        function s3CredsAutoCompleteBlur(systemType) {
            if (vm.flow[systemType].s3.credentials.selection)
                vm.flow[systemType].s3.credentials.id = vm.flow[systemType].s3.credentials.selection.id;
            else if (vm.flowForm[systemType + 'S3Creds'].$dirty) {
                vm.flow[systemType].s3.credentials.sharedCredsName = "";
                vm.flow[systemType].s3.credentials.id = null;
            }
        }
        function s3CredsAutoCompleteChange(systemType) {
            vm.flowForm[systemType + 'S3Creds'].$setDirty();
            vm.flow[systemType].s3.credentials.id = null;
        }


        vm.flowPgpDecryptKeyAutoComplete=  {
            minimumChars: 0,
            pagingEnabled: true,
            activateOnFocus: true,
            data: function (searchText, pagingParams) {
                var deferred = $q.defer();

                DataCenterAdmin.queryReusuableFlowPgpDecryptKeysForAutocomplete({label: searchText,
                        page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['label']},
                    function(result, headers) {
                        deferred.resolve(result);
                    }
                );

                return deferred.promise;
            },
            renderItem: function (item) {
                return {
                    value: item.label,
                    label: "<p class='auto-complete' ng-bind-html='entry.item.label'></p>"
                };
            },
            itemSelected: function (selection) {
                if (selection) {
                    vm.flow.flowPgpDecryptKeyAutoCompleteSelection = selection.item;
                    vm.flow.flowPgpDecryptKeyId = selection.item.id;
                }
            }
        }
        function flowPgpDecryptKeyAutoCompleteBlur() {
            if (vm.flow.flowPgpDecryptKeyAutoCompleteSelection)
                vm.flow.flowPgpDecryptKeyId = vm.flow.flowPgpDecryptKeyAutoCompleteSelection;
            else if (vm.flowForm.sharedPgpDecryptKeyValue.$dirty) {
                vm.flow.sharedPgpDecryptKeyValue =  null;
                vm.flow.flowPgpDecryptKeyId = null;
            }
        }
        function flowPgpDecryptKeyAutoCompleteChange() {
            vm.flowForm.sharedPgpDecryptKeyValue.$setDirty();
            vm.flow.flowPgpDecryptKeyId = null;
        }


        function onSystemTabClick(tab) {
            if (tab === 'sourceSystem') {
                vm.tab='sourceSystem'
            }
            else {
                vm.tab='targetSystem'
            }

            $scope.$broadcast('dcFlowUpdate');
        }

        function navFlows() {
            $state.go('dc-flows');
        }

        function saveFlowError(error) {
            if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.flowExists'){
                GlobalModal.displayError('Flow with this label already exist in system');
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyInvalid'){
                GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                vm.tab = 'pgp';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyPasswordInvalid'){
                GlobalModal.displayError('Private key passphrase is incorrect.');
                vm.tab = 'pgp';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.publicKeyInvalid') {
                GlobalModal.displayError('Public key is invalid');
                vm.tab = 'pgp';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.externalEntityNotFound'){
                GlobalModal.displayError('External entity does not exist in system');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.cifsCredentialsNotFound'){
                GlobalModal.displayError('Shared CIFS credentials not found');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.sftpClientCredentialsNotFound'){
                GlobalModal.displayError('Shared SFTP Client credentials not found');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.sftpHostCredentialsNotFound'){
                GlobalModal.displayError('Shared SFTP Host credentials not found');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3CredentialsNotFound'){
                GlobalModal.displayError('Shared S3 credentials not found');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.privateKeyInvalid'){
                GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.privateKeyPasswordInvalid'){
                GlobalModal.displayError('Private key passphrase is incorrect.');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.publicKeyInvalid') {
                GlobalModal.displayError('Public key is invalid');
                vm.tab = 'sourceSystem';
            }

            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.cifs.invalidSourceMoveFolderExpression') {
                GlobalModal.displayError('Move Folder expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.cifs.invalidTargetFilenameExpression') {
                GlobalModal.displayError('Filename expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.cifs.invalidFolderPathExpression') {
                GlobalModal.displayError('Folder expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3Client.invalidSourceMoveFolderExpression') {
                GlobalModal.displayError('Move Folder expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3Client.invalidTargetFilenameExpression') {
                GlobalModal.displayError('Filename expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3Client.invalidFolderPathExpression') {
                GlobalModal.displayError('Folder expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3Client.invalidTargetTempFilenameExpression') {
                GlobalModal.displayError('Temporary Filename expression is invalid');
                vm.tab = 'sourceSystem';
                showSftpClientAdvanced();
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3.invalidSourceMovePrefixExpression') {
                GlobalModal.displayError('Move Prefix expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3.invalidTargetObjectNameExpression') {
                GlobalModal.displayError('Object Name expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3.invalidPrefixExpression') {
                GlobalModal.displayError('Prefix expression is invalid');
                vm.tab = 'sourceSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3.invalidTagNameExpression') {
                GlobalModal.displayError('A tag name expression is invalid');
                vm.tab = 'sourceSystem';
                showSourceS3Advanced();
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.sourceSystem.s3.invalidTagValueExpression') {
                GlobalModal.displayError('A tag value expression is invalid');
                vm.tab = 'sourceSystem';
                showSourceS3Advanced();
            }

            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.externalEntityNotFound'){
                GlobalModal.displayError('External entity does not exist in system');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.cifsCredentialsNotFound'){
                GlobalModal.displayError('Shared CIFS credentials not found');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.sftpClientCredentialsNotFound'){
                GlobalModal.displayError('Shared SFTP Client credentials not found');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.sftpHostCredentialsNotFound'){
                GlobalModal.displayError('Shared SFTP Host credentials not found');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3CredentialsNotFound'){
                GlobalModal.displayError('Shared S3 credentials not found');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.privateKeyInvalid'){
                GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.privateKeyPasswordInvalid'){
                GlobalModal.displayError('Private key passphrase is incorrect.');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.publicKeyInvalid') {
                GlobalModal.displayError('Public key is invalid');
                vm.tab = 'targetSystem';
            }

            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.cifs.invalidSourceMoveFolderExpression') {
                GlobalModal.displayError('Move Folder expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.cifs.invalidTargetFilenameExpression') {
                GlobalModal.displayError('Filename expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.cifs.invalidFolderPathExpression') {
                GlobalModal.displayError('Folder expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3Client.invalidSourceMoveFolderExpression') {
                GlobalModal.displayError('Move Folder expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3Client.invalidTargetFilenameExpression') {
                GlobalModal.displayError('Filename expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3Client.invalidFolderPathExpression') {
                GlobalModal.displayError('Folder expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3Client.invalidTargetTempFilenameExpression') {
                GlobalModal.displayError('Temporary Filename expression is invalid');
                vm.tab = 'targetSystem';
                showTargetSftpClientAdvanced();
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3.invalidSourceMovePrefixExpression') {
                GlobalModal.displayError('Move Prefix expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3.invalidTargetObjectNameExpression') {
                GlobalModal.displayError('Object Name expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3.invalidPrefixExpression') {
                GlobalModal.displayError('Prefix expression is invalid');
                vm.tab = 'targetSystem';
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3.invalidTagNameExpression') {
                GlobalModal.displayError('A tag name expression is invalid');
                vm.tab = 'targetSystem';
                showTargetS3Advanced();
            }
            else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.targetSystem.s3.invalidTagValueExpression') {
                GlobalModal.displayError('A tag value expression is invalid');
                vm.tab = 'targetSystem';
                showTargetS3Advanced();
            }

            else {
                GlobalModal.displayError('Unable to save flow. Please try again.');
            }
        }

        function saveFlow() {
            if (vm.flow) {
                vm.flowForm.$setSubmitted();

                // make sure form is valid before continuing
                if(!vm.flowForm.$valid) {
                    // jump to first tab with issue
                    if (vm.flowForm.label.$invalid) {
                        vm.tab = 'configuration';
                        return;
                    }

                    // PGP Encrypt
                    if (vm.flow.pgpEncrypt) {
                        var fields = ['pgpPublicKey', 'pgpEncryptRecipient']

                        var fieldInvalid = false;
                        fields.forEach(function(field) {
                            if (fieldInvalid) return;
                            if (vm.flowForm[field].$invalid) {
                                vm.tab = 'pgp';
                                fieldInvalid = true;
                                return;
                            }
                        });
                        if (fieldInvalid) return;
                    }

                    // PGP Decrypt
                    if (vm.flow.pgpDecrypt) {
                        var fields = [];

                        if (vm.flow.sharedPgpDecryptKey) {
                            fields.push('sharedPgpDecryptKeyValue');
                        }
                        else {
                            fields.push('pgpDecryptPrivateKey');
                            fields.push('pgpDecryptPublicKey');
                        }

                        var fieldInvalid = false;
                        fields.forEach(function(field) {
                            if (fieldInvalid) return;
                            if (vm.flowForm[field].$invalid) {
                                vm.tab = 'pgp';
                                fieldInvalid = true;
                                return;
                            }
                        });
                        if (fieldInvalid) return;
                    }

                    // jump to source system tab with issue
                    var fields = ['sourceSystemLabel', 'sourceSystemSystemType', 'sourceSystemMaxRetries', 'sourceSystemSftpClientMaxAge']
                    if (!vm.flow.sourceSystem.internalSystem) fields.push('sourceSystemExternalEntity');

                    // CIFS section
                    if (vm.flow.sourceSystem.systemType === 'CIFS') {
                        if (vm.flow.sourceSystem.cifs.credentials.shared)
                            fields.push('sourceSystemCifsCreds');

                        fields.push('sourceSystemCifsHost');
                        fields.push('sourceSystemCifsShare');
                        fields.push('sourceSystemCifsFolderPath');

                        if (!vm.flow.sourceSystem.cifs.credentials.shared) {
                            fields.push('sourceSystemCifsDomain');
                            fields.push('sourceSystemCifsUsername');
                            fields.push('sourceSystemCifsPass');
                        }

                        fields.push('sourceSystemCifsPickupStrategy');
                        if (vm.flow.sourceSystem.cifs.sourceFilePickupCompleteStrategy === 'MOVE') {
                            fields.push('sourceSystemCifsMoveFolderPath');
                        }
                    }

                    // SFTP Client section
                    if (vm.flow.sourceSystem.systemType === 'SFTP_CLIENT') {
                        if (vm.flow.sourceSystem.sftpClient.credentials.shared)
                            fields.push('sourceSystemSftpClientCreds');

                        fields.push('sourceSystemSftpClientHost');
                        fields.push('sourceSystemSftpClientPort');
                        fields.push('sourceSystemSftpClientFolderPath');

                        if (!vm.flow.sourceSystem.sftpClient.credentials.shared)
                            fields.push('sourceSystemSftpClientUsername');

                        fields.push('sourceSystemSftpClientPickupStrategy');
                        if (vm.flow.sourceSystem.cifs.sourceFilePickupCompleteStrategy === 'MOVE') {
                            fields.push('sourceSystemSftpClientMoveFolderPath');
                        }
                    }

                    // SFTP Host section
                    if (vm.flow.sourceSystem.systemType === 'SFTP_HOST') {
                        if (vm.flow.sourceSystem.sftpHost.credentials.shared)
                            fields.push('sourceSystemSftpHostCreds');

                        fields.push('sourceSystemSftpHostFolderName');

                        if (!vm.flow.sourceSystem.sftpHost.credentials.shared)
                            fields.push('sourceSystemSftpHostUsername');
                    }

                    // S3 section
                    if (vm.flow.sourceSystem.systemType === 'S3') {
                        if (vm.flow.sourceSystem.s3.credentials.shared)
                            fields.push('sourceSystemS3Creds');

                        fields.push('sourceSystemS3Bucket');
                        fields.push('sourceSystemS3Region');
                        fields.push('sourceSystemS3Prefix');

                        fields.push('sourceSystemS3PickupStrategy');
                        if (vm.flow.sourceSystem.cifs.sourceFilePickupCompleteStrategy === 'MOVE') {
                            fields.push('sourceSystemS3MovePrefix');
                        }
                    }

                    var fieldInvalid = false;
                    fields.forEach(function(field) {
                        if (fieldInvalid) return;
                        if (vm.flowForm[field].$invalid) {
                            vm.tab = 'sourceSystem';
                            fieldInvalid = true;
                            return;
                        }
                    });
                    if (fieldInvalid) return;



                    // jump to target system tab with issue
                    var fields = ['targetSystemLabel', 'targetSystemSystemType', 'targetSystemMaxRetries', 'targetSystemSftpClientMaxAge']
                    if (!vm.flow.targetSystem.internalSystem) fields.push('targetSystemExternalEntity');

                    // CIFS section
                    if (vm.flow.targetSystem.systemType === 'CIFS') {
                        if (vm.flow.targetSystem.cifs.credentials.shared)
                            fields.push('targetSystemCifsCreds');

                        fields.push('targetSystemCifsHost');
                        fields.push('targetSystemCifsShare');
                        fields.push('targetSystemCifsFolderPath');

                        if (!vm.flow.targetSystem.cifs.credentials.shared) {
                            fields.push('targetSystemCifsDomain');
                            fields.push('targetSystemCifsUsername');
                            fields.push('targetSystemCifsPass');
                        }

                        fields.push('targetSystemCifsConflictStrategy');
                    }

                    // SFTP Client section
                    if (vm.flow.targetSystem.systemType === 'SFTP_CLIENT') {
                        if (vm.flow.targetSystem.sftpClient.credentials.shared)
                            fields.push('targetSystemSftpClientCreds');

                        fields.push('targetSystemSftpClientHost');
                        fields.push('targetSystemSftpClientPort');
                        fields.push('targetSystemSftpClientFolderPath');

                        if (!vm.flow.targetSystem.sftpClient.credentials.shared)
                            fields.push('targetSystemSftpClientUsername');

                        fields.push('targetSystemSftpClientConflictStrategy');
                    }

                    // SFTP Host section
                    if (vm.flow.targetSystem.systemType === 'SFTP_HOST') {
                        if (vm.flow.targetSystem.sftpHost.credentials.shared)
                            fields.push('targetSystemSftpHostCreds');

                        fields.push('targetSystemSftpHostFolderName');

                        if (!vm.flow.targetSystem.sftpHost.credentials.shared)
                            fields.push('targetSystemSftpHostUsername');
                    }

                    // S3 section
                    if (vm.flow.targetSystem.systemType === 'S3') {
                        if (vm.flow.targetSystem.s3.credentials.shared)
                            fields.push('targetSystemS3Creds');

                        fields.push('targetSystemS3Bucket');
                        fields.push('targetSystemS3Region');
                        fields.push('targetSystemS3Prefix');
                        fields.push('targetSystemS3ConflictStrategy');

                    }

                    var fieldInvalid = false;
                    fields.forEach(function(field) {
                        if (fieldInvalid) return;
                        if (vm.flowForm[field].$invalid) {
                            vm.tab = 'targetSystem';
                            fieldInvalid = true;
                            return;
                        }
                    });
                    if (fieldInvalid) return;


                    // source CIFS advanced
                    if (vm.flow.sourceSystem.systemType === 'CIFS') {
                        if (vm.flowForm.sourceSystemCifsMinSize.$invalid ||
                            vm.flowForm.sourceSystemCifsMaxSize.$invalid ||
                            vm.flowForm.sourceSystemCifsMinAge.$invalid ||
                            vm.flowForm.sourceSystemCifsMaxAge.$invalid)
                        {
                            vm.tab = 'sourceSystem';
                            showCifsAdvanced();
                            return;
                        }
                    }

                    // source SFTP Client advanced
                    if (vm.flow.sourceSystem.systemType === 'SFTP_CLIENT') {
                        if (vm.flowForm.sourceSystemSftpConnectionTimeout.$invalid ||
                            vm.flowForm.sourceSystemSftpDataTimeout.$invalid ||
                            vm.flowForm.sourceSystemSftpClientMinSize.$invalid ||
                            vm.flowForm.sourceSystemSftpClientMaxSize.$invalid ||
                            vm.flowForm.sourceSystemSftpClientMinAge.$invalid ||
                            vm.flowForm.sourceSystemSftpClientMaxAge.$invalid)
                        {
                            vm.tab = 'sourceSystem';
                            showSftpClientAdvanced();
                            return;
                        }
                    }

                    // source S3 advanced
                    if (vm.flow.sourceSystem.systemType === 'S3') {
                        if (vm.flowForm.sourceSystemS3CommunicationsTimeout.$invalid ||
                            vm.flowForm.sourceSystemS3MinSize.$invalid ||
                            vm.flowForm.sourceSystemS3MaxSize.$invalid ||
                            vm.flowForm.sourceSystemS3MinAge.$invalid ||
                            vm.flowForm.sourceSystemS3MaxAge.$invalid ||
                            vm.flowForm.sourceSystemS3MultipartThreshold.$invalid ||
                            vm.flowForm.sourceSystemS3MultipartPartSize.$invalid)
                        {
                            vm.tab = 'sourceSystem';
                            showSourceS3Advanced();
                            return;
                        }
                    }

                    // target SFTP Client advanced
                    if (vm.flow.targetSystem.systemType === 'SFTP_CLIENT') {
                        if (vm.flowForm.targetSystemSftpConnectionTimeout.$invalid ||
                            vm.flowForm.targetSystemSftpDataTimeout.$invalid)
                        {
                            vm.tab = 'targetSystem';
                            showTargetSftpClientAdvanced();
                            return;
                        }
                    }

                    // target S3 advanced
                    if (vm.flow.targetSystem.systemType === 'S3') {
                        if (vm.flowForm.targetSystemS3CommunicationsTimeout.$invalid ||
                            vm.flowForm.targetSystemS3MultipartThreshold.$invalid ||
                            vm.flowForm.targetSystemS3MultipartPartSize.$invalid)
                        {
                            vm.tab = 'targetSystem';
                            showTargetS3Advanced();
                            return;
                        }
                    }

                    // sanity check for hidden invalid field
                    var keys = Object.keys(vm.flowForm);
                    for (var i=0; i<keys.length; i++) {
                        var k = keys[i];
                        var f = vm.flowForm[k];
                        if (f && f.$invalid)
                            console.log('Invalid Field', k);
                    }

                    return;
                }

                // validate SFTP credentials are valid
                var systems = ['sourceSystem', 'targetSystem'];
                var systemFailed = false;
                systems.forEach(function(system) {
                    if (systemFailed) return;
                    // TODO: validate values supplied when editing and switching type
                    if (vm.flow[system].systemType === 'SFTP_CLIENT') {
                        if (!vm.flow[system].sftpClient.credentials.shared && vm.flowForm[system + 'SftpClientPassword']) {  // check embedded credentials
                            if ((!vm.flow.id || !vm.flowForm[system + 'SftpClientPassword'].$pristine || !vm.flowForm[system + 'SftpClientPrivateKey'].$pristine)
                                && !vm.flow[system].sftpClient.credentials.password && !vm.flow[system].sftpClient.credentials.privateKey)
                            {
                                GlobalModal.displayError('Please supply either an SFTP client password or private key');
                                vm.tab = system;
                                systemFailed = true;
                                return;
                            }
                        }
                    }
                    else if (vm.flow[system].systemType === 'SFTP_HOST') {
                        if (!vm.flow[system].sftpHost.credentials.shared && vm.flowForm[system + 'SftpHostPassword']) {  // check embedded credentials
                            if ((!vm.flow.id || !vm.flowForm[system + 'SftpHostPassword'].$pristine || !vm.flowForm[system + 'SftpHostPublicKey'].$pristine)
                                && !vm.flow[system].sftpHost.credentials.password && !vm.flow[system].sftpHost.credentials.publicKey)
                            {
                                GlobalModal.displayError('Please supply either an SFTP host password or public key');
                                vm.tab = system;
                                systemFailed = true;
                                return;
                            }
                        }
                    }
                });

                if (systemFailed) return;


                // target SFTP Client advanced
                if (vm.flow.targetSystem.systemType === 'SFTP_CLIENT') {
                    if (vm.flow.targetSystem.sftpClient.targetUseDotFileRename && vm.flow.targetSystem.sftpClient.targetTempFilename) {
                        GlobalModal.displayError('Please use either dot file rename or a temporary file name, not both');
                        vm.tab = 'targetSystem';
                        showSftpHostAdvanced();
                        return;
                    }
                }

                // build object to send to server
                var flow = {
                    id: vm.flow.id,
                    label: vm.flow.label,
                    direction: vm.flow.direction,
                    status: vm.flow.status,
                    transferFrequency: vm.flow.transferFrequency,
                    originPointOfData: vm.flow.originPointOfData,
                    notes: vm.flow.notes,
                    pgpEncrypt: vm.flow.pgpEncrypt,
                    pgpEncryptPublicKey: vm.flow.pgpEncrypt ? vm.flow.pgpPublicKey : null,
                    pgpEncryptRecipient: vm.flow.pgpEncrypt ? vm.flow.pgpEncryptRecipient : null,
                    pgpDecrypt: vm.flow.pgpDecrypt,
                    pgpDecryptKeys: {
                        shared: vm.flow.sharedPgpDecryptKey,
                        id: vm.flow.flowPgpDecryptKeyId,
                        privateKey: vm.flowForm.pgpDecryptPrivateKey && vm.flowForm.pgpDecryptPrivateKey.$dirty ? vm.flow.pgpDecryptPrivateKey : null,
                        privateKeyPassword: vm.flow.pgpDecryptPrivateKeyPassword,
                        publicKey: vm.flow.pgpDecryptPublicKey,
                    },
                    sourceSystem: {
                        label: vm.flow.sourceSystem.label,
                        internalSystem: vm.flow.sourceSystem.internalSystem,
                        systemType: vm.flow.sourceSystem.systemType,
                        externalEntityId: vm.flow.sourceSystem.externalEntityId,
                        technicalFirstName: vm.flow.sourceSystem.technicalFirstName,
                        technicalLastName: vm.flow.sourceSystem.technicalLastName,
                        technicalPhone: vm.flow.sourceSystem.technicalPhone,
                        technicalEmail: vm.flow.sourceSystem.technicalEmail,
                        contactFirstName: vm.flow.sourceSystem.contactFirstName,
                        contactLastName: vm.flow.sourceSystem.contactLastName,
                        contactPhone: vm.flow.sourceSystem.contactPhone,
                        contactEmail: vm.flow.sourceSystem.contactEmail,
                        pickupTrackingStrategy: vm.flow.sourceSystem.pickupTrackingStrategy,
                        maxRetries: vm.flow.sourceSystem.maxRetries,
                        retryWaitDuration: vm.flow.sourceSystem.retryWaitDuration,
                        cifs: {
                            hostname: vm.flow.sourceSystem.cifs.hostname,
                            shareName: vm.flow.sourceSystem.cifs.shareName,
                            folderPath: vm.flow.sourceSystem.cifs.folderPath,
                            sourceRecursive: vm.flow.sourceSystem.cifs.sourceRecursive,
                            sourceFileRegexPattern: vm.flow.sourceSystem.cifs.sourceFileRegexPattern,
                            sourceFolderRegexPattern: vm.flow.sourceSystem.cifs.sourceFolderRegexPattern,
                            sourceFilePickupCompleteStrategy: vm.flow.sourceSystem.cifs.sourceFilePickupCompleteStrategy,
                            sourceFilePickupMoveConflictStrategy: vm.flow.sourceSystem.cifs.sourceFilePickupMoveConflictStrategy,
                            sourceIgnoreHiddenFiles: vm.flow.sourceSystem.cifs.sourceIgnoreHiddenFiles,
                            sourceMoveShareName: vm.flow.sourceSystem.cifs.sourceMoveShareName,
                            sourceMoveFolderPath: vm.flow.sourceSystem.cifs.sourceMoveFolderPath,
                            sourceMinSize: vm.flow.sourceSystem.cifs.sourceMinSize,
                            sourceMaxSize: vm.flow.sourceSystem.cifs.sourceMaxSize,
                            sourceMinAge: vm.flow.sourceSystem.cifs.sourceMinAge,
                            sourceMaxAge: vm.flow.sourceSystem.cifs.sourceMaxAge,
                            targetFilename: vm.flow.sourceSystem.cifs.targetFilename,
                            credentials: {
                                shared: vm.flow.sourceSystem.cifs.credentials.shared,
                                id: vm.flow.sourceSystem.cifs.credentials.id,
                                domain: vm.flow.sourceSystem.cifs.credentials.domain,
                                username: vm.flow.sourceSystem.cifs.credentials.username,
                                password: vm.flowForm.sourceSystemCifsPass && vm.flowForm.sourceSystemCifsPass.$dirty ? vm.flow.sourceSystem.cifs.credentials.password : null,
                            }
                        },
                        sftpClient: {
                            hostname: vm.flow.sourceSystem.sftpClient.hostname,
                            port: vm.flow.sourceSystem.sftpClient.port,
                            folderPath: vm.flow.sourceSystem.sftpClient.folderPath,
                            sourceRecursive: vm.flow.sourceSystem.sftpClient.sourceRecursive,
                            sourceFileRegexPattern: vm.flow.sourceSystem.sftpClient.sourceFileRegexPattern,
                            sourceFolderRegexPattern: vm.flow.sourceSystem.sftpClient.sourceFolderRegexPattern,
                            sourceFilePickupCompleteStrategy: vm.flow.sourceSystem.sftpClient.sourceFilePickupCompleteStrategy,
                            sourceFilePickupMoveConflictStrategy: vm.flow.sourceSystem.sftpClient.sourceFilePickupMoveConflictStrategy,
                            sourceIgnoreDottedFiles: vm.flow.sourceSystem.sftpClient.sourceIgnoreDottedFiles,
                            sourceMoveFolderPath: vm.flow.sourceSystem.sftpClient.sourceMoveFolderPath,
                            sourceSystemTimestampPrecision: vm.flow.sourceSystem.sftpClient.sourceSystemTimestampPrecision,
                            connectionTimeout: vm.flow.sourceSystem.sftpClient.connectionTimeout,
                            dataTimeout: vm.flow.sourceSystem.sftpClient.dataTimeout,
                            hostKeys: vm.flow.sourceSystem.sftpClient.hostKeys,
                            useCompression: vm.flow.sourceSystem.sftpClient.useCompression,
                            sourceMinSize: vm.flow.sourceSystem.sftpClient.sourceMinSize,
                            sourceMaxSize: vm.flow.sourceSystem.sftpClient.sourceMaxSize,
                            sourceMinAge: vm.flow.sourceSystem.sftpClient.sourceMinAge,
                            sourceMaxAge: vm.flow.sourceSystem.sftpClient.sourceMaxAge,
                            targetFilename: vm.flow.sourceSystem.sftpClient.targetFilename,
                            credentials: {
                                shared: vm.flow.sourceSystem.sftpClient.credentials.shared,
                                id: vm.flow.sourceSystem.sftpClient.credentials.id,
                                username: vm.flow.sourceSystem.sftpClient.credentials.username,
                                password: vm.flowForm.sourceSystemSftpClientPass && vm.flowForm.sourceSystemSftpClientPass.$dirty ? vm.flow.sourceSystem.sftpClient.credentials.password : null,
                                privateKey: vm.flowForm.sourceSystemSftpClientPrivateKey && vm.flowForm.sourceSystemSftpClientPrivateKey.$dirty ? vm.flow.sourceSystem.sftpClient.credentials.privateKey : null,
                                privateKeyPassword: vm.flowForm.sourceSystemSftpClientPrivateKeyPassword && vm.flowForm.sourceSystemSftpClientPrivateKeyPassword.$dirty ? vm.flow.sourceSystem.sftpClient.credentials.privateKeyPassword : null,
                            }
                        },
                        sftpHost: {
                            folderName: vm.flow.sourceSystem.sftpHost.folderName,
                            credentials: {
                                shared: vm.flow.sourceSystem.sftpHost.credentials.shared,
                                id: vm.flow.sourceSystem.sftpHost.credentials.id,
                                username: vm.flow.sourceSystem.sftpHost.credentials.username,
                                password: vm.flowForm.sourceSystemSftpHostPass && vm.flowForm.sourceSystemSftpHostPass.$dirty ? vm.flow.sourceSystem.sftpHost.credentials.password : null,
                                publicKey: vm.flow.sourceSystem.sftpHost.credentials.publicKey,
                            }
                        },
                        s3: {
                            bucket: vm.flow.sourceSystem.s3.bucket,
                            region: vm.flow.sourceSystem.s3.region,
                            communicationsTimeout: vm.flow.sourceSystem.s3.communicationsTimeout,
                            prefix: vm.flow.sourceSystem.s3.prefix,
                            sourceDelimiter: vm.flow.sourceSystem.s3.sourceDelimiter,
                            sourceMinSize: vm.flow.sourceSystem.s3.sourceMinSize,
                            sourceMaxSize: vm.flow.sourceSystem.s3.sourceMaxSize,
                            sourceMinAge: vm.flow.sourceSystem.s3.sourceMinAge,
                            sourceMaxAge: vm.flow.sourceSystem.s3.sourceMaxAge,
                            sourceRecursive: vm.flow.sourceSystem.s3.sourceRecursive,
                            sourceKeyRegexPattern: vm.flow.sourceSystem.s3.sourceKeyRegexPattern,
                            sourcePrefixRegexPattern: vm.flow.sourceSystem.s3.sourcePrefixRegexPattern,
                            sourceFilePickupCompleteStrategy: vm.flow.sourceSystem.s3.sourceFilePickupCompleteStrategy,
                            sourceMovePrefix: vm.flow.sourceSystem.s3.sourceMovePrefix,
                            getTags: vm.flow.sourceSystem.s3.getTags,
                            ignoreTags: vm.flow.sourceSystem.s3.ignoreTags,
                            putTags: vm.flow.sourceSystem.s3.putTags,
                            deleteTags: vm.flow.sourceSystem.s3.deleteTags,
                            storageClass: vm.flow.sourceSystem.s3.storageClass,
                            targetObjectName: vm.flow.sourceSystem.s3.targetObjectName,
                            multipartThreshold: vm.flow.sourceSystem.s3.multipartThreshold,
                            multipartPartSize: vm.flow.sourceSystem.s3.multipartPartSize,
                            credentials: {
                                shared: vm.flow.sourceSystem.s3.credentials.shared,
                                id: vm.flow.sourceSystem.s3.credentials.id,
                                accessKey: vm.flow.sourceSystem.s3.accessKey,
                                secretKey: vm.flowForm.sourceSystemS3ASecretKey && vm.flowForm.sourceSystemS3ASecretKey.$dirty ? vm.flow.sourceSystem.s3.credentials.secretKey : null,
                            }
                        },
                    },
                    targetSystem: {
                        label: vm.flow.targetSystem.label,
                        internalSystem: vm.flow.targetSystem.internalSystem,
                        systemType: vm.flow.targetSystem.systemType,
                        externalEntityId: vm.flow.targetSystem.externalEntityId,
                        technicalFirstName: vm.flow.targetSystem.technicalFirstName,
                        technicalLastName: vm.flow.targetSystem.technicalLastName,
                        technicalPhone: vm.flow.targetSystem.technicalPhone,
                        technicalEmail: vm.flow.targetSystem.technicalEmail,
                        contactFirstName: vm.flow.targetSystem.contactFirstName,
                        contactLastName: vm.flow.targetSystem.contactLastName,
                        contactPhone: vm.flow.targetSystem.contactPhone,
                        contactEmail: vm.flow.targetSystem.contactEmail,
                        maxRetries: vm.flow.sourceSystem.maxRetries,
                        retryWaitDuration: vm.flow.sourceSystem.retryWaitDuration,
                        cifs: {
                            hostname: vm.flow.targetSystem.cifs.hostname,
                            shareName: vm.flow.targetSystem.cifs.shareName,
                            folderPath: vm.flow.targetSystem.cifs.folderPath,
                            targetDeliveryConflictStrategy: vm.flow.targetSystem.cifs.targetDeliveryConflictStrategy,
                            targetCreateMissingDirectories: vm.flow.targetSystem.cifs.targetCreateMissingDirectories,
                            targetMaximumFileCount: vm.flow.targetSystem.cifs.targetMaximumFileCount,
                            targetUpdateModifiedTime: vm.flow.targetSystem.cifs.targetUpdateModifiedTime,
                            targetFilename: vm.flow.targetSystem.cifs.targetFilename,
                            credentials: {
                                shared: vm.flow.targetSystem.cifs.credentials.shared,
                                id: vm.flow.targetSystem.cifs.credentials.id,
                                domain: vm.flow.targetSystem.cifs.credentials.domain,
                                username: vm.flow.targetSystem.cifs.credentials.username,
                                password: vm.flowForm.targetSystemCifsPass && vm.flowForm.targetSystemCifsPass.$dirty ? vm.flow.targetSystem.cifs.credentials.password : null,
                            }
                        },
                        sftpClient: {
                            hostname: vm.flow.targetSystem.sftpClient.hostname,
                            port: vm.flow.targetSystem.sftpClient.port,
                            folderPath: vm.flow.targetSystem.sftpClient.folderPath,
                            connectionTimeout: vm.flow.targetSystem.sftpClient.connectionTimeout,
                            dataTimeout: vm.flow.targetSystem.sftpClient.dataTimeout,
                            targetDeliveryConflictStrategy: vm.flow.targetSystem.sftpClient.targetDeliveryConflictStrategy,
                            targetCreateMissingDirectories: vm.flow.targetSystem.sftpClient.targetCreateMissingDirectories,
                            targetMaximumFileCount: vm.flow.targetSystem.sftpClient.targetMaximumFileCount,
                            targetUpdateModifiedTime: vm.flow.targetSystem.sftpClient.targetUpdateModifiedTime,
                            targetPermissions: vm.flow.targetSystem.sftpClient.targetPermissions,
                            targetOwner: vm.flow.targetSystem.sftpClient.targetOwner,
                            targetGroup: vm.flow.targetSystem.sftpClient.targetGroup,
                            targetBatchSize: vm.flow.targetSystem.sftpClient.targetBatchSize,
                            targetUseDotFileRename: vm.flow.targetSystem.sftpClient.targetUseDotFileRename,
                            targetTempFilename: vm.flow.targetSystem.sftpClient.targetTempFilename,
                            hostKeys: vm.flow.targetSystem.sftpClient.hostKeys,
                            useCompression: vm.flow.targetSystem.sftpClient.useCompression,
                            targetFilename: vm.flow.targetSystem.sftpClient.targetFilename,
                            credentials: {
                                shared: vm.flow.targetSystem.sftpClient.credentials.shared,
                                id: vm.flow.targetSystem.sftpClient.credentials.id,
                                username: vm.flow.targetSystem.sftpClient.credentials.username,
                                password: vm.flowForm.targetSystemSftpClientPass && vm.flowForm.targetSystemSftpClientPass.$dirty ? vm.flow.targetSystem.sftpClient.credentials.password : null,
                                privateKey: vm.flowForm.targetSystemSftpClientPrivateKey && vm.flowForm.targetSystemSftpClientPrivateKey.$dirty ? vm.flow.targetSystem.sftpClient.credentials.privateKey : null,
                                privateKeyPassword: vm.flowForm.targetSystemSftpClientPrivateKeyPassword && vm.flowForm.targetSystemSftpClientPrivateKeyPassword.$dirty ? vm.flow.targetSystem.sftpClient.credentials.privateKeyPassword : null,
                            }
                        },
                        sftpHost: {
                            folderName: vm.flow.targetSystem.sftpHost.folderName,
                            credentials: {
                                shared: vm.flow.targetSystem.sftpHost.credentials.shared,
                                id: vm.flow.targetSystem.sftpHost.credentials.id,
                                username: vm.flow.targetSystem.sftpHost.credentials.username,
                                password: vm.flowForm.targetSystemSftpHostPass && vm.flowForm.targetSystemSftpHostPass.$dirty ? vm.flow.targetSystem.sftpHost.credentials.password : null,
                                publicKey: vm.flow.targetSystem.sftpHost.credentials.publicKey,
                            }
                        },
                        s3: {
                            bucket: vm.flow.targetSystem.s3.bucket,
                            region: vm.flow.targetSystem.s3.region,
                            communicationsTimeout: vm.flow.targetSystem.s3.communicationsTimeout,
                            prefix: vm.flow.targetSystem.s3.prefix,
                            putTags: vm.flow.targetSystem.s3.putTags,
                            deleteTags: vm.flow.targetSystem.s3.deleteTags,
                            storageClass: vm.flow.targetSystem.s3.storageClass,
                            targetObjectName: vm.flow.targetSystem.s3.targetObjectName,
                            multipartThreshold: vm.flow.targetSystem.s3.multipartThreshold,
                            multipartPartSize: vm.flow.targetSystem.s3.multipartPartSize,
                            targetDeliveryConflictStrategy: vm.flow.targetSystem.s3.targetDeliveryConflictStrategy,
                            credentials: {
                                shared: vm.flow.targetSystem.s3.credentials.shared,
                                id: vm.flow.targetSystem.s3.credentials.id,
                                accessKey: vm.flow.targetSystem.s3.accessKey,
                                secretKey: vm.flowForm.targetSystemS3ASecretKey && vm.flowForm.targetSystemS3ASecretKey.$dirty ? vm.flow.targetSystem.s3.credentials.secretKey : null,
                            }
                        },
                    },
                }

                // update entity type
                if (flow.id > 0) {
                    DataCenterFlow.update({id: flow.id}, flow, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        editFlow(data);
                    }, saveFlowError);
                }

                // create entity type
                else {
                    DataCenterFlow.create(flow, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        editFlow(data);
                    }, saveFlowError);
                }
            }
        }

        function showCifsAdvanced() {
            $('#cifsAdvancedDetails').modal('show');
        }

        function hideCifsAdvanced() {
            $('#cifsAdvancedDetails').modal('hide');
        }

        function showSftpClientAdvanced() {
            $('#sftpClientAdvancedDetails').modal('show');
        }

        function hideSftpClientAdvanced() {
            $('#sftpClientAdvancedDetails').modal('hide');
        }

        function showTargetSftpClientAdvanced() {
            $('#targetSftpClientAdvancedDetails').modal('show');
        }

        function hideTargetSftpClientAdvanced() {
            $('#targetSftpClientAdvancedDetails').modal('hide');
        }

        function showSourceS3Advanced() {
            $('#sourceS3AdvancedDetails').modal('show');
        }

        function hideSourceS3Advanced() {
            $('#sourceS3AdvancedDetails').modal('hide');
        }

        function showTargetS3Advanced() {
            $('#targetS3AdvancedDetails').modal('show');
        }

        function hideTargetS3Advanced() {
            $('#targetS3AdvancedDetails').modal('hide');
        }

        function addTag(systemType, tagType) {
            var key = vm.flow[systemType].s3['new' + tagType + 'Key'];
            var val = vm.flow[systemType].s3['new' + tagType + 'Value'];

            if (key) {
                vm.flow[systemType].s3[tagType][key] = val;
                vm.flow[systemType].s3['new' + tagType + 'Key'] = null;
                vm.flow[systemType].s3['new' + tagType + 'Value'] = null;
            }
        }

        function removeTag(systemType, tagType, key) {
            delete vm.flow[systemType].s3[tagType][key];
        }

        function getTagKeys(systemType, tagType) {
            return Object.keys(vm.flow[systemType].s3[tagType]).sort();
        }

        function addDeleteTag(systemType) {
            var key = vm.flow[systemType].s3['newdeleteTagsKey'];

            if (key) {
                vm.flow[systemType].s3.deleteTags.push(key);
                vm.flow[systemType].s3['newdeleteTagsKey'] = null;
            }
        }

        function removeDeleteTag(systemType, key) {
            var tags = vm.flow[systemType].s3.deleteTags;
            var idx = tags.indexOf(key);

            if (idx >= 0)
                tags.splice(idx, 1);
        }


        // adding entity
        if ($stateParams.dcFlowId <= 0) {
            vm.editing = false;
            vm.tab = 'configuration';

            createEmptyFlow();

            // initialize direction change values
            this.onDirectionChange(vm.flow.direction);
        }

        // editing existing entity
        else {
            vm.editing = true;
            vm.tab = 'configuration';

            // initialize direction change and system type drop downs
            createEmptyFlow();
            this.onDirectionChange("INTERNAL");

            DataCenterFlow.get({ id: $stateParams.dcFlowId },
                function(response, headers) {
                    editFlow(response);
                }, function(error) {
                    GlobalModal.displayError('An error occurred retrieving flow');
                }
            );
        }

    }
})();
