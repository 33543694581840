(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('dcFlowsChosen', DcFlowsChosenDirective);

    DcFlowsChosenDirective.$inject = ['$timeout'];

    function DcFlowsChosenDirective ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);

    	        // initialize chosen select control
    	        $timeout(function() {
    	    	    select.chosen({
    	    	        disable_search_threshold: 10,
    	    	        width: attr.width || "200px"
    	    	    });
    	        });

                select.on('change', function() {
                    scope.vm.query();
                });
    	    }
    	}
    }
})();
