(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ExternalAssociationsController', ExternalAssociationsController);

    ExternalAssociationsController.$inject = ['$scope','$state', '$stateParams', 'ExternalEntity','GlobalModal', 'ExternalEntityType'];

    function ExternalAssociationsController ($scope, $state, $stateParams, ExternalEntity, GlobalModal, ExternalEntityType) {
        var vm = this;
        
        vm.entityFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['name'];
        vm.nextAvailableQueryId = 0;
        
        vm.addEntity = addEntity;
        vm.editEntity = editEntity;
        vm.query = query;
        vm.sortByName = sortByName;
        vm.sortByStatus = sortByStatus;
        vm.entitiesSelected = 0;
        vm.toggleFilterOptions = toggleFilterOptions;
        vm.filterHidden = false;
        vm.filterToggle = false;
        vm.nameFilter = '';
        vm.entityTypeFilter = '';
        vm.attributeFilter = '';
        vm.attributeValueFilter = '';
        vm.clearFilter = clearFilter;
        vm.filter = filter;
        vm.selectAll = selectAll;
        vm.activityReportSearch = {};
        vm.allSelected = false;
        vm.selectAllEntities = selectAllEntities;
        vm.selectEntity = selectEntity;
        vm.selectedEntityList = [];
        vm.addAssociations = addAssociations;
        var map = new Map();
        vm.isFilterApplied = false;
        vm.typeChanged = typeChanged;
        vm.entityReportSearch = {};
        
        // Get list of available entity types
		vm.availableTypes = [];
		ExternalEntityType.getActive({ id: 0 }, 
				function(response, headers) {
			vm.availableTypes = response;
			$scope.$broadcast('externalEntityUpdate');

		}, function(error){
		});
        
        vm.loadPage = loadPage;
        
        if ($stateParams.entitySelectionMap ) {
			
			vm.nameFilter =  $stateParams.nameFilter;
			vm.entityReportSearch.entityTypeSelect =  $stateParams.entityTypeFilter;
			vm.entityTypeFilter = vm.entityReportSearch.entityTypeSelect;
	        vm.attributeFilter =  $stateParams.attributeFilter;
	        vm.attributeValueFilter =  $stateParams.attributeValueFilter;
	        vm.isFilterApplied = $stateParams.isFilterApplied;
	        map = $stateParams.entitySelectionMap;       
			
		}
   
        
        query(true);

        function typeChanged(id) {
        	 vm.entityTypeFilter = id;
        }
        function addAssociations() {
      
	        	var entityIdList = [];
	        	var values = map.values();
	        	let keys = Array.from( map.keys() );
	        	var keyLength = keys.length;
	        	for (var i = 0; i < keyLength; i++) {
	        		var value = map.get(keys[i])
	        		var arrayLength = value.length;
	      		for (var j = 0; j < arrayLength; j++) {
	      			entityIdList.push(value[j]);  
	      		}
	        	}
	      
	        if (entityIdList.length > 0) {
	        	$state.go('external-user-associations', {
	        		entityIdList: entityIdList,
	        		entitySelectionMap: map,
	        		nameFilter:  vm.nameFilter,
	        		entityTypeFilter: vm.entityTypeFilter,
	        		attributeFilter: vm.attributeFilter,
	        		attributeValueFilter: vm.attributeValueFilter,
	        		isFilterApplied: vm.isFilterApplied
	    		});
	        } else {
		        	GlobalModal.displayError("Please Select External entities.");
	        		return;
	        }
        }
        
        function toggleFilterOptions(){
        	vm.filterToggle = !vm.filterToggle;
        }
        
        function addEntity() {
	    		$state.go('external-entity');
	    }
	
        function editEntity(entity) {
	    		$state.go('external-entity', {
	    			entityId: entity.id,
	    		});
        }
        
        function restoreSelectedEntities()  {
        	 var selectedEntityList = map.get(vm.page) ;
     	    //check if an entry exists
     	    if (selectedEntityList) {
     	    	
     	    		//restore all selected items on this page
	     	    	var arrayLength = selectedEntityList.length;
	      		  for (var i = 0; i < arrayLength; i++) {
	      			  
	      		 	var len =  vm.externalEntities.length;
	      		 	for (var j = 0; j < len; j++) {
	      		 		if (selectedEntityList[i] == vm.externalEntities[j].id) {
	      		 		 vm.externalEntities[j].selected = true;
	      		 		 break;
	      		 		}
	      		 	}
	      			  
	      		      
	      		  }
     	    	
     	    }
        }
        
        function selectAllEntities() {
        	
        	//todo: toggle the flag as mapping to model in the checkbox is working opposite. Needs to be fixed
        		if(vm.allSelected){
        			vm.allSelected = false;
        		} else {
        			vm.allSelected = true;
        		}
        			
	     
        	    var selectedEntityList = map.get(vm.page) ;
        	    //check if an entry exists
        	    if (selectedEntityList) {
        	    		
        	    		if (!vm.allSelected) {
        	    			 selectedEntityList.splice(0)
           	    		 selectedEntityList = [];
        	    			 map.set(vm.page, selectedEntityList)
        	    		
           	    } 
        	    } else   {
        	    		 selectedEntityList = [];
        	    } 
        		
        		
        		  
        		  var arrayLength = vm.externalEntities.length;
        		  for (var i = 0; i < arrayLength; i++) {
        			  if (vm.allSelected) {
        				  vm.externalEntities[i].selected = true;
        				  selectedEntityList.push(vm.externalEntities[i].id);
        			  } else {
        				  vm.externalEntities[i].selected = false;
        			  }
        		      
        		  }
        		         		 
        		  map.set(vm.page, selectedEntityList) 
        		
        		  
        	  
        }
        
        function selectEntity(externalEntity) {
	    
        	//todo: toggle the flag as mapping to model in the checkbox is working opposite. Needs to be fixed
        		if(externalEntity.selected) {
        			externalEntity.selected = false;
        		} else {
        			externalEntity.selected = true;
        		}
	        	
	        	var selectedEntityList = map.get(vm.page) ;
	        	if (selectedEntityList) {
	        		 var arrayLength = selectedEntityList.length;
	        		 var found  =false;
         		  for (var i = 0; i < arrayLength; i++) {
         			 if (selectedEntityList[i] == externalEntity.id) {
         				found = true;
         				if (!externalEntity.selected) {
         					selectedEntityList.splice(i,1);        					
         					break;
	         	      	  } 
         			 }
         		      
         		  }
         		  if (!found) {
         			 if (externalEntity.selected) {
         				selectedEntityList.push(externalEntity.id);
         			 }
         		  }
         		 map.set(vm.page, selectedEntityList);
	        	} else {
	        		selectedEntityList = [];
	        		if(externalEntity.selected) {
	        			selectedEntityList.push(externalEntity.id);
	        			  map.set(vm.page, selectedEntityList);
	        		}
	        	}
      	  
      }
        
        function filter () {
        	
         	vm.page = 1;    
         	map.clear();
         	map =  new Map();
         	vm.allSelected = false;
        	    query(false)	;
        	    if (vm.nameFilter.trim() == "" && vm.entityTypeFilter == ""  && vm.attributeFilter.trim() == "" ) {		        		
	        	 	
        	 		vm.isFilterApplied = false;
        	 	
        	    } else if (vm.attributeFilter.trim() == "" && vm.attributeValueFilter.trim() != "") {
        	    	vm.isFilterApplied = false;
        	    } else {
        	    	vm.isFilterApplied = true;
        	    }
        	
        
        }
        
        
     function clearFilter(){
        	
        	// Reset view     
    	 	 vm.nameFilter = '';
         vm.entityTypeFilter = '';
         $("#entityTypeSelect").val("");
		 $("#entityTypeSelect").trigger("chosen:updated");
         vm.attributeFilter = '';
         vm.attributeValueFilter = '';
        
         	// clear the previous selections
        	map = new Map();
       
        	// Rerun filter
            query(true)	;
            vm.isFilterApplied = false;
        }

        function query(skipValidation) {
        	
         	if (!skipValidation){
		       
		        	
		        	if (vm.nameFilter.trim() == "" && vm.entityTypeFilter == ""  && vm.attributeFilter.trim() == "" ) {		        		
		        	 	
		        	 		GlobalModal.displayError("Please enter at least one filter .");
			        		return;
		        	 	
		        	}
		        	
		        	if (vm.attributeFilter.trim() == "" && vm.attributeValueFilter.trim() != "") {
		        		GlobalModal.displayError("Attribute name is required to filter by attribute value.");
		        		return;
		        	}
		        	
		       
        	}
         	
        vm.currentAvailableQueryId = vm.nextAvailableQueryId++;
        var queryId = vm.currentAvailableQueryId; 
        ExternalEntity.queryWithFilters({name: vm.nameFilter, typeId: vm.entityTypeFilter, attribute: vm.attributeFilter,  attributeValue:  vm.attributeValueFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
	    		function(result, headers) {
                if (queryId == vm.currentAvailableQueryId) {
    		        		vm.externalEntities = result;
    		        		vm.totalItems = headers('X-Total-Count');
    		        		restoreSelectedEntities();
    		        		vm.allSelected  =false;
    		        		
                }
        		}
    		);
	        
	        return false;
        }
        
        function sortByName(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['name,' + sortDir];
	    		query();
	    }
        
        function sortByStatus(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['active,' + sortDir];
	    		query();
	    }
        
        function loadPage(page) {
            vm.page = page;
            query(true);
        }
    }   
})();
