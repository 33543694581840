(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('GlobalModal', GlobalModal);

    GlobalModal.$inject = ['$rootScope', '$compile', '$timeout'];

    function GlobalModal ($rootScope, $compile, $timeout) {
        var vm = this;

        function displaySuccess(msg) {
            displayMessage(msg, false, null, 'success', null, true);
        }
        function displayError(msg) {
            displayMessage(msg, false, null, 'failure', null, true);
        }
        function displayInfo(msg) {
            displayMessage(msg, false, null, 'info', null, true);
        }
        function displayRefresh(msg) {
            displayMessage(msg, false, null, 'refresh', null, false);
        }
        function displayRefreshHtml(msg, scope) {
            displayMessage(msg, true, scope, 'refresh', null, false);
        }
        function displayCustom(msg, asHtml, scope, className, icon, autoHide, hideCallback) {
            displayMessage(msg, asHtml, scope, className, icon, autoHide, hideCallback);
        }
        function displayMessage(msg, asHtml, scope, className, icon, autoHide, hideCallback) {
            // dismiss existing message
            if (vm.messageTimer) clearTimeout(vm.messageTimer);

            if (asHtml) {
                $rootScope.globalModalMessageText = null;
                $rootScope.globalModalMessageHtml = msg;

                if (scope) {
                    $timeout(function() {
                        $compile($('#globalModalMessageHtml').contents())(scope);
                    });
                }
            }
            else  {
                $rootScope.globalModalMessageText = msg;
                $rootScope.globalModalMessageHtml = null;
            }			

            $rootScope.globalModalMessageClassName = className == 'refresh' || className == 'info' ? "confirm" : className;
            $rootScope.globalModalMessageIcon = icon != null ? icon :
                className == 'refresh' ? 'fa-refresh' :
                    className == 'failure' ? 'fa-exclamation' :
                        className == 'info' ? 'fa-info' :
                            'fa-check';

            $('#globalModalNotification').off('hidden.bs.modal');
            if (hideCallback)
                $('#globalModalNotification').on('hidden.bs.modal', hideCallback);
            $('#globalModalNotification').modal('show');

            if (autoHide) {
                vm.messageTimer = setTimeout(function() {
                    hideModal();
                }, 3000);
            }
        }

        function confirm(msg, confirmCallback, rejectCallback) {
            $rootScope.globalModalConfirmText = msg;
            $rootScope.globalModalConfirm = confirmCallback;
            $rootScope.globalModalReject = rejectCallback;
            $('#globalModalConfirm').modal('show');
        }

        function hideModal() {
            $('#globalModalNotification').modal('hide')
            $('#globalModalConfirm').modal('hide')
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        }

        return {
            displaySuccess: displaySuccess,
            displayError: displayError,
            displayInfo: displayInfo,
            displayRefresh: displayRefresh,
            displayRefreshHtml: displayRefreshHtml,
            displayCustom: displayCustom,
            confirm: confirm,
            hideModal: hideModal,
        };
    }
})();
