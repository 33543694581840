(function() {
	'use strict';

	angular.module('squirrelboxApp').controller('ConsumerReportsController',
			ConsumerReportsController);

	ConsumerReportsController.$inject = [ '$rootScope', '$scope',
			'$localStorage', 'Notification', 'NotificationTimeline', '$window',
			'GlobalModal' ];

	function ConsumerReportsController($rootScope, $scope, $localStorage,
			Notification, NotificationTimeline, $window, GlobalModal) {
		var vm = this;

		vm.itemsPerPage = 20;
		vm.page = 1;

		vm.query = query;
		vm.loadPage = loadPage;
		vm.pageChanged = pageChanged;
		vm.notificationText = NotificationTimeline.notificationText;
		vm.notificationDescription = NotificationTimeline.notificationDescription;
		vm.generatePurgedDatasetTooltip = NotificationTimeline.generatePurgedDatasetTooltip;
		vm.notificationIcon = NotificationTimeline.notificationIcon;

		vm.searchStartDateOpen = false;
		vm.searchEndDateOpen = false;
		vm.filterVisible = false;
		
		vm.filterParams = {
				eventType: "",
				startDate: null,
				endDate: null
		};

		vm.openSearchStartDateCalendar = openSearchStartDateCalendar;
		vm.openSearchEndDateCalendar = openSearchEndDateCalendar;
		vm.toggleFilterOptions = toggleFilterOptions;
		vm.filter = filter;
		vm.clearFilter = clearFilter;
		vm.reload = reload;
		
		vm.selectEventType = selectEventType;
		vm.selectedType = "";
		
		vm.startDateChanged = startDateChanged;
		vm.endDateChanged = endDateChanged;
		
		vm.notificationReportSearch = {};
		
		vm.notificationEvents = [
			{
				text : "Dataset Uploaded",
				value : "DATASET_LIFECYCLE_UPLOADED"
			},
			{
				text: "Dataset Processing",
				value: "DATASET_LIFECYCLE_PROCESSING"
			},
			{
				text: "Dataset Ready",
				value: "DATASET_LIFECYCLE_READY"
			},
			{
				text: "Dataset Shared",
				value: "DATASET_LIFECYCLE_SHARED"
			},
			{
				text: "Dataset Error",
				value: "DATASET_LIFECYCLE_ERROR"
			},
			{
				text: "Dataset Shared With User",
				value: "DATASET_SHARED_WITH_USER"
			},
			{
				text: "Dataset Share Updated",
				value: "DATASET_SHARE_WAS_UPDATED"
			},
			{
				text: "Download Generating",
				value: "DOWNLOAD_LIFECYCLE_GENERATING"
			},
			{
				text: "Download Ready",
				value: "DOWNLOAD_LIFECYCLE_READY"
			},
			{
				text: "Download Error",
				value: "DOWNLOAD_LIFECYCLE_ERROR"
			},
			{
				text: "Invitation Received",
				value: "INVITATION_RECEIVED"
			},
			{
				text: "Invitation Accepted",
				value: "INVITATION_ACCEPTED"
			},
			{
				text: "Dataset Updated",
				value: "DATASET_DATA_WAS_UPDATED"
			}
		]

		$scope.notificationAnchorMethods = NotificationTimeline.notificationAnchorMethods;

		query()

		function query() {
			Notification.query({
				page : vm.page - 1,
				size : vm.itemsPerPage,
				notificationType : vm.filterParams.eventType,
				startDate : vm.filterParams.startDate,
				endDate : vm.filterParams.endDate
			}, {}, function(result, headers) {
				vm.notifications = result;
				vm.totalItems = headers('X-Total-Count');
			});
		}

		function loadPage(page) {
			vm.page = page;
			vm.query();
		}

		function reload() {
			loadPage(vm.page);
		}

		function pageChanged() {
			$window.scrollTo(0, 0);
		}

		function toggleFilterOptions() {
			vm.filterVisible = !vm.filterVisible;
		}

		// invoked when calendar button is pressed
		function openSearchStartDateCalendar(e) {
			e.preventDefault();
			e.stopPropagation();

			vm.searchStartDateOpen = true;
			vm.searchEndDateOpen = false;
		}

		function openSearchEndDateCalendar(e) {
			e.preventDefault();
			e.stopPropagation();

			vm.searchEndDateOpen = true;
			vm.searchStartDateOpen = false;
		}

		function clearFilter() {

			// Reset view
			vm.notificationReportSearch.eventTypeSelect = "";
			$("#eventTypeSelect").val("");
			$("#eventTypeSelect").trigger('chosen:updated');
			vm.notificationReportSearch.searchStartDate = "";
			vm.notificationReportSearch.searchEndDate = "";
			
			// Reset model
			vm.filterParams.eventType = "";
			vm.filterParams.startDate = null;
			vm.filterParams.endDate = null;
			// Rerun filter
			filter(true);
		}
		
		function filter(skipValidation) {
			if (!skipValidation) {
				if (vm.notificationReportSearchForm.$invalid) {
					GlobalModal.displayError("Invalid search parameters");
					return;
				}
				if ((vm.filterParams.startDate && vm.filterParams.endDate) && vm.filterParams.startDate > vm.filterParams.endDate){
	        		GlobalModal.displayError("Start date must occur before end date");
	        		return;
	        	}
			}
			vm.page = 1;
			vm.reload();
		}

		function selectEventType(id) {
			vm.filterParams.eventType = id;
		}
		
		function startDateChanged(id){
			if (id != null){
				vm.filterParams.startDate = id;
			}
		}
		
		function endDateChanged(id){
			if (id != null){
				vm.filterParams.endDate = id;
			}
		}

	}
})();
