(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('saml-login-landing', {
            parent: 'app',
            url: '/saml-login-landing?{error}',
            data: {
                authorities: [],
                loginState: true,
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/login/saml-login-landing/saml-login-landing.html',
                    controller: 'SquirrelBoxLoginLandingController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
