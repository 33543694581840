(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SquirrelBoxLoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$localStorage', '$window', 'Auth', 'Principal', 'AuthServerProvider', 'Logo', 'Announcement'];

    function LoginController ($rootScope, $scope, $state, $timeout, $localStorage, $window, Auth, Principal, AuthServerProvider, Logo, Announcement ) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.checkSamlLoginRedirect = checkSamlLoginRedirect;
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = $localStorage.rememberMe === true;
        vm.passwordForgot = passwordForgot;
        vm.username = $localStorage.username;
        vm.logoIconUrl = Logo.logoIconUrl;
        vm.loginImageUrl = Logo.loginImageUrl;

        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });
        $rootScope.$on('loginImageUpdate', function() {
            vm.loginImageUrl = Logo.loginImageUrl;
        });

        getAnnouncements();
        
        function getAnnouncements() {
            Announcement.getActiveForLoginScreen({id: 0}, function(announcements) {
                for (var idx=0; idx<announcements.length; idx++) {
                    var announcement = announcements[idx];
                    announcement.text = announcement.text.replaceAll("\n", "<br />");
                }
                vm.announcements = announcements;
            });
        }

        Auth.logout(false);
        vm.logoutTrue = $state.logoutTrue;
		// redirect to proper login for current organization
		AuthServerProvider.getAuthenticationMethod()
			.then(function(method) {
				vm.authMethod = method;

				// check if auth is disabled
				if (method.authenticationType == 'SAML') {
					if (method.disableSquirrelboxAuthentication) {
			        		$timeout(function() {
			            		$state.go('saml-login-landing');
			        		});
					}

					else if (method.orgIdpSigninUrl && method.orgIdpSigninUrl.length > 0 && method.orgEmailToRedirect && method.orgEmailToRedirect.length > 0) {
						vm.checkSamlIdpRedirect = true;
					}
				}
			});

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
        }

        function checkSamlLoginRedirect(callback) {
        		if (vm.checkSamlIdpRedirect && isEmail(vm.username)) {
        			var emailSuffix = '@' + vm.authMethod.orgEmailToRedirect;

        			if (vm.username.toLowerCase().endsWith(emailSuffix.toLowerCase())) {
        			    vm.checkingSamlUser = true;

        			    AuthServerProvider.checkSamlRedirect(vm.username)
            			    .then(function(response) {
            			        vm.authenticationError = false;
            			        if (response.performRedirect) {
                			        vm.redirectingToSaml = true;
                			        $timeout(function() {
                			            $window.location = vm.authMethod.orgIdpSigninUrl;
                			        }, 2000);
            			        }
            			        else {
            			            vm.checkingSamlUser = false;
            			            $scope.$$postDigest(function() {
                                    $('#password').focus();
            			            });

            			            if (callback)
                                    callback();
            			        }
            	            }).catch(function (error) {
            	                vm.checkingSamlUser = false;
                            vm.authenticationError = true;
            	            });
        			}
        			else if (callback)
                    callback();
        		}
            else if (callback)
                callback();
        }

        function login (event) {
            event.preventDefault();

            // check if we need to do a SAML redirect first
            checkSamlLoginRedirect(function() {
        	        $state.logoutTrue = false;
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function () {
                        vm.authenticationError = false;

                        if (vm.rememberMe) {
                        		$localStorage.rememberMe = true;
                        		$localStorage.username = vm.username;
                        }
                        else {
                    			delete $localStorage.rememberMe;
                        		delete $localStorage.username;
                        }

                        $rootScope.$broadcast('authenticationSuccess');

                        Principal.identity().then(function(identity) {
                            if (identity.daysToPasswordExpire >= 0 && identity.daysToPasswordExpire <= 7) {
                                $state.go('password-expiring');
                            }
                            else {
                                Principal.hasAuthority('ROLE_ADMIN')
                                .then(function(assigned) {
                                    if (assigned)
                                        $state.go('sysadmin-home')
                                        else
                                            $state.go('home');
                                });
                            }
                        }
                        );
                    }).catch(function (error) {
        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.accountlocked')
        					vm.authenticationErrorMessage = "Your account is locked. Please re-try after 30 minutes.";
        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.passwordexpired')
        					vm.authenticationErrorMessage = "Your password has expired. Please use reset password link to change it.";
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.accountinactive')
                            vm.authenticationErrorMessage = "Your account is not active. Please contact your administrator to reactivate it.";
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.accountold')
                            vm.authenticationErrorMessage = "Your account has been disabled due to inactivity. Please contact your administrator to reactivate it.";
        				else
        					vm.authenticationErrorMessage = "Please check your credentials and try again.";

        				vm.authenticationError = true;
                    });
            });
        }

        function register () {
            $state.go('register');
        }

        function passwordForgot(){
        	$state.go('password-forgot');
        }

        function isEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

    }
})();
