(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('share', {
            parent: 'supplier',
            url: '/supplier/share/:datasetId',
            views: {
                'content@': {
                    templateUrl: 'app/supplier/share/share.html',
                    controller: 'SupplierShareController',
                    controllerAs: 'vm'
                },
                'userselect@share': {
                    templateUrl: 'app/supplier/share/share-userselect.html',
                },
                'delegateselect@share': {
                    templateUrl: 'app/supplier/share/share-delegateselect.html',
                },
            },
        });
    }
})();
