(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('EndpointQueueController', EndpointQueueController);

    EndpointQueueController.$inject = ['$stateParams', 'GlobalModal', 'ServiceEndpoint'];

    function EndpointQueueController ($stateParams, GlobalModal, ServiceEndpoint) {
        var vm = this;

        vm.itemsPerPage = 12;
        vm.page = 1;
        
        vm.query = query;
        vm.loadPage = loadPage;
        vm.deleteEndpointQueueEntry =deleteEndpointQueueEntry;
        query();

        function query() {
            ServiceEndpoint.queryQueue({page: vm.page-1, size: vm.itemsPerPage, userId: $stateParams.userId},
                function(result, headers) {
                    vm.entries = result;
                    vm.totalItems = headers('X-Total-Count');
                });
            
            return false;
        }

        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
        
        function deleteEndpointQueueEntry(entry) {
        	
	        	GlobalModal.confirm('Do you want to delete this queue entry for dataset ' +entry.dataset.label + ' ?', function() {
	        		ServiceEndpoint.deleteQueueEntry({userId: $stateParams.userId, entryId: entry.id},
	        				function(result) {
	        				   //refresh the list
	        					loadPage (vm.page);
	        				},
	        				function(error) {	        					
	            				GlobalModal.displayError('An unexpected error occurred deleting this queue entry');
	        				}
	        			);
	    	 		return false;
	        });
	        
        }
        
        
    }
    

})();
