(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sysadmin-reports', {
            parent: 'sysadmin',
            url: '/sysadmin-reports',
            data: {
                authorities: ['ROLE_ADMIN'],
            	pageTitle: 'SysAdmin:Reports - DataRail'
            },
            views: {
                'content@': {
                    templateUrl: 'app/sysadmin/reports/sysadmin-reports.html',
                    controller: 'SysadminReportsController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
