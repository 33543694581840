(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('UserGroupAdminController', UserGroupAdminController);

    UserGroupAdminController.$inject = ['UserGroup', 'GlobalModal'];

    function UserGroupAdminController (UserGroup, GlobalModal) {
        var vm = this;
        
        vm.loadUserGroups = loadUserGroups;
        vm.loadPage = loadPage;
        vm.createUserGroup = createUserGroup;
        vm.editUserGroup = editUserGroup;
        vm.saveUserGroup = saveUserGroup;
        vm.selectUser = selectUser;
        vm.deselectUser = deselectUser;
        vm.selectAllUsers = selectAllUsers;
        vm.deselectAllUsers = deselectAllUsers;
        vm.availableUserFilterChange = availableUserFilterChange;
        vm.selectedUserFilterChange = selectedUserFilterChange;
        vm.selectUserGroupType = selectUserGroupType;
        vm.currentUserGroupType = '';
        vm.availableUsersListMessage = 'Availabe Users';
        vm.selectedUsersListMessage = 'Saelected Users';
        vm.originalAvailableUsers = null;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
       
		vm.userGroupFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.userGroupSort = ['groupName'];
        vm.activeOnly = false;
        
        loadUserGroups();
       
        function loadUserGroups() {
        		processUserGroups([])
                          vm.totalItems = 0;
                          
                          UserGroup.query({name: vm.userGroupFilter, activeOnly: vm.activeOnly, page: vm.page-1, size: vm.itemsPerPage, sort: vm.userGroupSort}, 
                function(userGroups, headers) {
        	            	processUserGroups(userGroups)
        	            	vm.totalItems = headers('X-Total-Count');
        	            	vm.userGroups = userGroups;
        	            	if (!vm.currentUserGroup && vm.userGroups.length > 0)
        	            		editUserGroup(vm.userGroups[0]);
        	            }
                );
        }
        
        function loadPage(page) {
            vm.page = page;
            loadUserGroups();
        }
        
        function resolveUserListMessage(groupType) {
        	
        		if (groupType == 'INTERNAL') {
        			vm.availableUsersListMessage = 'Availabe Internal Users';
        	        vm.selectedUsersListMessage = 'Selected  Internal Users';
        		} else if (groupType == 'EXTERNAL') {
        			vm.availableUsersListMessage = 'Availabe External  Users';
        	        vm.selectedUsersListMessage = 'Selected  External Users';
        		} else if (groupType == 'ALL') {
        			vm.availableUsersListMessage = 'All Availabe Users';
        	        vm.selectedUsersListMessage = 'Selected Users';
        		} else {
        			vm.availableUsersListMessage = 'Availabe Users';
        	        vm.selectedUsersListMessage = 'Selected Users';
        		}
        			
        }
        
        function createUserGroup() {
	       
	        	
	        	  UserGroup.create({}, 
      	                function(data) {
			        	    	 	vm.currentUserGroup = {
			        		        	    groupName: data.groupName,
			        		        		id: data.id,
			        		        		active: data.active,
			        		        		userGroupType: data.userGroupType,
			        		        		selectedUsers: data.selectedUsers,
			        		        		availableUsers: data.availableUsers,
			        		        		availableTypes: data.availableTypes
			        		        		
			        		        	}
			        	    	 	
			        	    	 // prepare internal users lists
			    	        		vm.originalUsers = buildOriginalUserList(vm.currentUserGroup.availableUsers, vm.currentUserGroup.selectedUsers)
			    	        		vm.selectedUsers = vm.currentUserGroup.selectedUsers;
			    				var allUsers = vm.originalUsers;
			    				processUsers(allUsers);
			    				 vm.currentUserGroupType = '';
			    				vm.currentUserGroup.availableUsers = vm.availableUsers;
			        		    vm.userGroupBeingEdited = data;
			        		    vm.availableUserFilter = '';
      	     });
        }
        
        function updateAvailableUserList() {
	        	UserGroup.getAvailableUsers({grouptype: vm.currentUserGroup.userGroupType}, 
	   	                function(data) {
			        	    	 	
			        	    	 // prepare  users lists
			    	        		vm.originalUsers = buildOriginalUserList(data.availableUsers, data.selectedUsers)
			    	        		vm.selectedUsers = data.selectedUsers;
			    				var allUsers = vm.originalUsers;
			    				processUsers(allUsers);	
			    				vm.currentUserGroup.availableUsers = vm.availableUsers;
			    				vm.currentUserGroup.selectedUsers = vm.selectedUsers;
			    				vm.originalAvailableUsers = vm.currentUserGroup.availableUsers;
			        		    vm.userGroupBeingEdited = data;
			        		    vm.currentUserGroupType = vm.currentUserGroup.userGroupType;
			        		    resolveUserListMessage(vm.currentUserGroup.userGroupType);
		 				});
        }
        
        function selectUserGroupType() {
        
        	  if (vm.currentUserGroupType != vm.currentUserGroup.userGroupType) {
        		   if(vm.currentUserGroup.id) {
        			 GlobalModal.displayError('Cannot Update User Group type after creation.')
                 vm.currentUserGroup.userGroupType = vm.currentUserGroupType ;
               	
        		   } else {
        			   updateAvailableUserList();
        		   }
        	  } 
        	  /* */
        }
        
        function editUserGroup(userGroup) {
        		if (vm.userGroupForm)
        			vm.userGroupForm.$setPristine();
	        	
        	     UserGroup.get({id: userGroup.id}, 
        	                function(data) {
			        	    	 	vm.currentUserGroup = {
			        		        	    groupName: data.groupName,
			        		        		id: data.id,
			        		        		active: data.active,
			        		        		userGroupType: data.userGroupType,
			        		        		selectedUsers: data.selectedUsers,
			        		        		availableUsers: data.availableUsers,
			        		        		availableTypes: data.availableTypes
			        		        		
			        		        	}
			        	    	 // prepare internal users lists
			    	        		vm.originalUsers = buildOriginalUserList(vm.currentUserGroup.availableUsers, vm.currentUserGroup.selectedUsers)
			    	        		vm.selectedUsers = vm.currentUserGroup.selectedUsers;
			    				var allUsers = vm.originalUsers;
			    				processUsers(allUsers);
			    				vm.currentUserGroup.availableUsers =vm.availableUsers ;
			    				vm.originalAvailableUsers = vm.currentUserGroup.availableUsers;
			        		    vm.userGroupBeingEdited = data;
			        		    vm.currentUserGroupType = vm.currentUserGroup.userGroupType;
			        		    resolveUserListMessage(vm.currentUserGroup.userGroupType);
			        		    vm.availableUserFilter = '';
        	     });
	       
        	   
       }

        function saveUserGroup() {
	        	if (vm.currentUserGroup) {
	        		vm.userGroupForm.$setSubmitted();
	            	
	        		if(!vm.userGroupForm.$valid) return;
	        		
	        	
	        		
	        		var userGroup = {
    		        	    groupName: vm.currentUserGroup.groupName,
    		        		id: vm.currentUserGroup.id,
    		        		active: vm.currentUserGroup.active,
    		        		userGroupType: vm.currentUserGroup.userGroupType,
    		        		selectedUsers: vm.currentUserGroup.selectedUsers,
    		        		availableUsers: vm.currentUserGroup.availableUsers,
    		        		availableTypes: vm.currentUserGroup.availableTypes
    		        		
    		        	}
	        		
	        		// update userGroup
	        		if (userGroup.id > 0) {
	        			if (userGroup.selectedUsers.length == 0) {
	        				GlobalModal.displayError('Please select at least one user');
	        			} else {
		        			UserGroup.update({id: userGroup.id}, userGroup, function(data) {
		        				GlobalModal.displaySuccess('Your changes have been saved.');
		        				loadUserGroups();
		        				editUserGroup(data);
		        				
		        			}, function(error) {
		        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.externalEntityTypeExists')
		        					GlobalModal.displayError('External Entity Type already exists in system');
		        				else
		        					GlobalModal.displayError('Unable to save user group. Please try again.');
		        			});
	        			}
	        		}
	        		
	        		// create entity type
	        		else {
	        			if (userGroup.selectedUsers.length == 0) {
	        				GlobalModal.displayError('Please select at least one user');
	        			} else {
		        			UserGroup.save(userGroup, function(data) {
		        		        GlobalModal.displaySuccess('Your changes have been saved.');
		        		        loadUserGroups();
		        				editUserGroup(data);
		        			}, function(error) {
	                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.externalEntityTypeExists')
	                            GlobalModal.displayError('user group already exists in system');
	                        else
	                            GlobalModal.displayError('Unable to save  user group. Please try again.');
		        			});
	        			}
	        		}
	        	}
        }
        
     
        // build list of original users from available and selected users
        function buildOriginalUserList(availableUsers, selectedUsers) {
            var originalUsers = [].concat(availableUsers);
            for (var i=0; i<selectedUsers.length; i++) {
                var u = selectedUsers[i];
                var exists = false;
                for (var j=0; j<originalUsers.length; j++) {
                    if (u.id === originalUsers[j].id) {
                        originalUsers[j] = u;
                        exists = true;
                        break;
                    }
                }
                if (!exists)
                    originalUsers.push(u);
            }
            return originalUsers;
        }
        
    	// processes available user set after retrieval from server
    	function processUsers(data) {
			vm.availableUsers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.displayName = buildUserDisplayName(user);
				
				// if duplicates of the same user name are in the list, mark to display email
				if (nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				// add user to available if not already selected
				var selected = false;
				for (var j=0; j<vm.selectedUsers.length; j++) {
					var selectedUser = vm.selectedUsers[j];
					if (selectedUser.id == user.id) {
						selected = true;
						break;
					}
				}
				if (!selected) vm.availableUsers.push(user);
			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}
		}
    	
    	// builds the display name key for a given user
    	function buildUserDisplayName(user) {
    		var name = '';
    		
    		// build name portion of string
    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
    			name += user.firstName;
    			
    			if  (user.lastName && user.lastName !== '') {
    				if (name.length>0) name += ' ';
    				name += user.lastName;
    			}
    		}
    		else {
    			name += user.email;
    		}
    		
    		return name;
    	}
    	
    	// builds the text used to display in listbox option
    	function buildUserNameOption(user) {
    		var name = user.displayName;
    		
    		if (user.showEmail) {
    			name += ' (';
    			name += user.email;
    			name += ')';
    		}
    		
    		// append "pending" notice for users that have not accepted yet
    		if (!user.inviteAccepted)
    			name += ' (pending)';
    		
    		return name;
    	}
    	
    	// selects a user
    	function selectUser(user) {
		// add user to selected if not already selected
		var selected = false;
		for (var j=0; j<vm.selectedUsers.length; j++) {
			var selectedUser = vm.selectedUsers[j];
			if (selectedUser.id == user.id) {
				selected = true;
				break;
			}
		}
		if (!selected) vm.selectedUsers.push(user);
    		
    		var idx = vm.availableUsers.indexOf(user);
    		if (idx >= 0)
    			vm.availableUsers.splice(idx, 1);
    		
    		vm.userGroupForm.$setDirty();
    	}
    	
    	// deselects a user
    	function deselectUser(user) {
    		vm.availableUsers.push(user);
    		
    		var idx = vm.selectedUsers.indexOf(user);
    		if (idx >= 0)
    			vm.selectedUsers.splice(idx, 1);
    		
    		vm.userGroupForm.$setDirty();
    	}
    	
    	// selects all users
    	function selectAllUsers() {
    		while (vm.availableUsers.length > 0)
    			selectUser(vm.availableUsers[0]);
    	}
    	
    	// deselects all users
    	function deselectAllUsers() {
    		while (vm.selectedUsers.length > 0)
    			deselectUser(vm.selectedUsers[0]);
    	}
    	
    	// invoked when available user filter changes
    	function availableUserFilterChange() {
    		if (vm.availableUserFilter.length > 1) {
    			UserGroup.queryUserAutocomplete({grouptype:  vm.currentUserGroup.userGroupType, name: vm.availableUserFilter}, function(data) {
    				
    				//vm.originalAvailableUsers = vm.currentUserGroup.availableUsers;
    				processUsers(data.availableUsers)
    				vm.currentUserGroup.availableUsers = vm.availableUsers;
    			});
    		}
    		else {
			processUsers(vm.originalAvailableUsers);
			vm.currentUserGroup.availableUsers = vm.availableUsers
    		}
    	}
    	
    	// invoked when selected user filter changes
    	function selectedUserFilterChange() {
    		vm.selectedUserFilterApplied = vm.selectedUserFilter.length > 1 ? vm.selectedUserFilter : '';
    	}
        
    	
    	
    	
        
    function processUserGroups(data) {
		vm.userGroups = [];
		
		for (var i=0; i<data.length; i++) {
			var userGroup = data[i];
			if(userGroup.active){userGroup.status = 'Active';}
			else {userGroup.status = 'Inactive';}
			vm.userGroups.push(userGroup);
		}	
		}
    
    function sortBySortingName(){
        var sortDir = vm.reverse ? 'desc' : 'asc';
        vm.userGroupSort = ['groupName,' + sortDir];
        loadUserGroups();
    }

    function addRequiredContractType(){
        if(vm.newRequiredContractType == null || vm.newRequiredContractType == ""){return;}
        
        if (vm.currentEntityType.requiredContractTypes.length>0) {
            for (var i=0; i<vm.currentEntityType.requiredContractTypes.length; i++) {
                if (vm.currentEntityType.requiredContractTypes[i].name === vm.newRequiredContractType) {
                    GlobalModal.displayError("Cannot have duplicate contract types");
                    return false;
                }
            }
        }
         
        var orgReqContentType = {};
        orgReqContentType.name = vm.newRequiredContractType; 
        orgReqContentType.active = true;
        vm.currentEntityType.requiredContractTypes.unshift(orgReqContentType);
        vm.newRequiredContractType = "";    
   }

    function removeRequiredContractType(ct){
        vm.currentEntityType.requiredContractTypes.splice(vm.currentEntityType.requiredContractTypes.indexOf(ct), 1);
    }
    
   }
   
})();
