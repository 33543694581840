(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sysadmin-saml', {
            parent: 'sysadmin',
            url: '/sysadmin-saml',
            views: {
                'content@': {
                    templateUrl: 'app/sysadmin/saml/sysadmin-saml.html',
                    controller: 'SysAdminSamlController',
                    controllerAs: 'vm'
                }
            	
            }
        });
    }
})();
