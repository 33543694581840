(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('dc-flows', {
			parent: 'orgadmin',
			url: '/dc-flows',
			data: {
				authorities: [
					'ROLE_ORG_ADMIN'
				]
			},
			views: {
				'content@': {
					templateUrl: 'app/datacenter/dc-flows/dc-flows.html',
					controller: 'DcFlowsController',
					controllerAs: 'vm'
				}
			}
		});
	}
})();
