(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .factory('AttributesService', AttributesService);

    AttributesService.$inject = ['$resource'];

    function AttributesService ($resource) {
        var service = $resource('api/userAttributes', {}, {
            'get': {
                method: 'GET',
                isArray: true
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'getUserAttributeValues': {
            	url:'api/userAttributes/:userId/values',
                method: 'GET',    
                isArray : true
            },
            'saveUserAttributeValues': {
            	url:'api/userAttributes/:userId/values',
                method: 'POST',
                isArray : true
            },            
        });

        return service;
    }
})();
