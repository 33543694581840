(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sysadmin-setup', {
            parent: 'sysadmin',
            url: '/sysadmin-setup',
            data: {
            	pageTitle: 'SysAdmin:OrgSetup - DataRail'
            },
            views: {
                'content@': {
                    templateUrl: 'app/sysadmin/setup/sysadmin-setup.html',
                    controller: 'SysAdminSetupController',
                    controllerAs: 'vm'
                },
	            'add@sysadmin-setup': {
	                templateUrl: 'app/sysadmin/setup/sysadmin-setup-add.html',
	            },
	            'neworg@sysadmin-setup': {
	                templateUrl: 'app/sysadmin/setup/sysadmin-setup-neworg.html',
	            },
                'existing-tabs@sysadmin-setup': {
                    templateUrl: 'app/sysadmin/setup/sysadmin-setup-existing-tabs.html',
                },
	            'existing-main@sysadmin-setup': {
	                templateUrl: 'app/sysadmin/setup/sysadmin-setup-existing-main.html',
	            },
                'existing-features@sysadmin-setup': {
                    templateUrl: 'app/sysadmin/setup/sysadmin-setup-existing-features.html',
                },
                'existing-files@sysadmin-setup': {
                    templateUrl: 'app/sysadmin/setup/sysadmin-setup-existing-files.html',
                },
            }
        });
    }
})();
