(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('HomeDatasetsController', HomeDatasetsController);

    HomeDatasetsController.$inject = ['$scope', '$state', 'Dataset', 'Principal', 'DownloadService', 'PreviewService'];

    function HomeDatasetsController ($scope, $state, Dataset, Principal, DownloadService, PreviewService) {
        var vm = this;
        
        Principal.identity()
	    	.then(function(identity) {
	    		vm.identity = identity;
            vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
	    	});
    
        vm.openDataset = openDataset;
        vm.viewMore = viewMore;
        vm.previewDataset = previewDataset;
        vm.generatePurgedDatasetTooltip = generatePurgedDatasetTooltip;
        
        Dataset.query({page: 0, size: 4}, {},
    		function(result, headers) {
        		vm.datasets = result;
                vm.totalItems = headers('X-Total-Count');
    		});
        
        $scope.$watch('vm.datasets', function() {
      		enableTooltips();
        });
        
        function openDataset(dataset) {
            	// send user back to configure page for new uploads
            	if (vm.isSupplier && dataset.status === 'NEW') {
            		$state.go('upload-configure', {
            			datasetId: dataset.id,
            		});
            	}
            	else if (dataset.status === 'READY' ||dataset.status === 'SHARED') {
            		if (vm.isSupplier && vm.identity.id === dataset.ownerId) {
                		$state.go('share', {
                			datasetId: dataset.id,
                		});
                }
                else if (dataset.previewOnly) {
                    previewDataset(dataset);
                }
                else {
                    downloadDataset(dataset);
        		    }
        	    }
        }
        
        function generatePurgedDatasetTooltip(dataset) {	    		        			        		
	       return 'This Dataset was purged on ' + moment(dataset.purgeTimeStamp).format( " YYYY-MM-DD");	        
   
        }
        
        function downloadDataset(dataset) {
        	if (dataset.datasetType == "BLOB") {
    			DownloadService.downloadDatasetBlobFile(dataset.id, function() {
					GlobalModal.displayError('There was a problem with your download. Please try again.');
				});
        	}
        	else {
	    		$state.go('download', {
	    			datasetId: dataset.id,
	    		});
        	}
        }
        
        function previewDataset(dataset) {
            if (dataset.datasetType == "BLOB") {
                PreviewService.previewDatasetBlobFile(dataset.id, function() {
                    GlobalModal.displayError('There was a problem with your preview. Please try again.');
                });
            }
        }
        
        function viewMore() {
        	$state.go('datasets');
        }
    }
})();
