(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AccountAdminController', AccountAdminController);

    AccountAdminController.$inject = ['$state', 'User', 'Principal', 'GlobalModal', 'AuthServerProvider', 'ServiceEndpoint', 'clipboard'];

    function AccountAdminController ($state, User, Principal, GlobalModal, AuthServerProvider, ServiceEndpoint, clipboard) {
        var vm = this;
        
        Principal.identity()
	        .then(function(identity) {
	        	vm.identity = identity;
	        });

        vm.loadUsers = loadUsers;
        vm.loadPage = loadPage;
        vm.createUser = createUser;
        vm.editUser = editUser;
        vm.saveUser = saveUser;
        vm.resendInvite = resendInvite;
        vm.markInvited = markInvited;
        vm.markNotInvited = markNotInvited;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
        vm.sortByEmail = sortByEmail;
        vm.unlockAccount = unlockAccount;
        vm.resetLastAccess = resetLastAccess;
        vm.generateAccessKey = generateAccessKey;
        vm.revokeAccessKey = revokeAccessKey;
        vm.copySecretKey = copySecretKey;
        vm.generateTestPassword = generateTestPassword;
        vm.copyTestPassword = copyTestPassword;
        vm.changeToService = changeToService;
        vm.updateEndpointRules = updateEndpointRules;
        vm.cancelEditEndpointRules = cancelEditEndpointRules;
        vm.saveEndpointRules = saveEndpointRules;
        vm.addMimeType = addMimeType;
        vm.removeMimeType = removeMimeType;
        vm.viewEndpointQueue = viewEndpointQueue;
        vm.sbAuth = false;
		vm.clipboardSupported = clipboard.supported;
		vm.editEndpointRules = false;
        
		vm.userFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.userSort = ['lastName', 'firstName'];
        vm.activeOnly = false;
        vm.nextAvailableUserQueryId = 0;
        vm.intialemployeeStatus = true;
        vm.levelupUser = levelupUser;
        
        // check that SB authentication is allowed to allow creation / modification of users
		AuthServerProvider.getAuthenticationMethod()
			.then(function(method) {
				vm.sbAuth = method.authenticationType == 'SB' || !method.disableSquirrelboxAuthentication;
			});
        
        loadUsers();
       
        function loadUsers() {
            vm.currentAvailableUserQueryId = vm.nextAvailableUserQueryId++;
            var queryId = vm.currentAvailableUserQueryId; 
            User.query({name: vm.userFilter, activeOnly: vm.activeOnly, page: vm.page-1, size: vm.itemsPerPage, sort: vm.userSort}, 
                function(users, headers) {
                    if (queryId == vm.currentAvailableUserQueryId) {
            	            	processUsers(users)
            	            	vm.totalItems = headers('X-Total-Count');
            	            	
            	            	if (!vm.currentUser && vm.users.length > 0)
            	            		editUser(vm.users[0]);
            	            }
                     }
                );
        }
        
        function loadPage(page) {
            vm.page = page;
            loadUsers();
        }
        
        function createUser() {
	        	vm.currentUser = {
	        		accountType: vm.sbAuth ? 'USER' : 'SERVICE',
	        		activated: true,
	        		authority: vm.sbAuth ? 'C' : 'S',
	        		accessKeyEnabled: false,
	        		serviceEndpoint: false,
	        		rcFilteringEnabled: true
	        	}
	        	
            vm.editEndpointRules = false;
        }
        
        function editUser(user) {
        	if (!vm.identity) return false;
        		
        		if (vm.userForm)
        			vm.userForm.$setPristine();
	        	
        		vm.editEndpointRules = false;

	        	var authoritySupplier = user.authorities.indexOf('ROLE_SUPPLIER') >= 0,
	    			authorityConsumer = user.authorities.indexOf('ROLE_CONSUMER') >= 0,
	    			authorityAdmin = user.authorities.indexOf('ROLE_ORG_ADMIN') >= 0;
	    			
	    		var isOwner = authoritySupplier && user.authorities.indexOf('ROLE_OWNER') >= 0;
	    		
	        	vm.currentUser = {
	        		accountType: user.accountType,
	        		id: user.id,
	        		login: user.login,
	        		email: user.email,
	        		firstName: user.firstName,
	        		lastName: user.lastName,
	        		activated: user.activated,
	        		authority: authorityAdmin ? 'A' : authoritySupplier ? 'S' : 'C',
	        		createdDate: user.createdDate,
	        		lastModifiedDate: user.lastModifiedDate,
	        		inviteAccepted: user.inviteAccepted,
	        		accountLocked: user.accountLocked,
	        		accountLockedTime: user.accountLockedTime,
	        		accessKeyEnabled: user.accessKeyEnabled,
	        		accessKey: user.accessKey,
	        		isOwner: isOwner,
	        		lastAccessDate: user.lastAccessDate,
	        		serviceEndpoint: user.serviceEndpoint ? true : false,
    				rcFilteringEnabled: user.rowColumnFilteringEnabled ? true : false,
    				employeeStatus: user.employeeStatus ? true : false
	        	}
	        	
	        	vm.intialemployeeStatus = user.employeeStatus ? true : false;
	        	
	        	vm.endpointRules = {
	        	    allMimeTypes: true,
	        	    docMimeTypes: true,
	        	    pptMimeTypes: true,
	        	    xlsMimeTypes: true,
	        	    csvMimeTypes: true,
	        	    textMimeTypes: true,
	        	    validMimeTypes: [],
	        	}
	        	
	        	vm.userBeingEdited = user;
	        	
	        	vm.secretKey = null;
	        	vm.testPassword = null;
        }
        
        function saveUser() {
	        	if (vm.currentUser) {
	        		vm.userForm.$setSubmitted();
	            	
	        		if(!vm.userForm.$valid) return;
	        		
	        		var user = {
	        			accountType: vm.currentUser.accountType,
	        			id: vm.currentUser.id,
	        			email: vm.currentUser.email,
	        			activated: vm.currentUser.activated,
	        			authorities: [],
	        			accessKeyEnabled: vm.currentUser.accessKeyEnabled,
	        			rowColumnFilteringEnabled: vm.currentUser.rcFilteringEnabled,
	        			employeeStatus: vm.currentUser.employeeStatus ? true : false
	        		}
	        		
	        		if (vm.currentUser.accountType === 'SERVICE') {
	        			user.login = vm.currentUser.login;
	        			user.email = null;
	        			user.lastName = vm.currentUser.lastName;
	        			user.firstName = '';
	        			user.serviceEndpoint = vm.currentUser.serviceEndpoint;
	        		}
                else if (vm.currentUser.accountType === 'TEST') {
                    if (user.email && !validateEmail(user.email)) {
                        GlobalModal.displayError("Please enter a valid email address");
                        return false;
                    }
                    
                    user.login = vm.currentUser.login;
                    user.lastName = vm.currentUser.lastName;
                    user.firstName = vm.currentUser.firstName;
                }
	        		else if (!validateEmail(user.email)) {
	        			GlobalModal.displayError("Please enter a valid email address");
	        			return false;
	        		}
		        		
	        		if (!vm.currentUser.authority) {
	        			GlobalModal.displayError("Please assign a role to user");
	        			return;
	        		}
	        		if (vm.currentUser.authority === 'C') {
	        			user.authorities.push('ROLE_CONSUMER');
	        		}
	        		else if (vm.currentUser.authority === 'S') {
	        			user.authorities.push('ROLE_CONSUMER');
	        			user.authorities.push('ROLE_SUPPLIER');
	        			
	        			if (vm.currentUser.isOwner)
		        			user.authorities.push('ROLE_OWNER');
	        		}
	        		else if (vm.currentUser.authority === 'A') {
	        			user.authorities.push('ROLE_CONSUMER');
	        			user.authorities.push('ROLE_SUPPLIER');
	        			user.authorities.push('ROLE_ORG_ADMIN');
	        			
	        			if (vm.currentUser.isOwner)
		        			user.authorities.push('ROLE_OWNER');
	        		}
	        		
	        		// update user
	        		if (user.id > 0) {
	        			User.update(user, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadUsers();
	        				editUser(data);
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userexists')
	        					GlobalModal.displayError('User already exists in system');
	        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.emailexists')
	        					GlobalModal.displayError('Email already assigned to another user');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.passwordvalidation')
                            GlobalModal.displayError('Password does not meet organization\'s requirements');
	        				else
	        					GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        		
	        		// create user
	        		else {
	        			User.save(user, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadUsers();
	        				editUser(data);
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userexists')
	        					GlobalModal.displayError('User already exists in system');
	        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.emailexists')
	        					GlobalModal.displayError('Email already assigned to another user');
	        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.passwordvalidation')
	        					GlobalModal.displayError('Password does not meet organization\'s requirements');
	        				else
	        					GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        	}
        }
        
        // resends invitation to current user
        function resendInvite() {
        		User.resendInvite({id: vm.currentUser.id}, {}, function() {
	        		GlobalModal.displaySuccess('The invitation to the user has been re-sent');
        		});
        }
        
     // Level up all files and ext assoications for this user
        function levelupUser() {
        	
        	GlobalModal.confirm('You are about to levelup all files owned or shared by this user as well as any external associations. Please confirm ?', function() {
			  	
        		User.levelupUser({id: vm.currentUser.id}, {}, function() {
	        		GlobalModal.displaySuccess('All files and external user associations are successfully leveled up');
        		});
    		}, function(error) {
    			
    		});
        	
        	
        	
        }
        
        // marks user as having accepted an invitation
        function markInvited() {
            User.markInvitedAccepted({id: vm.currentUser.id}, {}, function() {
                    GlobalModal.displaySuccess("The user's invitation has been marked accepted");
                    vm.currentUser.inviteAccepted = true;
                    
                    // update the list's value as well
                    for (var i=0; i<vm.users.length; i++) {
                        if (vm.users[i].id === vm.currentUser.id) {
                            vm.users[i].inviteAccepted = true;
                            break;
                        }
                    }
                });
        }
        
        // marks user as having not accepted an invitation
        function markNotInvited() {
            User.markInvitedNotAccepted({id: vm.currentUser.id}, {}, function() {
                    GlobalModal.displaySuccess("The user's invitation has been marked not accepted");
                    vm.currentUser.inviteAccepted = false;
                    
                    // update the list's value as well
                    for (var i=0; i<vm.users.length; i++) {
                        if (vm.users[i].id === vm.currentUser.id) {
                            vm.users[i].inviteAccepted = false;
                            break;
                        }
                    }
                });
        }
        
        function processUsers(data) {
			vm.users = [];
			
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				//the users are sorted based on the sorting name. 
				user.sortingName = buildUserName(user);
				if(user.activated){user.status = 'Active';}
				else {user.status = 'Inactive';}
				(user.activated) ? (user.inviteAccepted) ? user.status='Active' : user.status='New' : user.status = 'Inactive';
				vm.users.push(user);
			}	
    		}
        
        function buildUserName(user) {
	        	var name = '';
	    		// build name portion of string
	    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
	    			name += user.lastName;
	    			
	    			if  (user.firstName && user.firstName !== '' && user.firstName.length > 0) {
	    				if (name.length>0) name += ', ';
	    				name += user.firstName;
	    			}
	    		}
	    		else {
	    			name = "";
	    		}
	    		return name;
        }
        
        function sortBySortingName(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['lastName,' + sortDir, 'firstName,' + sortDir];
            loadUsers();
        }
        
        function sortByEmail(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['email,' + sortDir];
            loadUsers();
        }
        
        // unlocks a locked user account
        function unlockAccount(user) {
        		User.unlock({id: user.id}, {}, function() {
        			GlobalModal.displaySuccess('Account has been unlocked');
        			user.accountLocked = false;
        			loadUsers();
        		});
        }
        
        // resets access time on a user account
        function resetLastAccess(user) {
                User.resetLastAccess({id: user.id}, {}, function() {
                    GlobalModal.displaySuccess('Last access has been reset');
                    user.lastAccessDate = null;
                    loadUsers();
                });
        }
        
        function generateAccessKey() {
	    		if (vm.currentUser.accessKey)
	    			GlobalModal.confirm('Are you sure you want to replace this user\'s API keys?', invokeGenerateAccessKeyCall);
	    		else
	    			invokeGenerateAccessKeyCall();
	    }
	    
	    function invokeGenerateAccessKeyCall() {
	    		User.generateAccessKey({id: vm.currentUser.id}, function(response) {
	    			vm.currentUser.accessKey = response.accessKey;
	    			vm.secretKey = response.secretKey;
	    			
	    			vm.userBeingEdited.accessKey = response.accessKey;
	    		}, function(error) {
	    			GlobalModal.displayError('Error generating API keys');
	    		});
	    }
	    
	    function revokeAccessKey() {
	    		GlobalModal.confirm('Are you sure you want to revoke this user\'s API keys?', function() {
	    	    		User.revokeAccessKey({id: vm.currentUser.id}, function(response) {
	    	    			vm.currentUser.accessKey = null;
	    	    			vm.secretKey = null;
	    	    			
	    	    			vm.userBeingEdited.accessKey = null;
	    	    		}, function(error) {
	    	    			GlobalModal.displayError('Error revoking API keys');
	    	    		});
	    		});
	    }
	    
	    function copySecretKey() {
	    		if (vm.clipboardSupported) {
	    			try {
	        			clipboard.copyText(vm.secretKey);
	        			GlobalModal.displayInfo('API Secret Key has been copied to your clipboard');
	    			}
	    			catch (ignored) {}
	    		}	
	    }
        
        function generateTestPassword() {
            GlobalModal.confirm('Are you sure you want to replace this user\'s password?', invokeGenerateTestPasswordCall);
        }
        
        function invokeGenerateTestPasswordCall() {
            User.generateTestPassword({id: vm.currentUser.id}, function(response) {
                vm.testPassword = response.password;
            }, function(error) {
                GlobalModal.displayError('Error generating test password');
            });
        }
        
        function copyTestPassword() {
                if (vm.clipboardSupported) {
                    try {
                        clipboard.copyText(vm.testPassword);
                        GlobalModal.displayInfo('Password has been copied to your clipboard');
                    }
                    catch (ignored) {}
                }   
        }

	    function changeToService() {
	    		if (vm.sbAuth) {
	    			vm.currentUser.authority  = 'S';
	    			vm.currentUser.isOwner = true;
	    		}
	    }
        
        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);            
        }
        
        function updateEndpointRules() {
            ServiceEndpoint.get({userId: vm.currentUser.id}, function(endpointRules) {
                    vm.endpointRules = endpointRules;
                    vm.endpointRules.validMimeTypes.sort();
                    vm.editEndpointRules = true;
                });
        }
        
        function cancelEditEndpointRules() {
            vm.editEndpointRules = false;
        }
        
        function saveEndpointRules() {
            // reset all to false if all mime types selected
            if (vm.endpointRules.allMimeTypes) {
                vm.endpointRules = {
                    allMimeTypes: true,
                    docMimeTypes: true,
                    pptMimeTypes: true,
                    xlsMimeTypes: true,
                    csvMimeTypes: true,
                    textMimeTypes: true,
                    validMimeTypes: vm.endpointRules.validMimeTypes,
                }
            }
        
            ServiceEndpoint.save({userId: vm.currentUser.id}, vm.endpointRules, function() {
                    GlobalModal.displaySuccess("Endpoint Rules saved sucessfully");
                    vm.editEndpointRules = false;
                }, function() {
                    GlobalModal.displayError("Failed to save Endpoint Rules");
                });
        }
        
        // add a mime type
        function addMimeType() {
            if (!vm.newMimeTypePattern || vm.newMimeTypePattern.length == 0) {
                GlobalModal.displayError('Please enter a mime type pattern');
                return;
            }
            
            var mimePattern = /^\S+\/\S+$/
            if (!mimePattern.test(vm.newMimeTypePattern)) {
                GlobalModal.displayError('Invalid mime type pattern');
                return;
            }
            
            for (var i=0; i<vm.endpointRules.validMimeTypes.length; i++) {
                var existingMimeType = vm.endpointRules.validMimeTypes[i];
                if (existingMimeType === vm.newMimeTypePattern) {
                    GlobalModal.displayError('Attribute already exists');
                    return;
                }
            }
            
            vm.endpointRules.validMimeTypes.push(vm.newMimeTypePattern);
            vm.endpointRules.validMimeTypes.sort();
            
            vm.newMimeTypePattern = '';
        }
        
        // removes a mime type
        function removeMimeType(value) {
            var idx = -1;
            for (var i=0; i<vm.endpointRules.validMimeTypes.length; i++) {
                var existingMimeType = vm.endpointRules.validMimeTypes[i];
                if (existingMimeType === value) {
                    idx = i;
                    break;
                }
            }
            
            vm.endpointRules.validMimeTypes.splice(idx, 1);
        }
        
        function viewEndpointQueue(user) {
            $state.go('endpoint-queue', {
                userId: user.id,
            });
        }
    }
   
})();
