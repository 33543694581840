(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('ExternalEntity', ExternalEntity);

    ExternalEntity.$inject = ['$resource', 'DateUtils'];

    function ExternalEntity ($resource, DateUtils) {
        var resourceUrl =  'api/externalEntities/:id';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' },
            'query': { method: 'GET', isArray: true },
            'queryForAutocomplete': { url: 'api/externalEntities/autocomplete', method: 'GET', isArray: true },
            'queryWithFilters': { url: 'api/externalEntities/queryWithFilter', method: 'GET', isArray: true },
            'getUsersByList' : { url: 'api/externalEntities/usersByList', method: 'GET' },
            'saveUserAssociations' : { url: 'api/externalEntities/bulkassociate', method: 'PUT' },
            'create': { method: 'POST' },
            'update': { method: 'PUT' },
            'queryUsers': {
	        		url: resourceUrl + '/users',
	        		method: 'GET',
	        		isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.map(function(item) {
                        item.creationTimeStamp = DateUtils.convertDateTimeFromServer(data.creationTimeStamp);
                    });
                    return data;
                }
	        	},
        	'getReport': {
        		url: '/api/entity-report',
        		method: 'GET',
        		isArray: true
    			},
    		'getAttestationReport': {
            		url: '/api/externalUserAttestConfirmations/update/:attestationId',
            		method: 'GET',
            		isArray: true
        			},
		'saveAttestationReport': {
    				url: '/api/externalUserAttestConfirmations/:attestationId',
    				method: 'PUT'
			},
			'getPendingAttestation': {
				url: '/api/extuseratteststatus/pending',
				method: 'GET'
			},
            'getUser': {
	        		url: resourceUrl + '/users/:eeuId',
	        		method: 'GET'
	        	},
            'createUser': {
            		url: resourceUrl + '/users',
            		method: 'POST'
            	},
            'updateUser': {
            		url: resourceUrl + '/users/:eeuId',
            		method: 'PUT'
            	},
            'resendInvite': {
            		url: resourceUrl + '/users/:eeuId/resendInvite',
            		method: 'PUT'
            	},
            'queryInternalUserAutocomplete': {
	        		url: resourceUrl + '/users/internal',
	        		method: 'GET',
	        		isArray: true,
	        	},
	        	'queryInternalUserAutocompleteForBulkAssoc': {
	        		url:  'api/externalEntities/internalusers',
	        		method: 'GET',
	        		isArray: true,
	        	},
	        	'queryExternalUserAutocompleteForBulkAssoc': {
	        		url:  'api/externalEntities/externalusers',
	        		method: 'GET',
	        		isArray: true,
	        	},
	        	'signEntityForAgreementRevision': {
	        		url: 'api/externalEntities/:id/signAgreements/:arId',
	        		method: 'PUT'
	        	},
        });
    }
})();
