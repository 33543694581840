(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('UserGroup', UserGroup);

	UserGroup.$inject = ['$resource'];

	var resourceUrl =  'api/userGroups';
	function UserGroup ($resource) {
		var service = $resource(resourceUrl, {}, {
			'query': {
				url: resourceUrl,
				method: 'GET', 
				isArray: true},
			'getAvailableUsers': {
				url: resourceUrl + '/users/:grouptype',
				method: 'GET' },
			'queryUserAutocomplete': {
				url: resourceUrl + '/users/query',
				method: 'GET' ,	
				},	
            'save': { 
	            	url: resourceUrl,
	            	method:'POST' },
            'update': { 
             	url: resourceUrl + '/:id',
             	method:'PUT' },
             'create': { 
             	url: resourceUrl + '/create',
             	method:'GET' },
            'get': { 
             	url: resourceUrl + '/:id',
             	method:'GET' },
		});

		return service;
	}
})();
