(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ContentTypeAdminController', ContentTypeAdminController);

    ContentTypeAdminController.$inject = ['ContentType', 'GlobalModal'];

    function ContentTypeAdminController (ContentType, GlobalModal) {
        var vm = this;
        
        vm.loadContentTypes = loadContentTypes;
        vm.loadPage = loadPage;
        vm.createContentType = createContentType;
        vm.editContentType = editContentType;
        vm.saveContentType = saveContentType;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
        
		vm.contentTypeFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.contentTypeSort = ['contentType'];
        vm.activeOnly = false;
        
        loadContentTypes();
       
        function loadContentTypes() {
            ContentType.query({name: vm.contentTypeFilter, activeOnly: vm.activeOnly, page: vm.page-1, size: vm.itemsPerPage, sort: vm.contentTypeSort}, 
                function(contentTypes, headers) {
        	            	processContentTypes(contentTypes)
        	            	vm.totalItems = headers('X-Total-Count');
        	            	
        	            	if (!vm.currentContentType && vm.contentTypes.length > 0)
        	            		editContentType(vm.contentTypes[0]);
        	            }
                );
        }
        
        function loadPage(page) {
            vm.page = page;
            loadContentTypes();
        }
        
        function createContentType() {
	        	vm.currentContentType = {
	        		activated: true,
	        		requireBusinessArea: false
	        	}
        }
        
        function editContentType(contentType) {
        		if (vm.contentTypeForm)
        			vm.contentTypeForm.$setPristine();
	        	
	        	vm.currentContentType = {
	        	    contentType: contentType.contentType,
	        		id: contentType.id,
	        		description: contentType.description,
	        		active: contentType.active,
	        		attestation: contentType.attestation,
	        		requireBusinessArea: contentType.requireBusinessArea ? true : false,
	        	}
	        	
	        	vm.contentTypeBeingEdited = contentType;
        }
        
        function saveContentType() {
	        	if (vm.currentContentType) {
	        		vm.contentTypeForm.$setSubmitted();
	            	
	        		if(!vm.contentTypeForm.$valid) return;
	        		
	        		var contentType = {
                    contentType: vm.currentContentType.contentType,
                    id: vm.currentContentType.id,
                    description: vm.currentContentType.description,
                    active: vm.currentContentType.active,
                    attestation: vm.currentContentType.attestation,
                    requireBusinessArea: vm.currentContentType.requireBusinessArea,
	        		}
	        		
	        		// update content type
	        		if (contentType.id > 0) {
	        		    ContentType.update({id: contentType.id}, contentType, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadContentTypes();
	        				editContentType(data);
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.contentTypeExists')
	        					GlobalModal.displayError('Content Type already exists in system');
	        				else
	        					GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        		
	        		// create content type
	        		else {
	        		    ContentType.save(contentType, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadContentTypes();
	        				editContentType(data);
	        			}, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.contentTypeExists')
                            GlobalModal.displayError('Content Type already exists in system');
                        else
                            GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        	}
        }
        
        function processContentTypes(data) {
			vm.contentTypes = [];
			
			for (var i=0; i<data.length; i++) {
				var contentType = data[i];
				if(contentType.activated){contentType.status = 'Active';}
				else {contentType.status = 'Inactive';}
				vm.contentTypes.push(contentType);
			}	
    		}
        
        function sortBySortingName(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['contentType,' + sortDir];
            loadContentTypes();
        }
    }
   
})();
