(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('UserAccountController', UserAccountController);

    UserAccountController.$inject = ['$scope', '$state', 'Principal', 'User', 'AuthServerProvider', 'OrgSetUpService', 'GlobalModal', 'Account', 'clipboard'];

    function UserAccountController ($scope, $state, Principal, User, AuthServerProvider, OrgSetUpService, GlobalModal, Account, clipboard) {
        var vm = this;
        
        vm.saveChanges = saveChanges;
        vm.validateEmail = validateEmail;
        vm.generateAccessKey = generateAccessKey;
        vm.revokeAccessKey = revokeAccessKey;
        vm.copySecretKey = copySecretKey;
        
        vm.emailValidity = true;
        vm.saveChangesError = false; 
        vm.sbAuth = false;
		vm.clipboardSupported = clipboard.supported;
		vm.emailRequired = true;
		vm.firstNameRequired = true;
		vm.viewSignedAgreements = viewSignedAgreements;
		vm.isExternal = false;
		
        OrgSetUpService.getOrganization().then(function(response) {
        		vm.org = response.data;
        });

        // check that SB authentication is allowed to allow creation / modification of password
		AuthServerProvider.getAuthenticationMethod()
			.then(function(method) {
				vm.sbAuth = method.authenticationType == 'SB' || !method.disableSquirrelboxAuthentication;
			});
        
        Principal.identity()
        		.then(function(identity) {
        			vm.userDetails = angular.copy(identity);
        			isEmailRequired(identity.accountType);
        			vm.isExternal = Principal.hasAuthority('ROLE_EXTERNAL', identity);      			
        		}
        	);
        
        function viewSignedAgreements() {
        	 	$state.go('user-agreement-history');
        }
        function saveChanges(){
	        	if (vm.sbAuth) {
		        	if (vm.password!=null && vm.password.length < vm.org.passwordMinLength) return false;
		        	if (vm.password !== vm.confirmPassword){
		        		vm.noMatch = true;
		        		return false;
		        	}
	        	}
	        	
	        	if($scope.updateForm.$valid && vm.password == vm.confirmPassword){
	        	    vm.userDetails.password = vm.password;
                vm.saveChangesError = false;
	        		User.saveChanges({id : vm.userDetails.id},vm.userDetails, function() {
	        			Principal.identity(true)
		        	        .then(function(identity) {
		        	        	vm.userDetails = angular.copy(identity);        	
		        	        	GlobalModal.displaySuccess("Your changes have been saved");
		        	        });
	        		}, function(error) {
                    if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.passwordvalidation')
                        GlobalModal.displayError('Password does not meet organization\'s requirements');
                    else
	        			    vm.saveChangesError = true;
	        		});
	        	}
	        	else {
	        		vm.saveChangesError = true;
	        	}
        }
        
        function generateAccessKey() {
        		if (vm.userDetails.accessKey)
        			GlobalModal.confirm('Are you sure you want to replace your API keys?', invokeGenerateAccessKeyCall);
        		else
        			invokeGenerateAccessKeyCall();
        }
        
        function invokeGenerateAccessKeyCall() {
	    		Account.generateAccessKey(function(response) {
	    			vm.userDetails.accessKey = response.accessKey;
	    			vm.secretKey = response.secretKey;
	    		}, function(error) {
	    			GlobalModal.displayError('Error generating API keys');
	    		});
	    }
	    
        function revokeAccessKey() {
        		GlobalModal.confirm('Are you sure you want to revoke your API keys?', function() {
	    	    		Account.revokeAccessKey(function(response) {
	    	    			vm.userDetails.accessKey = null;
	    	    			vm.secretKey = null;
	    	    		}, function(error) {
	    	    			GlobalModal.displayError('Error revoking API keys');
	    	    		});
        		});
	    }
	    
        function copySecretKey() {
	    		if (vm.clipboardSupported) {
	    			try {
	        			clipboard.copyText(vm.secretKey);
	        			GlobalModal.displayInfo('API Secret Key has been copied to your clipboard');
	    			}
	    			catch (ignored) {}
	    		}	
        }
        
        function validateEmail(email) {
        		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            vm.emailValidity = re.test(email); 
           
        		if( !vm.emailRequired && typeof email == "string" && email.length == "") {
        			vm.emailValidity = true;
        		}
            
        }
        
        function isEmailRequired(accountType) {
        		
        		if (accountType == 'TEST') {
        			vm.emailRequired = false;
        			vm.firstNameRequired =false;
        		} else {
        			vm.emailRequired = true;
        			vm.firstNameRequired =true;
        		}
        		           
        }
        
    }   
})();
