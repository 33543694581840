(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('User', User);

	User.$inject = ['$resource'];

	function User ($resource) {
		var service = $resource('api/users/:login', {}, {
			'query': {method: 'GET', isArray: true},
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'save': { method:'POST' },
			'update': { method:'PUT' },
			'delete':{ method:'DELETE'},
			'checkInvitation': {
				url: 'api/users/invitations/:activationKey',
				method: 'GET',
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},   
			'checkInvitationExpiry': {
				url: 'api/users/invitations/expiry/:activationKey',
				method: 'GET',
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'acceptInvitation': { 
				url: 'api/users/invitations/:activationKey',
				method:'PUT' 
			},

			'saveChanges': { 
				url: 'api/users/:id',
				method:'PUT' 
			},           

			'resendInvite': { 
				url: 'api/users/:id/resendInvite',
				method:'PUT' 
			},

            'markInvitedAccepted': { 
                url: 'api/users/:id/markInvitedAccepted',
                method:'PUT' 
            },

            'markInvitedNotAccepted': { 
                url: 'api/users/:id/markInvitedNotAccepted',
                method:'PUT' 
            },

			'checkForEmail': { 
				url: 'api/users/passwordReset/checkForEmail/sendResetEmail',
				method:'PUT' 
			},

			'checkResetKey': { 
				url: 'api/users/passwordReset/checkResetKey/:resetKey',
				method:'GET'
			},
			'resetPassword':{
				url: 'api/users/passwordReset/resetPassword/:resetKey',
				method:'PUT'
			},
			'unlock': { 
				url: 'api/users/:id/unlock',
				method:'PUT' 
			},
            'resetLastAccess': { 
                url: 'api/users/:id/resetLastAccess',
                method:'PUT' 
            },
            'generateAccessKey': {
	        		url: 'api/users/:id/newAccessKey',
	        		method: 'GET'
	        },
	        'revokeAccessKey': {
	        		url: 'api/users/:id/revokeAccessKey',
	        		method: 'GET'
	        },
            'generateTestPassword': {
                url: 'api/users/:id/generateTestPassword',
                method: 'GET'
            },
            'levelupUser': { 
                url: 'api/users/:id/levelupuser',
                method:'PUT' 
            },
		});

		return service;
	}
})();
