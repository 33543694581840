(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$state', 'VERSION', 'ProfileService'];

    function FooterController ($state, VERSION, ProfileService) {
        var vm = this;
        vm.inDevelopment = true;
        vm.version = VERSION;
        vm.refresh = refresh;
        
        ProfileService.getProfileInfo().then(function(response) {
            vm.inDevelopment = response.inDevelopment;
            vm.serverVersion = response.version;
        })
        .catch(function (e) {
        	console.error(e);
        });
        
        function refresh() {
        	location.reload();
        }        
    }
})();
