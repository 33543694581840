(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('tagAdminDroppable', TagAdminDroppableDirective);

    TagAdminDroppableDirective.$inject = ['Tag', 'GlobalModal'];

    function TagAdminDroppableDirective (Tag, GlobalModal) {
        	return {
        	    link: function(scope, element, attr) {
        	        var select = $(element);
        	        
        	        select.droppable({
        	            tolerance: 'pointer',
        	            accept: '.tag-admin-tag',
        	            classes: {
        	                "ui-droppable-active": scope.tag ? "tag-droppable-active" : "",
        	                "ui-droppable-hover": scope.tag ?"tag-droppable-hover" : "" 
        	            },
        	            drop: function( event, ui ) {
        	                var vm = scope.vm;
        	                var draggableTagId = ui.draggable.attr('tag_id'); 
        	                var draggableTag = vm.tagMap[draggableTagId];
        	                
        	                // if targeting a tag, make sure it is not a descendent
        	                if (scope.tag) {
        	                    var p = scope.tag.parent;
        	                    while (p) {
        	                        if (p === draggableTag) {
        	                            return false;
        	                        }
        	                        p = p.parent;
        	                    }
        	                }
        	                
        	                // remove from parent tag
        	                if (draggableTag.parent) {
        	                    var idx = draggableTag.parent.children.indexOf(draggableTag);
        	                    draggableTag.parent.children.splice(idx, 1);
        	                    draggableTag.parent.numChildren = draggableTag.parent.children.length;
        	                }
        	                
        	                // remove from top level tags
        	                else {
                            var draggableGroup = vm.tagGroup(draggableTag);
                            var tagList = vm.tagGroups[draggableGroup];
                            var idx = tagList.indexOf(draggableTag);
                            tagList.splice(idx, 1);
                            
                            var idx = vm.topTags.indexOf(draggableTag);
                            vm.topTags.splice(idx, 1);
        	                }
        	                
        	                // assign to target tag
        	                var parentTagId = 0;
        	                if (scope.tag) {
                            var droppableTag = scope.tag;
                            parentTagId = droppableTag.id;
                            draggableTag.parent = droppableTag;
                            if (!droppableTag.children) droppableTag.children = [];
                            droppableTag.children.push(draggableTag);
                            droppableTag.numChildren = droppableTag.children.length;
                            vm.sortTagArray(droppableTag.children);
                            droppableTag.open = true;
        	                }
        	                else {
                            draggableTag.parent = null;
        	                    vm.topTags.push(draggableTag);
                            vm.sortTagArray(vm.topTags);
        	                }

                        // update top level groups
        	                vm.updateTagGroups();
        	                
        	                scope.$apply();
        	                
        	                // make assignment on server
        	                Tag.assignParentTag({id: draggableTag.id}, {parentTagId: parentTagId}, function() {
        	                }, function() {
        	                    GlobalModal.displayError('Error updating tag parent.');
        	                })
        	                
        	                return true;
        	            }
        	        });
        	    }
        	}
    }
})();
