(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('UserImportService', UserImportService);

    UserImportService.$inject = ['$resource', 'DateUtils'];

    function UserImportService($resource, DateUtils) {
        var resourceUrl =  'api/userInfo';

        return $resource(resourceUrl, {}, {
            	
            	'processUserImport': {
             		url: resourceUrl + '/processimport/:batchid',
             		method: 'PUT' 
            },
            'checkExistingBatchProcess': {
         		url: resourceUrl + '/batchprocess',
         		method: 'GET' ,
         		isArray: true
            },
            'getFilesByBatchId': {
         		url: resourceUrl + '/files/:batchid',
         		method: 'GET' ,
         		isArray: true
            },           
            'deleteBatchEntry': {
         		url: resourceUrl + '/delete/:batchid',
         		method: 'PUT'
            },
            'createNewBatchProcess': {
         		url: resourceUrl + '/createnewbatchprocess',
         		method: 'POST',
         		isArray: true
            }
           
        });
    }
})();
