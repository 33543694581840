(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AnnouncementsController', AnnouncementsController);

    AnnouncementsController.$inject = ['Announcement', 'GlobalModal'];

    function AnnouncementsController(Announcement, GlobalModal) {
        var vm = this;

        vm.loadAnnouncements = loadAnnouncements;
        vm.loadPage = loadPage;
        vm.createAnnouncement = createAnnouncement;
        vm.editAnnouncement = editAnnouncement;
        vm.saveAnnouncement = saveAnnouncement;
        vm.clearDismissals = clearDismissals;
        vm.closePreview = closePreview;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;

        vm.calendarOpen = false;
        vm.openCalendar = openCalendar;

        vm.announcementFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.announcementSort = ['name'];
        vm.activeOnly = false;

        loadAnnouncements();

        function loadAnnouncements() {
            processAnnouncements([])
            vm.totalItems = 0;

            Announcement.query({ name: vm.announcementFilter, activeOnly: vm.activeOnly, page: vm.page - 1, size: vm.itemsPerPage, sort: vm.announcementSort },
                function(announcements, headers) {
                    processAnnouncements(announcements)
                    vm.totalItems = headers('X-Total-Count');

                    if (!vm.currentAnnouncement && vm.announcements.length > 0)
                        editAnnouncement(vm.announcements[0]);
                }
            );
        }

        function loadPage(page) {
            vm.page = page;
            loadAnnouncements();
        }

        function createAnnouncement() {
            vm.currentAnnouncement = {
                active: true,
                dismissable: true,
                loginDisplay: false,
                type: 'GENERAL'
            }
        }

        function editAnnouncement(announcement) {
            if (vm.announcementForm)
                vm.announcementForm.$setPristine();
            if (vm.preview)
                vm.preview = null;
            vm.currentAnnouncement = {
                name: announcement.name,
                id: announcement.id,
                active: announcement.active && !announcement.expired,
                loginDisplay: announcement.loginDisplay,
                text: announcement.text,
                dismissable: announcement.dismissable,
                type: announcement.type,
                expires: announcement.expires ? new Date(announcement.expires) : null
            }

            vm.announcementBeingEdited = announcement;
        }

        function closePreview() {
            vm.preview = null;
        }

        function clearDismissals(announcement) {
            Announcement.clearDismissals(announcement, function(data) {
                GlobalModal.displaySuccess('Previous user dismissals have been reset.');
                loadAnnouncements();
                editAnnouncement(data);
            }, function(error) {
                if (error) {
                    GlobalModal.displayError('Unable to reset dismissals.');
                }
            });
        }

        function saveAnnouncement(preview) {
            if (vm.currentAnnouncement) {
                vm.announcementForm.$setSubmitted();

                if (!vm.announcementForm.$valid) return;

                if (preview) {
                    vm.preview = {
                        name: vm.currentAnnouncement.name,
                        dismissable: vm.currentAnnouncement.dismissable,
                        type: vm.currentAnnouncement.type,
                        text: vm.currentAnnouncement.text
                    }
                    return;
                }

                var announcement = {
                    name: vm.currentAnnouncement.name,
                    org: vm.currentAnnouncement.org,
                    id: vm.currentAnnouncement.id,
                    active: vm.currentAnnouncement.active,
                    dismissable: vm.currentAnnouncement.dismissable,
                    loginDisplay: vm.currentAnnouncement.loginDisplay,
                    type: vm.currentAnnouncement.type,
                    expires: vm.currentAnnouncement.expires,
                    text: vm.currentAnnouncement.text
                }

                // update announcement
                if (announcement.id > 0) {
                    Announcement.update({ id: announcement.id }, announcement, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadAnnouncements();
                        editAnnouncement(data);
                    }, function(error) {
                        if (error) {
                            GlobalModal.displayError('Unable to save announcement. Please try again.');
                        }
                    });
                }

                // create announcement
                else {
                    Announcement.save(announcement, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadAnnouncements();
                        editAnnouncement(data);
                    }, function(error) {
                        if (error) {
                            GlobalModal.displayError('Unable to save announcement. Please try again.');
                        }
                    });
                }
            }
        }

        function processAnnouncements(data) {
            vm.announcements = [];

            for (var i = 0; i < data.length; i++) {
                var announcement = data[i];
                if (announcement.active) { announcement.status = 'Active'; }
                else { announcement.status = 'Inactive'; }

                vm.announcements.push(announcement);
            }
        }

        function openCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.calendarOpen = true;
        }

        function sortBySortingName() {
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['name,' + sortDir];
            loadAnnouncements();
        }
    }

})();
