(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('HomeNotificationsController', HomeNotificationsController);

    HomeNotificationsController.$inject = ['$rootScope', '$scope', '$state', '$localStorage', '$interval', 'Notification', 'NotificationTimeline'];

    function HomeNotificationsController ($rootScope, $scope, $state, $localStorage, $interval, Notification, NotificationTimeline) {
        var vm = this;

        vm.expandCollapse = expandCollapse;
        vm.notificationText = NotificationTimeline.notificationText;
        vm.notificationDescription = NotificationTimeline.notificationDescription;
        vm.generatePurgedDatasetTooltip = NotificationTimeline.generatePurgedDatasetTooltip;
        vm.notificationIcon = NotificationTimeline.notificationIcon;
        
        vm.viewMore = viewMore;
        
        $scope.notificationAnchorMethods = NotificationTimeline.notificationAnchorMethods;
        
        Notification.query({page: 0, size: 10}, {},
        		function(result, headers) {
            		vm.notifications = NotificationTimeline.insertDateHeaders(result);
                    vm.totalItems = headers('X-Total-Count');
        		});
        
        vm.refreshIntervalPromise = $interval(function() {
        	if (vm.notifications && !vm.backgroundRefreshInProgress) {
    			vm.backgroundRefreshInProgress = true;
    			
    	        Notification.query({page: 0, size: 10}, {},
    	        		function(result, headers) {
    	            		vm.notifications = NotificationTimeline.insertDateHeaders(result);
    	                    vm.totalItems = headers('X-Total-Count');
    	        			vm.backgroundRefreshInProgress = false;
    		    		},
    		    		function(error) {
    		    			console.log(error);
    		    		});
        	}
        }, 30000);
        $scope.$on('$destroy', function() {
        	$interval.cancel(vm.refreshIntervalPromise);
        })
        
        
        function expandCollapse() {
        	$rootScope.notificationExpanded = !$rootScope.notificationExpanded;
        	$localStorage.notificationExpanded = $rootScope.notificationExpanded; 
        }
        
        function viewMore() {
        	$state.go('consumer-reports');
        }
    }
})();
