(function() {
    'use strict';
        angular
            .module('squirrelboxApp')
            .service('Logo', Logo);

    Logo.$inject = ['$location', '$http', '$rootScope'];

    function Logo ($location, $http, $rootScope) {
        var logo = {
            fetch: function() {
                fetchLogoIcon();
                fetchLoginImage();
            }
        };

        var hostname = $location.host();
        var orgDomainName = hostname.substring(0,hostname.indexOf("."));

        if (orgDomainName !== '')
            logo.fetch();

        function fetchLogoIcon() {
            var url = 'zuul/squirrelboxfileservice/file/logofile' + '/' + orgDomainName + '/logoicon?base64Encode=true';
            $http.get(url, {
                 etagCache: 'persistentCache'
             }).then(function(response, status, headers, config, itemCache) {
                if (status === 200) {
                    var imageUrl = 'data:' + headers('content-type') + ';base64,' + response;
                    itemCache.set(imageUrl);
                    logo.logoIconUrl = imageUrl;
                }
                else {
                    logo.logoIconUrl = '';
                }
                $rootScope.$broadcast('logoIconUpdate');
             }, function(error, status) {
                 if (status !== 304) {
                     logo.logoIconUrl = '';
                    $rootScope.$broadcast('logoIconUpdate');
                 }
             }).ifCached(function (itemCache) {
                 logo.logoIconUrl = itemCache.data;
                 $rootScope.$broadcast('logoIconUpdate');
             })
        }

        function fetchLoginImage() {
            var url = 'zuul/squirrelboxfileservice/file/logofile' + '/' + orgDomainName + '/loginimage?base64Encode=true';
            $http.get(url,  {
                etagCache: 'persistentCache'
            }).then(function(response, status, headers, config, itemCache) {
                if (status === 200) {
                    var imageUrl = 'data:' + headers('content-type') + ';base64,' + response;
                    itemCache.set(imageUrl);
                    logo.loginImageUrl = imageUrl;
                }
                else {
                    logo.loginImageUrl = '';
                }
                $rootScope.$broadcast('loginImageUpdate');
            }, function(error, status) {
                if (status !== 304) {
                    logo.loginImageUrl = '';
                    $rootScope.$broadcast('loginImageUpdate');
                }
            }).ifCached(function (itemCache) {
                logo.loginImageUrl = itemCache.data;
                $rootScope.$broadcast('loginImageUpdate');
            })

        }

        return logo;
    }
})();
