(function() {
    'use strict';

    // hack for removed function in angular 1.7
    angular.lowercase = function(text) {
        return text.toLowerCase();
    }

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            data: {
            	pageTitle: 'DataRail'
            },
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
		        'footer@': {
		            templateUrl: 'app/layouts/footer/footer.html',
		            controller: 'FooterController',
		            controllerAs: 'vm'
		        }
	        },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    }
})();
