(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SftpClientCredentialsController', SftpClientCredentialsController);

    SftpClientCredentialsController.$inject = ['DataCenterAdmin', 'GlobalModal'];

    function SftpClientCredentialsController (DataCenterAdmin, GlobalModal) {
        var vm = this;

        vm.loadCredentials = loadCredentials;
        vm.loadPage = loadPage;
        vm.createCredentials = createCredentials;
        vm.editCredentials = editCredentials;
        vm.saveCredentials = saveCredentials;
        vm.deleteCredentials = deleteCredentials;
        vm.reverse = false;
        vm.sortByLabel = sortByLabel;

        vm.credentialsFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['label'];
        vm.activeOnly = false;

        loadCredentials();

        function loadCredentials() {
            vm.totalItems = 0;

            DataCenterAdmin.queryReusuableSftpClientCredentials({label: vm.credentialsFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
                function(result, headers) {
                    vm.credentials = result;
                    vm.totalItems = headers('X-Total-Count');

                    if (!vm.currentCredentials && vm.credentials.length > 0)
                        editCredentials(vm.credentials[0]);
                });
        }

        function loadPage(page) {
            vm.page = page;
            loadCredentials();
        }

        function createCredentials() {
            vm.currentCredentials = {
            }
        }

        function editCredentials(credentials) {
            if (vm.credentialsForm)
                vm.credentialsForm.$setPristine();

            vm.currentCredentials = {
                label: credentials.label,
                id: credentials.id,
                domain: credentials.domain,
                username: credentials.username,
                password: credentials.passwordUsed ? "**********" : "",
                privateKey: credentials.privateKeyUsed ? "**********" : "",
                passwordUsed: credentials.passwordUsed,
                privateKeyUsed: credentials.privateKeyUsed
            }

            vm.credentialsBeingEdited = credentials;
        }

        function deleteCredentials(credentials) {
            GlobalModal.confirm('Are you sure you want to delete these credentials?', function() {
                DataCenterAdmin.deleteSftpClientCredentials({id: credentials.id}, function() {
                        loadCredentials();
                        vm.currentCredentials = null;
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsInUse')
                            GlobalModal.displayError('Credentials are in use and cannot be deleted.');
                        else
                            GlobalModal.displayError('Unable to delete credentials. Please try again.');
                    });
            });
        }

        function saveCredentials() {
            if (vm.currentCredentials) {
                vm.credentialsForm.$setSubmitted();

                if ((!vm.currentCredentials.id || !vm.credentialsForm.password.$pristine || !vm.credentialsForm.privateKey.$pristine)
                    && !vm.currentCredentials.password && !vm.currentCredentials.privateKey)
                {
                    GlobalModal.displayError('Please supply either a password or a private key');
                    return;
                }

                if(!vm.credentialsForm.$valid) return;

                var credentials = {
                    id: vm.currentCredentials.id,
                    label: vm.currentCredentials.label,
                    reusable: true,
                    username: vm.currentCredentials.username,
                    password: vm.credentialsForm.password.$pristine ? null : vm.currentCredentials.password,
                    privateKey: vm.credentialsForm.privateKey.$pristine ? null : vm.currentCredentials.privateKey,
                    privateKeyPassword: vm.credentialsForm.privateKeyPassword.$pristine ? null : vm.currentCredentials.privateKeyPassword
                }

                // update entity type
                if (credentials.id > 0) {
                    DataCenterAdmin.updateSftpClientCredentials({id: credentials.id}, credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyInvalid')
                            GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyPasswordInvalid')
                            GlobalModal.displayError('Private key passphrase is incorrect.');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }

                // create entity type
                else {
                    DataCenterAdmin.createSftpClientCredentials(credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyInvalid')
                            GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyPasswordInvalid')
                            GlobalModal.displayError('Private key passphrase is incorrect.');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }
            }
        }

        function sortByLabel(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['label,' + sortDir];
            loadCredentials();
        }
   }

})();
