(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('attributeChosen', attributeChosenDirective);

    attributeChosenDirective.$inject = ['$timeout'];

    function attributeChosenDirective ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);
    	        
    	        // initialize chosen select control
    	        $timeout(function() {
    	    	    select.chosen({
    	    	        disable_search_threshold: 10,
    	    	        width: "300px"
    	    	    });
    	        })
    	        
    	       // notify control if a new attribute is added
    	        scope.$on('userAttributeValue', function() {
        	        $timeout(function() {
        	        	select.trigger('chosen:updated');
        	        })
    	        });
    	    }
    	}
    }
})();
