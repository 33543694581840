(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SftpHostCredentialsController', SftpHostCredentialsController);

    SftpHostCredentialsController.$inject = ['$scope', '$timeout', '$q', '$sce', 'DataCenterAdmin', 'ExternalEntity', 'GlobalModal'];

    function SftpHostCredentialsController ($scope, $timeout, $q, $sce, DataCenterAdmin, ExternalEntity, GlobalModal) {
        var vm = this;

        vm.externalEntityAutoCompleteBlur = externalEntityAutoCompleteBlur;
        vm.loadCredentials = loadCredentials;
        vm.loadPage = loadPage;
        vm.createCredentials = createCredentials;
        vm.editCredentials = editCredentials;
        vm.saveCredentials = saveCredentials;
        vm.deleteCredentials = deleteCredentials;
        vm.reverse = false;
        vm.sortByLabel = sortByLabel;

        vm.credentialsFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['label'];
        vm.activeOnly = false;

        vm.externalEntityAutoComplete = {
            minimumChars: 1,
            pagingEnabled: true,
            data: function (searchText, pagingParams) {
                var deferred = $q.defer();

                ExternalEntity.queryForAutocomplete({name: searchText, page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['name']},
                    function(result, headers) {
                        deferred.resolve(result);
                    }
                );

                return deferred.promise;
            },
            renderItem: function (item) {
                return {
                    value: item.name,
                    label: "<p class='auto-complete' ng-bind-html='entry.item.name'></p>"
                };
            },
            itemSelected: function (selection) {
                if (selection) {
                    vm.currentCredentials.externalEntitySelection = selection.item;
                    vm.currentCredentials.externalEntityId = selection.item.id;
                }
            }
        }
        function externalEntityAutoCompleteBlur() {
            if (vm.currentCredentials.externalEntitySelection)
                vm.currentCredentials.externalEntityId = vm.currentCredentials.externalEntitySelection.id;
            else if (vm.credentialsForm.externalEntityId.$dirty) {
                vm.currentCredentials.externalEntityName = "";
                vm.currentCredentials.externalEntityId = null;
            }
        }

        loadCredentials();

        function loadCredentials() {
            vm.totalItems = 0;

            DataCenterAdmin.queryReusuableSftpHostCredentials({label: vm.credentialsFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
                function(result, headers) {
                    vm.credentials = result;
                    vm.totalItems = headers('X-Total-Count');

                    if (!vm.currentCredentials && vm.credentials.length > 0)
                        editCredentials(vm.credentials[0]);
                });
        }

        function loadPage(page) {
            vm.page = page;
            loadCredentials();
        }

        function createCredentials() {
            vm.currentCredentials = {
            }
            $scope.$broadcast('hostCredentialsExtEntityChange');
        }

        function editCredentials(credentials) {
            if (vm.credentialsForm)
                vm.credentialsForm.$setPristine();

            vm.currentCredentials = {
                label: credentials.label,
                id: credentials.id,
                domain: credentials.domain,
                username: credentials.username,
                password: credentials.passwordUsed ? "**********" : "",
                passwordUsed: credentials.passwordUsed,
                publicKey: credentials.publicKey,
                externalEntityId: credentials.externalEntityId ? credentials.externalEntityId : null,
                externalEntityName: credentials.externalEntityName,
            }
            vm.currentCredentialsList = [vm.currentCredentials]
            vm.credentialsBeingEdited = credentials;

            $timeout(function() {
                $scope.$broadcast('hostCredentialsExtEntityChange');
            });
        }

        function deleteCredentials(credentials) {
            GlobalModal.confirm('Are you sure you want to delete these credentials?', function() {
                DataCenterAdmin.deleteSftpHostCredentials({id: credentials.id}, function() {
                        loadCredentials();
                        vm.currentCredentials = null;
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsInUse')
                            GlobalModal.displayError('Credentials are in use and cannot be deleted.');
                        else
                            GlobalModal.displayError('Unable to delete credentials. Please try again.');
                    });
            });
        }

        function saveCredentials() {
            if (vm.currentCredentials) {
                vm.credentialsForm.$setSubmitted();

                if ((!vm.currentCredentials.id || !vm.credentialsForm.password.$pristine || !vm.credentialsForm.publicKey.$pristine)
                    && !vm.currentCredentials.password && !vm.currentCredentials.publicKey)
                {
                    GlobalModal.displayError('Please supply either a password or a public key');
                    return;
                }

                if(!vm.credentialsForm.$valid) return;

                var credentials = {
                    id: vm.currentCredentials.id,
                    label: vm.currentCredentials.label,
                    reusable: true,
                    username: vm.currentCredentials.username,
                    password: vm.credentialsForm.password.$pristine ? null : vm.currentCredentials.password,
                    publicKey: vm.credentialsForm.publicKey.$pristine ? null : vm.currentCredentials.publicKey,
                    externalEntityId: vm.currentCredentials.externalEntityId ? parseInt(vm.currentCredentials.externalEntityId) : null,
                }

                // update entity type
                if (credentials.id > 0) {
                    DataCenterAdmin.updateSftpHostCredentials({id: credentials.id}, credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.publicKeyInvalid')
                            GlobalModal.displayError('Public key is invalid');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }

                // create entity type
                else {
                    DataCenterAdmin.createSftpHostCredentials(credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.publicKeyInvalid')
                            GlobalModal.displayError('Public key is invalid');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }
            }
        }

        function sortByLabel(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['label,' + sortDir];
            loadCredentials();
        }
   }

})();
