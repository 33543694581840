(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('tagAdminDraggable', TagAdminDraggableDirective);

    TagAdminDraggableDirective.$inject = [];

    function TagAdminDraggableDirective () {
        	return {
        	    link: function(scope, element, attr) {
        	        var select = $(element);
        	        
        	        // assign tag id to element
        	        select.attr({
        	            "tag_id": scope.tag.id,
        	        })
        	        
        	        select.draggable({
        	            revert: true,
        	            delay: 100, 
        	            zIndex: 100,
        	            refreshPositions: true
        	            //handle: ".tag-admin-drag-handle"
        	        });
        	    }
        	}
    }
})();
