(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('notificationTypeChosen', notificationTypeChosen);

    notificationTypeChosen.$inject = ['$timeout'];

    function notificationTypeChosen ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);
    	        
    	        // initialize chosen select control
    	        $timeout(function() {
    	    	    select.chosen({
    	    	        disable_search_threshold: 10,
    	    	        width: attr.width || "400px"
    	    	    });
    	        })
    	 
    	    }
    	}
    }
})();
