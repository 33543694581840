(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SupplierUploadConfigureController', SupplierUploadConfigureController);

    SupplierUploadConfigureController.$inject = ['$scope', '$state', '$stateParams', 'DatasetFileMicroservice', 'GlobalModal', 'Principal'];

    function SupplierUploadConfigureController ($scope, $state, $stateParams, DatasetFileMicroservice, GlobalModal, Principal) {
        var vm = this;
        
        vm.datasetId = $stateParams.datasetId;
        vm.dataset = $stateParams.dataset;
        vm.tab = 'datasetType';
        
        vm.confirmDataset = confirmDataset;
        vm.sortOnColumn = sortOnColumn;
        vm.sortOnType = sortOnType;
        vm.changeAllSelections = changeAllSelections;
        vm.updateColumnSelection = updateColumnSelection;
        vm.returnToDatasets = returnToDatasets;
        vm.textFormatChange = textFormatChange;
        vm.refreshTextColumns = refreshTextColumns;
        vm.updateDatasetType = updateDatasetType;
        vm.selectContentTypeId = selectContentTypeId;
        
	    	vm.sortColumn = 'columnNum';
	    	vm.sortReverse = false;
	    	vm.allSelected = true;
	    	vm.readyToProcess = false;
	    	vm.reparseHeaders = false;
	    	vm.confirmDatasetType = false;
	    	vm.datasetType = "BLOB";
	    	
	        vm.showInfo = showInfo;
	        vm.hideModalInfo = hideModalInfo;
	    	
        Principal.identity()
	        .then(function(identity) {
			        	vm.identity = identity;
			        	vm.isExternal = Principal.hasAuthority('ROLE_EXTERNAL', identity);
			        	
				    	DatasetFileMicroservice.getConfirmDatasetViewModel({id: vm.datasetId}, function(model) {
			    			vm.dataset = model.dataset;
			    			vm.contentTypeId = model.dataset.contentType ? model.dataset.contentType.id : null;
			    			vm.businessAreaId = model.dataset.businessArea ? model.dataset.businessArea.id : null;
			    			vm.fileFormat = model.fileFormat;
			    			vm.tableSafe = model.tableSafe;
			    			vm.availableContentTypes = model.availableContentTypes;
			    			vm.availableBusinessAreas = model.availableBusinessAreas;
			            	initFormData();
			    		});
		        });
        
	        
        function initFormData() {
            if (vm.confirmForm)
                vm.confirmForm.$setPristine();
	        	vm.datasetLabel = vm.dataset.label ? vm.dataset.label : vm.dataset.sourceName;
	        	vm.datasetDescription = vm.dataset.description;
	        	
	        	for (var i=0; i<vm.dataset.columns.length; i++) {
	        		var column = vm.dataset.columns[i];
	        		if (!column.columnType)
	        			column.columnType = 'Not specified';
	        		
	        		column.selected = true;
	        	}
	        	
	        	if (vm.isExternal || !vm.tableSafe) {
	        		vm.dataset.datasetType = 'BLOB';
	        		vm.datasetType = 'BLOB';
	        		vm.confirmDatasetType = true;
	        		vm.readyToProcess = false;
	        	}
	        	else if (vm.dataset.datasetType == 'UNSPECIFIED' || vm.dataset.datasetType == "BLOB") {
	        		vm.confirmDatasetType = true;
	        	}
	        	else if (vm.fileFormat.text) {
	        		vm.confirmDatasetType = false;
	        		
	        		if (vm.dataset.columns.length == 0) {
	        			vm.tab = 'format';
	        			vm.reparseHeaders = true;
	            		vm.readyToProcess = false;
	        		}
	        		else {
	        			vm.tab = 'columns';
	        			vm.reparseHeaders = false;
	            		vm.readyToProcess = true;
	        		}
	        		
	        		if (vm.fileFormat.textDelimiterChar === "\t") {
	        			vm.textDelimiterChar = 'tab';
	        			vm.otherTextDelimiterChar = null;
	        		}
	        		else if (vm.fileFormat.textDelimiterChar === ',') {
	        			vm.textDelimiterChar = 'comma';
	        			vm.otherTextDelimiterChar = null;
	        		}
	        		else if (vm.fileFormat.textDelimiterChar === ';') {
	        			vm.textDelimiterChar = 'semi';
	        			vm.otherTextDelimiterChar = null;
	        		}
	        		else if (vm.fileFormat.textDelimiterChar === '|') {
	        			vm.textDelimiterChar = 'pipe';
	        			vm.otherTextDelimiterChar = null;
	        		}
	        		else {
	        			vm.textDelimiterChar = 'other';
	        			vm.otherTextDelimiterChar = vm.fileFormat.textDelimiterChar;
	        		}
	        		
	        		if (vm.fileFormat.textQuoteChar === '"')
	        			vm.textQuoteChar = "double";
	        		else if (vm.fileFormat.textQuoteChar === "'")
	        			vm.textQuoteChar = "single";
	        		else
	        			vm.textQuoteChar = "none";
	
	        		if (vm.fileFormat.textEscapeChar === '' || vm.fileFormat.textEscapeChar == null) {
	        			vm.textEscapeChar = 'none';
	        			vm.otherTextEscapeChar = null;
	        		}
	        		else if (vm.fileFormat.textEscapeChar === "\\") {
	        			vm.textEscapeChar = 'backslash';
	        			vm.otherTextEscapeChar = null;
	        		}
	        		else {
	        			vm.textEscapeChar = 'other';
	        			vm.otherTextEscapeChar = vm.fileFormat.textEscapeChar;
	        		}
	        	}
	        	else {
	        		vm.confirmDatasetType = false;
	        		vm.readyToProcess = true;
	        		vm.tab = 'columns';
	        	}
        }
        
        function confirmDataset() {
			vm.confirmForm.$setSubmitted();
			if (!vm.confirmForm.$valid) return false;
				
	        	vm.noColumnsError = false;
	        	
	        	// verify at least one column is selected
	        	if (vm.dataset.datasetType == "TABLE") {
		        	var columnSelected = false;
		        	for (var i=0; i<vm.dataset.columns.length; i++) {
		        		var column = vm.dataset.columns[i];
		        		if (column.selected) {
		        			columnSelected = true;
		        			break;
		        		}
		        	}
		        	if (!columnSelected) {
		        		vm.noColumnsError = true;
		        		return false;
		        	}
	        	}
	        	
	        	// verify label and description entered
	        	if (!vm.datasetLabel || vm.datasetLabel.length === 0 || 
	        		!vm.datasetDescription || vm.datasetDescription.length === 0) {
	        		return false;
	        	}
	        	
	        	// set values entered by user before sending to server
	        	vm.dataset.label = vm.datasetLabel;
	        	vm.dataset.description = vm.datasetDescription;
	        	
	        	// send confirmation to server
	        	DatasetFileMicroservice.confirm({id: vm.dataset.id}, vm.dataset, function(result) {
	        			if (vm.isExternal) {
	                		$state.go('share', {
	                			datasetId: vm.dataset.id,
	                		});
	        			}
	        			else {
	        			    // if status is 200, processing is complete go to share
	        			    if (result.status === 200)
                            $state.go('share', {
                                datasetId: vm.dataset.id,
                            });
	        			    
                        // processing is ongoing, go to datasets screen
	        			    else
	        			        $state.go('datasets', {confirmSuccess: true});
	        			}
		        	},
		        	function(error) {
		        		if (error.status === 409)
		        			GlobalModal.displayError('Your dataset has been confirmed for processing already.');
		        		else if (error.status === 412)
                        GlobalModal.displayError('You have existing datasets processing already. Please wait for them to complete first.');
		        		else
		        			GlobalModal.displayError('Your dataset submission failed.');
		        	});
	        	
	        	return false;
        }
	        
        function sortOnColumn() {
	        	changeSort('columnName');
        }
	        
        function sortOnType() {
	        	changeSort('columnType');
        }
	        
	        // called to change the sort column
        function changeSort(columnName) {
	        	if (vm.sortColumn == 'columnNum' || vm.sortColumn != columnName) {
	        		vm.sortColumn = columnName;
	        		vm.sortReverse = false;
	        	}
	        	else if (!vm.sortReverse) {
	        		vm.sortReverse = true;
	        	}
	        	else {
	        		vm.sortColumn = 'columnNum';
	        		vm.sortReverse = false;
	        	}
        }
	
        function changeAllSelections() {
        		for (var i=0; i<vm.dataset.columns.length; i++) {
	        		vm.dataset.columns[i].selected = vm.allSelected;
	        	}
	    }
	        
        function updateColumnSelection(index) {
	        	var allSelected = true;
	        	for (var i=0; i<vm.dataset.columns.length; i++) {
	        		if (!vm.dataset.columns[i].selected) {
	        			allSelected = false;
	        			break;
	        		}
	        	}
	        	
	        	vm.allSelected = allSelected;
        }
    
        function returnToDatasets() {
    			$state.go('datasets');
	    	}
        
        function textFormatChange() {
            	vm.readyToProcess = false;
            	vm.reparseHeaders = true;
        }
        
        function refreshTextColumns() {
            	var fileFormat = {
            		text: true
            	};
            	
            	switch (vm.textDelimiterChar) {
            		case 'tab':
            			fileFormat.textDelimiterChar = '\t';
            			break;
            		case 'comma':
            			fileFormat.textDelimiterChar = ',';
            			break;
            		case 'semi':
            			fileFormat.textDelimiterChar = ';';
            			break;
            		case 'pipe':
            			fileFormat.textDelimiterChar = '|';
            			break;
            		case 'other':
            			fileFormat.textDelimiterChar = vm.otherTextDelimiterChar;
            			break;
            	}
            	
            	if (fileFormat.textDelimiterChar == null || fileFormat.textDelimiterChar.length === 0) {
            	    GlobalModal.displayError("Please select a delimiter");
            	    return;
            	}
            	
            	switch (vm.textQuoteChar) {
        	    		case 'none':
        	    			fileFormat.textQuoteChar = null;
        	    			break;
        	    		case 'single':
        	    			fileFormat.textQuoteChar = "'";
        	    			break;
        	    		case 'double':
        	    			fileFormat.textQuoteChar = '"';
        	    			break;
        	    	}
                	
            switch (vm.textEscapeChar) {
        	    		case 'none':
        	    			fileFormat.textEscapeChar = null;
        	    			break;
        	    		case 'backslash':
        	    			fileFormat.textEscapeChar = "\\";
        	    			break;
        	    		case 'other':
        	    			fileFormat.textEscapeChar = vm.otherTextEscapeChar;
        	    			break;
        	    	}
            	
            	DatasetFileMicroservice.updateDatasetTextFormat({id: vm.datasetId}, fileFormat, function(model) {
    	    			vm.dataset = model.dataset;
    	    			vm.contentTypeId = model.dataset.contentType ? model.dataset.contentType.id : null;
    	    			vm.businessAreaId = model.dataset.businessArea ? model.dataset.businessArea.id : null;
    	    			vm.fileFormat = model.fileFormat;
    	    			vm.availableContentTypes = model.availableContentTypes;
                vm.availableBusinessAreas = model.availableBusinessAreas;
    	            	initFormData();
    	    		}, function (error) {
    	    			GlobalModal.displayError('Unable to parse file with selected file format options');
    	    		});
        }

        
        function updateDatasetType() {
            vm.confirmForm.$setSubmitted();
            if (!vm.confirmForm.$valid) return false;
            
            var contentType = null;
            for (var idx=0; idx<vm.availableContentTypes.length; idx++) {
                var ct = vm.availableContentTypes[idx];
                if (ct.id === vm.contentTypeId) {
                    contentType = ct;
                    break;
                }
            }
            if (!contentType) return false;
            
            // check if attestation confirmation is required
            vm.attestationVerified = false;
            if (contentType.attestation && contentType.attestation.trim().length > 0) {
                vm.attestationVerified = true;
                GlobalModal.confirm(contentType.attestation, performUpdateDatsetType);
            }
            else {
                performUpdateDatsetType();
            }
        }
        
        function performUpdateDatsetType() {
            var request =  {
                datasetType: vm.datasetType,
                contentTypeId: vm.contentTypeId,
                businessAreaId: vm.businessAreaId,
                attestationVerified: vm.attestationVerified,
            }
            
            DatasetFileMicroservice.updateDatasetType({id: vm.datasetId}, request, function(model) {
        	    			vm.dataset = model.dataset;
        	    			vm.contentTypeId = model.dataset.contentType ? model.dataset.contentType.id : null;
        	    			vm.businessAreaId = model.dataset.businessArea ? model.dataset.businessArea.id : null;
        	    			vm.fileFormat = model.fileFormat;
        	    			vm.availableContentTypes = model.availableContentTypes;
                    vm.availableBusinessAreas = model.availableBusinessAreas;
        	    			
        	    			if (vm.dataset.datasetType == "BLOB") {
        	    				confirmDataset();
        	    			}
        	    			else {
        	    				initFormData();
        		    			vm.confirmForm.$setPristine();
        	    			}
        	    			
        	    		}, function (error) {
        	    			GlobalModal.displayError('Unable to parse file with selected dataset type');
        	    		});
        }
        
        function selectContentTypeId(id) {
            for (var idx=0; idx<vm.availableContentTypes.length; idx++) {
                var contentType = vm.availableContentTypes[idx];
                if (contentType.id === id) {
                    vm.dataset.contentType = contentType;
                    break;
                }
            }
            
            vm.contentTypeId = id;
            vm.businessAreaId = null;
        }
        $scope.$watch('vm.businessAreaId', function(newValue, oldValue) {
            $scope.$broadcast('chosenSelectUpdated');
        });
        
        function selectBusinessAreaId(id) {
            for (var idx=0; idx<vm.availableBusinessAreas.length; idx++) {
                var ba = vm.availableBusinessAreas[idx];
                if (ba.id === id) {
                    vm.dataset.businessArea = ba;
                    break;
                }
            }
            
            vm.businessAreaId = id;
        }
        
        function showInfo(){
            $('#showInfoModal').modal('show');          
       }
       
       function hideModalInfo() {
           $('#showInfoModal').modal('hide');
       }
    }
})();
