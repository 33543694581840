(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('Announcement', Announcement);

	Announcement.$inject = ['$resource'];

	function Announcement ($resource) {
		var service = $resource('api/announcements/:id', {}, {
			'query': {method: 'GET', isArray: true},
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'getActive': {
            	url: 'api/activeAnnouncements',
            	method: 'GET',
            	isArray: true
    		},
            'getActiveForLoginScreen': {
                url: 'api/loginScreenAnnouncements',
                method: 'GET',
                isArray: true
            },
            'dismiss': {
                url: 'api/dismissAnnouncement/:id',
                method: 'POST'
            },
            clearDismissals: {
                url: 'api/announcements/clearDismissals',
                method: 'POST'
            }
		});

		return service;
	}
})();
