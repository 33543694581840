    angular
        .module('squirrelboxApp').directive('orgValidator',['SysAdmin',function(SysAdmin) {
        	return {
        		restrict: 'A',
        		require:'ngModel',
        		link: function (scope, element, attr, ctrl) {
        		
        			element.bind('blur', function() {
        				var value=element.val();
        				validate(scope,value,attr, ctrl,element,SysAdmin);
        			});
        			element.bind('submit', function() {
        				var value=element.val();
        				validate(scope,value,attr, ctrl,SysAdmin);
        			});


        		}
        	}
        }]);

        function validate(scope,value,attr,ctrl,element,adminService)
        {
        	
        	if(element.parent().children().length>=0)
        	{
        		element.parent().children('span.error').remove();
        		if(element.parent()[0].className=="input-group")
        		{
        			element.parent().parent().children('span.error').remove();
        		}
        	}
        	validatorNames=(attr.orgValidator).split(",");
        	var tempValuePersentageName = validatorNames[0].split("-");
        	var reqValid = (value!='')? true : false ;
        	var regex,valid=true,i=0;

        	 if (validatorNames[i]=="orgname") 
        	{
        		
            	var checkType = "OrgNameChecking";
            	adminService.checkForExistingValue({
           		orgName: value
            	
            	},checkType).then(function (response) {
        	
                 	if(response.data == false){
                 		element.parent().append("<span class='error'>Please enter a unique organization name</span>");
                 	  	}
                 	
                   }).catch(function () {
                       scope.addSucess = false;
                   });
         	
        		
        	}
        	 else if (validatorNames[i]=="orgdomain") 
        	{
            	var checkType = "DomainChecking";
            	adminService.checkForExistingValue({
           		orgDomain: value
            	
            	},checkType).then(function (response) {
            		
                 	if(response.data == false){
                 		element.parent().append("<span class='error'>Please enter a unique organization domain</span>");
                 	  	}
                 	
                   }).catch(function () {
                       scope.addSucess = false;
                   });
         	
        	}
        	if(reqValid==false)
        	{
        		ctrl.$setValidity('orgValidator', reqValid);
        		}
        	else
        	{
        		ctrl.$setValidity('orgValidator', valid);
        	}
        }

     