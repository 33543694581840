(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('PgpDecryptKeysController', PgpDecryptKeysController);

    PgpDecryptKeysController.$inject = ['DataCenterAdmin', 'GlobalModal'];

    function PgpDecryptKeysController (DataCenterAdmin, GlobalModal) {
        var vm = this;

        vm.loadKeys = loadKeys;
        vm.loadPage = loadPage;
        vm.createKeys = createKeys;
        vm.editKeys = editKeys;
        vm.saveKeys = saveKeys;
        vm.deleteKeys = deleteKeys;
        vm.reverse = false;
        vm.sortByLabel = sortByLabel;

        vm.keysFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['label'];
        vm.activeOnly = false;

        loadKeys();

        function loadKeys() {
            vm.totalItems = 0;

            DataCenterAdmin.queryReusuableFlowPgpDecryptKeys({label: vm.keysFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
                function(result, headers) {
                    vm.keys = result;
                    vm.totalItems = headers('X-Total-Count');

                    if (!vm.currentKeys && vm.keys.length > 0)
                        editKeys(vm.keys[0]);
                });
        }

        function loadPage(page) {
            vm.page = page;
            loadKeys();
        }

        function createKeys() {
            vm.currentKeys = {
            }
        }

        function editKeys(keys) {
            if (vm.keysForm)
                vm.keysForm.$setPristine();

            vm.currentKeys = {
                label: keys.label,
                id: keys.id,
                domain: keys.domain,
                privateKey: keys.privateKeyUsed ? "**********" : "",
                privateKeyUsed: keys.privateKeyUsed,
                publicKey: keys.publicKey
            }

            vm.keysBeingEdited = keys;
        }

        function deleteKeys(keys) {
            GlobalModal.confirm('Are you sure you want to delete these keys?', function() {
                DataCenterAdmin.deleteFlowPgpDecryptKeys({id: keys.id}, function() {
                        loadKeys();
                        vm.currentKeys = null;
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.keysInUse')
                            GlobalModal.displayError('Keys are in use and cannot be deleted.');
                        else
                            GlobalModal.displayError('Unable to delete keys. Please try again.');
                    });
            });
        }

        function saveKeys() {
            if (vm.currentKeys) {
                vm.keysForm.$setSubmitted();

                if(!vm.keysForm.$valid) return;

                var keys = {
                    id: vm.currentKeys.id,
                    label: vm.currentKeys.label,
                    reusable: true,
                    privateKey: vm.keysForm.privateKey.$pristine ? null : vm.currentKeys.privateKey,
                    privatePassword: vm.keysForm.privateKeyPassword.$pristine ? null : vm.currentKeys.privateKeyPassword,
                    publicKey: vm.keysForm.publicKey.$pristine ? null : vm.currentKeys.publicKey
                }

                // update entity type
                if (keys.id > 0) {
                    DataCenterAdmin.updateFlowPgpDecryptKeys({id: keys.id}, keys, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadKeys();
                        editKeys(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.keysExists')
                            GlobalModal.displayError('Keys with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyInvalid')
                            GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyPasswordInvalid')
                            GlobalModal.displayError('Private key passphrase is incorrect.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.publicKeyInvalid')
                            GlobalModal.displayError('Public key is not valid. Ensure PEM format.');
                        else
                            GlobalModal.displayError('Unable to save keys. Please try again.');
                    });
                }

                // create entity type
                else {
                    DataCenterAdmin.createFlowPgpDecryptKeys(keys, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadKeys();
                        editKeys(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.keysExists')
                            GlobalModal.displayError('Keys with this label already exist in system');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyInvalid')
                            GlobalModal.displayError('Private key is not valid. Ensure PEM format.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.privateKeyPasswordInvalid')
                            GlobalModal.displayError('Private key passphrase is incorrect.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.publicKeyInvalid')
                            GlobalModal.displayError('Public key is not valid. Ensure PEM format.');
                        else
                            GlobalModal.displayError('Unable to save keys. Please try again.');
                    });
                }
            }
        }

        function sortByLabel(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['label,' + sortDir];
            loadKeys();
        }
   }

})();
