
(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.controller('ExtUserAssociationController', ExtUserAssociationController);

	ExtUserAssociationController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$window', '$q', '$sce', 'Dataset', 'User', 'GlobalModal', 'AuthServerProvider', 'Principal',  'ExternalEntity'];

	function ExtUserAssociationController ($scope, $state, $stateParams, $timeout, $window, $q, $sce, Dataset, User, GlobalModal, AuthServerProvider, Principal,  ExternalEntity) {
		var vm = this;

		vm.tab = 'internalUsers';
		
		//internal users
		vm.selectInternalUser = selectInternalUser;
		vm.deselectInternalUser = deselectInternalUser;
		vm.selectAllInternalUsers = selectAllInternalUsers;
		vm.deselectAllInternalUsers = deselectAllInternalUsers;
		vm.availableInternalUserFilterChange = availableInternalUserFilterChange;
		vm.selectedInternalUserFilterChange = selectedInternalUserFilterChange;
		
		//external users
		
		vm.selectExternalUser = selectExternalUser;
		vm.deselectExternalUser = deselectExternalUser;
		vm.selectAllExternalUsers = selectAllExternalUsers;
		vm.deselectAllExternalUsers = deselectAllExternalUsers;
		vm.availableExternalUserFilterChange = availableExternalUserFilterChange;
		vm.selectedExternalUserFilterChange = selectedExternalUserFilterChange;
		
		
		vm.toggleFilterOptions = toggleFilterOptions;
        vm.filterHidden = false;
        vm.filterToggle = false;      
        vm.attributeFilter = '';
        vm.attributeValueFilter = '';
        vm.clearFilter = clearFilter;
        vm.filter = filter;
        
        vm.nameFilter = '';
        vm.entityTypeFilter = '';
        vm.attributeFilter = '';
        vm.attributeValueFilter = '';
        vm.map = new Map();
        vm.isFilterApplied = false;
       
		
		vm.navExternalEntityAssociations = navExternalEntityAssociations;		
		
		vm.saveUserAssociations = saveUserAssociations;
		
		vm.entityIdList = [];
		vm.load = load;
		if ($stateParams.entityIdList ) {
			vm.entityIdList = $stateParams.entityIdList;			
	        vm.map = $stateParams.entitySelectionMap; 
	        vm.isFilterApplied  = $stateParams.isFilterApplied;
	        if (vm.isFilterApplied) {
		        vm.nameFilter =  $stateParams.nameFilter;
		        vm.entityTypeFilter =  $stateParams.entityTypeFilter;
		        vm.attributeFilter =  $stateParams.attributeFilter;
		        vm.attributeValueFilter =  $stateParams.attributeValueFilter;
	        }
			
		}
		//console.log($state);
		
		load();
		
	  function toggleFilterOptions(){
        		vm.filterToggle = !vm.filterToggle;
        }
	  
	  function filter () {
      	
		  ExternalEntity.queryExternalUserAutocompleteForBulkAssoc({ name: vm.availableExternalUserFilter, entityList: $stateParams.entityIdList, attributeKey: vm.attributeFilter, attributeValue: vm.attributeValueFilter }, function(data) {
  			// prepare external users lists
     		vm.originalExternalUsers = buildOriginalUserList(data, []);
     		vm.selectedExternalUsers = [];
			var allExternalUsers = vm.originalExternalUsers;
			processExternalUsers(allExternalUsers);	
			});
      }
      
      
   function clearFilter(){
      	
      
       vm.attributeFilter = '';
       vm.attributeValueFilter = '';
      	
       vm.availableExternalUserFilter = '';
       vm.selectedExternalUserFilter = '';
    
    	   ExternalEntity.queryExternalUserAutocompleteForBulkAssoc({ name: vm.availableExternalUserFilter, entityList: $stateParams.entityIdList, attributeKey: vm.attributeFilter, attributeValue: vm.attributeValueFilter }, function(data) {
    			// prepare external users lists
       		vm.originalExternalUsers = buildOriginalUserList(data, []);
       		vm.selectedExternalUsers = [];
			var allExternalUsers = vm.originalExternalUsers;
			processExternalUsers(allExternalUsers);	
			});
		
       
       
      }
		
		 // loads a users data for editing
        function load() {
        		vm.currentEEU = null;
            vm.showAssociations = false;
        		
			ExternalEntity.getUsersByList({entityList: $stateParams.entityIdList}, function(eeu) {
				vm.bulkExternalEntityUserAssocVM  = eeu;
	        		// prepare internal users lists
	        		vm.originalInternalUsers = buildOriginalUserList(eeu.internalAvailableUsers, eeu.internalSelectedUsers)
	        		vm.selectedInternalUsers = eeu.internalSelectedUsers;
				var allInternalUsers = vm.originalInternalUsers;
				processInternalUsers(allInternalUsers);	
				
				
				// prepare external users lists
        		vm.originalExternalUsers = buildOriginalUserList(eeu.externalAvailableUsers, eeu.externalSelectedUsers)
        		vm.selectedExternalUsers = eeu.externalSelectedUsers;
			var allExternalUsers = vm.originalExternalUsers;
			processExternalUsers(allExternalUsers);	
			
			}, function(error) {
				GlobalModal.displayError('Unable load user data.');
			});
        }
        
        function saveUserAssociations() {
        	   
	        	vm.bulkExternalEntityUserAssocVM.internalSelectedUsers = vm.selectedInternalUsers;
	        	vm.bulkExternalEntityUserAssocVM.externalSelectedUsers = vm.selectedExternalUsers;
	        	
	        	 var intUserCnt  = 	vm.bulkExternalEntityUserAssocVM.internalSelectedUsers.length;
	         var extUserCnt  = 	vm.bulkExternalEntityUserAssocVM.externalSelectedUsers.length;
	         if (extUserCnt == 0 || 	intUserCnt == 0) {
	       
	        		GlobalModal.displayError('Please select Internal and External users.');
	       
	        	} else {
	                
		        GlobalModal.confirm('You are about to Associate ' + intUserCnt + ' Internal Users with ' + extUserCnt + ' external users. Please confirm ? ', function() {
					  
		        		ExternalEntity.saveUserAssociations({}, vm.bulkExternalEntityUserAssocVM, function(result) {        		
		        		GlobalModal.displaySuccess('User Associations data saved successfully');
			
					}, function(error) {
						GlobalModal.displayError('Unable save user associations.');
					});
		    		}, function(error) {
		    			
		    		});
	        	}
	        
	        	
	       
        	
        }
		
		 // build list of original users from available and selected users
        function buildOriginalUserList(availableUsers, selectedUsers) {
            var originalUsers = [].concat(availableUsers);
            for (var i=0; i<selectedUsers.length; i++) {
                var u = selectedUsers[i];
                var exists = false;
                for (var j=0; j<originalUsers.length; j++) {
                    if (u.id === originalUsers[j].id) {
                        originalUsers[j] = u;
                        exists = true;
                        break;
                    }
                }
                if (!exists)
                    originalUsers.push(u);
            }
            return originalUsers;
        }
        
        function navExternalEntityAssociations() {
        	 
          $state.go('external-associations', {
    		entitySelectionMap: vm.map,
    		nameFilter:  vm.nameFilter,
    		entityTypeFilter: vm.entityTypeFilter,
    		attributeFilter: vm.attributeFilter,
    		attributeValueFilter: vm.attributeValueFilter,
    		isFilterApplied: vm.isFilterApplied
		});
            
        }
        
    	// processes available user set after retrieval from server
    	function processInternalUsers(data) {
			vm.availableInternalUsers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.displayName = buildUserDisplayName(user);
				
				// if duplicates of the same user name are in the list, mark to display email
				if (nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				// add user to available if not already selected
				var selected = false;
				for (var j=0; j< vm.selectedInternalUsers.length; j++) {
					var selectedUser = vm.selectedInternalUsers[j];
					if (selectedUser.id == user.id) {
						selected = true;
						break;
					}
				}
				if (!selected) vm.availableInternalUsers.push(user);
			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}
		}
    	
     	// processes available user set after retrieval from server
    	function processExternalUsers(data) {
			vm.availableExternalUsers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.displayName = buildUserDisplayName(user);
				
				// if duplicates of the same user name are in the list, mark to display email
				if (nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				// add user to available if not already selected
				var selected = false;
				for (var j=0; j< vm.selectedExternalUsers.length; j++) {
					var selectedUser = vm.selectedExternalUsers[j];
					if (selectedUser.id == user.id) {
						selected = true;
						break;
					}
				}
				if (!selected) vm.availableExternalUsers.push(user);
			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}
		}
    	
    	// builds the display name key for a given user
    	function buildUserDisplayName(user) {
    		var name = '';
    		
    		// build name portion of string
    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
    			name += user.firstName;
    			
    			if  (user.lastName && user.lastName !== '') {
    				if (name.length>0) name += ' ';
    				name += user.lastName;
    			}
    		}
    		else {
    			name += user.email;
    		}
    		
    		return name;
    	}
    	
    	// builds the text used to display in listbox option
    	function buildUserNameOption(user) {
    		var name = user.displayName;
    		
    		if (user.showEmail) {
    			name += ' (';
    			name += user.email;
    			name += ')';
    		}
    		
    		// append "pending" notice for users that have not accepted yet
    		if (!user.inviteAccepted)
    			name += ' (pending)';
    		
    		return name;
    	}
    	
    	// selects a user
    	function selectInternalUser(user) {
		// add user to selected if not already selected
		var selected = false;
		for (var j=0; j<vm.selectedInternalUsers.length; j++) {
			var selectedUser = vm.selectedInternalUsers[j];
			if (selectedUser.id == user.id) {
				selected = true;
				break;
			}
		}
		if (!selected) vm.selectedInternalUsers.push(user);
    		
    		var idx = vm.availableInternalUsers.indexOf(user);
    		if (idx >= 0)
    			vm.availableInternalUsers.splice(idx, 1);
    		
    		//vm.userGroupForm.$setDirty();
    	}
    	
    	// deselects a user
    	function deselectInternalUser(user) {
    		vm.availableInternalUsers.push(user);
    		
    		var idx = vm.selectedInternalUsers.indexOf(user);
    		if (idx >= 0)
    			vm.selectedInternalUsers.splice(idx, 1);
    		
    	
    	}
    	
    	// selects all users
    	function selectAllInternalUsers() {
    		while (vm.availableInternalUsers.length > 0)
    			selectInternalUser(vm.availableInternalUsers[0]);
    	}
    	
    	// deselects all users
    	function deselectAllInternalUsers() {
    		while (vm.selectedInternalUsers.length > 0)
    			deselectInternalUser(vm.selectedInternalUsers[0]);
    	}
    	
    	// invoked when available user filter changes
    	function availableInternalUserFilterChange() {
    		if (vm.availableInternalUserFilter.length > 1) {
    			ExternalEntity.queryInternalUserAutocompleteForBulkAssoc({ name: vm.availableInternalUserFilter}, function(data) {
    				processInternalUsers(data);
    			});
    		}
    		else
			processInternalUsers(vm.originalInternalUsers);
    	}
    	
    	// invoked when selected user filter changes
    	function selectedInternalUserFilterChange() {
    		vm.selectedInternalUserFilterApplied = vm.selectedInternalUserFilter.length > 1 ? vm.selectedInternalUserFilter : '';
    	}
        
    	
    	
     	// selects a user
    	function selectExternalUser(user) {
		// add user to selected if not already selected
		var selected = false;
		for (var j=0; j<vm.selectedExternalUsers.length; j++) {
			var selectedUser = vm.selectedExternalUsers[j];
			if (selectedUser.id == user.id) {
				selected = true;
				break;
			}
		}
		if (!selected) vm.selectedExternalUsers.push(user);
    		
    		var idx = vm.availableExternalUsers.indexOf(user);
    		if (idx >= 0)
    			vm.availableExternalUsers.splice(idx, 1);
    		
    		
    	}
    	
    	// deselects a user
    	function deselectExternalUser(user) {
    		vm.availableExternalUsers.push(user);
    		
    		var idx = vm.selectedExternalUsers.indexOf(user);
    		if (idx >= 0)
    			vm.selectedExternalUsers.splice(idx, 1);
    		
    		
    	}
    	
    	// selects all users
    	function selectAllExternalUsers() {
    		while (vm.availableExternalUsers.length > 0)
    			selectExternalUser(vm.availableExternalUsers[0]);
    	}
    	
    	// deselects all users
    	function deselectAllExternalUsers() {
    		while (vm.selectedExternalUsers.length > 0)
    			deselectExternalUser(vm.selectedExternalUsers[0]);
    	}
    	
    	// invoked when available user filter changes
    	function availableExternalUserFilterChange() {
    		if (vm.availableExternalUserFilter.length > 1) {
    			ExternalEntity.queryExternalUserAutocompleteForBulkAssoc({ name: vm.availableExternalUserFilter, entityList: $stateParams.entityIdList, attributeKey: vm.attributeFilter, attributeValue: vm.attributeValueFilter }, function(data) {
    				processExternalUsers(data);
    			});
    		}
    		else
			processExternalUsers(vm.originalExternalUsers);
    	}
    	
    	// invoked when selected user filter changes
    	function selectedExternalUserFilterChange() {
    		vm.selectedExternalUserFilterApplied = vm.selectedExternalUserFilter.length > 1 ? vm.selectedExternalUserFilter : '';
    	}
        
    	
   
		
		
    }
})();
