(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('upload', {
            parent: 'supplier',
            url: '/supplier/upload',
            views: {
                'content@': {
                    templateUrl: 'app/supplier/upload/upload.html',
                    controller: 'SupplierUploadController',
                    controllerAs: 'vm'
                },
            },
        });
        
    }
})();
