(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AgreementTypeAdminController', AgreementTypeAdminController);

    AgreementTypeAdminController.$inject = ['AgreementType', 'GlobalModal'];

    function AgreementTypeAdminController (AgreementType, GlobalModal) {
        var vm = this;
        
        vm.loadAgreementTypes = loadAgreementTypes;
        vm.loadPage = loadPage;
        vm.createAgreementType = createAgreementType;
        vm.editAgreementType = editAgreementType;
        vm.saveAgreementType = saveAgreementType;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
        
		vm.agreementTypeFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.agreementTypeSort = ['name'];
        vm.activeOnly = false;
        
        loadAgreementTypes();
       
        function loadAgreementTypes() {
        	AgreementType.query({name: vm.agreementTypeFilter, activeOnly: vm.activeOnly, page: vm.page-1, size: vm.itemsPerPage, sort: vm.agreementTypeSort}, 
                function(agreementTypes, headers) {
        	            	processAgreementTypes(agreementTypes)
        	            	vm.totalItems = headers('X-Total-Count');
        	            	
        	            	if (!vm.currentAgreementType && vm.agreementTypes.length > 0)
        	            		editAgreementType(vm.agreementTypes[0]);
        	            }
                );
        }
        
        function loadPage(page) {
            vm.page = page;
            loadAgreementTypes();
        }
        
        function createAgreementType() {
	        	vm.currentAgreementType = {
	        		activated: true        		
	        	}
        }
        
        function editAgreementType(agreementType) {
        		if (vm.agreementTypeForm)
        			vm.agreementTypeForm.$setPristine();
	        	
	        	vm.currentAgreementType = {
	        		name: agreementType.name,
	        		id: agreementType.id,
	        		description: agreementType.description,
	        		active: agreementType.active
	        	}
	        	
	        	vm.agreementTypeBeingEdited = agreementType;
        }
        
        function saveAgreementType() {
	        	if (vm.currentAgreementType) {
	        		vm.agreementTypeForm.$setSubmitted();
	            	
	        		if(!vm.agreementTypeForm.$valid) return;
	        		
	        		var agreementType = {
	        			name: vm.currentAgreementType.name,
                    id: vm.currentAgreementType.id,
                    description: vm.currentAgreementType.description,
                    active: vm.currentAgreementType.active               
	        		}
	        		
	        		// update agreement type
	        		if (agreementType.id > 0) {
	        			AgreementType.update({id: agreementType.id}, agreementType, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadAgreementTypes();
	        				editAgreementType(data);
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementTypeExists')
	        					GlobalModal.displayError('Agreement Type already exists in system');
	        				else
	        					GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        		
	        		// create agreement type
	        		else {
	        			AgreementType.save(agreementType, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadAgreementTypes();
	        				editAgreementType(data);
	        			}, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementTypeExists')
                            GlobalModal.displayError('Agreement Type already exists in system');
                        else
                            GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        	}
        }
        
        function processAgreementTypes(data) {
			vm.agreementTypes = [];
			
			for (var i=0; i<data.length; i++) {
				var agreementType = data[i];
				if(agreementType.active){agreementType.status = 'Active';}
				else {agreementType.status = 'Inactive';}
				vm.agreementTypes.push(agreementType);
			}	
    		}
        
        function sortBySortingName(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['name,' + sortDir];
            loadAgreementTypes();
        }
    }
   
})();
