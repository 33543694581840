(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SignUpController', SignUpController);

    SignUpController.$inject = ['$scope','$rootScope', '$state', '$timeout','$location', 'SignUp', 'Auth', 'OrgSetUpService', 'GlobalModal'];

    function SignUpController ($scope, $rootScope, $state, $timeout,  $location, SignUp, Auth, OrgSetUpService, GlobalModal) {
        var vm = this;

        vm.openLogin = openLogin;
        vm.checkInvitation = checkInvitation;
        vm.acceptInvitation = acceptInvitation;

        vm.firstName = "";
        vm.lastName = "";
        vm.password = "";
        vm.confirmPassword = "";

        OrgSetUpService.getOrganization().then(function(response) {
        	vm.org = response.data;
        });

        Auth.logout(false);
        checkInvitation();

        function openLogin() {
        	$state.go('login');
        }

        function checkInvitation(){
        	var activationKey = $location.path().split("/").pop();

        	SignUp.checkInvitation(activationKey).then(function (response) {
            	if(response.status === 200){
            		vm.receiverEmail = response.data.receiverEmail;
            	}else {
            		$state.go("signUpError");
            	}
            }).catch(function () {
            	$state.go("signUpError");
            });

        }

        function acceptInvitation() {
        	if (vm.firstName.length == 0) {
        		return false;
        	}
        	if (vm.lastName.length == 0) {
        		return false;
        	}
        	if (vm.password !== vm.confirmPassword || vm.password.length < 6) {
        		return false;
        	}

        	var activationKey = $location.path().split("/").pop();
        	var acceptance = {
        		firstName: vm.firstName,
        		lastName: vm.lastName,
        		password: vm.password
        	};

			vm.signupError = false;
			vm.conflictError = false;
        	SignUp.acceptInvitation(activationKey, acceptance).then(function (response) {
                Auth.login({
                    username: vm.receiverEmail,
                    password: vm.password,
                }).then(function () {
                    $rootScope.$broadcast('authenticationSuccess');
                	$state.go("home");
                }).catch(function() {
            		vm.signupError = true;
                });
            }).catch(function (response) {
            	if (response.status == 400) // bad request, password does not conform to standards
            		GlobalModal.displayError('Password does not meet organization\'s requirements');
            	else if (response.status == 409) // conflict, duplicate accept
        			vm.conflictError = true;
        		else
            		vm.signupError = true;
            });

        }


    }


})();
