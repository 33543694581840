(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .directive('shareTokchifyInput', ShareTokchifyInputDirective);

    ShareTokchifyInputDirective.$inject = ['$timeout', 'Tag'];

    function ShareTokchifyInputDirective ($timeout, Tag) {
        return {
            link: function(scope, element, attr) {
                var div = $(element);
                
                // intialize tokenized input control
                div.tokchify({
                    autoFocus: false,
                    dropdownStyle : 'fixed',
                    searchKeywordDelimiter: null,
                    onReady : function (tokchi) {
                        // wipe out any extra typing not wrapped in a token on loss of focus
                        tokchi._input.blur(function() {
                            $timeout(function() {
                                if (document.activeElement != tokchi._input[0])
                                    tokchi.setValue(tokchi.getTokens());
                            }, 500)
                        });
                        
                        // override show dorpdown to ensure it is in correct position
                        tokchi._showDropdown = function () {
                            if (!this._dropdownShowing) {            
                                var offset = this._input.position();
                                var top = offset.top + this._input.outerHeight() + 10;
                                
                                this._dropdown.css({
                                    position : 'absolute',
                                    top : top,
                                    left : offset.left,
                                    width : this._input.width()
                                });

                                this._dropdown.show();
                                this._dropdownShowing = true;
                            }
                            else {
                                var offset = this._input.position();
                                var top = offset.top + this._input.outerHeight() + 10;
                                this._dropdown.css({
                                    top : top
                                });
                            }
                            
                            this._dropdownIndex = 0;
                            this._blurSafeGuard = 0;
                            this._dropdown.scrollTop(0);
                        }
                        
                        restoreTags(tokchi, scope.vm.dataset.tags);
                    },
                    onCreateDropdownItem : function (tokchi, itemHTMLNode, resultItem) {
                        $(itemHTMLNode)
                            .text(resultItem.name);
                    },
                    onUnwrapToken : function (tokchi, tokenHTMLNode, tokenObj) {
                        return tokenObj.name;
                    },
                    onChange : function (tokchi) {
                        if (scope.vm.dataset)
                            scope.vm.dataset.tags = tokchi.getTokens();
                    },
                    onSearchKeyword : function (tokchi, keyword) {
                        keyword = scrubCharactersForTag(keyword);
                        if (keyword === '') return;
                        
                        Tag.query({name: keyword, page: 0, size: 10}, function(response) {
                            var tags = [];
                            tags.push({ id: -1, name: keyword, label: keyword });
                            for (var i=0; i<response.length; i++) {
                                var t = response[i];
                                tags.push({
                                   id: t.id,
                                   name: t.name,
                                   label: t.name
                                });
                            }
                            tokchi.setSearchResult(tags);
                        });
                    }
                });
                
            }
        }
    }
    
    function restoreTags(tokchi, tags) {
        var tokens = []
        for (var i=0; i<tags.length; i++) {
            var tag = tags[i];
            tokens.push({
               id: tag.id,
               name: tag.name,
               label: tag.name
            });
        }
        tokchi.setValue(tokens);
    }
    
    function scrubCharactersForTag(keyword) {
        var scrubbed = keyword.replace(/[^\w\s\.\^\$\@\!]/gi, '')
        
        var maxChars = 100;
        if (scrubbed.length > maxChars)
            scrubbed = scrubbed.substring(0, maxChars);
        
        return scrubbed;
    }
})();
