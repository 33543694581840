(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SysAdminSamlController', SysAdminSamlController);

    SysAdminSamlController.$inject = ['$state', 'SAML', 'GlobalModal'];

    function SysAdminSamlController ($state, SAML, GlobalModal) {
        var vm = this;
        
        vm.selectedConfig = null;
        
        vm.newConfig = newConfig;
        vm.editConfig = editConfig;
        vm.saveConfig = saveConfig;
        vm.deleteConfig = deleteConfig;
        
        loadConfigList();
        newConfig();
        
        // loads available configs for editing
        function loadConfigList() {
			SAML.getAllIdpConfigurations(function(result) {
					vm.configurations = result;
				}, function(error) {
					GlobalModal.displayError(error.statusText);
				});
        }
        
        // creates a new configuration
        function newConfig() {
        	if (vm.configForm)
        		vm.configForm.$setPristine();
        	
        	vm.selectedConfig = {
        		id: 0,
        		entityId: '',
        		metadata: '',
        		attributeMap: {
        			email: 'urn:oid:0.9.2342.19200300.100.1.3',
        			firstName: 'urn:oid:2.5.4.42',
        			lastName: 'urn:oid:2.5.4.4',
        			role: 'http://squirrelbox.io/naming/2017/01/role'
        		},
        		roleMap: {
        			orgAdmin: 'admin',
        			supplier: 'supplier',
        		},
        		spInitiatedLoginAllowed: true,
        		logoutRedirectURL: ''
        	}
        }
        
        // edits an existing configuration
        function editConfig(config) {
        	if (vm.configForm)
        		vm.configForm.$setPristine();
        	
			SAML.getIdpConfiguration({id: config.id}, function(result) {
	    			vm.selectedConfig = result;
				}, function(error) {
					GlobalModal.displayError(error.statusText);
				});
        }

        // deletes an existing configuration
        function deleteConfig(config) {
        	GlobalModal.confirm("Are you sure you want to delete this configuration", function() {
	            	SAML.deleteIdpConfiguration({id: config.id}, function() {
	            			loadConfigList();
			    			newConfig();
						}, function(error) {
							GlobalModal.displayError(error.statusText);
						});
        		});
        }

        // saves a configuration that is being edit
        function saveConfig() {
        	vm.configForm.$setSubmitted();
        	
        	// make sure top level fields are filled in
        	if (!vm.configForm.$valid) return false;
        	
    		var data = vm.selectedConfig;

    		vm.saving = true;
    		
    		// creating a new config
    		if (data.id <= 0) {
    			SAML.createIdpConfiguration(data, function(result) {
	        			GlobalModal.displaySuccess('Configuration created successfully.');
	        			console.log(result);
	        			editConfig(result);
	        			loadConfigList();
	        			vm.saving = false;
					}, function(error) {
						GlobalModal.displayError(error.statusText);
	        			vm.saving = false;
					});
    		}
    		
    		// editing an existing config
    		else {
    			SAML.updateIdpConfiguration({id: data.id}, data, function(result) {
	        			GlobalModal.displaySuccess('Configuration updated successfully.');
	        			editConfig({id: result.id}, true);
	        			loadConfigList();
	        			vm.saving = false;
					}, function(error) {
						GlobalModal.displayError(error.statusText);
	        			vm.saving = false;
					});
    		}
        }
    }
})();
