(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .controller('UserImportController', UserImportController);

    UserImportController.$inject = ['AuthServerProvider', '$state','$scope', 'GlobalModal', 'Principal','UserImportService'];

    function UserImportController (AuthServerProvider, $state, $scope, GlobalModal, Principal, UserImportService) {
        var vm = this;
        vm.showUploadText = true;
        vm.showProcessButton = false;
        vm.showUploadButton = true;
        vm.showReUploadButton = false;
        vm.showWaitText = false;
        vm.showProcessText = false;
        vm.showError = false;
        vm.errorMessage = '';

        vm.getToken = AuthServerProvider.getToken;
        vm.processDropzoneQueue = processDropzoneQueue;
        vm.configure = configure;
        vm.processUserImport = processUserImport;

        vm.showInfo = showInfo;
        vm.hideModalInfo = hideModalInfo;
        
        vm.newBatchStarted = false;
        vm.batchProcessEntry = null;
        vm.startUserImport = startUserImport;
        vm.batchProcessEntryList = null;
        vm.selectedBatchProcessFileList = null;
        vm.selectedBatchProcess = null;
        vm.selectBatchProcess = selectBatchProcess;
        vm.createNewBatch = createNewBatch;
        vm.deleteBatch = deleteBatch;
        
        
        
        getExistingBatchProcess();
        
        function getExistingBatchProcess() {
        	UserImportService.checkExistingBatchProcess({}, 
                function(batchProcess, headers) {
        	            	if(batchProcess.length > 0) {
        	            		 vm.batchProcessEntryList = batchProcess;  
        	            		 if ( vm.selectedBatchProcess != null) {
        	            			 getFilesByBatchId(vm.selectedBatchProcess.id);
        	            			 for(var i = 0; i <batchProcess.length  ; i ++){
        	            				 if(vm.selectedBatchProcess.id == batchProcess[i].id ){
        	            					 vm.selectedBatchProcess = batchProcess[i];
        	            					 break;
        	            				 }
        	            			 }
        	            		 } else {
	        	            		 getFilesByBatchId(batchProcess[0].id);
	        	            		 vm.selectedBatchProcess = batchProcess[0];       	            		 
	        	            		 var url = "zuul/squirrelboxfileservice/api/users/upload";
	        	                 	
	        	                 	 vm.dropzone.options.url = url + '/' + vm.selectedBatchProcess.id;
        	            		 }
        	            	}
      	        },
				function(error) {
        	            	 vm.batchProcessEntry = null
				}
           );
        }
        
       
        
        function getFilesByBatchId(batchid) {
        	UserImportService.getFilesByBatchId({batchid: batchid}, 
                function(fileList, headers) {
        	            	if(fileList.length > 0) {
        	            		 vm.selectedBatchProcessFileList = fileList;       	            		 
        	            	} else {
        	            		vm.selectedBatchProcessFileList = null;
        	            	}
      	        },
				function(error) {
      	        	vm.selectedBatchProcessFileList = null
				}
           );
        }
        
        function selectBatchProcess(batchProcessEntry) {
        	
        	getFilesByBatchId(batchProcessEntry.id);
        	vm.selectedBatchProcess = batchProcessEntry;
        	var url = vm.dropzone.options.url;
        	url = url.substring(0,url.lastIndexOf("/"))
        	vm.dropzone.options.url = url + '/' + vm.selectedBatchProcess.id;
        	 vm.showError = false;
             vm.errorMessage = '';
        	
        	
        }
        
        
        function deleteBatch(batchProcessEntry ) {
        	
        	GlobalModal.confirm('You are about to delete all the data corresponding to this batch (' + batchProcessEntry.totalRecordsUploaded + ' records) . Please confirm ? ', function() {
			  	vm.showWaitText =true;
    			vm.showProcessText = false;
         		UserImportService.deleteBatchEntry({batchid: batchProcessEntry.id},null,
    				function(result) {
    					GlobalModal.displaySuccess('Batch successfully deleted');
    					getExistingBatchProcess();
    					 
    				},
    				function(error) {
    					if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementnameexists')
        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));          			
        				else
        					GlobalModal.displayError('An unexpected error occurred deleting batch');
    				}
    			);
    		}, function(error) {
    			
    		});
        	
        }
        
        

        vm.dropzone = new Dropzone("div#uploaddrop", { url: "zuul/squirrelboxfileservice/api/users/upload" ,
            autoProcessQueue: false,
            addRemoveLinks: true,
            uploadMultiple : true,
            parallelUploads : 10,
            maxFilesize: 512,
            maxFiles: 1,

        });



        vm.dropzone.on('sendingmultiple', function (file, xhr, formData) {
        	
            xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
        });
      
        vm.dropzone.on("removedfile", function(file){
        	 $scope.$apply(function(){
 	            vm.errorMessage='';
 				vm.showError=false;
             });
          });
        vm.dropzone.on('successmultiple',function(files,datasets) {
        		GlobalModal.displaySuccess('User import completed successfully. Click on  process button to load users.');           
        		$scope.$apply(function(){
        				vm.showUploadText=false;
                    vm.showReUploadButton = true;
                    vm.showUploadButton = false;
                    vm.showProcessButton = true;
                    vm.showProcessText = true;
                                   
                   
                });
        		
        		//remove the file from drop zone and diaplay the original message
        		vm.dropzone.removeFile(files[0]);
                files[0].previewElement.remove();
        		getExistingBatchProcess();
         
            
        });

        vm.dropzone.on('error',function(file,response, headers){
        	var message = '';
            if(response.status == 0){
                $(file.previewElement).find('.dz-error-message').text('timed out');
                message ='Timed out uploading file.';
            }
            else if(headers.status == 500){
                $(file.previewElement).find('.dz-error-message').text(headers.response);
                message = headers.response;
            }
            else if(headers.status == 400){
                $(file.previewElement).find('.dz-error-message').text(headers.response);
                message =headers.response;
            	
                
            } else if (headers.status == 406){		
    			$(file.previewElement).find('.dz-error-message').text(headers.response);
    			 message = headers.response;
    		}
            $scope.$apply(function(){
	            vm.errorMessage=message;
				vm.showError=true;
            });
        });

        function processDropzoneQueue() {
        	//external users can upload only one file
	         if (	vm.dropzone.options.maxFiles == 1 && vm.dropzone.files.length > 1 ) {
	        	 	GlobalModal.displayError('Only 1 file allowed to upload at a time. Please remove additional files.');
	         } else {
	            vm.dropzone.processQueue();
	            return false;
	         }
        }

        function configure() {
            $state.go('upload-configure', {
                datasetId: vm.selectedDataset
            });
        }
        
        function processUserImport() {
        		
        		GlobalModal.confirm('You are about to import users into user table. (' + vm.selectedBatchProcess.totalRecordsUploaded + ' records). Records with duplicate emails will be eliminated. Please confirm ? ', function() {
				  	vm.showWaitText =true;
        			vm.showProcessText = false;
	         		UserImportService.processUserImport({batchid: vm.selectedBatchProcess.id},null,
	    				function(result) {
	    					GlobalModal.displaySuccess('User data  successfully processed');
	    					  $state.go('accountAdmin');
	    				},
	    				function(error) {
	    					if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementnameexists')
	        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));          			
	        				else
	        					GlobalModal.displayError('An unexpected error occurred processing users');
	    				}
	    			);
	    		}, function(error) {
	    			
	    		});
        		
        	
        }
        
      
        function showInfo(){
             $('#showInfoModal').modal('show');          
        }
        
        function hideModalInfo() {
            $('#showInfoModal').modal('hide');
        }
        
        function createNewBatch() {
        	
        	GlobalModal.confirm('Do you want to start uploading a new batch of files ?', function() {
	           	 UserImportService.createNewBatchProcess({}, 
	                     function(batchProcess, headers) {
	             	            	if(batchProcess.length > 0) {
	             	            		vm.selectedBatchProcess = null;
	             	            		 getExistingBatchProcess();
	             	            		 
	             	            	}
	           	        },
	     				function(error) {
	             	            	 vm.batchProcessEntry = null
	     				}
	                );
    		}, function(error) {
    			
    		});
        	

        }
        
        function startUserImport() {
        	
        	if (vm.batchProcessEntry != null) {
        		 GlobalModal.confirm('Another upload batch was started. Please click continue ? ', function() {
            		 vm.newBatchStarted = true;
    				  
    	         		
    	    		}, function(error) {
    	    			 vm.newBatchStarted = true;
    	    			 UserImportService.createNewBatchProcess({}, 
    	                         function(batchProcess, headers) {
    	                 	            	if(batchProcess.length > 0) {
    	                 	            		 vm.batchProcessEntry = batchProcess[0];   
    	                 	            		 vm.dropzone.options.url = vm.dropzone.options.url + '/' + vm.batchProcessEntry.id;
    	                 	            	}
    	               	        },
    	         				function(error) {
    	                 	            	 vm.batchProcessEntry = null
    	         				}
    	                    );
    	    		});
        		
        	} else {
        		vm.newBatchStarted = true;
        		UserImportService.createNewBatchProcess({}, 
                        function(batchProcess, headers) {
                	            	if(batchProcess.length > 0) {
                	            		 vm.batchProcessEntry = batchProcess[0];   
                	            		 vm.dropzone.options.url = vm.dropzone.options.url + '/' + vm.batchProcessEntry.id;
                	            	}
              	        },
        				function(error) {
                	            	 vm.batchProcessEntry = null
        				}
                   );
        	}
        	
        	
        }
    }
})();
