(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .controller('AccountAcceptController', AccountAcceptController);

    AccountAcceptController.$inject = ['$scope','$rootScope', '$state', '$timeout', '$location', 'User', 'Auth', 'OrgSetUpService', 'GlobalModal', 'Logo'];

    function AccountAcceptController ($scope, $rootScope, $state, $timeout,  $location, User, Auth, OrgSetUpService, GlobalModal, Logo) {
        var vm = this;

        vm.openLogin = openLogin;
        vm.checkInvitation = checkInvitation;
        vm.acceptInvitation = acceptInvitation;

        vm.firstName = "";
        vm.lastName = "";
        vm.password = "";
        vm.confirmPassword = "";
        vm.logoIconUrl = Logo.logoIconUrl;
        vm.loginImageUrl = Logo.loginImageUrl;

        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });
        $rootScope.$on('loginImageUpdate', function() {
            vm.loginImageUrl = Logo.loginImageUrl;
        });

        OrgSetUpService.getOrganization().then(function(response) {
            vm.org = response.data;
        });

        Auth.logout(false);
        checkInvitation();      

        function openLogin() {
            $state.go('login');
        }

        function checkInvitation(){
            var activationKey = $location.path().split("/").pop();

            User.checkInvitation({ activationKey: activationKey }, function (data) {
                vm.receiverEmail = data.email;
                vm.login = data.login;
                User.checkInvitationExpiry({ activationKey: activationKey }, function (data) {                    
                }, function () {
                    $state.go("accept-expired");
                });
            }, function () {
                $state.go("acceptError");
            });

        }        

        function acceptInvitation() {
            if (vm.firstName.length == 0) {
                return false;
            }
            if (vm.lastName.length == 0) {
                return false;
            }
            if (vm.password !== vm.confirmPassword || vm.password.length < 6) {
                return false;
            }

            var activationKey = $location.path().split("/").pop();
            var acceptance = {
                    firstName: vm.firstName,
                    lastName: vm.lastName,
                    password: vm.password
            };

            vm.signupError = false;
            vm.conflictError = false;
            User.acceptInvitation({ activationKey: activationKey }, acceptance, function (data) {
                Auth.login({
                    username: vm.login,
                    password: vm.password,
                    rememberMe: false
                }).then(function () {
                    $rootScope.$broadcast('authenticationSuccess');
                    $state.go("home");
                }).catch(function() {
                    vm.signupError = true;
                });
            }, function (response) {
                if (response.status == 400) // bad request, password does not conform to standards
                    GlobalModal.displayError('Password does not meet organization\'s requirements');
                else if (response.status == 409) // conflict, duplicate accept
                    vm.conflictError = true;
                else
                    vm.signupError = true;
            });
        }

    }


})();
