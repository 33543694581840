(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SupplierUpdateController', SupplierUpdateController);

    SupplierUpdateController.$inject = ['$rootScope', 'AuthServerProvider', '$state', '$scope', '$stateParams', 'GlobalModal', 'Dataset'];

    function SupplierUpdateController ($rootScope, AuthServerProvider, $state, $scope, $stateParams, GlobalModal, Dataset) {
        var vm = this;
        vm.showText = false;

        vm.getToken = AuthServerProvider.getToken;
        vm.processDropzoneQueue = processDropzoneQueue;
        vm.returnToDatasets = returnToDatasets;
        vm.appendDataset = true;
        vm.datasetType = $stateParams.datasetType;
        vm.serviceUrl = "zuul/squirrelboxfileservice/api/update/" + $stateParams.datasetId ;

        Dataset.getDownloadInfo({id: $stateParams.datasetId}, function(data) {
			vm.dataset = data.dataset;
		});

	    vm.dropzone = new Dropzone("div#uploaddrop", { url: vm.serviceUrl,
	    	autoProcessQueue: false,
	    	addRemoveLinks: true,
	    	uploadMultiple : false,
	    	parallelUploads : 10,
            maxFilesize: 50000,
	    	maxFiles: 1,
	    });

	    vm.dropzone.on("maxfilesexceeded", function(file) {
		    vm.dropzone.removeFile(file);
		});

	    vm.dropzone.on('processing', function (file) {
	    		//if not table based always replace
	    		if ( vm.datasetType != 'TABLE') {
	 			vm.appendDataset = true;
	 		}

		    	vm.dropzone.options.url =  	vm.serviceUrl +  "?replace=" + !vm.appendDataset;

	    });




	    vm.dropzone.on('sending', function (file, xhr, formData) {
	    		xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
	    });

    	vm.dropzone.on('success',function(file, dataset) {
    		vm.dropzone.removeEventListeners();
			$state.go('datasets');
        });

    	vm.dropzone.on('error',function(file,response, headers){
    		if (response.errorMessage) {
    			$(file.previewElement).find('.dz-error-message').text(response.errorMessage);
    			GlobalModal.displayError(response.errorMessage);
    		}
    		else if (response.status == 0){
    			$(file.previewElement).find('.dz-error-message').text('timed out');
    			GlobalModal.displayError('Timed out uploading file.');
    		}
    		else if(response.status == 500){
    			$(file.previewElement).find('.dz-error-message').text('server error uploading file');
    			GlobalModal.displayError('Error uploading file.');
    		}
    		else if(response.status == 400){
    			var message = 'unsupported file format';
    			$(file.previewElement).find('.dz-error-message').text(message);
    			GlobalModal.displayError('Unrecognized file format.');
    		} else if (headers.status == 406){		
    			$(file.previewElement).find('.dz-error-message').text('File extension not allowed.');
    			GlobalModal.displayError('File extension not allowed.');
    		}
    		$rootScope.$apply();
    	});

    	function processDropzoneQueue() {
    		var userMessage = '';

    		if ( vm.dropzone.files.length > 0  && vm.datasetType == 'TABLE') {
    			vm.dropzone.files
    			if ( vm.appendDataset) {
    				userMessage ='Are you sure you want to append this dataset ?';
    			} else {
    				userMessage ='Are you sure you want to replace this dataset ?';
    			}
    			GlobalModal.confirm(userMessage, function() {
        	 		vm.dropzone.processQueue();
        	 		return false;
            });
    		} else {
    			vm.dropzone.processQueue();
    	 		return false;
    		}




    	}

        // send user back to datasets screen
    	function returnToDatasets() {
    		$state.go('datasets');
    	}
    }
})();
