(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ExternalEntityController', ExternalEntityController);

    ExternalEntityController.$inject = ['$state', '$stateParams', 'GlobalModal', 'ExternalEntity', 'User', 'Agreement'];

    function ExternalEntityController ($state, $stateParams, GlobalModal, ExternalEntity, User, Agreement) {
        var vm = this;

        vm.userFilter = '';
        vm.usersPerPage = 20;
        vm.usersPage = 1;
        vm.userSort = ['user.lastName', 'user.firstName'];
        vm.reverse = false;
        vm.annualRecertificationDateOpen = false;
        vm.bizOwnerContactDateOpen = false;
        vm.nextAvailableQueryId = 0;

        vm.navExternalEntities = navExternalEntities;
        vm.addAttribute = addAttribute;
        vm.removeAttribute = removeAttribute;
        vm.attrKeys = attrKeys;
        vm.addUserAttribute = addUserAttribute;
        vm.removeUserAttribute = removeUserAttribute;
        vm.userAttrKeys = userAttrKeys;
        vm.saveEntity = saveEntity;
        vm.loadUsers = loadUsers;
        vm.loadUserPage = loadUserPage;
        vm.sortBySortingName = sortBySortingName;
        vm.sortByEmail = sortByEmail;
        vm.sortByStatus = sortByStatus;
        vm.addUser = addUser;
        vm.editUser = editUser;
        vm.saveUser = saveUser;
        vm.resendInvite = resendInvite;
        vm.markInvited = markInvited;
        vm.markNotInvited = markNotInvited;
        vm.selectUser = selectUser;
        vm.deselectUser = deselectUser;
        vm.selectAllUsers = selectAllUsers;
        vm.deselectAllUsers = deselectAllUsers;
        vm.availableUserFilterChange = availableUserFilterChange;
        vm.selectedUserFilterChange = selectedUserFilterChange;
        vm.openAnnualRecertificationDateCalendar = openAnnualRecertificationDateCalendar;
        vm.openBizOwnerContactDateCalendar = openBizOwnerContactDateCalendar;
        vm.selectEntityTypeId = selectEntityTypeId;
        vm.signForAllActiveFutureAgreements = signForAllActiveFutureAgreements;
        vm.getAllActiveFutureAgreements = getAllActiveFutureAgreements;
        vm.agreementRevisionVM = '';
        vm.activeFutureAgreementRevisions = '';
        vm.resetLastAccess = resetLastAccess;
        vm.showUpdateHistory = showUpdateHistory;
        vm.hideUpdateHistory = hideUpdateHistory;
        vm.showEntityUpdateHistory = showEntityUpdateHistory;
        vm.hideEntityUpdateHistory = hideEntityUpdateHistory;


        getAllActiveFutureAgreements();

        // adding entity
        if (!$stateParams.entityId || $stateParams.entityId === '' || $stateParams.entityId == '0') {
        		vm.editing = false;
	    		vm.tab = 'configuration';

            ExternalEntity.get({ id: 0 },
                    function(response, headers) {
                        vm.entity = response;
                        vm.availableTypes = response.availableTypes;
                    }, function(error) {
                        GlobalModal.displayError('An error occurred retrieving entity');
                    }
                );
	    }

        // editing existing entity
	    else {
	        loadUsers();

	    		vm.editing = true;
	    		vm.tab = 'users';

	    		ExternalEntity.get({ id: $stateParams.entityId },
	    			function(response, headers) {
	    		        vm.availableTypes = response.availableTypes;
	    				vm.entity = response;

	    				if (vm.entity.entityType)
	    				    vm.entityTypeId = vm.entity.entityType.id;
	    				else
	    				    vm.entityTypeId = 0;
	    				selectEntityTypeId(vm.entityTypeId);

	    				if (vm.entity.annualRecertificationDate)
	    				    vm.entity.annualRecertificationDate = moment(vm.entity.annualRecertificationDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();

	    				if (vm.entity.bizOwnerContactDate)
	    				    vm.entity.bizOwnerContactDate = moment(vm.entity.bizOwnerContactDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();
	    			}, function(error) {
	    				GlobalModal.displayError('An error occurred retrieving entity');
	    			}
	    		);
	    }

        function showUpdateHistory(eeu) {
        	 $('#auditDetailsPopover').modal('show');


        }

        function hideUpdateHistory() {
            $('#auditDetailsPopover').modal('hide');
        }
        
        function showEntityUpdateHistory(entity) {
       	 $('#entityUpdatePopover').modal('show');


       }

       function hideEntityUpdateHistory() {
           $('#entityUpdatePopover').modal('hide');
       }

        // navigate back to entity list
        function navExternalEntities() {
        		$state.go('external-entities');
        }

        // add an attribute
        function addAttribute() {
        		if (!vm.newAttrKey || vm.newAttrKey.length == 0) {
        			GlobalModal.displayError('Please enter an attribute key');
        			return;
        		}

        		if (vm.entity.attributes[vm.newAttrKey]) {
        			GlobalModal.displayError('Attribute already exists');
        			return;
        		}

        		vm.entity.attributes[vm.newAttrKey] = vm.newAttrValue;

        		vm.newAttrKey = '';
        		vm.newAttrValue = '';
        }

        // removes an attribute
        function removeAttribute(key) {
        		delete vm.entity.attributes[key];
        }

        // returns an array of sorted attribute keys
        function attrKeys() {
        		if (!vm.entity.attributes) return;
        		var keys = [];
        		$.each(vm.entity.attributes, function( key, value ) {
        		    keys.push(key);
        		});

        		if (vm.sortedKeys && arraysAreIdentical(keys, vm.sortedKeys))
        			return vm.sortedKeys;

        		vm.sortedKeys = keys;
        		return keys;
        }

        // add a user attribute
        function addUserAttribute() {
        		if (!vm.newUserAttrKey || vm.newUserAttrKey.length == 0) {
        			GlobalModal.displayError('Please enter an attribute key');
        			return;
        		}

        		if (vm.currentEEU.attributes[vm.newUserAttrKey]) {
        			GlobalModal.displayError('Attribute already exists');
        			return;
        		}

        		vm.currentEEU.attributes[vm.newUserAttrKey] = vm.newUserAttrValue;

        		vm.newUserAttrKey = '';
        		vm.newUserAttrValue = '';
        }

        // removes an attribute
        function removeUserAttribute(key) {
        		delete vm.currentEEU.attributes[key];
        }

        // returns an array of sorted attribute keys
        function userAttrKeys() {
        		if (!vm.currentEEU.attributes) return;
            var keys = [];
            $.each(vm.currentEEU.attributes, function( key, value ) {
                keys.push(key);
            });

        		if (vm.sortedUserKeys && arraysAreIdentical(keys, vm.sortedUserKeys))
        			return vm.sortedUserKeys;

        		vm.sortedUserKeys = keys;
        		return keys;
        }

        function arraysAreIdentical(arr1, arr2){
            if (arr1.length !== arr2.length) return false;
            for (var i = 0, len = arr1.length; i < len; i++){
                if (arr1[i] !== arr2[i]){
                    return false;
                }
            }
            return true;
        }

        // saves entity information after changes have been made
        function saveEntity() {
            // DAT-221 : force entity type to display validation if not entered on save
            // if we load a record that was never set to begin with we need to mark the field as dirty programatically
            if (!vm.entity.entityType) {
                vm.entityForm.entityType.$setViewValue(null);
            }

            vm.entityForm.$setSubmitted();

            if (!vm.entityForm.$valid) {
                GlobalModal.displayError('Please ensure all required fields have valid entries before saving.');
                return false;
            }

        		var entity = {
        		  id: vm.entity.id,
        		  name: vm.entity.name,
        		  active: vm.entity.active,
        		  contractNumbers: vm.entity.contractNumbers,
        		  attributes: vm.entity.attributes,
        		  entityType: vm.entity.entityType,
        		  updateReason: vm.entity.updateReason
        		}

            if (vm.entity.annualRecertificationDate && vm.entity.annualRecertificationDate != "") {
                var d = moment(vm.entity.annualRecertificationDate, ["M-D-YYYY"], true);
                entity.annualRecertificationDate = d.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
            }

            if (vm.entity.bizOwnerContactDate && vm.entity.bizOwnerContactDate != "") {
                var d = moment(vm.entity.bizOwnerContactDate, ["M-D-YYYY"], true);
                entity.bizOwnerContactDate = d.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
            }

        		if (!vm.entityForm.$valid) return;

        		if (vm.editing) {
        			ExternalEntity.update({id: vm.entity.id}, entity,
        				function(result) {
        					GlobalModal.displaySuccess('Entity data saved successfully');
        				},
        				function(error) {
        					GlobalModal.displayError('An unexpected error occurred updating the entity');
        				}
        			);
        		}
        		else {
        			ExternalEntity.create(entity,
        				function(result) {
    						GlobalModal.displaySuccess('Entity data saved successfully');
	    			    		$state.go('external-entity', {
	    			    			entityId: result.id,
	    			    		});
        				},
        				function(error) {
        					GlobalModal.displayError('An unexpected error occurred creating the entity');
        				}
        			);
        		}
        }

        // retrieves list of users from db
        function loadUsers(idToEdit, forceRefresh) {
            vm.currentAvailableQueryId = vm.nextAvailableQueryId++;
            var queryId = vm.currentAvailableQueryId;
            ExternalEntity.queryUsers({ name: vm.userFilter, id: $stateParams.entityId, page: vm.userPage-1, size: vm.usersPerPage, sort: vm.userSort },
            		function(users, headers) {
                    if (queryId == vm.currentAvailableQueryId) {
        		            	vm.users = users;
        		            	vm.totalUsers = headers('X-Total-Count');

        		            	if ((forceRefresh || !vm.currentEEU) && vm.users.length > 0) {
        		            	    if (idToEdit) {
        		            	        for (var i=0; i<vm.users.length; i++) {
        		            	            if (vm.users[i].id == idToEdit) {
        		            	                editUser(vm.users[i]);
        		            	                return;
        		            	            }
        		            	        }
        		            	    }

        	            	        editUser(vm.users[0]);
        		            	}
                    }
	            }
            );
        }

        // loads a page of users during pagination
        function loadUserPage(page) {
        		vm.currentEEU = null;
            vm.userPage = page;
            loadUsers();
        }

        function sortBySortingName(){
        		vm.currentEEU = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.userSort = ['user.lastName,' + sortDir, 'user.firstName,' + sortDir];
	    		loadUsers();
        }

        function sortByEmail(){
        		vm.currentEEU = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.userSort = ['user.email,' + sortDir];
	    		loadUsers();
        }

        function sortByStatus(){
        		vm.currentEEU = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.userSort = ['user.activated,' + sortDir];
	    		loadUsers();
        }

        // creates a new user
        function addUser() {
        		vm.currentEEU =  {
        			user: {
        				activated: true,
        				authority: 'C'
        			},
        			attributes: {}
        		}

        		vm.selectedInternalUsers = [];

        		vm.showAssociations = false;
        		if (vm.userForm)
                    vm.userForm.$setPristine();
        }

        // loads a users data for editing
        function editUser(eeu) {
        		vm.currentEEU = null;
            vm.showAssociations = false;
            if (vm.userForm)
        		    vm.userForm.$setPristine();

			ExternalEntity.getUser({id: $stateParams.entityId, eeuId: eeu.id}, function(eeu) {
		        	var authoritySupplier = eeu.user.authorities.indexOf('ROLE_SUPPLIER') >= 0,
					authorityConsumer = eeu.user.authorities.indexOf('ROLE_CONSUMER') >= 0,
					authorityAdmin = eeu.user.authorities.indexOf('ROLE_ORG_ADMIN') >= 0;

	        		vm.currentEEU = {
	        			id: eeu.id,
	            		attributes: $.extend({}, eeu.attributes),
	            		updateReasonHistoryData: eeu.updateReasonHistoryData,
	        			user: {
	            			id: eeu.user.id,
	            			firstName: eeu.user.firstName,
	            			lastName: eeu.user.lastName,
	            			login: eeu.user.login,
	            			email: eeu.user.email,
	            			activated: eeu.user.activated,
	                		authority: authorityAdmin ? 'A' : authoritySupplier ? 'S' : 'C',
	                		langKey: eeu.user.langKey,
	                		timeZone: eeu.user.timeZone,
	                		inviteAccepted: eeu.user.inviteAccepted,
	                		lastAccessDate: eeu.user.lastAccessDate,
	                		updateReason: eeu.user.updateReason
	        			}
	        		}

	        		// prepare internal users lists
	        		vm.originalInternalUsers = buildOriginalUserList(eeu.availableInternalUsers, eeu.selectedInternalUsers)
	        		vm.selectedInternalUsers = eeu.selectedInternalUsers;
				var allUsers = vm.originalInternalUsers;
				processUsers(allUsers);
			}, function(error) {
				GlobalModal.displayError('Unable load user data.');
			});
        }

        // build list of original users from available and selected users
        function buildOriginalUserList(availableUsers, selectedUsers) {
            var originalUsers = [].concat(availableUsers);
            for (var i=0; i<selectedUsers.length; i++) {
                var u = selectedUsers[i];
                var exists = false;
                for (var j=0; j<originalUsers.length; j++) {
                    if (u.id === originalUsers[j].id) {
                        originalUsers[j] = u;
                        exists = true;
                        break;
                    }
                }
                if (!exists)
                    originalUsers.push(u);
            }
            return originalUsers;
        }

        // saves user information after changes have been made
        function saveUser() {
        		vm.userForm.$setSubmitted();

        		if (!vm.userForm.$valid) return;

        		if (!validateEmail(vm.currentEEU.user.email)) {
        			GlobalModal.displayError("Please enter a valid email address");
        			return;
        		}

        		var user = {
        			id: vm.currentEEU.user.id,
        			firstName: vm.currentEEU.user.firstName,
        			lastName: vm.currentEEU.user.lastName,
        			login: vm.currentEEU.user.login,
        			email: vm.currentEEU.user.email,
        			activated: vm.currentEEU.user.activated,
        			authorities: [],
        		}

        		if (!vm.currentEEU.user.authority) {
        			GlobalModal.displayError("Please assign a role to user");
        			return;
        		}
        		if (vm.currentEEU.user.authority === 'C') {
        			user.authorities.push('ROLE_CONSUMER');
        		}
        		else if (vm.currentEEU.user.authority === 'S') {
        			user.authorities.push('ROLE_CONSUMER');
        			user.authorities.push('ROLE_SUPPLIER');
        		}

        		// validate data before sending to server
        		if (user.email.length < 3 || user.login.length < 3)
        			return false;

        		var eeu = {
        			id: vm.currentEEU.id,
        			user: user,
        			attributes: vm.currentEEU.attributes,
        			selectedInternalUsers: vm.selectedInternalUsers,
        			updateReason: vm.currentEEU.updateReason,
        		}

        		if (user.id > 0) {
        			ExternalEntity.updateUser({id: $stateParams.entityId, eeuId: eeu.id}, eeu, function(data) {
        				GlobalModal.displaySuccess('Your changes have been saved.');
        				loadUsers(data.id, true);
        			}, function(error) {
        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userexists')
        					GlobalModal.displayError('User login already exists in system');
        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.emailexists')
        					GlobalModal.displayError('Email already assigned to another user');
        				else
        					GlobalModal.displayError('Unable to save user. Please try again.');
        			});
        		}
        		else {
        			ExternalEntity.createUser({id: $stateParams.entityId}, eeu, function(data) {
        			    GlobalModal.displaySuccess('Your changes have been saved.');
        			    vm.currentEEU = null;
        			    loadUsers(data.id, true);
        			}, function(error) {
        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userexists')
        					GlobalModal.displayError('User login already exists in system');
        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.emailexists')
        					GlobalModal.displayError('Email already assigned to another user');
        				else
        					GlobalModal.displayError('Unable to save user. Please try again.');
        			});
        		}
        }

        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        // resends invitation to current user
        function resendInvite() {
	        	vm.userForm.$setSubmitted();

	    		if (!vm.userForm.$valid) return;
        		ExternalEntity.resendInvite({id: $stateParams.entityId, eeuId: vm.currentEEU.id, updateReason: vm.currentEEU.updateReason}, {}, function() {
		        		GlobalModal.displaySuccess('The invitation to the user has been re-sent');
		        		editUser(vm.currentEEU);
	        		});
        }

        // marks user as having accepted an invitation
        function markInvited() {
	        	vm.userForm.$setSubmitted();

	    		if (!vm.userForm.$valid) return;
            User.markInvitedAccepted({id: vm.currentEEU.user.id, updateReason: vm.currentEEU.updateReason}, {}, function() {
                    GlobalModal.displaySuccess("The user's invitation has been marked accepted");
                    vm.currentEEU.user.inviteAccepted = true;

                    // update the list's value as well
                    for (var i=0; i<vm.users.length; i++) {
                        if (vm.users[i].user.id === vm.currentEEU.user.id) {
                            vm.users[i].user.inviteAccepted = true;
                            break;
                        }
                    }

                    editUser(vm.currentEEU);
                });
        }

        // marks user as having not accepted an invitation
        function markNotInvited() {
	        	vm.userForm.$setSubmitted();

	    		if (!vm.userForm.$valid) return;
            User.markInvitedNotAccepted({id: vm.currentEEU.user.id, updateReason: vm.currentEEU.updateReason}, {}, function() {
                    GlobalModal.displaySuccess("The user's invitation has been marked not accepted");
                    vm.currentEEU.user.inviteAccepted = false;

                    // update the list's value as well
                    for (var i=0; i<vm.users.length; i++) {
                        if (vm.users[i].user.id === vm.currentEEU.user.id) {
                            vm.users[i].user.inviteAccepted = false;
                            break;
                        }
                    }
                    editUser(vm.currentEEU);
                });
        }

	    	// processes available user set after retrieval from server
	    	function processUsers(data) {
				vm.availableInternalUsers = [];

				var nameSet = {};
				for (var i=0; i<data.length; i++) {
					var user = data[i];
					user.displayName = buildUserDisplayName(user);

					// if duplicates of the same user name are in the list, mark to display email
					if (nameSet[user.displayName]) {
						nameSet[user.displayName].showEmail = true;
						user.showEmail = true;
					}
					else  {
						nameSet[user.displayName] = user;
					}

					// add user to available if not already selected
					var selected = false;
					for (var j=0; j<vm.selectedInternalUsers.length; j++) {
						var selectedUser = vm.selectedInternalUsers[j];
						if (selectedUser.id == user.id) {
							selected = true;
							break;
						}
					}
					if (!selected) vm.availableInternalUsers.push(user);
				}

				// build option text for display to user in select box
				for (var i=0; i<data.length; i++) {
					var user = data[i];
					user.optionText = buildUserNameOption(user);
				}
			}

	    	// builds the display name key for a given user
	    	function buildUserDisplayName(user) {
	    		var name = '';

	    		// build name portion of string
	    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
	    			name += user.firstName;

	    			if  (user.lastName && user.lastName !== '') {
	    				if (name.length>0) name += ' ';
	    				name += user.lastName;
	    			}
	    		}
	    		else {
	    			name += user.email;
	    		}

	    		return name;
	    	}

	    	// builds the text used to display in listbox option
	    	function buildUserNameOption(user) {
	    		var name = user.displayName;

	    		if (user.showEmail) {
	    			name += ' (';
	    			name += user.email;
	    			name += ')';
	    		}

	    		// append "pending" notice for users that have not accepted yet
	    		if (!user.inviteAccepted)
	    			name += ' (pending)';

	    		return name;
	    	}

	    	// selects a user
	    	function selectUser(user) {
			// add user to selected if not already selected
			var selected = false;
			for (var j=0; j<vm.selectedInternalUsers.length; j++) {
				var selectedUser = vm.selectedInternalUsers[j];
				if (selectedUser.id == user.id) {
					selected = true;
					break;
				}
			}
			if (!selected) vm.selectedInternalUsers.push(user);

	    		var idx = vm.availableInternalUsers.indexOf(user);
	    		if (idx >= 0)
	    			vm.availableInternalUsers.splice(idx, 1);

	    		vm.userForm.$setDirty();
	    	}

	    	// deselects a user
	    	function deselectUser(user) {
	    		vm.availableInternalUsers.push(user);

	    		var idx = vm.selectedInternalUsers.indexOf(user);
	    		if (idx >= 0)
	    			vm.selectedInternalUsers.splice(idx, 1);

	    		vm.userForm.$setDirty();
	    	}

	    	// selects all users
	    	function selectAllUsers() {
	    		while (vm.availableInternalUsers.length > 0)
	    			selectUser(vm.availableInternalUsers[0]);
	    	}

	    	// deselects all users
	    	function deselectAllUsers() {
	    		while (vm.selectedInternalUsers.length > 0)
	    			deselectUser(vm.selectedInternalUsers[0]);
	    	}

	    	// invoked when available user filter changes
	    	function availableUserFilterChange() {
	    		if (vm.availableUserFilter.length > 1) {
	    			ExternalEntity.queryInternalUserAutocomplete({id: $stateParams.entityId, name: vm.availableUserFilter}, function(data) {
	    				processUsers(data);
	    			});
	    		}
	    		else
				processUsers(vm.originalInternalUsers);
	    	}

	    	// invoked when selected user filter changes
	    	function selectedUserFilterChange() {
	    		vm.selectedUserFilterApplied = vm.selectedUserFilter.length > 1 ? vm.selectedUserFilter : '';
	    	}

	    	// invoked when calendar button is pressed
        function openAnnualRecertificationDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.annualRecertificationDateOpen = true;
        };
        function openBizOwnerContactDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.bizOwnerContactDateOpen = true;
        };

        function selectEntityTypeId(id) {
            for (var idx=0; idx<vm.entity.availableTypes.length; idx++) {
                var entityType = vm.entity.availableTypes[idx];
                if (entityType.id === id) {
                    vm.entity.entityType = entityType;
                    break;
                }
            }

            vm.entityTypeId = id;

            if (vm.entity.entityType) {
                // remove any contract numbers not valid for this type
                var contractNumbers = {}
                for (var idx=0; idx<vm.entity.entityType.requiredContractTypes.length; idx++) {
                    var contractType = vm.entity.entityType.requiredContractTypes[idx];
                    contractNumbers[contractType] = vm.entity.contractNumbers[contractType];
                }
                vm.entity.contractNumbers = contractNumbers;
            }
        }

        function signForAllActiveFutureAgreements() {
        	    var name = vm.agreementRevisionVM.agreementName + ' - ' + vm.agreementRevisionVM.name;
	        	 GlobalModal.confirm('Do you want to mark all users as signed for agreeement' + name +  ' ?', function() {
	        		 ExternalEntity.signEntityForAgreementRevision({id: vm.entity.id, arId: vm.agreementRevisionVM.id},{}, function(data) {
	        			 GlobalModal.displaySuccess('All users  are marked as signed for agreement -' + name );
	     			}, function(error) {
	     				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.revisionsignerror')
	     					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
	     				else
	     					GlobalModal.displayError('Unable to auto sign all users. Please try again.');
	     			});
	          });

        }

        function getAllActiveFutureAgreements() {

       		Agreement.getAllActiveAndFutureRevisions( function(agreementRevisionVMData) {
       			vm.activeFutureAgreementRevisions = agreementRevisionVMData;
    			});


        }

        // resets access time on a user account
        function resetLastAccess(user) {
	        	vm.userForm.$setSubmitted();

	    		if (!vm.userForm.$valid) return;
                User.resetLastAccess({id: user.id, updateReason: vm.currentEEU.updateReason}, {}, function() {
                    GlobalModal.displaySuccess('Last access has been reset');
                    user.lastAccessDate = null;
                    loadUsers();
                });
        }


    }
})();
