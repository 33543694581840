(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('PreviewService', PreviewService);

    PreviewService.$inject = ['DatasetFileMicroservice', 'Account'];

    function PreviewService (DatasetFileMicroservice, Account) {
        function previewDatasetBlobFile(datasetId, failure) {
            previewDatasetBlobFileWithId(datasetId, 0, failure);
        }

        function previewDatasetBlobFileWithId(datasetId, downloadId, failure) {
            DatasetFileMicroservice.getDownloadToken({id: datasetId, downloadId: downloadId, preview: true}, function(response) {
                if (!response.token) {
                    if (failure) failure();
                    return;
                }

                // previewing an audio file
                if (response.mimetype.indexOf("video/") === 0) {
                    var div = PreviewService.videoDiv || $('<div/>')
                        .css({
                            width: '90vw',
                            top: '0px',
                            left: '0px',
                            display: 'visible',
                        })
                    PreviewService.videoDiv = div;

                    var closeDiv = $('<div id="close" style="text-align: right"><i class="fa white fa-window-close"></i></div>')
                        .click(function() {
                            div.popup('hide')
                        })
                    
                    div.append(closeDiv);
                    
                    var url = '/zuul/squirrelboxfileservice/preview/datasets/'+datasetId+'/stream';
                    url += '?token='+response.token;
                    url += '&preview=true';
                    if (downloadId > 0) url += '&downloadId='+downloadId;
                    

                    var videoEl = $('<video id="dr_video_1" class="video-js vjs-default-skin" '+
                            'data-setup=\'{ "aspectRatio":"640:358", "playbackRates": [1, 1.5, 2] }\'> '+
                            '<source src="' + url + '" type=\'' + response.mimetype + '\' /> '+
                            '</video>')
                             
                    div.append(videoEl);
                    
                    div.popup({
                        autoopen: true,
                        closebutton: false, 
                        onclose: function() {
                            videojs('dr_video_1').dispose();
                            closeDiv.remove();
                            videoEl.remove();
                        }
                    });
                    
                    videojs('dr_video_1', {
                        controls: true,
                        autoplay: false,
                        preload: 'auto'
                      });
                    
                }
                else if (response.mimetype.indexOf("audio/") === 0) {
                    var div = PreviewService.audioDiv || $('<div/>')
                        .css({
                            width: '90vw',
                            height: '60vh',
                            top: '0px',
                            left: '0px',
                            display: 'visible',
                        })
                    PreviewService.audioDiv = div;
                        
                    div.popup({
                        autoopen: true,
                        closebutton: false, 
                        onclose: function() {
                            if (window.howl) window.howl.stop();
                            delete window.howl;
                        }
                    });
                    
                    div.load('content/howlerjs/player.html', function() {
                        var url = '/zuul/squirrelboxfileservice/file/datasets/'+datasetId+'/downloadBlob';
                        url += '?token='+response.token;
                        url += '&preview=true';
                        if (downloadId > 0) url += '&downloadId='+downloadId;
                        
                        /**
                         * Format the time from seconds to M:SS.
                         * @param  {Number} secs Seconds to format.
                         * @return {String}      Formatted time.
                         */
                        function formatTime(secs) {
                          var minutes = Math.floor(secs / 60) || 0;
                          var seconds = (secs - minutes * 60) || 0;

                          return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
                        }
                        
                        /**
                         * The step called within requestAnimationFrame to update the playback position.
                         */
                        function step() {
                            // Get the Howl we want to manipulate.
                            var sound = window.howl;
                            if (sound) {
                                // Determine our current seek position.
                                var seek = sound.seek() || 0;
                                div.find('#timer').html(formatTime(Math.round(seek)));
                                div.find('#progress').css('width', (((seek / sound.duration()) * 100) || 0) + '%');

                                // If the sound is still playing, continue stepping.
                                if (sound.playing()) {
                                    requestAnimationFrame(step);
                                }
                            }
                        }
                        
                        /**
                         * Seek to a new position in the currently playing track.
                         * @param  {Number} per Percentage through the song to skip.
                         */
                        function seek(per) {
                          // Get the Howl we want to manipulate.
                          var sound = window.howl;
                          if (sound) {
                              // Convert the percent into a seek position.
                              if (sound.playing()) {
                                  sound.seek(sound.duration() * per);
                              }
                          }
                        }
                        
                        // Bind our player controls.
                        div.find('#close').click(function() {
                          div.popup('hide')
                        });
                        div.find('#playBtn').click(function() {
                          window.howl.play();
                        });
                        div.find('#pauseBtn').click(function() {
                            window.howl.pause();
                        });
                        div.find('#waveform').click(function(event) {
                            seek(event.offsetX / div.outerWidth());
                        });

                        // Setup the "waveform" animation.
                        var wave = new SiriWave({
                            container: div.find('#waveform')[0],
                            width: window.innerWidth,
                            height: window.innerHeight * 0.3,
                            cover: true,
                            speed: 0.03,
                            amplitude: 0.7,
                            frequency: 2
                        });
                        wave.start();

                        window.howl = new Howl({
                            src: [url],
                            format: ['mp3'],
                            onplay: function() {
                              // Display the duration.
                              div.find('#duration').html(formatTime(Math.round(howl.duration())));
                                
                              // Start upating the progress of the track.
                              requestAnimationFrame(step);
    
                              // Start the wave animation if we have already loaded
                              div.find('#waveform').show();
                              div.find('#bar').hide();
                              div.find('#playBtn').hide();
                              div.find('#pauseBtn').show();
                            },
                            onload: function() {
                              // Start the wave animation.
                              div.find('#waveform').show();
                              div.find('#bar').hide();
                              div.find('#loading').hide();
                            },
                            onend: function() {
                              // Stop the wave animation.
                              div.find('#waveform').hide();
                              div.find('#bar').show();
                              div.find('#playBtn').show();
                              div.find('#pauseBtn').hide();
                            },
                            onpause: function() {
                              // Stop the wave animation.
                              div.find('#waveform').hide();
                              div.find('#bar').show();
                              div.find('#playBtn').show();
                              div.find('#pauseBtn').hide();
                            },
                            onstop: function() {
                              // Stop the wave animation.
                              div.find('#waveform').hide();
                              div.find('#bar').show();
                            }
                          });
                        howl.play();
                    });
                }
                
                // previewing a document
                else {
                    var url = '/zuul/squirrelboxfileservice/preview/datasets/'+datasetId+'/preview.pdf?token='+response.token;
                    if (downloadId > 0) url += '&downloadId='+downloadId;
                    
                    var div = PreviewService.documentDiv || $('<div/>')
                        .css({
                            top: '0px',
                            left: '0px',
                            display: 'visible',
                        });
                    PreviewService.documentDiv = div;
                    
                    var closeDiv = $('<div id="close" style="text-align: right"><i class="fa white fa-window-close"></i></div>')
                        .click(function() {
                            div.popup('hide')
                        })
                    
                    div.append(closeDiv);
                    
                    var previewDiv = $('<div/>')
                        .css({
                            width: '90vw',
                            height: '90vh',
                            top: '0px',
                            left: '0px',
                            display: 'visible',
                        });
                    
                    div.append(previewDiv);
                    
                    div.popup({
                        autoopen: true,
                        closebutton: false,
                        onclose: function() {
                            closeDiv.remove()
                            previewDiv.remove()
                        }
                    });
                    
                    var options = {
                        pdfOpenParams: {
                            navpanes: 0,
                            toolbar: 0,
                            statusbar: 0,
                            view: "FitV",
                            pagemode: "thumbs",
                        },
                        forcePDFJS: true,
                        PDFJS_URL: "content/pdfjs/web/viewer.html"
                    };
    
                  var myPDF = PDFObject.embed(url, previewDiv[0], options);
                }                
            }, function(error) {
                if (failure) failure();
            });
        }
        
        function previewAgreementPdf(orgId, agreementId, revisionId) {
                      
                var url = '/zuul/squirrelboxfileservice/file/org/' + orgId+ '/agreement/' +agreementId + '/revisions/' + revisionId;
                
                var div = PreviewService.documentDiv || $('<div/>')
                    .css({
                        top: '0px',
                        left: '0px',
                        display: 'visible',
                    });
                PreviewService.documentDiv = div;
                
                var closeDiv = $('<div id="close" style="text-align: right"><i class="fa white fa-window-close"></i></div>')
                    .click(function() {
                        div.popup('hide')
                    })
                
                div.append(closeDiv);
                
                var previewDiv = $('<div/>')
                    .css({
                        width: '90vw',
                        height: '90vh',
                        top: '0px',
                        left: '0px',
                        display: 'visible',
                    });
                
                div.append(previewDiv);
                
                div.popup({
                    autoopen: true,
                    closebutton: false,
                    onclose: function() {
                        closeDiv.remove()
                        previewDiv.remove()
                    }
                });
                
                var options = {
                    pdfOpenParams: {
                        navpanes: 0,
                        toolbar: 0,
                        statusbar: 0,
                        view: "FitV",
                        pagemode: "thumbs",
                    },
                    forcePDFJS: true,
                    PDFJS_URL: "content/pdfjs/web/viewer.html"
                };

              var myPDF = PDFObject.embed(url, previewDiv[0], options);
                              
            
        }


        return {
            previewDatasetBlobFile: previewDatasetBlobFile,
            previewDatasetBlobFileWithId: previewDatasetBlobFileWithId,
            previewAgreementPdf :previewAgreementPdf,
        };
    }
})();
