(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('DownloadHistoryController', DownloadHistoryController);

    DownloadHistoryController.$inject = ['$scope', '$state', '$stateParams', '$location', 'Dataset', 'DownloadService', 'GlobalModal', 'clipboard', 'Principal'];

    function DownloadHistoryController ($scope, $state, $stateParams, $location, Dataset, DownloadService, GlobalModal, clipboard, Principal) {
        var vm = this;
        
        vm.itemsPerPage = 12;
        vm.page = 1;
        
        vm.query = query;
        vm.loadPage = loadPage;
        vm.downloadDatasetFile = downloadDatasetFile;
        vm.deleteDownload = deleteDownload;
        vm.returnToDatasets = returnToDatasets;
        vm.openApi = openApi;
        vm.buildDownloadUserDisplayName = buildDownloadUserDisplayName;
        vm.generatePurgedDatasetTooltip = generatePurgedDatasetTooltip;
        vm.shaCodes = "";
        vm.openShaCodes = openShaCodes;
        
        
        Principal.identity().then(function(identity) {
        	vm.identity = identity;
        });

        query();
        
        function query() {
	        Dataset.getDownloadHistory({id: $stateParams.datasetId, allUsers: $stateParams.allUsers, page: vm.page-1, size: vm.itemsPerPage},
	    		function(result, headers) {
	        		vm.ownerId = result.ownerId;
	        		vm.datasetName = result.datasetName;
	        		vm.datasetType = result.datasetType;
	        		vm.delegate = result.delegate;
	        		vm.downloads = result.downloads;
	        		vm.totalItems = headers('X-Total-Count');
	    		});
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
        
    	function downloadDatasetFile(download, $event) {
			download.status = 'DOWNLOADED';
			
			if (vm.datasetType == 'BLOB') {
    			    DownloadService.downloadDatasetBlobFileWithId($stateParams.datasetId, download.id, function() {
					GlobalModal.displayError('There was a problem with your download. Please try again.');
				}, $event.altKey);
			}
			else {
				DownloadService.downloadDatasetFile($stateParams.datasetId, download.id, function() {
					GlobalModal.displayError('There was a problem with your download. Please try again.');
				}, $event.altKey);
			}
    	}
    	
        function deleteDownload(download) {
        	GlobalModal.confirm('Are you sure you want to delete this download?', function() {
            	Dataset.deleteDownloadHistoryEntry({id: $stateParams.datasetId, downloadId: download.id}, function() {
    	        		query();
    	        	}, function(error) {
    	        		GlobalModal.displayError('There was a problem deleting your donwload. Please try again.');
    	        	});
            });
        }
        
        // send user back to datasets screen
    	function returnToDatasets() {
    		$state.go('datasets');
    	}
    	
    	function openApi(download) {
    		vm.apiEndpointUrl = relPathToAbs('/squirrelboxfileservice/api/datasets/'+$stateParams.datasetId+'/download/'+download.id+'/json');
    		
    		var displayUrlModal = true;
    		vm.clipboardSupported = clipboard.supported;
    		if (vm.clipboardSupported) {
    			try {
	    			clipboard.copyText(vm.apiEndpointUrl);
	    			GlobalModal.displayCustom('JSON API Endpoint has been copied to your clipboard', false, null, 'info', 'fa-code', true);
	    			displayUrlModal = false;
    			}
    			catch (ignored) {}
    		}
    		
    		if (displayUrlModal) {
    			var text = '<div style="width:250px; word-wrap: break-word;">' +
    					   '<strong>Please use the following URL</strong><br><br>' + 
    					   vm.apiEndpointUrl + 
    					   '</div>';
    			GlobalModal.displayCustom(text, true, null, 'info', 'fa-code', false);
    		}
    	}
    	
    	function openShaCodes(download) {
    		vm.shaCodes = 'SHA1: ' + download.sha1_hash + '\nSHA256: ' + download.sha256_hash  + '\nSHA512: ' + download.sha512_hash;
    		
    		var displayUrlModal = true;
    		vm.clipboardSupported = clipboard.supported;
    		if (vm.clipboardSupported) {
    			try {
	    			clipboard.copyText(vm.shaCodes);
	    			GlobalModal.displayCustom('SHA Hash codes have been copied to your clipboard', false, null, 'info', 'fa-hashtag', true);
	    			displayUrlModal = false;
    			}
    			catch (ignored) {}
    		}
    		
    		if (displayUrlModal) {
    			var text = '<div style="width:250px; word-wrap: break-word;">' +
    					   '<strong>Please use the following SHA hash codes</strong><br><br>' + 
    					   '<div><b>SHA1:</b> ' + download.sha1_hash + '</div>' +
    					   '<div><b>SHA256:</b> ' + download.sha256_hash + '</div>' +
    					   '<div><b>SHA512:</b> ' + download.sha512_hash + '</div>'
    					   '</div>';
    			GlobalModal.displayCustom(text, true, null, 'info', 'fa-hashtag', false);
    		}
    	}
    	
     
    	
    	function hideModal() {
            $('#apiDownloadDialog').modal('hide')
    		$('body').removeClass('modal-open');
    		$('.modal-backdrop').remove();
    	}
    	
    	 function generatePurgedDatasetTooltip(download) {	    		        			        		
  	       return 'This Dataset was purged on ' + moment(download.purgeTimeStamp).format( " YYYY-MM-DD");	             
     }

    	function relPathToAbs (sRelPath) {
		  var nUpLn, sDir = "", sPath = location.pathname.replace(/[^\/]*$/, sRelPath.replace(/(\/|^)(?:\.?\/+)+/g, "$1"));
		  for (var nEnd, nStart = 0; nEnd = sPath.indexOf("/../", nStart), nEnd > -1; nStart = nEnd + nUpLn) {
		    nUpLn = /^\/(?:\.\.\/)*/.exec(sPath.slice(nEnd))[0].length;
		    sDir = (sDir + sPath.substring(nStart, nEnd)).replace(new RegExp("(?:\\\/+[^\\\/]*){0," + ((nUpLn - 1) / 3) + "}$"), "/");
		  }
		  return $location.protocol() + "://" + location.host + sDir + sPath.substr(nStart);
		}
    	
    	// builds the display name for a user
    	function buildDownloadUserDisplayName(user) {
    		var name = '';
    		
    		// build name portion of string
    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
    			name += user.firstName;
    			
    			if  (user.lastName && user.lastName !== '') {
    				if (name.length>0) name += ' ';
    				name += user.lastName;
    			}
    			
    			if (user.email)
    			    name += ' (' + user.email + ')';
    		}
    		else {
    			name += user.email;
    		}
    		
    		return name;
    	}
    }
})();
