(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('Agreement', Agreement);

    Agreement.$inject = ['$resource', 'DateUtils'];

    function Agreement($resource, DateUtils) {
        var resourceUrl =  'api/agreementList/:id';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' },
            'query': { method: 'GET', isArray: true },
            'create': { method: 'POST' },
            'update': { method: 'PUT' },
            'queryRevisions': {
	        		url: resourceUrl + '/revisions',
	        		method: 'GET',
	        		isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.map(function(item) {
                        item.creationTimeStamp = DateUtils.convertDateTimeFromServer(data.creationTimeStamp);
                    });
                    return data;
                }
	        	},
            'getRevision': {
	        		url: resourceUrl + '/revisions/:arId',
	        		method: 'GET' 
	        	},
	        	'getRevisionDetails': {
		        		url: resourceUrl + '/revisions/:arId/details',
		        		method: 'GET' 
		     },
            'createRevision': {
            		url: resourceUrl + '/revisions',
            		method: 'POST' 
            	},
            'updateRevision': {
            		url: resourceUrl + '/revisions/:arId',
            		method: 'PUT' 
            	},
            	'signRevisionForAllUsers': {
            		url: resourceUrl + '/revisions/sign/:arId',
            		method: 'PUT' 
            	},
            	'getAllActiveAndFutureRevisions': {
            		url:  'api/agreementList/revisions/active',
            		method: 'GET' ,  isArray: true
            	},
            	'removePdf': {
         		url: resourceUrl + '/revisions/:arId/removepdf',
         		method: 'PUT' 
             },
        });
    }
})();
