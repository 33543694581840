(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('HomeUploadController', HomeUploadController);

    HomeUploadController.$inject = ['$rootScope', '$localStorage', 'AuthServerProvider', '$state' ,'$scope', 'GlobalModal', 'Principal'];

    function HomeUploadController ($rootScope, $localStorage, AuthServerProvider, $state, $scope, GlobalModal, Principal) {
        var vm = this;
        vm.showText = false;

        vm.getToken = AuthServerProvider.getToken;
        vm.processDropzoneQueue = processDropzoneQueue;
        vm.openUpload = openUpload;
        vm.configure = configure;
        vm.expandCollapse = expandCollapse;

        Principal.identity()
            .then(function(identity) {
                vm.identity = identity;
                vm.isExternal = Principal.hasAuthority('ROLE_EXTERNAL', identity);
                //external users limit to 1 file at a time
                if (vm.isExternal) {
                		vm.dropzone.options.maxFiles = 1;
                }
            });

	    vm.dropzone = new Dropzone("div#uploaddrop", { url: "zuul/squirrelboxfileservice/api/upload",
	    	autoProcessQueue: false,
	    	addRemoveLinks: true,
	    	uploadMultiple : true,
	    	parallelUploads : 10,
	    	maxFilesize: 50000,
	    });

	    vm.dropzone.on('sendingmultiple', function (file, xhr, formData) {
	    	xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
	    });

    	vm.dropzone.on('successmultiple',function(files,datasets) {
    		vm.dropzone.removeEventListeners();
    		if (datasets && datasets.length) {
	    		if(files.length==1){
                if (vm.isExternal) {
                    $state.go('share', {
                        datasetId: datasets[0].id
                    });
                }
                else {
        	    			$state.go('upload-configure', {
    	            			datasetId: datasets[0].id
    	            		});
    	    			}
    		    }
	    		else{
	    			$scope.$apply(function(){
	    	    		vm.showText=true;
	    	    		vm.datasets = datasets;
	    	    		vm.selectedDataset = datasets[0].id;
	    	    		});
	    			}
    			}
        	});

        vm.dropzone.on('error',function(file,response, headers){
    		if(response.status == 0){
    			$(file.previewElement).find('.dz-error-message').text('timed out');
    			GlobalModal.displayError('Timed out uploading file.');
    		}
    		else if(response.status == 500){
    			$(file.previewElement).find('.dz-error-message').text('server error uploading file');
    			GlobalModal.displayError('Error uploading file.');
    		}
    		else if(response.status == 400){
    			$(file.previewElement).find('.dz-error-message').text('unsupported file format');
    			GlobalModal.displayError('Unrecognized file format.');
    		}else if (headers.status == 406){		
    			$(file.previewElement).find('.dz-error-message').text('File extension not allowed.');
    			GlobalModal.displayError('File extension not allowed.');
		}
    	});


    	function processDropzoneQueue() {
    	 	//external users can upload only one file
	         if (	vm.dropzone.options.maxFiles == 1 && vm.dropzone.files.length > 1 ) {
	        	 	GlobalModal.displayError('Only 1 file allowed to upload at a time. Please remove additional files.');
	         } else {
		 		vm.dropzone.processQueue();
		 		return false;
	         }
    	}

        function openUpload() {
        	$state.go('upload');
        }

        function configure() {
    		$state.go('upload-configure', {
    			datasetId: vm.selectedDataset
    		});
    	}

        function expandCollapse() {
        	$rootScope.uploadExpanded = !$rootScope.uploadExpanded;
        	$localStorage.uploadExpanded = $rootScope.uploadExpanded;
        }
    }
})();
