(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('user-groups', {
            parent: 'app',
            url: '/user-groups',
            data: {
            	authorities: [
                    'ROLE_ORG_ADMIN',
				],
                pageTitle: 'User Groups - DataRail'
            },
            
            views: {
                'content@': {
                    templateUrl:'app/groups/user-groups/user-group.html',
                    controller: 'UserGroupAdminController',
                    controllerAs: 'vm'
                }                
            }
            
        });
    }
})();
