(function() {
	'use strict';

	angular
		.module('squirrelboxApp')
		.controller('AttestationReportController', AttestationReportController)

	AttestationReportController.$inject = [ '$scope', '$state', '$stateParams',
			'$window', 'GlobalModal', 'ExternalEntity', 'ParseLinks', 'Principal', 'ExternalEntityType' ];

	function AttestationReportController($scope, $state, $stateParams, $window,
			GlobalModal, ExternalEntity, ParseLinks, Principal, ExternalEntityType) {
		var vm = this;
		
		
		// Figure out if user is an admin
        Principal.identity().then(function(identity) {
            // vm.identity = identity;
            vm.isAdmin = Principal.hasAuthority('ROLE_ORG_ADMIN', identity);
        });
		
		// Get list of available entity types
		vm.availableTypes = [];
		ExternalEntityType.getActive({ id: 0 }, 
				function(response, headers) {
			vm.availableTypes = response;
			$scope.$broadcast('externalEntityUpdate');

		}, function(error){
		});

		
		vm.page = 1;		
		vm.entities = null;
		vm.reload = reload;
		
		
		vm.showPendingOnly = false;
		vm.resetSelected = false;
		vm.changeAllColumnSelections = changeAllColumnSelections;
		vm.togglePendingOnly = togglePendingOnly;
		vm.attestationId = null;
		
		vm.query = query;
		vm.entityReportSearch = {};
		
		
		vm.loadPage = loadPage;

		$scope.sort = "sort";
		$scope.reverse = true;

		
		vm.save = save;
		if ($stateParams.attestationId && $stateParams.attestationId != '') {
			vm.attestationId = $stateParams.attestationId;
		}
		
		
		vm.query();
				
		// toggle select all buttons 
		function changeAllColumnSelections(selection){
			
			for (var i = 0 ; i< vm.entities.length; i++) {
				for (var j = 0 ; j < vm.entities[i].externalUserAttestConfirmations.length; j++) {
					if (selection == "CONTINUE") {						
						vm.entities[i].externalUserAttestConfirmations[j].extUserAttestConfirmStatus = "CONTINUE";
						
					} else if (selection == "TERMINATE") {
						vm.entities[i].externalUserAttestConfirmations[j].extUserAttestConfirmStatus = "TERMINATE";
						
					} else if (selection == "PENDING") {
						vm.entities[i].externalUserAttestConfirmations[j].extUserAttestConfirmStatus = "PENDING";
						
					} 
						
				}
			}
			
		}
		
		function togglePendingOnly(pendingOnly){
			
			for (var i = 0 ; i< vm.entities.length; i++) {
				var pendingItems = false;
				for (var j = 0 ; j < vm.entities[i].externalUserAttestConfirmations.length; j++) {
					if (!pendingOnly) {
						vm.entities[i].externalUserAttestConfirmations[j].show = true;
						continue;
					}
					if (vm.entities[i].externalUserAttestConfirmations[j].extUserAttestConfirmStatus == 'PENDING') {						
						vm.entities[i].externalUserAttestConfirmations[j].show = true;
						pendingItems = true;
						
					} else {
						vm.entities[i].externalUserAttestConfirmations[j].show = false;
					}
						
				}
				
				if (!pendingOnly) {
					vm.entities[i].show = true;
					continue;
				}
				if (pendingItems) {
					vm.entities[i].show = true;
				} else {
					vm.entities[i].show = false;
				}
				
			}
			
			vm.showPendingOnly = pendingOnly;
			
		}
		
	
		
		// Query for all external entities attestations the database. Runs on page load 
		function query(){
	       
	        	var sort;
	        	if (Array.isArray($scope.sort)) {
	        		sort = [];
	        		for (var i=0; i<$scope.sort.length; i++) {
	            		sort.push($scope.sort[i] + "," + ($scope.reverse ? 'desc' : 'asc'));
	        		}
	        	}
	        	else {
	        		sort = $scope.sort + "," + ($scope.reverse ? 'desc' : 'asc');
	        	}
        	
	   
        	
	        	var queryParams = {       			
	        			attestationId: vm.attestationId
	    			}
        	
        	
	        	ExternalEntity.getAttestationReport(queryParams,
	        			function(result, headers){
			        		vm.entities = result;
			        		vm.togglePendingOnly(false);
	        			}
				);
		}
		
		function save(isSubmit){
	   
			//if submit, make sure nothing is pending. Also check if user has selected all ext users for terminate
			// we would like to alert them if they did by mistake
	        if (isSubmit) {
		        	var allUsersTerminated = true;
		        var someUsersPending = false;
		        	for (var i = 0 ; i< vm.entities.length; i++) {
						var pendingItems = false;
						for (var j = 0 ; j < vm.entities[i].externalUserAttestConfirmations.length; j++) {
							var status = vm.entities[i].externalUserAttestConfirmations[j].extUserAttestConfirmStatus;
							if (status == 'PENDING') {						
								someUsersPending= true;
								allUsersTerminated = false;
								break;
								
							} 
							if (status != 'TERMINATE') {	
								allUsersTerminated = false;
							}
								
						}									
						
				}
		        	var message = '';
		        	if (allUsersTerminated) {
		        		message = "ALL USERS are marked to be 'terminated' which will remove them from your associated list. ";
		        	} else if (someUsersPending) {
		        		message = "Cannot submit this attestation. All users should be marked as either 'continue' or 'terminate' to submit. ";
		        	} else {
		        		message = "Any users that you have marked as 'terminate' will be removed from your associated list. ";
		        	}
	        	
		        	 if (someUsersPending){
		        		 GlobalModal.displayError(message);
		        		 return;
		        	 }
		        	 
		        	GlobalModal.confirm('You are about to submit the attestation for all external users. ' + message + ' Please click Yes to confirm.? ', function() {
				  
		    			ExternalEntity.saveAttestationReport({attestationId: vm.attestationId, isSubmit: true}, vm.entities,
		    				function(result) {
		    					GlobalModal.displaySuccess('Attestation  completed successfully');
		    					$state.go('home');	 
		    					 
		    				},
		    				function(error) {
		    					GlobalModal.displayError('An unexpected error occurred updating the entity');
		    				}
		    			);
		    		}, function(error) {
		    			
		    		});
	       

	        } else {
	        		ExternalEntity.saveAttestationReport({attestationId: vm.attestationId, isSubmit: false}, vm.entities,
	    				function(result) {
	    					GlobalModal.displaySuccess('Attestation  data saved successfully');
	    					   					
	    				},
	    				function(error) {
	    					GlobalModal.displayError('An unexpected error occurred updating the entity');
	    				}
	    			);
	        }
	        	
	       
	      
        		
		}
		
		// Reload the vm.
		function reload() {
			loadPage(vm.page);
		}
		
	
		// Loads a new page of the query results
        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
		
		
		
		
	}
})();
