(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('downloadHistory', {
            parent: 'consumer',
            url: '/downloadHistory/:datasetId?:allUsers',
            views: {
                'content@': {
                    templateUrl: 'app/reports/history/history.html',
                    controller: 'DownloadHistoryController',
                    controllerAs: 'vm'
                },
            },
        });
    }
})();
