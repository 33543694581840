(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('sb-login', {
            parent: 'app',
            url: '/sb-login',
            data: {
                authorities: [],
                loginState: true,
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/login/sb-login/sb-login.html',
                    controller: 'SquirrelBoxLoginController',
                    controllerAs: 'vm'
                }
            },
        });
    }
})();
