(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('CifsCredentialsController', CifsCredentialsController);

    CifsCredentialsController.$inject = ['DataCenterAdmin', 'GlobalModal'];

    function CifsCredentialsController (DataCenterAdmin, GlobalModal) {
        var vm = this;

        vm.loadCredentials = loadCredentials;
        vm.loadPage = loadPage;
        vm.createCredentials = createCredentials;
        vm.editCredentials = editCredentials;
        vm.saveCredentials = saveCredentials;
        vm.deleteCredentials = deleteCredentials;
        vm.reverse = false;
        vm.sortByLabel = sortByLabel;

        vm.credentialsFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['label'];
        vm.activeOnly = false;

        loadCredentials();

        function loadCredentials() {
            vm.totalItems = 0;

            DataCenterAdmin.queryReusuableCifsCredentials({label: vm.credentialsFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
                function(result, headers) {
                    vm.credentials = result;
                    vm.totalItems = headers('X-Total-Count');

                    if (!vm.currentCredentials && vm.credentials.length > 0)
                        editCredentials(vm.credentials[0]);
                });
        }

        function loadPage(page) {
            vm.page = page;
            loadCredentials();
        }

        function createCredentials() {
            vm.currentCredentials = {
            }
        }

        function editCredentials(credentials) {
            if (vm.credentialsForm)
                vm.credentialsForm.$setPristine();

            vm.currentCredentials = {
                label: credentials.label,
                id: credentials.id,
                domain: credentials.domain,
                username: credentials.username,
                password: credentials.passwordUsed ? "**********" : "",
            }

            vm.credentialsBeingEdited = credentials;
        }

        function deleteCredentials(credentials) {
            GlobalModal.confirm('Are you sure you want to delete these credentials?', function() {
                DataCenterAdmin.deleteCifsCredentials({id: credentials.id}, function() {
                        loadCredentials();
                        vm.currentCredentials = null;
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsInUse')
                            GlobalModal.displayError('Credentials are in use and cannot be deleted.');
                        else
                            GlobalModal.displayError('Unable to delete credentials. Please try again.');
                    });
            });
        }

        function validateDomain(domain) {
            var re = RegExp('^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$');
            return re.test(domain);
        }

        function saveCredentials() {
            if (vm.currentCredentials) {
                vm.credentialsForm.$setSubmitted();

                // make sure entered domain is valid
                vm.credentialsForm.domain.$setValidity('format', validateDomain(vm.currentCredentials.domain));

                if(!vm.credentialsForm.$valid) return;

                var credentials = {
                    id: vm.currentCredentials.id,
                    label: vm.currentCredentials.label,
                    reusable: true,
                    domain: vm.currentCredentials.domain,
                    username: vm.currentCredentials.username,
                    password: vm.credentialsForm.password.$pristine ? null : vm.currentCredentials.password,
                }

                // update entity type
                if (credentials.id > 0) {
                    DataCenterAdmin.updateCifsCredentials({id: credentials.id}, credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }

                // create entity type
                else {
                    DataCenterAdmin.createCifsCredentials(credentials, function(data) {
                        GlobalModal.displaySuccess('Your changes have been saved.');
                        loadCredentials();
                        editCredentials(data);
                    }, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.credentialsExists')
                            GlobalModal.displayError('Credentials with this label already exist in system');
                        else
                            GlobalModal.displayError('Unable to save credentials. Please try again.');
                    });
                }
            }
        }

        function sortByLabel(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['label,' + sortDir];
            loadCredentials();
        }
   }

})();
