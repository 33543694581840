(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('SAML', SAML);

    SAML.$inject = ['$resource', 'DateUtils'];

    function SAML ($resource, DateUtils) {
        var resourceUrl =  'api/saml/:id';

        return $resource(resourceUrl, {}, {
            'getAllIdpConfigurations': {
            	url: 'api/saml/idp',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.map(function(config) {
                        	config.creationDate = DateUtils.convertDateTimeFromServer(config.creationDate);
                        });
                    }
                    return data;
                },
                isArray: true
            },
            'createIdpConfiguration': {
            	url: 'api/saml/idp',
            	method: 'POST',
            },
            'updateIdpConfiguration': {
            	url: 'api/saml/idp/:id',
            	method: 'PUT',
            },
            'getIdpConfiguration': {
            	url: 'api/saml/idp/:id',
            	method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                },
            },
            'deleteIdpConfiguration': {
            	url: 'api/saml/idp/:id',
            	method: 'DELETE',
            },
        });
    }
})();
