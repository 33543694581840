(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dc-flow', {
            parent: 'app',
            url: '/dc-flow/{dcFlowId}',
            data: {
                authorities: [
                	'ROLE_ORG_ADMIN',
                ]
            },
            views: {
                'content@': {
					templateUrl: 'app/datacenter/dc-flow/dc-flow.html',
					controller: 'DataCenterFlowController',
					controllerAs: 'vm'
                },
                'configuration@dc-flow': {
					templateUrl: 'app/datacenter/dc-flow/dc-flow-configuration.html',
                },
                'pgp@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-pgp.html',
                },
                'source-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-source-system.html',
                },
                'target-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-target-system.html',
                },
                'sourceSystem-cifs-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-source-cifs-system.html',
                },
                'sourceSystem-sftp-client-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-source-sftp-client-system.html',
                },
                'sourceSystem-sftp-host-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-source-sftp-host-system.html',
                },
                'sourceSystem-s3-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-source-s3-system.html',
                },
                'targetSystem-cifs-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-target-cifs-system.html',
                },
                'targetSystem-sftp-client-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-target-sftp-client-system.html',
                },
                'targetSystem-sftp-host-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-target-sftp-host-system.html',
                },
                'targetSystem-s3-system@dc-flow': {
                    templateUrl: 'app/datacenter/dc-flow/dc-flow-target-s3-system.html',
                },
            },
        });
    }
})();
