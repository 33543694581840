(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$scope','$window', 'User', '$state', 'Auth', '$rootScope', 'Principal', 'OrgSetUpService', 'GlobalModal' , 'Logo'];

    function PasswordResetController ($scope, $window, User, $state, Auth, $rootScope, Principal, OrgSetUpService, GlobalModal, Logo) {

        var vm = this;
        vm.logoIconUrl = Logo.logoIconUrl;

        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });


        OrgSetUpService.getOrganization().then(function(response) {
            vm.org = response.data;
        });

        vm.checkResetKey = checkResetKey;
        vm.changePassword = changePassword;
        vm.login = login;
        var resetKey;

        function checkResetKey(){
            resetKey = $window.location.href.split("/").pop();
            User.checkResetKey({
                resetKey : resetKey
            }, function(response) {
                vm.email = response.email;
            }, function() {
                $state.go('password-forgot',{
                    linkExpired : true,
                });

            });}

        function changePassword(event){
            event.preventDefault();

            if(vm.newPassword == null || vm.newPassword == ""){return;}
            if (vm.newPassword !== vm.confirmPassword) {

                vm.passwordMatchError = true;
                return false;
            }
            var user = {};
            user.email = vm.email;
            user.password = vm.newPassword;
            User.resetPassword({resetKey:resetKey},user,function(data){

                Auth.login({
                    username: data.login,
                    password: vm.newPassword,

                }).then(function () {
                    $rootScope.$broadcast('authenticationSuccess');

                    Principal.hasAuthority('ROLE_ADMIN')
                    .then(function(assigned) {
                        if (assigned)
                            $state.go('sysadmin-home')
                            else
                                $state.go('home');
                    });
                }).catch(function () {
                    vm.authenticationError = true;
                });

            },function(error){
                if(error.status === 400){
                    GlobalModal.displayError("Password does not meet organization's requirements");
                }
                if(error.status === 404){
                    vm.resetError=true;
                }
            });
        }



        function login() {
            $state.go('login');
        }
    }
})();
