(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('userImportAdmin', {
            parent: 'admin',
            url: '/admin/userimport',
            data: {
				authorities: [
					'ROLE_ORG_ADMIN'
				]
			},
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-import/user-import.html',
                    controller: 'UserImportController',
                    controllerAs: 'vm'
                },
            },
        });
        
    }
})();
