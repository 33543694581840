(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('ExternalEntityType', ExternalEntityType);

	ExternalEntityType.$inject = ['$resource'];

	function ExternalEntityType ($resource) {
		var service = $resource('api/externalEntityTypes/:id', {}, {
			'query': {method: 'GET', isArray: true},
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'getActive': {
            	url: 'api/activeEntityTypes',
            	method: 'GET',
            	isArray: true
    		}
		});

		return service;
	}
})();
