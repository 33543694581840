(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('revisionValidDate', revisionValidDate);

    revisionValidDate.$inject = ['$timeout'];

    function revisionValidDate ($timeout) {
    	return {
    	    require: "ngModel",
    	    restrict: "A", // Attributes only
    	    link: function (scope, elem, attr, ctrl) {
    	        ctrl.$validators.eeValidDate = function(value) {
    	            if (value === undefined || value === null || value === "") {
    	                return true;
    	            }

    	            var valid = moment(value, ["M-D-YYYY"], true).isValid();
    	            return valid;
    	        }
    	    }
    	}
    }
})();
