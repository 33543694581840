(function() {
    'use strict';

    angular
        .module('squirrelboxApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngSanitize',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'base64',
            'angular-clipboard',
            'autoCompleteModule',
            'http-etag',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .run(run)
        .config(config);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$transitions', '$state'];

    function run(stateHandler, translationHandler, $rootScope, $transitions, $state) {
        stateHandler.initialize();
        translationHandler.initialize();

        $transitions.onStart({}, function(trans) {
            $rootScope.transitioning = true;
            $rootScope.toState = trans.$to();
            $rootScope.fromState = trans.$from();
        });

        $transitions.onFinish({}, function(trans) {
            $rootScope.transitioning = false;

            if ($rootScope.nextState) {
                var next = $rootScope.nextState;
                $rootScope.nextState = null;
                return trans.router.stateService.target(next);
            }
        });
    }

    config.$inject = ['httpEtagProvider'];

    function config(httpEtagProvider) {
        httpEtagProvider
            .defineCache('persistentCache', {
              cacheService: 'sessionStorage'
            })
    }
})();

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
      var subjectString = this.toString();
      if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
        position = subjectString.length;
      }
      position -= searchString.length;
      var lastIndex = subjectString.indexOf(searchString, position);
      return lastIndex !== -1 && lastIndex === position;
  };
}

function enableTooltips() {
    // fix conflict between jquery-ui and bootstrap tooltip functions
    // renamem jquery-ui tooltip() to uitooltip()
    $.widget.bridge("uitooltip", $.ui.tooltip);

	$('[data-toggle="tooltip"]').tooltip().click(function() {
		$('.tooltip').remove();
	})
	$(document).click(function() {
		$('.tooltip').remove();
	})
}
