(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('Tag', Tag);

    Tag.$inject = ['$resource'];

    function Tag ($resource) {
        var resourceUrl =  'api/tags/:id';

        return $resource(resourceUrl, {}, {
            'create': { method: 'POST' },
            'delete': { method: 'DELETE' },
            'query': { method: 'GET', isArray: true },
            'queryAdmin': {
                url: 'api/tags/admin',
                method: 'GET'
            },
            'assignParentTag': {
                url: resourceUrl + '/parent',
                method: 'PUT'
            },
            'renameTag': {
                url: resourceUrl + '/rename',
                method: 'PUT'
            }
        });
    }
})();
