(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('UserAgreementController', UserAgreementController);

    UserAgreementController.$inject = ['$window', '$state', '$rootScope','UserAgreement', 'GlobalModal', 'Logo', 'Auth', 'AuthServerProvider'];

    function UserAgreementController ($window, $state, $rootScope, UserAgreement, GlobalModal, Logo, Auth, AuthServerProvider) {
        var vm = this;

        vm.isHistory = $state.current.data.isHistory;
        vm.loadUserAgreements = loadUserAgreements;
        vm.loadPage = loadPage;

        vm.editUserAgreement = editUserAgreement;
        vm.saveUserAgreement = saveUserAgreement;
        vm.continueToDashboard = continueToDashboard;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
        vm.emailUserAgreement = emailUserAgreement;
        vm.unsignedCount = 0;
		vm.userAgreementFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.userAgreementSort = ['name'];
        vm.activeOnly = false;
        vm.logoIconUrl = Logo.logoIconUrl;
        vm.unsignedOnly = false;
        vm.checkStatus = checkStatus;
        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });

        loadUserAgreements();

        function loadUserAgreements() {
	        	if (!vm.isHistory) {
		        	UserAgreement.query({sort: vm.userAgreementSort},
		                function(userAgreements, headers) {
		        	            	processUserAgreements(userAgreements)
		        	            	vm.totalItems = headers('X-Total-Count');

		        	            	if (!vm.currentUserAgreement && vm.userAgreements.length > 0)
		        	            		editUserAgreement(vm.userAgreements[0]);
		        	            }

		                );
	        	} else {
	        	 	UserAgreement.history({sort: vm.userAgreementSort},
			                function(userAgreements, headers) {
			        	            	processUserAgreements(userAgreements)
			        	            	vm.totalItems = headers('X-Total-Count');

			        	            	if (!vm.currentUserAgreement && vm.userAgreements.length > 0)
			        	            		editUserAgreement(vm.userAgreements[0]);
			        	            }

			                );
	        	}

        }

        function loadPage(page) {
            vm.page = page;
            loadUserAgreements();
        }

        function createUserAgreement() {
	        	vm.currentUserAgreement = {
	        		activated: true
	        	}
        }
        function checkStatus() {

        	  if (!vm.currentUserAgreement.status) {
        	  GlobalModal.confirm('Declining will log you out of the system. Are you sure you want to continue with this course of action?', function() {
        		  saveUserAgreement(false);
        		  return;
   		   },function() {
   			if (vm.userAgreementBeingEdited.signedDate==null && vm.userAgreementBeingEdited.unsignedDate==null) {
   				vm.currentUserAgreement.status = null;
   			} else {
   				vm.currentUserAgreement.status = true;
   			}
   		   });
        	  } else {
        		  saveUserAgreement(true)
        	  }

        }
        function  emailUserAgreement() {

    			UserAgreement.sendEmail(vm.currentUserAgreement,
                function(result) {
    						GlobalModal.displaySuccess('User agreement is sent to your email address on file.');
        	            }, function(error) {
						GlobalModal.displayError('Error sending email');

					});

        }

        function continueToDashboard() {
	        	UserAgreement.checkForUnsignedAgreements(function(data) {
					$state.go('home');
				}, function(data) {
					GlobalModal.displayError('User agreement need to be signed');
				});
        }

        function editUserAgreement(userAgreement) {

	        	vm.currentUserAgreement = {
	        		agreementName: userAgreement.agreementName,
	        		revisionName: userAgreement.revisionName,
	        		id: userAgreement.id,
	        		revisionId: userAgreement.revisionId,
	        		agreementText: userAgreement.agreementText,
	        		signed: userAgreement.signed,
	        		signedDate: userAgreement.signedDate,
				unsignedDate: userAgreement.unsignedDate,
				revisionStartDate: userAgreement.revisionStartDate,
				revisionEndDate: userAgreement.revisionEndDate,
				originalStatus: userAgreement.signed,
				agreementId: userAgreement.agreementId
	        	}
	        	vm.currentUserAgreement.status = userAgreement.signed;
	        	if (userAgreement.signedDate==null && userAgreement.unsignedDate==null) {
	        	 	vm.currentUserAgreement.status = null;
	        	}

	        	vm.userAgreementBeingEdited = userAgreement;
        }


        function saveUserAgreement(value) {

	        	if (vm.currentUserAgreement) {
	        		vm.userAgreementForm.$setSubmitted();

	        		if(!vm.userAgreementForm.$valid) return;

	        		var userAgreement = {

		    	        		id: vm.currentUserAgreement.id,
		    	        		signed: value,
		    	        		revisionId: vm.currentUserAgreement.revisionId,
		    	        		agreementName: vm.currentUserAgreement.agreementName,
		    	        		revisionName: vm.currentUserAgreement.revisionName,
		    	        		agreementText: vm.currentUserAgreement.agreementText,

		        		}

	        		// update user agreement
	        		if (userAgreement.id > 0) {
	        			UserAgreement.update({id: userAgreement.id}, userAgreement, function(data) {
	        				if (!value) {
	        					logout();
	        				} else {
	        					GlobalModal.displaySuccess('Your changes have been saved.');
		        				loadUserAgreements();
		        				editUserAgreement(data);
	        				}
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userAgreementExists')
	        					GlobalModal.displayError('User agreement Type already exists in system');
	        				else
	        					GlobalModal.displayError('Unable to save user agreement. Please try again.');
	        			});
	        		}

	        		// create agreement type
	        		else {
	        			UserAgreement.save(userAgreement, function(data) {
	        				if (!value) {
	        					logout();
	        				} else {
	        					GlobalModal.displaySuccess('Your changes have been saved.');
		        				loadUserAgreements();
		        				editUserAgreement(data);
	        				}
	        			}, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.userAgreementExists')
                            GlobalModal.displayError('User Agreement Type already exists in system');
                        else
                            GlobalModal.displayError('Unable to save user agreement. Please try again.');
	        			});
	        		}
	        	}

        }

        function processUserAgreements(data) {
			vm.userAgreements = [];
			vm.unsignedCount = 0;
			for (var i=0; i<data.length; i++) {
				var userAgreement = data[i];
				vm.userAgreements.push(userAgreement);
				if (!userAgreement.signed) {
					vm.unsignedCount = vm.unsignedCount+1;
				}

			}

    		}


        function sortBySortingName(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['name,' + sortDir];
            vm.loadUserAgreements();
        }

        function logout() {
            Auth.logout();
        }
    }

})();
