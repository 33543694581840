(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AttestationController', AttestationController);

    AttestationController.$inject = ['ContentType', 'Attestation', 'GlobalModal'];

    function AttestationController (ContentType, Attestation, GlobalModal) {
        var vm = this;
        
        vm.loadAttestations = loadAttestations;
        vm.loadPage = loadPage;
        vm.createAttestation = createAttestation;
        vm.editAttestation = editAttestation;
        vm.saveAttestation = saveAttestation;
        vm.startAttestation = startAttestation;
        vm.openEndDateCalendar = openEndDateCalendar;
        vm.openStartDateCalendar = openStartDateCalendar;
        vm.reverse = false;
        vm.originalActivatedStatus = false;

        
		vm.contentTypeFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.contentTypeSort = ['contentType'];
        vm.activeOnly = false;
        
        loadAttestations();
       
        function loadAttestations() {
        	Attestation.getAll({}, 
                function(attestations, headers) {
        	            	processAttestations(attestations)
        	            	vm.totalItems = headers('X-Total-Count');
        	            	
        	            	if (!vm.currentAttestation && vm.attestations.length > 0)
        	            		editAttestation(vm.attestations[0]);
        	            }
                );
        }
        
        function startAttestation() {
         	
         	
         	GlobalModal.confirm('You are about to start the attestation process. This will send emails to all internal users who have an association with external entity. Please confirm ? ', function() {
				  
         		Attestation.startExtUserAttest({attestationId: vm.currentAttestation.id}, vm.currentAttestation, 
                        function(result) {
             				GlobalModal.displaySuccess('Attestation process is started.');
             	});
	    		}, function(error) {
	    			
	    		});
        }
        
        function loadPage(page) {
            vm.page = page;
            loadAttestations();
        }
        
        function createAttestation() {
	       
	        	vm.currentAttestation =  {
	        			id: 0,
	        			startDate: '',
	        			endDate: '',
	        			description: '',
	        			attestationPeriod: '',
	        			activated: false,
	        			
	        		}
        }
        
        function editAttestation(attestation) {
        		if (vm.attestationForm)
        			vm.attestationForm.$setPristine();
	        	
	     
	         	vm.currentAttestation =  {
	        			id: attestation.id,
	        			startDate: attestation.startDate,
	        			endDate: attestation.endDate,
	        			description: attestation.description,
	        			attestationPeriod: attestation.attestationPeriod,
	        			activated: attestation.activated,
	        			attestProcessStartDate: attestation.attestProcessStartDate,
	        			attestProcessStarted: attestation.attestProcessStarted
	        			
	        		}
	         	vm.originalActivatedStatus = attestation.activated;
	         	
	         	if (vm.currentAttestation.startDate) {
        			vm.currentAttestation.startDate = moment(vm.currentAttestation.startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();
	         	}
				
				if (vm.currentAttestation.endDate) {
					vm.currentAttestation.endDate = moment(vm.currentAttestation.endDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();
        			}
				 	
	        	
	        	vm.attestationBeingEdited = attestation;
        }
        
        function saveAttestation() {
	        	if (vm.currentAttestation) {
	        		vm.attestationForm.$setSubmitted();
	            	
	        		if(!vm.attestationForm.$valid) return;
	        		
	        		
		         	var attestation =  {
		        			id: vm.currentAttestation.id,
		        			startDate: vm.currentAttestation.startDate,
		        			endDate: vm.currentAttestation.endDate,
		        			description: vm.currentAttestation.description,
		        			attestationPeriod: vm.currentAttestation.attestationPeriod,
		        			activated: vm.currentAttestation.activated,
		        			
		        		}
	        		
	        		// update attestation
	        		if (attestation.id > 0) {
	        			
	        			//check if is active and alert if you want to restart again
	        			if (!vm.currentAttestation.activated && vm.currentAttestation.attestProcessStarted) {
	        				GlobalModal.confirm('The attestation process has already started. Making it inactive will stop the daily check cron job. Please confirm? ', function() {	        					  
	        					Attestation.updateExtUserAttest({attestationId: attestation.id}, attestation, function(data) {
			        				GlobalModal.displaySuccess('Your changes have been saved.');
			        				loadAttestations();
			        				editAttestation(data);
			        			}, function(error) {
			        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.datesoverlap')
			                            GlobalModal.displayError('Start date is after end date');
			                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.duplicateattestationperiod')
			                            GlobalModal.displayError('Attestation period already exists.');
			                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.activeexists')
				                            GlobalModal.displayError('An active attestation already exists. Only one attestation can be active.');
			                        else 
			                        		GlobalModal.displayError('Unable to save attestation. Please try again.');
			        			});
	        	             	
	        		    		});
	        			} else {
	        				Attestation.updateExtUserAttest({attestationId: attestation.id}, attestation, function(data) {
		        				GlobalModal.displaySuccess('Your changes have been saved.');
		        				loadAttestations();
		        				editAttestation(data);
		        			}, function(error) {
		        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.datesoverlap')
		                            GlobalModal.displayError('Start date is after end date');
		                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.duplicateattestationperiod')
		                            GlobalModal.displayError('Attestation period already exists.');
		                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.activeexists')
			                            GlobalModal.displayError('An active attestation already exists. Only one attestation can be active.');
		                        else 
		                        		GlobalModal.displayError('Unable to save attestation. Please try again.');
		        			});
	        			}
		         	
		         	
	        		}
	        		
	        		// create attestation
	        		else {
	        			Attestation.createExtUserAttest(attestation, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadAttestations();
	        				editAttestation(data);
	        			}, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.datesoverlap')
                            GlobalModal.displayError('Start date is after end date');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.duplicateattestationperiod')
                            GlobalModal.displayError('Attestation period already exists.');
                        else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.activeexists')
                            GlobalModal.displayError('Unable to create an active attestation while another active attestation exists.')
                        else
                    		GlobalModal.displayError('Unable to save attestation. Please try again.');
	        			});
	        		}
	        	}
        }
        
        function processAttestations(data) {
			vm.attestations = [];
			
			for (var i=0; i<data.length; i++) {
				var attestation = data[i];
				if(attestation.activated){attestation.status = 'Active';}
				else {attestation.status = 'Inactive';}
				vm.attestations.push(attestation);
			}	
    		}
        
      	// invoked when calendar button is pressed
        function openStartDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.startDateOpen = true;
        };
        function openEndDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.endDateOpen = true;
        };
        
      
    }
   
})();
