(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password-resetlink-confirmation', {
            parent: 'password',
            url: '/confirmation',
            data: {
                authorities: [],
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/password/confirmation/confirmation.html',
                    controller: 'ConfirmationController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
