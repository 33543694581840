(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('EntityTypeAdminController', EntityTypeAdminController);

    EntityTypeAdminController.$inject = ['ExternalEntityType', 'GlobalModal'];

    function EntityTypeAdminController (ExternalEntityType, GlobalModal) {
        var vm = this;
        
        vm.loadEntityTypes = loadEntityTypes;
        vm.loadPage = loadPage;
        vm.createEntityType = createEntityType;
        vm.editEntityType = editEntityType;
        vm.saveEntityType = saveEntityType;
        vm.reverse = false;
        vm.sortBySortingName = sortBySortingName;
        vm.addRequiredContractType = addRequiredContractType;
        vm.removeRequiredContractType = removeRequiredContractType;
        
		vm.entityTypeFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.entityTypeSort = ['name'];
        vm.activeOnly = false;
        
        loadEntityTypes();
       
        function loadEntityTypes() {
                          processEntityTypes([])
                          vm.totalItems = 0;
                          
            ExternalEntityType.query({name: vm.entityTypeFilter, activeOnly: vm.activeOnly, page: vm.page-1, size: vm.itemsPerPage, sort: vm.entityTypeSort}, 
                function(entityTypes, headers) {
        	            	processEntityTypes(entityTypes)
        	            	vm.totalItems = headers('X-Total-Count');
        	            	
        	            	if (!vm.currentEntityType && vm.entityTypes.length > 0)
        	            		editEntityType(vm.entityTypes[0]);
        	            }
                );
        }
        
        function loadPage(page) {
            vm.page = page;
            loadEntityTypes();
        }
        
        function createEntityType() {
	        	vm.currentEntityType = {
	        		active: true,
	        		requiredContractTypes: []
	        	}
        }
        
        function editEntityType(entityType) {
        		if (vm.entityTypeForm)
        			vm.entityTypeForm.$setPristine();
	        	
	        	vm.currentEntityType = {
	        	    name: entityType.name,
	        		id: entityType.id,
	        		active: entityType.active,
	        		requiredContractTypes: entityType.requiredContractTypes ? entityType.requiredContractTypes.map(function(ctname) {
	        		        return { name: ctname }
	        		    }) : [],
	        	}
	        	
	        	vm.entityTypeBeingEdited = entityType;
        }
        
        function saveEntityType() {
	        	if (vm.currentEntityType) {
	        		vm.entityTypeForm.$setSubmitted();
	            	
	        		if(!vm.entityTypeForm.$valid) return;
	        		
	        		var entityType = {
                    name: vm.currentEntityType.name,
                    id: vm.currentEntityType.id,
                    active: vm.currentEntityType.active,
                    requiredContractTypes: vm.currentEntityType.requiredContractTypes.map(function(ct) { return ct.name; }),
	        		}
	        		
	        		// update entity type
	        		if (entityType.id > 0) {
	        		    ExternalEntityType.update({id: entityType.id}, entityType, function(data) {
	        				GlobalModal.displaySuccess('Your changes have been saved.');
	        				loadEntityTypes();
	        				editEntityType(data);
	        			}, function(error) {
	        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.externalEntityTypeExists')
	        					GlobalModal.displayError('External Entity Type already exists in system');
	        				else
	        					GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        		
	        		// create entity type
	        		else {
	        		    ExternalEntityType.save(entityType, function(data) {
	        		        GlobalModal.displaySuccess('Your changes have been saved.');
	        		        loadEntityTypes();
	        		        editEntityType(data);
	        			}, function(error) {
                        if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.externalEntityTypeExists')
                            GlobalModal.displayError('External Entity Type already exists in system');
                        else
                            GlobalModal.displayError('Unable to save user. Please try again.');
	        			});
	        		}
	        	}
        }
        
        function processEntityTypes(data) {
			vm.entityTypes = [];
			
			for (var i=0; i<data.length; i++) {
				var entityType = data[i];
				if(entityType.activated){entityType.status = 'Active';}
				else {entityType.status = 'Inactive';}
				vm.entityTypes.push(entityType);
			}	
    		}
        
        function sortBySortingName(){
            var sortDir = vm.reverse ? 'desc' : 'asc';
            vm.userSort = ['name,' + sortDir];
            loadEntityTypes();
        }

        function addRequiredContractType(){
            if(vm.newRequiredContractType == null || vm.newRequiredContractType == ""){return;}
            
            if (vm.currentEntityType.requiredContractTypes.length>0) {
                for (var i=0; i<vm.currentEntityType.requiredContractTypes.length; i++) {
                    if (vm.currentEntityType.requiredContractTypes[i].name === vm.newRequiredContractType) {
                        GlobalModal.displayError("Cannot have duplicate contract types");
                        return false;
                    }
                }
            }
             
            var orgReqContentType = {};
            orgReqContentType.name = vm.newRequiredContractType; 
            orgReqContentType.active = true;
            vm.currentEntityType.requiredContractTypes.unshift(orgReqContentType);
            vm.newRequiredContractType = "";    
       }

        function removeRequiredContractType(ct){
            vm.currentEntityType.requiredContractTypes.splice(vm.currentEntityType.requiredContractTypes.indexOf(ct), 1);
        }
   }
   
})();
