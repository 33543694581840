(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('external-user-associations', {
            parent: 'orgadmin',
            url: '/external-user-associations/{entityIdList}',
            data: {
				authorities: [
					'ROLE_ORG_ADMIN'
				]
			},
			params: { entitySelectionMap: null,
				nameFilter: '',
				entityTypeFilter: '',
	    		attributeFilter: '',
	    		attributeValueFilter: '',
	    		isFilterApplied: false
			
			},
            views: {
                'content@': {
                    templateUrl: 'app/orgadmin/external-user-associations/ext-user-associations.html',
                    controller: 'ExtUserAssociationController',
                    controllerAs: 'vm'
                },
                'internalUsers@external-user-associations': {
                    templateUrl: 'app/orgadmin/external-user-associations/int-user-select.html',
                },
                'externalUsers@external-user-associations': {
                    templateUrl: 'app/orgadmin/external-user-associations/ext-user-select.html',
                }
            },
        });
    }
})();
