(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .controller('SupplierTagAdminController', SupplierTagAdminController);

    SupplierTagAdminController.$inject = ['$scope', '$state', '$timeout', 'Tag', 'GlobalModal'];

    function SupplierTagAdminController ($scope, $state, $timeout, Tag, GlobalModal) {
        var vm = this;
        
        vm.newTag = false;
        vm.loadPage = loadPage;
        vm.queryTags = queryTags;
        vm.sortTagArray = sortTagArray;
        vm.updateTagGroups = updateTagGroups;
        vm.tagGroup = tagGroup;
        vm.searchDatasets = searchDatasets;
        vm.openCloseTag = openCloseTag;
        vm.activateTag = activateTag;
        vm.tagHightlightClass = tagHightlightClass;
        vm.renameTag = renameTag;
        vm.confirmRenameTag = confirmRenameTag;
        vm.deleteTag = deleteTag;
        vm.createTag = createTag;
        vm.confirmCreateTag = confirmCreateTag;
        vm.tagEditorKeyPress = tagEditorKeyPress;
        vm.tagEditorKeyUp = tagEditorKeyUp;
        
        vm.itemsPerPage = 50;
        vm.page = 1;
        
        // initial query for top level
        vm.tagFilter = '';
        queryTags();
        
        function loadPage(page) {
            vm.page = page;
            vm.queryTags();
        }
        
        function queryTags() {
            Tag.queryAdmin({page: vm.page-1, size: vm.itemsPerPage, parentTagId: 0, name: vm.tagFilter}, function(result) {
                vm.totalItems = result.totalTags;
                vm.activeTagId = null;
                
                // build tag map
                vm.tagMap = {}
                var tags = result.tags;
                for (var i=0; i<tags.length; i++) { 
                    var tag = tags[i];
                    vm.tagMap[tag.id] = tag;
                }
                
                // generate tag groups
                vm.topTags = tags;
                updateTagGroups();
            }, function() {
                GlobalModal.displayError("An error occurred loading tag details.")
            });
        }
        
        function sortTagArray(tags) {
            tags.sort(function(a, b){
                var keyA = a.name,
                    keyB = b.name;
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            });
        }
        
        function updateTagGroups() {
            var tags = vm.topTags;
            
            var groupKeys = [];
            var groups = {};
            for (var i=0; i<tags.length; i++) {
                var tag = tags[i];
                
                // determine group
                var group = tagGroup(tag);
                if (!groups[group])
                    groupKeys.push(group);
                
                // assign to group list
                var groupList = groups[group];
                if (!groupList) {
                    groupList = [];
                    groups[group] = groupList;
                }
                groupList.push(tag);
            }
            
            vm.tagGroupKeys = groupKeys.sort();
            vm.tagGroups = groups;
        }
        
        function tagGroup(tag){
            // determine group
            var group = tag.name.substring(0,1).toUpperCase();
            if (!group.match("\\w") || group === '_')
                group = '#';
            return group;
        }
        
        function searchDatasets(tag) {
            $state.go('datasets', {
                query: '#' + tag.name
            })
        }
        
        function openCloseTag(tag) {
            vm.activeTagId = null;
            
            // retrieve children if not done already
            if (!tag.children) {
                Tag.queryAdmin({parentTagId: tag.id}, function(result) {
                    // update tag map
                    var tags = result.tags;
                    for (var i=0; i<tags.length; i++) { 
                        var t = tags[i];
                        t.parent = tag;
                        vm.tagMap[t.id] = t;
                    }
                    
                    tag.children = tags;
                }, function() {
                    GlobalModal.displayError("An error occurred loading tag details.")
                });
            }
            
            // toggle open state
            tag.open = !tag.open;
        }
        
        function activateTag(tag, event) {
            if (event)
                event.stopPropagation();
            
            if (tag) {
                vm.activeTagId = tag.id;
            }
            else {
                vm.activeTagId = null;
            }
            
            vm.newTag = false;
        }
        
        function tagHightlightClass(tag) {
            if (vm.activeTagId) {
                if (vm.activeTagId == tag.id)
                    return 'active-tag';
                else
                    return 'inactive-tag';
            }
            else {
                tag.editing = false;
                return null;
            }
        }
        
        function renameTag(tag, event) {
            if (event)
                event.stopPropagation();
            
            vm.tagEditorValue = tag.name;
            vm.newTag = false;
            vm.editTag = tag;
            tag.editing = true;
        }
        
        function confirmRenameTag(tag, event) {
            if (event)
                event.stopPropagation();
            
            Tag.renameTag({id: tag.id}, {name: vm.tagEditorValue}, function(result) {
                tag.name = result.name;
                tag.editing = false;
            }, function (response) {
                if (response.status == 409) // conflict, duplicate
                    GlobalModal.displayError('Tag already exists.');
                else
                    GlobalModal.displayError('Error updating tag name.');
            });
        }
        
        function deleteTag(tag, event) {
            if (event)
                event.stopPropagation();
            
            GlobalModal.confirm('Are you sure you want to delete this tag?', function() {
                    Tag.delete({id: tag.id}, function() {
                        // remove from parent tag
                        if (tag.parent) {
                            var idx = tag.parent.children.indexOf(tag);
                            tag.parent.children.splice(idx, 1);
                            tag.parent.numChildren = tag.parent.children.length;
                        }
                        
                        // remove from top level tags
                        else {
                            var group = vm.tagGroup(tag);
                            var tagList = vm.tagGroups[group];
                            var idx = tagList.indexOf(tag);
                            tagList.splice(idx, 1);
                            
                            var idx = vm.topTags.indexOf(tag);
                            vm.topTags.splice(idx, 1);
                        }
                        
                        vm.updateTagGroups();
                        
                        vm.activeTagId = null;
                    }, function() {
                        GlobalModal.displayError('Error deleting tag.');
                    });
                });
        }

        function createTag(event) {
            if (event)
                event.stopPropagation();
            
            vm.tagEditorValue = '';
            vm.newTag = true;
            
            $timeout(function() {        
                $('#newTagEditor').focus();
            });
        }
        
        function confirmCreateTag(tag, event) {
            if (event)
                event.stopPropagation();
            
            vm.newTag = false;
            
            if (vm.tagEditorValue.trim() === '') return;
            
            Tag.create({name: vm.tagEditorValue}, function(result) {
                var tag = result;
                vm.tagMap[tag.id] = tag;
                vm.topTags.push(tag);

                vm.sortTagArray(vm.topTags);
                vm.updateTagGroups();
            }, function (response) {
                if (response.status == 409) // conflict, duplicate
                    GlobalModal.displayError('Tag already exists.');
                else
                    GlobalModal.displayError('Error creating tag.');
            });
        }
        
        function tagEditorKeyPress(event) {
            if (event.keyCode === 13) {
                if (vm.newTag)
                    confirmCreateTag(null, event);
                else
                    confirmRenameTag(vm.editTag, event);
            }
        }
        
        function tagEditorKeyUp(event) {
            if (event.keyCode === 27) {
                vm.newTag = false;
                if (vm.editTag)
                    vm.editTag.editing = false;
            }
        }
    }
})();
