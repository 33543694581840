(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('uploadConfigureChosenContentType', UploadConfigureChosenContentTypeDirective);

    UploadConfigureChosenContentTypeDirective.$inject = ['$timeout'];

    function UploadConfigureChosenContentTypeDirective ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);
    	        
    	        $timeout(function() {
		    	    select.chosen({
		    	        disable_search_threshold: 10,
		    	        width: attr.width || "200px"
		    	    });
    	        });
                
            // notify control if updated programatically
            scope.$on('chosenSelectUpdated', function() {
                $timeout(function() {
                    select.trigger('chosen:updated');
                })
            });
    	    }
    	}
    }
})();
