(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('PasswordExpiringController', PasswordExpiringController);

    PasswordExpiringController.$inject = ['$state', 'Principal'];

    function PasswordExpiringController ($state, Principal) {
        var vm = this;

        vm.resetPassword = resetPassword;
        vm.dashboard = dashboard;
        
        Principal.identity().then(function(identity) {
        	vm.daysToPasswordExpire = identity.daysToPasswordExpire;
        });
        
        function resetPassword() {
        	$state.go('userAccount');
        }        
        
        function dashboard() {
        	$state.go('home');
        }        
    }
})();
