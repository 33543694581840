(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SysadminReportsController', LoginController);

    LoginController.$inject = ['$scope', 'AuditsService', 'ParseLinks','$window'];

    function LoginController ($scope, AuditsService, ParseLinks,$window) {
        var vm = this;

        vm.audits = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.reload = reload;
        vm.query = query;
        vm.pageChanged = pageChanged;
        vm.page = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;
        
        $scope.sort = 'auditEventDate';
        $scope.reverse = true;

        vm.query();

        function query () {
        	// build sort logic based on user's column heading click
        	var sort;
        	if (Array.isArray($scope.sort)) {
        		sort = [];
        		for (var i=0; i<$scope.sort.length; i++) {
            		sort.push($scope.sort[i] + "," + ($scope.reverse ? 'desc' : 'asc'));
        		}
        	}
        	else {
        		sort = $scope.sort + "," + ($scope.reverse ? 'desc' : 'asc');
        	}
        	
            AuditsService.query({page: vm.page-1, size: vm.itemsPerPage, sort: sort }, function(result, headers){
            	vm.audits = result;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
        
        function reload() {
        	loadPage(vm.page);
        }
        
        function pageChanged(){
        	$window.scrollTo(0,0);
        }
    }
})();
