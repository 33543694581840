(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .filter('to_trusted', ToTrusted);

    ToTrusted.$inject = ['$sce'];

    function ToTrusted ($sce) {
        return function(text) {
            return $sce.trustAsHtml(text);
        }
    }
})();
