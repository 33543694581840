(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('ServiceEndpoint', ServiceEndpoint);

    ServiceEndpoint.$inject = ['$resource', 'DateUtils'];

    function ServiceEndpoint ($resource, DateUtils) {
        var resourceUrl =  'api/serviceEndpoint/:userId';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET' },
            'save': { method: 'PUT' },
            'queryQueue': {
                url: 'api/serviceEndpointQueue/query',
                method: 'GET', 
                isArray: true,
            },
            'deleteQueueEntry': {
                url: resourceUrl + '/entry/:entryId',
                method: 'DELETE', 
            }
            
        });
    }
})();
