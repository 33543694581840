(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .filter('bytes', bytes);

    function bytes() {
        	return bytesFilter;
        	
        	function bytesFilter(bytes, precision) {
        		if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes <= 0) return '-';
        		if (typeof precision === 'undefined') precision = 1;
        		var factor = 1000;
        		var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
        			number = Math.floor(Math.log(bytes) / Math.log(factor));
        		return (bytes / Math.pow(factor, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
        	}
    }
})();
