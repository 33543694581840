(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AgreementListController', AgreementListController);

    AgreementListController.$inject = ['$state', 'Agreement'];

    function AgreementListController ($state, Agreement) {
        var vm = this;
        
        vm.entityFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['name'];
        
        vm.addAgreement = addAgreement;
        vm.editAgreement = editAgreement;
        vm.query = query;
        vm.sortByName = sortByName;
        vm.sortByType = sortByType;
        vm.sortByRevisionCount = sortByRevisionCount;
        vm.loadPage = loadPage;
        
        query();

        function addAgreement() {
	    		$state.go('agreement', {	    			
	    			orgId: '',
	    			agreementId: '',
	    		});
	    }
	
        function editAgreement(agreement) {
	    		$state.go('agreement', {
	    			agreementId: agreement.id,
	    			orgId: agreement.org.id,
	    		});
        }

        function query() {
        		Agreement.query({name: vm.agreementFilter, page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
		    		function(result, headers) {
		        		vm.agreements = result;
		        		vm.totalItems = headers('X-Total-Count');
	        		}
	    		);
	        
	        return false;
        }
        
        function sortByName(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['name,' + sortDir];
	    		query();
	    }
        
        function sortByType(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['agreementType,' + sortDir];
	    		query();
	    }
        
        function sortByRevisionCount(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['revisionCount,' + sortDir];
	    		query();
        }
        
        function loadPage(page) {
            vm.page = page;
            query();
        }
    }   
})();
