(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('DatasetFileMicroservice', DatasetFileMicroservice);

    DatasetFileMicroservice.$inject = ['$resource', 'DateUtils'];

    function DatasetFileMicroservice ($resource, DateUtils) {
        var resourceUrl =  'zuul/squirrelboxfileservice/api/datasets/:id';

        return $resource(resourceUrl, {}, {
            'getConfirmDatasetViewModel': {
                url: resourceUrl + '/confirm',
                method: 'GET'
            },
            'updateDatasetType': {
                url: resourceUrl + '/datasetType',
                method: 'PUT'
            },
            'updateDatasetTextFormat': {
                url: resourceUrl + '/textFormat',
                method: 'PUT'
            },
            'confirm': {
                url: resourceUrl + '/confirm',
                method: 'PUT',
                interceptor: {
                    response: function (response) {
                        return response;
                    }
                }
            },
            'requestDownload': {
                url: resourceUrl + '/requestDownload',
                method: 'PUT'
            },
            'requestPreviewDownload': {
                url: resourceUrl + '/requestPreviewDownload',
                method: 'PUT'
            },
            'getDownloadInfo': {
                url: resourceUrl + '/download/:downloadId',
                method: 'GET'
            },
            'getDownloadToken': {
                url: resourceUrl + '/download/:downloadId/token',
                method: 'GET'
            },
        });
    }
})();
