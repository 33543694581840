(function () {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider.state('sysadmin', {
            abstract: true,
            parent: 'app',
            data: {
                authorities: [
                	'ROLE_ADMIN'
                ]
            }
        });
    }
})();
