(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('entity-report', {
            parent: 'app',
            url: '/entity-report',
            data: {
            	authorities: ["ROLE_INTERNAL", "ROLE_ORG_ADMIN"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/reports/entity/entity-report.html',
                    controller: 'EntityReportController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();