(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('notificationRecompile', NotificationRecompileDirective);
    
    NotificationRecompileDirective.$inject = ['$timeout', '$compile'];

    function NotificationRecompileDirective ($timeout, $compile) {
    	return {
    	    link: function(scope, element, attr) {
    	        var el = $(element);
    	        
    	        $timeout(function () {
					$compile(el.contents())(scope);
    	        });
    	    }
    	}
    }
})();
