(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('AgreementType', AgreementType);

	AgreementType.$inject = ['$resource'];

	function AgreementType ($resource) {
		var service = $resource('api/agreementTypes/:id', {}, {
			'query': {method: 'GET', isArray: true},
            'save': { method:'POST' },
            'update': { method:'PUT' },
		});

		return service;
	}
})();
