(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('tag-admin', {
            parent: 'supplier',
            url: '/supplier/tag-admin',
            views: {
                'content@': {
                    templateUrl: 'app/supplier/tag-admin/tag-admin.html',
                    controller: 'SupplierTagAdminController',
                    controllerAs: 'vm'
                },
            },
        });
    }
})();
