(function() {
	'use strict';

	angular.module('squirrelboxApp').factory('SignUp', SignUp);

	SignUp.$inject = [ '$rootScope', '$state', '$sessionStorage', '$q',
			'$http', '$resource' ];

	function SignUp($rootScope, $state, $sessionStorage, $q, $http, $resource) {
		var service = {
			checkInvitation : checkInvitation,
			acceptInvitation : acceptInvitation
		};

		return service;

		function checkInvitation(activationKey) {
			return $http.get('api/invitations/' + activationKey).then(
					function(response) {
						return response;
					});
		}

		function acceptInvitation(activationKey, acceptance) {
			return $http.put('api/invitations/' + activationKey, acceptance).then(
					function(response) {
						return response;
					});
		}
	}
})();
