(function () {
	'use strict';

	angular
	.module('squirrelboxApp')
	.factory('UserAgreement', UserAgreement);

	UserAgreement.$inject = ['$resource','$rootScope'];

	function UserAgreement ($resource, $rootScope) {

		var verifiedAggrementsStatus = null;

		var resourceUrl =  'api/userAgreements';

		var resource = $resource(resourceUrl, {}, {
			'query': {method: 'GET', isArray: true},
			'history': {
				url: resourceUrl + '/history',
				method: 'GET',
				isArray: true, },
            'save': {
	            	url: resourceUrl,
	            	method:'POST' },
            'update': {
             	url: resourceUrl + '/:id',
             	method:'PUT' },
            'checkForUnsignedAgreements': {
	        		url: resourceUrl + '/check',
	        		method: 'GET'
            },
            'sendEmail': {
	        		url: resourceUrl + '/email',
	        		method: 'PUT'
            },

		});

		function checkForUnsignedAgreements(success) {
			if (verifiedAggrementsStatus) {
				success(verifiedAggrementsStatus);
			}
			else {
				resource.checkForUnsignedAgreements(function(agreementsStatus) {
					if (agreementsStatus.count === 0) {
						verifiedAggrementsStatus = agreementsStatus;
					}
					success(agreementsStatus);
				});
			}
		}

		function reset() {
			verifiedAggrementsStatus = null;
		}

		var service = {
			query: resource.query,
			history: resource.history,
			save: resource.save,
			update: resource.update,
			checkForUnsignedAgreements: checkForUnsignedAgreements,
			reset: reset,
			sendEmail: resource.sendEmail,
		}

		return service;

	}


})();
