(function () {
    'use strict';

    angular
        .module('squirrelboxApp')
        .factory('SysAdmin', SysAdmin);

    SysAdmin.$inject = ['$rootScope', '$state', '$sessionStorage', '$q','$http', '$resource'];

    function SysAdmin($rootScope, $state, $sessionStorage, $q, $http, $resource) {
        var service = {
                registerOrganization: registerOrganization,
                loadOrganizations:loadOrganizations,
                resendInvitation :resendInvitation,
                deleteOrg : deleteOrg,
                changeOrgStatus : changeOrgStatus,
                editNewOrg : editNewOrg,
                checkForExistingValue:checkForExistingValue,
    			getSamlIdps: getSamlIdps,
    			deleteOrganizationHelpFile: deleteOrganizationHelpFile,
    			deleteOrganizationLogoImageFile: deleteOrganizationLogoImageFile

                        };


            return service;


        function getSamlIdps(){
        	return $http.get('api/organizations/samlidp')
        		.then(function(response){
	        		return response;
        		});
        }

    function registerOrganization(organization){


    return $http.post('api/organizations', organization
    ).then(function (response) {

        return response;
    });
    }

    function checkForExistingValue(orgobject,checkType){

    	return $http.post('api/organizations/checkForExistingValue?checkType='+checkType,orgobject

    	).then(function(response){

    		return response;
    	}

    	);
    }


    function loadOrganizations(){
    	return $http.get('api/organizations'

    	).then(function(response){

    		return response;
    	}

    	);
    }

    function editNewOrg(org){
    	return $http.put('api/organizations',org

    	).then(function(response){

    		return response;
    	}

    	);
    }

    function resendInvitation(org){

    	return $http.put('api/organizations/resendInvitation',org

    	).then(function(response){

    		return response;
    	}

    	);
    }


    function changeOrgStatus(org){

    	return $http.put('api/organizations/changeOrgStatus',org

    	).then(function(response){

    		return response;
    	}

    	);
    }

 function deleteOrg(orgDetails){
    	return $http.post('api/organizations/deleteOrg', orgDetails

    	).then(function(response){

    		return response;
    	}

    	);
    }
    function SysAdmin ($resource) {
        var service = $resource('api/organizations', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },

        });

        return service;
    }


    function deleteOrganizationHelpFile(orgId, type){
	    	return $http.delete('api/organizations/'+orgId+'/helpfile/'+type)
	    		.then(function(response){
	        		return response;
	        	});
	}

    function deleteOrganizationLogoImageFile(orgId, type){
	    	return $http.delete('api/organizations/'+orgId+'/logofile/'+type)
	    		.then(function(response){
	        		return response;
	        	});
	}



   }

})();
