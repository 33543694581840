(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('external-entity', {
            parent: 'app',
            url: '/external-entity/{entityId}',
            data: {
                authorities: [
                	'ROLE_ORG_ADMIN',
                ]
            },
            views: {
                'content@': {
					templateUrl: 'app/orgadmin/external-entity/external-entity.html',
					controller: 'ExternalEntityController',
					controllerAs: 'vm'
                },
                'userselect@external-entity': {
					templateUrl: 'app/orgadmin/external-entity/external-entity-userselect.html',
                },
                'users@external-entity': {
					templateUrl: 'app/orgadmin/external-entity/external-entity-users.html',
                },
                'configuration@external-entity': {
					templateUrl: 'app/orgadmin/external-entity/external-entity-configuration.html',
                },
            },
        });
    }
})();
