(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('NotificationTimeline', NotificationTimeline);

    NotificationTimeline.$inject = ['$state', '$filter', 'DownloadService', 'PreviewService', 'GlobalModal', 'Principal'];

    function NotificationTimeline ($state, $filter, DownloadService, PreviewService, GlobalModal, Principal) {

        var vm = this;
        
        Principal.identity()
            .then(function(identity) {
                vm.identity = identity;
                vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
            });
        
        // insert date headers to separte notifications
        function insertDateHeaders(notifications) {
            var results = [];

            // loop through notifications looking for new entries
            var today = new Date().toDateString();
            var current = null;
            for (var i=0; i<notifications.length; i++) {
                var notification = notifications[i];
                var notifyDateString = notification.creationDate.toDateString();
                var isMay = notification.creationDate.getMonth() === 4;

                if (notifyDateString !== current) {
                    current = notifyDateString;
                    results.push({
                        header: true,
                        today: current === today,	
                        date: current === today ? 'Today' : $filter('date')(notification.creationDate, isMay ? 'MMM d, yyyy' : 'MMM. d, yyyy')
                    });
                }
                results.push(notification);
            }

            return results;
        }
        
     
        function checkForPurgedDatasets(notification, anchor) {
        	//make sure this notification is associated with a dataset
        	  if (notification.dataset && notification.dataset.purgeTimeStamp) {     		   
				return "<a class='wrapped-dataset-label' data-toggle='tooltip' data-placement='right' title='{{vm.generatePurgedDatasetTooltip(notification)}}' >" +  notification.dataset.label  + "</a>";					
          } else {
        	  		return anchor;
          }
          
        }
        
        function generatePurgedDatasetTooltip(notification) {
        		//make sure this notification is assoicated with a dataset
	        	if (notification.dataset && notification.dataset.purgeTimeStamp) {		        		
	         	return 'This Dataset was purged on ' + moment(notification.dataset.purgeTimeStamp).format( " YYYY-MM-DD");
	        	} else {
	        		return;
	        	}
       
        }

        // returns notification text
        function notificationText(notification) {
            var fromUserText = null;
            var entityName = '';
            if (notification.fromUser) {
                fromUserText = notification.fromUser.firstName;
                if (notification.fromUser.lastName && notification.fromUser.lastName.length > 0) fromUserText += ' ' + notification.fromUser.lastName;
                if (!fromUserText || fromUserText.length == 0) fromUserText = notification.fromUser.email;
                if (notification.fromUser.externalEntityName) {
                		fromUserText += ' from ' + notification.fromUser.externalEntityName;
                		entityName = ' ( from ' + notification.fromUser.externalEntityName + ' )' ;
                }
            }

            switch(notification.event) {
                case 'DATASET_LIFECYCLE_UPLOADED':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';
                    anchor += vm.isSupplier ? 'openConfigureDataset' : notification.dataset.datasetType == 'BLOB' ? 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';              
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return (vm.isSupplier ? anchor : notification.dataset.label) + ' has been uploaded and is ready to be configured for sharing';
    
                case 'DATASET_LIFECYCLE_PROCESSING':
                    return '<span class="wrapped-dataset-label">' + notification.dataset.label + '</span> is being processed for sharing';
    
                case 'DATASET_LIFECYCLE_READY':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';
                    anchor += vm.isSupplier ? 'openShareDataset' : notification.dataset.datasetType == 'BLOB' ? 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return anchor + ' is ready to be shared';
                    
                case 'DATASET_LIFECYCLE_SHARED':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';
                    anchor += vm.isSupplier ? 'openShareDataset' : notification.dataset.datasetType == 'BLOB' ? 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return 'You shared ' + (vm.isSupplier ? anchor : notification.dataset.label);
    
                case 'DATASET_LIFECYCLE_ERROR':
                    return '<span class="wrapped-dataset-label">' + notification.dataset.label + '</span> could not be processed for sharing';
    
                case 'DATASET_SHARED_WITH_USER':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';
                    anchor += notification.dataset.datasetType == 'BLOB' ? notification.dataset.previewOnly ? 'previewDataset' : 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return fromUserText + ' shared '+ anchor + ' with you';
    
                case 'DATASET_SHARE_WAS_UPDATED':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';
                    anchor += notification.dataset.datasetType == 'BLOB' ? 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return fromUserText + ' updated '+ anchor;
    
                case 'DOWNLOAD_LIFECYCLE_GENERATING':
                    return '<span class="wrapped-dataset-label">' + notification.download.name + '</span> is being processed for download';
    
                case 'DOWNLOAD_LIFECYCLE_READY':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.downloadFile('+notification.dataset.id+','+notification.download.id+')">' + notification.download.name + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return anchor + ' is ready to be downloaded';
    
                case 'DOWNLOAD_LIFECYCLE_ERROR':
                    var downloadName = "File";
                    if (notification && notification.download && notification.download.name)
                        downloadName = notification.download.name;
                    return '<span class="wrapped-dataset-label">' + downloadName + '</span> could not be downloaded';
    
                case 'INVITATION_RECEIVED':
                    var text = 'You have been invited to be ';
                    if (notification.data.role == 'ROLE_ORG_ADMIN') text += 'an administrator';
                    else if (notification.data.role == 'ROLE_SUPPLIER') text += 'a supplier';
                    else text += 'a consumer';
                    text += ' in this organization';
                    return text;
    
                case 'DATASET_DATA_WAS_UPDATED':
                    var anchor = '<a class="wrapped-dataset-label" ng-click="notificationAnchorMethods.';                   
                    anchor += vm.isSupplier ? 'openShareDataset' : notification.dataset.datasetType == 'BLOB' ? 'openDownloadDatasetBlob' : 'openDownloadDataset';
                    anchor += '('+notification.dataset.id+')">' + notification.dataset.label + '</a>';
                    anchor = checkForPurgedDatasets(notification, anchor);
                    return (vm.isSupplier ? anchor : notification.dataset.label) + entityName + ' was updated';
    
                case 'INVITATION_ACCEPTED':
                    return fromUserText + ' accepted your invitation to join DataRail';
    
                default:
                    return notification.event;
                }
        }

        // returns notification description
        function notificationDescription(notification) {
            switch(notification.event) {
            case 'DATASET_LIFECYCLE_PROCESSING':
            case 'DATASET_LIFECYCLE_READY':
            case 'DATASET_LIFECYCLE_SHARED':
            case 'DATASET_LIFECYCLE_ERROR':
            case 'DATASET_SHARED_WITH_USER':
            case 'DATASET_SHARE_WAS_UPDATED':
            case 'DOWNLOAD_LIFECYCLE_GENERATING':
            case 'DOWNLOAD_LIFECYCLE_READY':
            case 'DOWNLOAD_LIFECYCLE_ERROR':
            case 'DATASET_DATA_WAS_UPDATED':
                return notification.dataset.description;

            default:
                return null;
            }
        }

        // returns notification icon to use
        function notificationIcon(notification) {
            switch(notification.event) {
            case 'DATASET_LIFECYCLE_UPLOADED':
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-upload fa-stack-1x f9 darkblue"></i>';

            case 'DATASET_LIFECYCLE_PROCESSING':
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-refresh fa-stack-1x darkblue f8"></i>';

            case 'DATASET_LIFECYCLE_READY':
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-user-o fa-stack-1x darkblue bold f8"></i>';

            case 'DATASET_LIFECYCLE_SHARED':
                return '<i class="fa fa-circle fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-users fa-stack-1x white f8"></i>';

            case 'DATASET_LIFECYCLE_ERROR':
                return '<i class="fa fa-circle fa-stack-2x red"></i>'+
                '<i class="fa fa-exclamation fa-stack-1x white f8"></i>';

            case 'DATASET_SHARED_WITH_USER':
                return (notification.dataset.datasetType == 'BLOB' && notification.dataset.previewOnly ? 
                            '<i class="fa fa-circle fa-stack-2x darkblue"></i><i class="fa fa-eye fa-stack-1x white f8"></i>' : 
                            '<i class="fa fa-circle fa-stack-2x black"></i><i class="fa fa-download fa-stack-1x white f8"></i>');

            case 'DATASET_SHARE_WAS_UPDATED':
                return '<i class="fa fa-circle fa-stack-2x green"></i>'+
                '<i class="fa fa-download fa-stack-1x white f8"></i>';

            case 'DOWNLOAD_LIFECYCLE_GENERATING':
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x black"></i>'+
                '<i class="fa fa-refresh fa-stack-1x black f8"></i>';

            case 'DOWNLOAD_LIFECYCLE_READY':
                return '<i class="fa fa-circle fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-download fa-stack-1x white f8"></i>';

            case 'DOWNLOAD_LIFECYCLE_ERROR':
                return '<i class="fa fa-circle fa-stack-2x red"></i>'+
                '<i class="fa fa-exclamation fa-stack-1x white f8"></i>';

            case 'INVITATION_RECEIVED':
                return '<i class="fa fa-circle fa-stack-2x yellow"></i>'+
                '<i class="fa fa-user-plus fa-stack-1x white f8"></i>';

            case 'INVITATION_ACCEPTED':
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x yellow"></i>'+
                '<i class="fa fa-check fa-stack-1x yellow f8"></i>';

            case 'DATASET_DATA_WAS_UPDATED':
                return '<i class="fa fa-circle fa-stack-2x green"></i>'+
                '<i class="fa fa-pencil-square-o fa-stack-1x white f8"></i>';

            default:
                return '<i class="fa fa-circle fa-stack-2x white"></i>'+
                '<i class="fa fa-circle-thin fa-stack-2x darkblue"></i>'+
                '<i class="fa fa-file-o fa-stack-1x f9 darkblue"></i>';
            }
        }

        // called to open a dataset link
        function openConfigureDataset(datasetId) {
            $state.go('upload-configure', {
                datasetId: datasetId,
            });
        }
        function openShareDataset(datasetId) {
            $state.go('share', {
                datasetId: datasetId,
            });
        }
        function openDownloadDataset(datasetId) {
            $state.go('download', {
                datasetId: datasetId,
            });
        }
        function openDownloadDatasetBlob(datasetId) {
            DownloadService.downloadDatasetBlobFile(datasetId, function() {
                GlobalModal.displayError('There was a problem with your download. Please try again.');
            });
        }
        function downloadFile(datasetId, downloadId) {
            DownloadService.downloadDatasetFile(datasetId, downloadId, function() {
                GlobalModal.displayError('There was a problem with your download. Please try again.');
            });
        }
        function previewDataset(datasetId) {
            PreviewService.previewDatasetBlobFile(datasetId, function() {
                GlobalModal.displayError('There was a problem with your preview. Please try again.');
            });
        }
        
        return {
            insertDateHeaders: insertDateHeaders,
            notificationText: notificationText,
            notificationDescription: notificationDescription,
            generatePurgedDatasetTooltip: generatePurgedDatasetTooltip,        
            notificationIcon: notificationIcon,
            notificationAnchorMethods:  {
                openConfigureDataset: openConfigureDataset,
                openShareDataset: openShareDataset,
                openDownloadDataset: openDownloadDataset,
                openDownloadDatasetBlob: openDownloadDatasetBlob,
                downloadFile: downloadFile,
                previewDataset: previewDataset,
            }
        };
    }
})();
