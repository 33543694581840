(function() {
	'use strict';

	angular
		.module('squirrelboxApp')
		.controller('EntityReportController', EntityReportController)

	EntityReportController.$inject = [ '$scope', '$state', '$stateParams',
			'$window', 'GlobalModal', 'ExternalEntity', 'ParseLinks', 'Principal', 'ExternalEntityType' ];

	function EntityReportController($scope, $state, $stateParams, $window,
			GlobalModal, ExternalEntity, ParseLinks, Principal, ExternalEntityType) {
		var vm = this;
		
		
		// Figure out if user is an admin
        Principal.identity().then(function(identity) {
            // vm.identity = identity;
            vm.isAdmin = Principal.hasAuthority('ROLE_ORG_ADMIN', identity);
        });
		
		// Get list of available entity types
		vm.availableTypes = [];
		ExternalEntityType.getActive({ id: 0 }, 
				function(response, headers) {
			vm.availableTypes = response;
			$scope.$broadcast('externalEntityUpdate');

		}, function(error){
		});

		vm.filterVisible = false;
		vm.itemsPerPage = 20;
		vm.page = 1;
		vm.totalItems = null;
		vm.entities = null;

		vm.reload = reload;
		vm.toggleFilterOptions = toggleFilterOptions;
		vm.filter = filter;
		vm.clearFilter = clearFilter;
		
		vm.entitySearchBoxChanged = entitySearchBoxChanged;
        vm.userSearchBoxChanged = userSearchBoxChanged;
		vm.typeChanged = typeChanged;
		vm.statusChanged = statusChanged;
		
		vm.query = query;
		vm.entityReportSearch = {};
		
		vm.pageChanged = pageChanged;
		vm.loadPage = loadPage;

		$scope.sort = "sort";
		$scope.reverse = true;

		vm.filterParams = {
				entityNameFilter: null,
                userFilter: null,
				type: null,
				active: null
		}
		
		vm.openEntity = openEntity;
		
		vm.query();
				

		// Query for all external entities in the database. Runs on page load or when
		// the filter is changed.
		function query(){
        	// build sort logic based on user's column heading click
        	var sort;
        	if (Array.isArray($scope.sort)) {
        		sort = [];
        		for (var i=0; i<$scope.sort.length; i++) {
            		sort.push($scope.sort[i] + "," + ($scope.reverse ? 'desc' : 'asc'));
        		}
        	}
        	else {
        		sort = $scope.sort + "," + ($scope.reverse ? 'desc' : 'asc');
        	}
        	
        	var queryParams = {
        			entityNameFilter: vm.filterParams.entityNameFilter,
                    userFilter: vm.filterParams.userFilter,
        			typeId: vm.filterParams.type,
        			active: vm.filterParams.active,
    				page: vm.page - 1,
    				size: vm.itemsPerPage
    			}
        	
        	ExternalEntity.getReport(queryParams,
        			function(result, headers){
		        		vm.entities = result;
		        		vm.links = ParseLinks.parse(headers('link'));
		        		vm.totalItems = headers('X-Total-Count');
        			}
			);
		}
		
		// Reload the vm.
		function reload() {
			loadPage(vm.page);
		}
		
		// Show/hide the filter options
		function toggleFilterOptions() {
			vm.filterVisible = !vm.filterVisible;
		}

		// Filter the main results view. Validation is skipped when the filter is cleared.
		function filter(skipValidation) {
			query();
		}
		
		// Called whenever the text in the entity box is changed
		function entitySearchBoxChanged(id){
			vm.filterParams.entityNameFilter = id;
		}
        
        // Called whenever the text in the user search box is changed
        function userSearchBoxChanged(id){
            vm.filterParams.userFilter = id;
        }
		
		// Called whenever the dropdown for type is changed
		function typeChanged(id){
			vm.filterParams.type = id;
			filter();
		}
		
		// Called whenever the status dropdown is changed
		function statusChanged(id){
			vm.filterParams.active = (id === "Active") ? true : false;
			vm.filter();
		}
		
		// Called when the report page is changed
		function pageChanged(){
			$window.scrollTo(0,0);
		}
		
		// Loads a new page of the query results
        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
		
		// Called whenever the clear button is pressed. Resets the filter
		// parameters and filters again (showing everything).
		function clearFilter(){
			// Reset search parameters
			vm.filterParams.entityNameFilter = null;
            vm.filterParams.userFilter = null;
			vm.filterParams.type = null;
			vm.filterParams.active = null;
			
			vm.entityReportSearch.entityNameFilter = "";
            vm.entityReportSearch.userFilter = "";
			$("#entityTypeSelect").val("");
			$("#entityTypeSelect").trigger("chosen:updated");
			$("#statusSelect").val("");
			$("#statusSelect").trigger("chosen:updated");
			vm.filter(true);
		}
		
		// Opens the management page for the given entity
		function openEntity(id){
			var target = "external-entity/" + id;
			$state.go("external-entity", {
				entityId: id
			});
		}
	}
})();
