(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ConsumerDownloadController', ConsumerDownloadController);

    ConsumerDownloadController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$window', 'Dataset', 'DatasetFileMicroservice', 'DownloadService', 'GlobalModal'];

    function ConsumerDownloadController ($scope, $state, $stateParams, $timeout, $window, Dataset, DatasetFileMicroservice, DownloadService, GlobalModal) {
        var vm = this;
        
        var MILLIS_TO_REFRESHING_DISPLAY = 2000;
        var MILLIS_TO_REFRESHING_TIMEOUT = 10000;
        
        vm.saving = false;

        vm.newView = newView;
        vm.editView = editView;
        vm.saveView = saveView;
        vm.copyView = copyView;
        vm.onViewNameChange = onViewNameChange;
        vm.deleteView = deleteView;
        vm.changeAllColumnSelections = changeAllColumnSelections;
        vm.updateColumnSelection = updateColumnSelection;
        vm.changeSort = changeSort;
        vm.returnToDatasets = returnToDatasets;
        vm.initiateDownload = initiateDownload;
        vm.navigateToDownloadHistory = navigateToDownloadHistory;
        
        // be sure to stop polling for any downloads when user navigates away from screen
        vm.refreshIntervalPromises = [];
        $scope.$on('$destroy', function() {
        	vm.refreshIntervalPromises.map(function(promise) {
            	$timeout.cancel(promise);
        	})
        })

        newView();
        
        // creates a new empty view for editing
        function newView() {
        	resetForm();
        	
        	vm.viewId = -1;
        	vm.editViewLabel = false;
        	vm.dataset = null;
            vm.currentViewName = '';
        	vm.allSelected = true;
        	vm.noneSelected = false;
        	vm.sortColumn = 'columnNum';
        	vm.sortReverse = false;
        	
        	Dataset.getDownloadInfo({id: $stateParams.datasetId}, function(data) {
        	        vm.dataset = data.dataset;
                vm.excelOk = data.segment.sourceSize < 5000000 && data.segment.rowCount < 10000;
				
				// update download & description names as well as form dirty flags
				updateDownloadName();
				resetForm();
				
				vm.availableViews = data.availableViews;
			});
        }    

        // loads a view for editing
        function editView(view, force) {
        	resetForm();
        	
        	// if clicked on same label more than once, display edit input
        	// for changing label
        	if (!force && vm.viewId == view.id) {
        		vm.editViewLabel = true;
        		$timeout(function() {
            		$('#viewName').focus();
        		})
        		return;
        	}
        	vm.editViewLabel = false;
        	
        	vm.viewId = view.id;
        	vm.currentViewName = view.name;
        	vm.dataset = null;
        	vm.allSelected = true;
        	vm.noneSelected = false;
        	vm.sortColumn = 'columnNum';
        	vm.sortReverse = false;
        	
        	Dataset.getDownloadView({id: $stateParams.datasetId, viewId: vm.viewId}, function(data) {
				vm.dataset = data.dataset;
				vm.currentViewName = data.viewName;
				
				// update download & description names as well as form dirty flags
				updateDownloadName(data.viewName);
				resetForm();
				
				vm.availableViews = data.availableViews;
				updateColumnSelection();
			});
        }
        
        // saves a view that is being edit
        function saveView() {
        	vm.downloadForm.$setSubmitted();
        	
        	// make sure top level fields are filled in
        	if (!vm.downloadForm.$valid) return false;
        	
        	// make sure at least one column is selected
        	var colSelected = false;
    		for (var i=0; i<vm.dataset.columns.length; i++) {
    			var column = vm.dataset.columns[i];
    			if (column.selected) {
    				colSelected = true;
    				break;
    			}
    		}
    		if (!colSelected) {
    			vm.activeTab = 'columns';
    			GlobalModal.displayError('Please select a column to download.');
    			return false;
    		}
    		
    		var data = {
    			dataset: vm.dataset,
    			viewName: vm.currentViewName,
    			viewDescription: vm.viewDescription,
    		}

    		vm.saving = true;
    		
    		// creating a new view
    		if (vm.viewId <= 0) {
	        	Dataset.createDownloadView({id: $stateParams.datasetId}, data, function(result) {
	        			GlobalModal.displaySuccess('View created successfully.');
	        			editView({id: result.id, name: vm.currentViewName}, true);
	        			vm.saving = false;
					}, function(error) {
						GlobalModal.displayError(error.statusText);
	        			vm.saving = false;
					});
    		}
    		
    		// editing an existing view
    		else {
	        	Dataset.updateDownloadView({id: $stateParams.datasetId, viewId: vm.viewId}, data, function() {
	        			GlobalModal.displaySuccess('View updated successfully.');
	        			editView({id: vm.viewId, name: vm.currentViewName}, true);
	        			vm.saving = false;
					}, function(error) {
						GlobalModal.displayError(error.statusText);
	        			vm.saving = false;
					});
    		}
        }
        
        // copies current view for editing
        function copyView(view) {
        	resetForm();
        	
        	vm.viewId = -1;
        	vm.currentViewName += " Copy";
        	updateDownloadName(vm.currentViewName);
        	vm.downloadForm.viewName.$setDirty();
        }
        
        // load download name and description during load if they are not already edited by the user
        function updateDownloadName(viewName) {
        	if (vm.downloadForm) {
				if (!vm.downloadForm.downloadName.$dirty || !vm.downloadName || vm.downloadName.length==0) {
					vm.downloadName = vm.dataset.label;
					vm.downloadForm.downloadName.$setPristine();
					
					if (viewName && viewName.length>0)
						vm.downloadName += "." + viewName;
				}
				
				if (!vm.downloadForm.description.$dirty || !vm.downloadDescription || vm.downloadDescription.length==0) {
					vm.downloadDescription = vm.dataset.description;
					vm.downloadForm.description.$setPristine();
				}
        	}
        }
        
        // called whenever the view name is edited
        function onViewNameChange() {
        	updateDownloadName(vm.currentViewName);
        }
        
        // resets a form before it is loaded with new data for editing
        function resetForm() {
        	if (vm.downloadForm) {
	        	var downloadNameDirty = vm.downloadForm.downloadName.$dirty;
	        	var downloadDescriptionDirty = vm.downloadForm.description.$dirty;
        		vm.downloadForm.$setPristine();
	        	if (downloadNameDirty) vm.downloadForm.downloadName.$setDirty();
	        	if (downloadDescriptionDirty) vm.downloadForm.description.$setDirty();
        	}
        }
        
        // deletes a view
        function deleteView(view, $event) {  
        	GlobalModal.confirm('Are you sure you want to delete this dataset download view?', function() {
            	Dataset.deleteDownloadView({id: $stateParams.datasetId, viewId: view.id}, function() {
            		newView();
        		});
            });
    		
    	    // Prevent bubbling to editView.
    	    // On recent browsers, only $event.stopPropagation() is needed
    	    if ($event.stopPropagation) $event.stopPropagation();
    	    if ($event.preventDefault) $event.preventDefault();
    	    $event.cancelBubble = true;
    	    $event.returnValue = false;
        }
        
    	// called when all column selections is checked
        function changeAllColumnSelections() {
        	for (var i=0; i<vm.dataset.columns.length; i++) {
        		vm.dataset.columns[i].selected = vm.allSelected;
        	}
        	
        	vm.noneSelected = !vm.allSelected;
        }
        
    	// called when a column selection changes
        function updateColumnSelection() {
        	var allSelected = true;
        	vm.noneSelected = true;
        	for (var i=0; i<vm.dataset.columns.length; i++) {
        		if (!vm.dataset.columns[i].selected) {
        			allSelected = false;
        		}
        		else {
        			vm.noneSelected = false;
        		}
        	}
        	
        	vm.allSelected = allSelected;
        }
        
        // called to change the sort column
        function changeSort(columnName) {
        	if (vm.sortColumn == 'columnNum' || vm.sortColumn != columnName) {
        		vm.sortColumn = columnName;
        		vm.sortReverse = false;
        	}
        	else if (!vm.sortReverse) {
        		vm.sortReverse = true;
        	}
        	else {
        		vm.sortColumn = 'columnNum';
        		vm.sortReverse = false;
        	}
        }
        
        // send user back to datasets screen
    	function returnToDatasets() {
    		$state.go('datasets');
    	}
    	
    	// initiates a download request
    	function initiateDownload(type) {
    		if (vm.noneSelected) return;
    		
    		// build list of column ids
    		var columnIds = vm.dataset.columns.reduce(function(result, column){
    				if (column.selected) result.push(column.id);
    				return result;
    			},[]);
    		
    		// build options object to send to server
    		var options = {
    			resultMimeType: type == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv',
    		    columnIds: columnIds,
    			name: vm.downloadName,
    			description: vm.downloadDescription
    		};
    		
    		// send request to server
    		DatasetFileMicroservice.requestDownload({id: $stateParams.datasetId}, options, function(download) {
    				pollDownloadProgress(download.id);
    			}, function(error) {
    			    if (error.status === 412)
                    GlobalModal.displayError('Please wait for previous download to generate before requesting another');
    			    else
    			        GlobalModal.displayError('Your download request failed');
    			});
    	}
    	
    	// transitions to download history screen
    	function navigateToDownloadHistory() {
    		GlobalModal.hideModal();
    		
    		$state.go('downloadHistory', {
    			datasetId: $stateParams.datasetId,
    		});
    	}
    	
    	// monitors a download request to perform actual download
    	function pollDownloadProgress(downloadId) {
    		var refreshPromise = null;
    		var downloadStart = new Date().getTime();
    		var notifiedRefreshing = false;
    		var pollingTimeOut = 1000;
    		var pollingBackOff = 1000;
    		
    		function pollingFunc () {
            	DatasetFileMicroservice.getDownloadInfo({id: $stateParams.datasetId, downloadId: downloadId},
    		    		function(result) {
            				var elapsed = new Date().getTime() - downloadStart;
            				
			            	// success, ready to download
            				if (result.status == 'READY') {
            					stopDownloadPolling(refreshPromise);
            					GlobalModal.hideModal();
            					
            					// start actual file download
            					DownloadService.downloadDatasetFile($stateParams.datasetId, downloadId, function() {
            							GlobalModal.displayError('There was a problem with your download. Please try again.');
            						});
            					
            					return;
            				}
            				
            				// failure stop polling
            				else if (result.status == 'ERROR') {
            					GlobalModal.displayError('There was a problem with your download. Please try again.');
            					stopDownloadPolling(refreshPromise);
            					return;
            				}
            				
            				// download has taken longer than 2 sec
            				else if (elapsed > MILLIS_TO_REFRESHING_DISPLAY && elapsed < MILLIS_TO_REFRESHING_TIMEOUT) {
            					if (!notifiedRefreshing) {
            						GlobalModal.displayRefresh('Your download is in process.');
            						notifiedRefreshing = true;
            					}
            				}
            				
            				// download has taken longer than 5 sec
            				else if (elapsed >= MILLIS_TO_REFRESHING_TIMEOUT) {
            			        GlobalModal.displayRefreshHtml('Your download is in process. It will be available on the <a ng-click="vm.navigateToDownloadHistory()" class="white bold">Datasets</a> page when processing is complete.', 
            			        		$scope);
            					stopDownloadPolling(refreshPromise);
            					return;
            				}
            				
            				// reschedule polling function
        					stopDownloadPolling(refreshPromise);
        					
        					// increase polling time to back off by 1sec for up to 10sec
        					if (pollingTimeOut < 10000)
        						pollingTimeOut += pollingBackOff;
        		    		refreshPromise = $timeout(pollingFunc, pollingTimeOut);
        		    		
        		            vm.refreshIntervalPromises.push(refreshPromise);
    		    		},
    		    		function(error) {
    		    			GlobalModal.displayError('There was a problem with your download. Please try again.');
        					stopDownloadPolling(refreshPromise);
    		    		});
            }
    		
    		// poll for completion
    		var refreshPromise = $timeout(pollingFunc, pollingTimeOut);
            vm.refreshIntervalPromises.push(refreshPromise);
    	}
    	
    	// stops polling for a particular download
    	function stopDownloadPolling(refreshPromise) {
			vm.refreshIntervalPromises = vm.refreshIntervalPromises.filter(function(promise) { return promise != refreshPromise; });
			$timeout.cancel(refreshPromise);
    	}
    }
})();
