(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('DcFlowsController', DcFlowsController);

    DcFlowsController.$inject = ['$state', '$q', 'DataCenterFlow', 'ExternalEntity'];

    function DcFlowsController ($state, $q, DataCenterFlow, ExternalEntity) {
        var vm = this;

        vm.flowFilter = '';
        vm.flowDirection = '';
        vm.flowStatus = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['label'];
        vm.nextAvailableQueryId = 0;

        vm.externalEntityAutoComplete = externalEntityAutoComplete;
        vm.externalEntityAutoCompleteBlur = externalEntityAutoCompleteBlur;
        vm.externalEntityAutoCompleteChange = externalEntityAutoCompleteChange;
        vm.addFlow = addFlow;
        vm.editFlow = editFlow;
        vm.query = query;
        vm.sortBy = sortBy;
        vm.loadPage = loadPage;
        vm.getSourceLabel = getSourceLabel;
        vm.getTargetLabel = getTargetLabel;
        vm.toDirectionText = toDirectionText;
        vm.toStatusText = toStatusText;

        query();

        function externalEntityAutoComplete(systemType) {
            return  {
                minimumChars: 1,
                pagingEnabled: true,
                data: function (searchText, pagingParams) {
                    var deferred = $q.defer();

                    ExternalEntity.queryForAutocomplete({name: searchText, page: pagingParams.pageIndex, size: pagingParams.pageSize, sort: ['name']},
                        function(result, headers) {
                            deferred.resolve(result);
                        }
                    );

                    return deferred.promise;
                },
                renderItem: function (item) {
                    return {
                        value: item.name,
                        label: "<p class='auto-complete' ng-bind-html='entry.item.name'></p>"
                    };
                },
                itemSelected: function (selection) {
                    if (selection) {
                        vm[systemType + 'ExternalEntitySelection'] = selection.item;
                        vm[systemType + 'ExternalEntityId'] = selection.item.id;
                        query();
                    }
                }
            }
        }
        function externalEntityAutoCompleteBlur(systemType) {
            var sel = vm[systemType + 'ExternalEntitySelection'];
            var entity = vm[systemType + 'ExternalEntity']
            if (sel && entity === sel.name) {
                vm[systemType + 'ExternalEntityId'] = sel.id;
            }
            else {
                vm[systemType + 'ExternalEntity'] = "";
                vm[systemType + 'ExternalEntityId'] = null;
                query();
            }
        }
        function externalEntityAutoCompleteChange(systemType) {
        }


        function addFlow() {
	    	$state.go('dc-flow', {
                dcFlowId: 0
            });
	    }

        function editFlow(flow) {
    		$state.go('dc-flow', {
    			dcFlowId: flow.id,
    		});
        }

        function query() {
            vm.currentAvailableQueryId = vm.nextAvailableQueryId++;
            var queryId = vm.currentAvailableQueryId;

	        DataCenterFlow.query({label: vm.flowFilter,
                sourceExternalEntityId: vm.sourceSystemExternalEntityId,
                targetExternalEntityId: vm.targetSystemExternalEntityId,
                direction: vm.flowDirection ? vm.flowDirection : null,
                status: vm.flowStatus ? vm.flowStatus : null,
                page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
		    	function(result, headers) {
                    if (queryId == vm.currentAvailableQueryId) {
		        		vm.flows = result;
		        		vm.totalItems = headers('X-Total-Count');
                    }
        		}
            );

	        return false;
        }

        function sortBy(field){
    		var sortDir = vm.reverse ? 'desc' : 'asc';
    		vm.sort = [field + ',' + sortDir];
    		query();
	    }

        function loadPage(page) {
            vm.page = page;
            query();
        }

        function getSourceLabel(flow) {
            if (flow.sourceSystem.systemInternal)
                return "Internal";
            else
                return flow.sourceSystem.externalEntityName;
        }

        function getTargetLabel(flow) {
            if (flow.targetSystem.systemInternal)
                return "Internal";
            else
                return flow.targetSystem.externalEntityName;
        }

        function toDirectionText(direction) {
            switch(direction) {
                case 'INBOUND':
                    return 'Inbound';
                case 'OUTBOUND':
                    return 'Outbound';
                case 'INTERNAL':
                    return 'Internal';
                default:
                    return direction;
            }
        }

        function toStatusText(status) {
            switch(status) {
                case 'PENDING':
                    return 'Pending';
                case 'ACTIVE':
                    return 'Active';
                default:
                    return status;
            }
        }
    }
})();
