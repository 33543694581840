(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('DatasetsController', DatasetsController);

    DatasetsController.$inject = ['$scope', '$state', '$stateParams', '$rootScope', '$interval', '$timeout', '$localStorage', 'Dataset', 'DatasetFileMicroservice', 'Principal', 'DownloadService', 'PreviewService', 'GlobalModal', 'clipboard', '$location'];

    function DatasetsController ($scope, $state, $stateParams, $rootScope, $interval, $timeout, $localStorage, Dataset, DatasetFileMicroservice, Principal, DownloadService, PreviewService, GlobalModal, clipboard, $location) {
        var vm = this;
        
        Principal.identity()
        	.then(function(identity) {
        	    vm.identity = identity;
        	    vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
        	});
        
        if (!$rootScope.datasetDisplayMode)
        	$rootScope.datasetDisplayMode = $localStorage.datasetDisplayMode || 'grid';
        
        if ($rootScope.datasetDisplayPage) {
        		vm.page= $rootScope.datasetDisplayPage;
        } else {
        		vm.page = 1;
        }
        
        vm.itemsPerPage = 12;
       
        if ($stateParams.query) {
        	 	vm.queryFilter = $stateParams.query;
        } else {
	        	if ($rootScope.datasetQueryFilter) {
	        		//copy the search string from root scope and set it to null - page reloads will not retain search filter
	        		vm.queryFilter = $rootScope.datasetQueryFilter;
	        		$rootScope.datasetQueryFilter = null;
	        }
        }
      
        
        vm.query = query;
        vm.loadPage = loadPage;
        vm.openDataset = openDataset;
        vm.downloadDataset = downloadDataset;
        vm.blobDatasetIcon = blobDatasetIcon;
        vm.previewDataset = previewDataset;
        vm.openDatasetAuditReport = openDatasetAuditReport;
        vm.openDownloadHistory = openDownloadHistory;
        vm.updateDataset = updateDataset;
        vm.changeDisplay = changeDisplay;
        vm.deleteDataset = deleteDataset;
        vm.buildOwnerDisplayName = buildOwnerDisplayName;
        vm.downloadDatasetFile = downloadDatasetFile;
        vm.clearQueryFilter = clearQueryFilter;
        vm.openApi = openApi;
        vm.searchDatasets = searchDatasets;
        vm.getPurgeMessage = getPurgeMessage;
        query();
        vm.showInfo = showInfo;
        vm.hideModalInfo = hideModalInfo;
        
        function retainPageDetails() {
	        	//add the page number and queryfilter to root scope, so that the same page and query filter can be display
	    		// when coming back to dataset view
	        	$rootScope.datasetDisplayPage = vm.page;
	    	 	$rootScope.datasetQueryFilter = vm.queryFilter ;
        }
        
        $.widget( "custom.datasetSearchFilter", $.ui.autocomplete, {
            _create: function() {
                this._super();
                this.widget().menu( "option", "items", "> :not(.dataset-search-category)" );
            },
            _renderMenu: function( ul, items ) {
                var that = this,
                    currentCategory = "";
                $.each( items, function( index, item ) {
                    var li;
                    if ( item.category != currentCategory ) {
                        ul.append( "<li class='dataset-search-category'>" + item.category + "</li>" );
                        currentCategory = item.category;
                    }
                    li = that._renderItemData( ul, item );
                });
            },
            _renderItem: function( ul, item ) {
                switch (item.type) {
                    case 'recent_search':
                    case 'saved_search':
                        var li = $( "<li class='dataset-search-item'>" )
                        var contentDiv = $("<div class='recent-search'>")
                            .appendTo(li);
                        
                        // label for searh
                        $( "<div class='recent-search-label'>" + item.label + "</div>" )
                            .appendTo(contentDiv);
                        
                        // radio for search
                        var radioDiv = $( "<div> ")
                            .appendTo(contentDiv);
                        
                        var starCheckbox = $("<input id='saved_search_"+item.id+"' class='star' type='checkbox'>" )
                            .attr("checked", item.type == 'saved_search')
                            .on('click', function(event) {
                                event.stopPropagation();
                                
                                var checkbox = event.target;
                                var id = item.id;

                                Dataset.starAutoSuggestQuery({id: id}, {id: id, starred: checkbox.checked},
                                    function() {
                                        queryFilterAutoComplete.datasetSearchFilter( "search", vm.queryFilter );
                                    });
                            })
                            .appendTo(radioDiv);
                            
                        return li.appendTo( ul );
                    default:
                        return $( "<li class='dataset-search-item'>" )
                            .append( "<div>" + item.label + "</div>" )
                            .appendTo( ul );
                }
            },
        });
        
        var queryFilterAutoComplete = $('#queryFilter').datasetSearchFilter({
          minLength: 0,
          source: queryAutoSuggestSource,
          classes: {
              "ui-autocomplete": "dataset-search-dropdown"
          },
          
          // make sure model is in sync with input when user navigates through list
          focus: function( event, ui ) {
              $scope.$apply(function (scope) {
                  vm.queryFilter = ui.item.value;
              });
          },
          
          // make sure model is in sync with input when closed
          close: function( event ) {
              $scope.$apply(function (scope) {
                  vm.queryFilter = event.target.value;
              });
          },
          
          
          select: function( event, ui ) {
              // replace query filter with value selected
              vm.queryFilter = ui.item.value;
              
              // invoke query operation
              query();              
              
              // cancel default update of text field
              return false;
          }
        });
        
        function queryAutoSuggestSource (request, response) {
            Dataset.queryAutoSuggest({query: request.term},
                    function(result, headers) {
                        var items = [];
                        
                        // starred searches first
                        for (var i=0; i<result.starredSearches.length; i++) {
                            var item = result.starredSearches[i];
                            items.push({
                                id: item.id,
                                type: 'saved_search',
                                value: item.query,
                                label: item.query,
                                category: 'Saved Searches'
                            });
                        }
                        
                        // recent searches second
                        for (var i=0; i<result.recentSearches.length; i++) {
                            var item = result.recentSearches[i];
                            items.push({
                                id: item.id,
                                type: 'recent_search',
                                value: item.query,
                                label: item.query,
                                category: 'Recent Searches'
                            });
                        }
                        
                        // tags third
                        for (var i=0; i<result.tags.length; i++) {
                            var item = result.tags[i];
                            items.push({
                                id: item.id,
                                type: 'tag',
                                value: '#' + item.name,
                                label: item.name,
                                category: 'Tags'
                            });
                        }

                        response(items);
                    });
        }
        
        $scope.$on('$destroy', function() {
	    		$interval.cancel(vm.refreshIntervalPromise);
	    })
        $scope.$watch('vm.datasets', function() {
      		enableTooltips();
        });
        
        // show notice that dataset was saved successfully
        if ($stateParams.confirmSuccess) {
        		GlobalModal.displaySuccess('Your dataset is being processed');
        }
        
		// reduce list of ids to ones needing refreshed
        function initiateRefreshProcessing() {
        		$interval.cancel(vm.refreshIntervalPromise);

			var processingIds = vm.datasets.reduce(function(result, dataset) {
				if (dataset.status === 'PROCESSING') result.push(dataset.id);
				return result;
			}, []);
			if (processingIds.length > 0) {
			    vm.refreshTimeOut = 500;
			    function refreshDatasetsOnce() {
                    if (!vm.backgroundRefreshInProgress) {
                        refreshProcessingDatasets(processingIds);
                    }
                    
                    vm.refreshTimeOut = vm.refreshTimeOut * 2;
                    if (vm.refreshTimeOut < 30000)
                        $timeout(refreshDatasetsOnce, vm.refreshTimeOut);
                }
    				$timeout(refreshDatasetsOnce, vm.refreshTimeOut);
				
		        // query to refresh the datasets in 'PROCESSING' status on the page every 2 seconds
		        vm.refreshIntervalPromise = $interval(function() {
			        	if (vm.datasets && !vm.backgroundRefreshInProgress) {
			        		// reduce list of ids to ones needing refreshed
			        		processingIds = vm.datasets.reduce(function(result, dataset) {
			        			if (dataset.status === 'PROCESSING') result.push(dataset.id);
			        			return result;
			        		}, []);
			        		
			        		if (processingIds.length > 0) {
			        			refreshProcessingDatasets(processingIds);
			        		}
			        		else {
				        		$interval.cancel(vm.refreshIntervalPromise);
			        		}
			        	}
		        }, 30000);
			}
        }
        
        function refreshProcessingDatasets(ids) {
			vm.backgroundRefreshInProgress = true;
	        Dataset.refresh({page: vm.page-1, size: vm.itemsPerPage, ids: ids},
		    		function(result) {
	        			// update the UI with refreshed data
	        			result.map(function(dataset) {
	        				for (var i=0; i<vm.datasets.length; i++) {
	        					if (vm.datasets[i].id == dataset.id) 
	        						vm.datasets[i] = dataset;
	        				}
	        				
	        				vm.chunkedDatasets.map(function(chunk) {
    	        				for (var i=0; i<chunk.length; i++) {
    	        					if (chunk[i].id == dataset.id) 
    	        						chunk[i] = dataset;
    	        				}
	        				});
	        			});
	        			
	        			vm.backgroundRefreshInProgress = false;
		    		});
        }
        
        function searchDatasets() {
        	 //clear the page number and set it to 1 - since a new query filter may produce different set of results
        	 $rootScope.datasetDisplayPage = null;
        	 vm.page = 1;
        	 query();
        	 
        }
        
        function query() {
            if (queryFilterAutoComplete) queryFilterAutoComplete.datasetSearchFilter("close");
           
	        Dataset.query({page: vm.page-1, size: vm.itemsPerPage, query: vm.queryFilter, includeViews: true},
        	    		function(result, headers) {
        	        		vm.datasets = result;
        	        		vm.totalItems = headers('X-Total-Count');
        	        		vm.chunkedDatasets = chunk(result, 3);
        	        		initiateRefreshProcessing();
        	    		});
	        
	        return false;
        }
        
        function loadPage (page) {
        		
	        	if ($rootScope.datasetDisplayPage) {
	        		 vm.page = $rootScope.datasetDisplayPage;
	        		 //reset the root scope ONLY AFTER the page is loaded- by default, ui-pagination sets the page number to 1
	        		 $rootScope.datasetDisplayPage = null;
	        	} else {
	        		 vm.page = page;
	        	}
          
            vm.query();
        }
        
        function changeDisplay(mode) {
        	$rootScope.datasetDisplayMode = mode;
        	$localStorage.datasetDisplayMode = mode;
        }
        
        function getPurgeMessage(dataset) {
	      
	      	  if (dataset.status==='PURGED' && dataset.purgeTimeStamp) {     		   
					return  'Purged on ' + moment(dataset.purgeTimeStamp).format( " YYYY-MM-DD");
	         } else {
	      	  		return '';
	         }
         
        }
       
        
        function openDataset(dataset, $event) {
        		retainPageDetails();
            // send user back to configure page for new uploads      	 	
            if (vm.isSupplier && dataset.status === 'NEW') {
                $state.go('upload-configure', {
                    datasetId: dataset.id,
                });
            }
            else if (dataset.status === 'READY' ||dataset.status === 'SHARED') {
                if (vm.isSupplier && (vm.identity.id === dataset.ownerId || dataset.delegate)) {
                    $state.go('share', {
                        datasetId: dataset.id,
                    });
                }
                else if (dataset.previewOnly) {
                    previewDataset(dataset);
                }
                else {
                    downloadDataset(dataset, $event);
                }
            }
        }
        
        function downloadDataset(dataset, $event) {
        		retainPageDetails();
            	if (dataset.datasetType == "BLOB") {
        			DownloadService.downloadDatasetBlobFile(dataset.id, function() {
    					GlobalModal.displayError('There was a problem with your download. Please try again.');
    				}, $event.altKey);
            	}
            	else {
        	    		$state.go('download', {
        	    			datasetId: dataset.id,
        	    		});
            	}
        }
        
        function previewDataset(dataset) {
    			retainPageDetails();
            if (dataset.datasetType == "BLOB") {
                PreviewService.previewDatasetBlobFile(dataset.id, function() {
                    GlobalModal.displayError('There was a problem with your preview. Please try again.');
                });
            }
        }
    
        function openDatasetAuditReport(dataset) {
    			retainPageDetails();
        		$state.go('activity-report', {
        			datasetId: dataset.id,
        		});
        }
        
        function openDownloadHistory(dataset) {
    			retainPageDetails();
        		// don't pass allUsers flag at all if not owner (true or false) 
        		// to keep out of url
        		var options = {
    	    			datasetId: dataset.id,
    	    		};
        		if (vm.identity.id === dataset.ownerId || dataset.delegate) options.allUsers = true;
        		
	    		$state.go('downloadHistory', options);
        } 
        
        function updateDataset(dataset) {
    			retainPageDetails();
    		if (vm.identity.id === dataset.ownerId || dataset.delegate) {
        		$state.go('update', {
        			datasetId: dataset.id,
        			datasetType: dataset.datasetType,
        		});
    		}
        }
        
        function deleteDataset(dataset) {
        	GlobalModal.confirm('Are you sure you want to delete this dataset?', function() {
            	Dataset.delete({id: dataset.id}, function() {
	            		query();
	            	}, function(error) {
	            		console.log(error);
	            	});
            });
        }
        
        function chunk(arr, size) {
        	var newArr = [];
        	for (var i=0; i<arr.length; i+=size) {
        		newArr.push(arr.slice(i, i+size));
        	}
        	return newArr;
        }
        
        	// builds the display name for an owner user
        	function buildOwnerDisplayName(user) {
        		var name = '';
        		
        		// build name portion of string
        		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
        			name += user.firstName;
        			
        			if  (user.lastName && user.lastName !== '') {
        				if (name.length>0) name += ' ';
        				name += user.lastName;
        			}
        			 if (user.externalEntityName) {
        				 name += ' (' + user.externalEntityName + ' )';
                 } else if (user.email) {
        			    name += ' (' + user.email + ')';
                 }
        		}
        		else {
        			name += user.email;
        		}
        		
        		return name;
        	}
        	
        	function blobDatasetIcon(dataset) {
        	    switch (dataset.sourceFormat) {
            	    case "application/msword":
            	    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            	        return 'fa-file-word-o';
            	        
                case "application/vnd.ms-excel":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    return 'fa-file-excel-o';
                    
                case "application/vnd.ms-powerpoint":
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                    return 'fa-file-powerpoint-o';
                    
                case "application/pdf":
                    return 'fa-file-pdf-o';
                    
                case "audio/mpeg":
                    return 'fa-file-audio-o';
                    
                case "video/mp4":
                    return 'fa-file-video-o';
        	    }
        	    
        	    return 'fa-file-o';
        	}
        	
        	function downloadDatasetFile(dataset, download) {
        		if (dataset.datasetType === 'BLOB') {
        			downloadDataset(dataset);
        		}
        		else {
    				DownloadService.downloadDatasetFile(dataset.id, download.id, function() {
    						GlobalModal.displayError('There was a problem with your download. Please try again.');
    					});
        		}
        	}
    	
        	function clearQueryFilter() {
        	    vm.queryFilter = '';
        	    $('#queryFilter').focus();
        	     //clear the page number and set it to 1 - since clearing query filter may produce different set of results
        		 $rootScope.datasetDisplayPage = null;
            	 vm.page = 1;
        	     query();
        	}
	
        function relPathToAbs (sRelPath) {
            var nUpLn, sDir = "", sPath = location.pathname.replace(/[^\/]*$/, sRelPath.replace(/(\/|^)(?:\.?\/+)+/g, "$1"));
            for (var nEnd, nStart = 0; nEnd = sPath.indexOf("/../", nStart), nEnd > -1; nStart = nEnd + nUpLn) {
              nUpLn = /^\/(?:\.\.\/)*/.exec(sPath.slice(nEnd))[0].length;
              sDir = (sDir + sPath.substring(nStart, nEnd)).replace(new RegExp("(?:\\\/+[^\\\/]*){0," + ((nUpLn - 1) / 3) + "}$"), "/");
            }
            return $location.protocol() + "://" + location.host + sDir + sPath.substr(nStart);
        }
        
        function openApi(dataset) {
            var apiEndpointUrl = relPathToAbs('/squirrelboxfileservice/api/datasets/'+dataset.id+'/json');
            
            var displayUrlModal = true;
            if (clipboard.supported) {
                try {
                    clipboard.copyText(apiEndpointUrl);
                    GlobalModal.displayCustom('JSON API Endpoint has been copied to your clipboard', false, null, 'info', 'fa-code', true);
                    displayUrlModal = false;
                }
                catch (ignored) {}
            }
            
            if (displayUrlModal) {
                var text = '<div style="width:250px; word-wrap: break-word;">' +
                           '<strong>Please use the following URL</strong><br><br>' + 
                           apiEndpointUrl + 
                           '</div>';
                GlobalModal.displayCustom(text, true, null, 'info', 'fa-code', false);
            }
        }
        
        function showInfo(){
            $('#showInfoModal').modal('show');          
       }
       
       function hideModalInfo() {
           $('#showInfoModal').modal('hide');
       }
    }
}
)();
