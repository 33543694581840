(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('download', {
            parent: 'consumer',
            url: '/consumer/download/:datasetId',
            views: {
                'content@': {
                    templateUrl: 'app/consumer/download/download.html',
                    controller: 'ConsumerDownloadController',
                    controllerAs: 'vm'
                },
            },
        });
    }
})();
