(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('UserAttributeController', UserAttributeController);

    UserAttributeController.$inject = ['$state', '$stateParams', '$rootScope', 'Principal', 'Dataset', 'User', 'AttributesService','$scope','GlobalModal','orderByFilter'];

    function UserAttributeController ($state, $stateParams, $rootScope, Principal, Dataset, User, AttributesService, $scope,GlobalModal,orderBy) {
        
    	var vm = this;
    	vm.selectedIndex=null;
        vm.showAttributes = showAttributes ;
        vm.saveUserAttributeValues = saveUserAttributeValues;
        vm.getUserAttributeValues = getUserAttributeValues;
        vm.loadUsers = loadUsers;
        vm.loadPage = loadPage;
        
        vm.attributeValuesList = [];
        vm.loadUsers = loadUsers ;
        vm.attributeFormError = false;
        vm.reverse = false;
        vm.sort = sort;
        vm.availableUserFilter = null;
        vm.itemsPerPage = 20;
        vm.page = 1;
       
        checkAuthentication();      
        loadAttributes();
        
        
        function sort(){
        	    vm.availableUsers = orderBy(vm.availableUsers, 'displayName',vm.reverse);
        }
              
        function loadUsers() {	
            Dataset.getShareMatchingAvailableUsers({id: 0, name: vm.availableUserFilter, page: vm.page-1, size: vm.itemsPerPage}, function(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                processUsers(data);
                sort();
                showAttributes(vm.availableUsers[0],0);
            });	
        }
        
        function loadPage(page) {
            vm.page = page;
            loadUsers();
        }
        
        function saveUserAttributeValues(){
    	    if(!vm.identity) return false;
    	    
    	    //add value to the list if only a value is entered
    		if((vm.value && !vm.attribute) || (!vm.value && vm.attribute)){
    			vm.addFailure = true;
    			return false;
    		}
    		else if (vm.value && vm.attribute) {
        		var userAttributeValue = {};
            	userAttributeValue.value = vm.value;     	
            	userAttributeValue.attribute = vm.attribute;        	
            	userAttributeValue.user = vm.currentUser;
            	
            	vm.attributeValuesList.push(userAttributeValue);
    		}
            	
        	AttributesService.saveUserAttributeValues({userId : vm.currentUser.id},vm.attributeValuesList, function() {
        		vm.getUserAttributeValues();
        		if(vm.value!=null){
        			vm.value="";
        			vm.value=null};
        		vm.attribute=null;
        		vm.attributeForm.$setPristine();
        		vm.addFailure=false;
        		$scope.$broadcast('userAttributeValue');
        		GlobalModal.displaySuccess('Your changes have been saved.');
			}, function(error) {
				vm.addFailure=true;
			});
        	
        	
        	
        }
        
        function loadAttributes() {
        	AttributesService.query(function(userAttributes){
        		vm.userAttributes = userAttributes;	
        	});	
        	
        }
        
        function showAttributes(user, index) {
            	vm.selectedIndex = index;
            	vm.currentUser = {
            		id: user ? user.id : -1,	
            	}
            	vm.getUserAttributeValues();
        }
        
        function getUserAttributeValues() {
            	vm.attribute == null;
            	vm.value = null;
            	vm.attributeValuesList = null;
            	vm.addFailure = false;
            	if (vm.attributeForm)
            	    vm.attributeForm.$setPristine();
            	
            	AttributesService.getUserAttributeValues({userId : vm.currentUser.id}, function(userAttributeValues) {
                		 vm.attributeValuesList = userAttributeValues;
                		 $scope.$broadcast('userAttributeValue');
     			});
        	
        }
        
        function checkAuthentication() {
        	 Principal.identity()
		        .then(function(identity) {
		        	vm.identity = identity;
		        	vm.isAdmin = Principal.hasAuthority('ROLE_ADMIN', identity);
		        	vm.isOrgAdmin = Principal.hasAuthority('ROLE_ORG_ADMIN', identity);
		        	vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
		        	loadUsers();	
		        });
        }
        
        
        $scope.optionsFilter = function (option) {
        if(vm.attributeValuesList && vm.attributeValuesList.length > 0){	
          var exists = vm.attributeValuesList.some(function(el){return el.attribute.id === option.id;});
          return !exists;
          }
        else{return true;}
        }
        
     // processes available user set after retrieval from server
    	function processUsers(data) {
			vm.availableUsers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				
				user.displayName = buildUserDisplayName(user);
				
				// if duplicates of the same user name are in the list, mark to display email
				if (nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				 vm.availableUsers.push(user);
			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}
			
			
    	}
    	
    	// builds the display name key for a given user
    	function buildUserDisplayName(user) {
    		var name = '';
    		
    		// build name portion of string
    		if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
    			name += user.firstName;
    			
    			if  (user.lastName && user.lastName !== '') {
    				if (name.length>0) name += ' ';
    				name += user.lastName;
    			}
    		}
    		else {
    			name += user.email;
    		}
    		
    		return name;
    	}
    	
    	function buildUserNameOption(user) {
    		var name = user.displayName;
    		
    		if (user.showEmail) {
    			name += ' (';
    			name += user.email;
    			name += ')';
    		}
    		
    		// append "pending" notice for users that have not accepted yet
    		if (!user.inviteAccepted)
    			name += ' (pending)';
    		
    		return name;
    	}
        
    }
})();
