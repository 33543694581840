(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('AgreementController', AgreementController);

    AgreementController.$inject = ['AuthServerProvider', '$scope','$state', '$stateParams', 'GlobalModal', 'Agreement', 'PreviewService'];

    function AgreementController (AuthServerProvider, $scope, $state, $stateParams, GlobalModal, Agreement, PreviewService) {
        var vm = this;

        vm.revisionFilter = '';
        vm.revisionsPerPage = 20;
        vm.revisionsPage = 1;
        vm.revisionSort = ['id', 'startDate'];
        vm.reverse = false;
        vm.navAgreementList = navAgreementList;
        vm.startDateOpen = false;
        vm.endDateOpen = false;
        vm.removeAgreementPdfFile = removeAgreementPdfFile;
        vm.previewAgreementPdfFile = previewAgreementPdfFile;
        vm.saveAgreement = saveAgreement;
        vm.loadRevisions = loadRevisions;
        vm.loadRevisionPage = loadRevisionPage;
        vm.sortBySortingName = sortBySortingName;
        vm.sortByEndDate = sortByEndDate;
        vm.sortByStartDate = sortByStartDate;
        vm.addRevision = addRevision;
        vm.editRevision = editRevision;
        vm.saveRevision = saveRevision;
        vm.openStartDateCalendar = openStartDateCalendar;
        vm.openEndDateCalendar = openEndDateCalendar;
        vm.selectAgreementTypeId = selectAgreementTypeId;
        vm.signForAllUsers = signForAllUsers;
        vm.createDropZone = createDropZone;
     	vm.processDropzoneQueue = processDropzoneQueue;
     	vm.orgId = '';
     	vm.serviceUrl  = 'zuul/squirrelboxfileservice/api/org/';

        // adding agreemet
        if (!$stateParams.agreementId || $stateParams.agreementId === '') {
        		vm.editing = false;
	    		vm.tab = 'configuration';

	    		Agreement.get({ id: 0 },
                    function(response, headers) {
                        vm.agreement = response;
                        vm.availableTypes = response.availableTypes;
                        vm.orgId = vm.agreement.org.id;
                        //add a default value for agreement type
                        if ( vm.agreement.availableTypes) {
	                        vm.agreement.agreementType = vm.agreement.availableTypes[0];
                            if (vm.agreement.agreementType)
	                           vm.agreementTypeId = vm.agreement.agreementType.id;
                        }

                    }, function(error) {
                        GlobalModal.displayError('An error occurred retrieving entity');
                    }
                );
	    }

        // editing existing entity
	    else {
	        loadRevisions();

	    		vm.editing = true;
	    		vm.tab = 'revisions';

	    		Agreement.get({ id: $stateParams.agreementId },
	    			function(response, headers) {
	    		        vm.availableTypes = response.availableTypes;
	    				vm.agreement = response;
	    				vm.orgId = vm.agreement.org.id;
	    				if (vm.agreement.agreementType)
	    				    vm.agreementTypeId = vm.agreement.agreementType.id;
	    				else
	    				    vm.agreementTypeId = 0;
	    				selectAgreementTypeId(vm.agreementTypeId);


	    			}, function(error) {
	    				GlobalModal.displayError('An error occurred retrieving entity');
	    			}
	    		);
	    }


        // navigate back to agreement list
        function navAgreementList() {
        		$state.go('agreement-list');
        }


        function arraysAreIdentical(arr1, arr2){
            if (arr1.length !== arr2.length) return false;
            for (var i = 0, len = arr1.length; i < len; i++){
                if (arr1[i] !== arr2[i]){
                    return false;
                }
            }
            return true;
        }

        // saves entity information after changes have been made
        function saveAgreement() {
        		vm.agreementForm.$setSubmitted();

        		var agreement = {
        		  id: vm.agreement.id,
        		  name: vm.agreement.name,
        		  agreementType: vm.agreement.agreementType,
        		}


        		if (!vm.agreementForm.$valid) return;

        		if (vm.editing) {
        			Agreement.update({id: vm.agreement.id}, agreement,
        				function(result) {
        					GlobalModal.displaySuccess('Agreement data saved successfully');
        				},
        				function(error) {
        					if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementnameexists')
            					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
            				else
            					GlobalModal.displayError('An unexpected error occurred updating the agreement');
        				}
        			);
        		}
        		else {
        			Agreement.create(agreement,
        				function(result) {
    						GlobalModal.displaySuccess('Agreement data saved successfully');
	    			    		$state.go('agreement', {
	    			    			agreementId: result.id,
	    			    		});
        				},
        				function(error) {
        					if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.agreementnameexists')
            					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
            				else
            					GlobalModal.displayError('An unexpected error occurred updating the agreement');
        				}
        			);
        		}
        }

        // retrieves list of Revisions from db
        function loadRevisions(idToEdit) {
	        	Agreement.queryRevisions({ name: vm.revisionFilter, id: $stateParams.agreementId, page: vm.userPage-1, size: vm.agreementsPerPage, sort: vm.revisionSort },
	            		function(revisions, headers) {
			            	vm.revisions = revisions;
			            	vm.totalRevisions = headers('X-Total-Count');

			            	if (!vm.currentEEU && vm.revisions.length > 0) {
			            	    if (idToEdit) {
			            	        for (var i=0; i<vm.revisions.length; i++) {
			            	            if (vm.revisions[i].id == idToEdit) {
		                                editRevision(vm.revisions[i]);
			            	                return;
			            	            }
			            	        }
			            	    }

		            	        editRevision(vm.revisions[0]);
			            	}
		            }
	            );
        }

        function removeAgreementPdfFile(agreementRevision) {
        		GlobalModal.confirm('Do you want to remove PDF version ?', function() {
	        		vm.currentAR.revision.s3Key = '';
	        		Agreement.removePdf({id: $stateParams.agreementId, arId: agreementRevision.id}, agreementRevision,
	        				function(result) {
	        				},
	        				function(error) {
	            				GlobalModal.displayError('An unexpected error occurred deleting pdf file');
	        				}
	        			);
	    	 		return false;
	        });

        }

        function previewAgreementPdfFile() {
	    	 	PreviewService.previewAgreementPdf( vm.orgId, vm.currentAR.id, vm.currentAR.revision.id, function() {
	             GlobalModal.displayError('There was a problem with your preview. Please try again.');
	         });

        }


        // loads a page of revisions during pagination
        function loadRevisionPage(page) {
        		vm.currentAR = null;
            vm.revisionPage = page;
            loadRevisions();
        }

        function sortBySortingName(){
        		vm.currentAR = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.revisionSort = ['name,' + sortDir];
	    		loadRevisions();
        }

        function sortByEndDate(){
        		vm.currentAR = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.revisionSort = ['endDate,' + sortDir];
	    		loadRevisions();
        }

        function sortByStartDate(){
        		vm.currentAR = null;
        		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.revisionSort = ['startDate,' + sortDir];
	    		loadRevisions();
        }

        // creates a new revision
        function addRevision() {
        	var dropZone;
        	if (vm.currentAR && vm.currentAR.agreementdropzone) {
        		dropZone = vm.currentAR.agreementdropzone;
        	}
        		vm.currentAR =  {
        			revision: {
        				agreementText: ''
        			}
        		}
        		vm.currentAR.agreementdropzone = dropZone

        		if (vm.userForm)
                    vm.userForm.$setPristine();
        }

        // loads a revisions data for editing
        function editRevision(ar) {
            var a = this;
        		vm.currentAR = null;

            if (vm.revisionForm)
        		    vm.revisionForm.$setPristine();

            Agreement.getRevision({id: $stateParams.agreementId, arId: ar.id}, function(ar) {

	        		vm.currentAR = {
	        			id: $stateParams.agreementId,
	        			revision: {
	            			id: ar.id,
	            			startDate: ar.startDate,
	            			endDate: ar.endDate,
	            			agreementText: ar.agreementText,
	            			name: ar.name,
	            			s3Key: ar.s3Key,
	            			pdfFileName: ''
	        			}
	        		}
	        		if (vm.currentAR.revision.s3Key && vm.currentAR.revision.s3Key.indexOf("|") !== -1) {

	        			vm.currentAR.revision.pdfFileName = vm.currentAR.revision.s3Key.substring(vm.currentAR.revision.s3Key.indexOf("|") +1);
	        		}
	        		if (vm.currentAR.revision.startDate) {
	        			vm.currentAR.revision.startDate = moment(vm.currentAR.revision.startDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();
	        		}

    				if (vm.currentAR.revision.endDate) {
    					vm.currentAR.revision.endDate = moment(vm.currentAR.revision.endDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").toDate();
	        		}

    		    		//resetDropzone();

    				 Agreement.getRevisionDetails({id: $stateParams.agreementId, arId: ar.id}, function(arDetails) {

    	        			vm.currentAR.details = arDetails	;


    				}, function(error) {
    					GlobalModal.displayError('Unable load revision data.');
    				}, function(success) {
    				});

			}, function(error) {
				GlobalModal.displayError('Unable load revision data.');
			}, function(success) {
			});



        }

        //this should be called from load method, but the DOM model does not show the div element, so invoking from ng-init
        function createDropZone(s3Key) {


        		if (!vm.currentAR)
        			return;

            if (!document.getElementById("agreementpdffiledropzone")) {
                if (vm.currentAR.agreementdropzone) {
                    delete vm.currentAR.agreementdropzone;
                }


           }
           if (vm.currentAR.agreementdropzone) {
        	   		vm.currentAR.agreementdropzone.options.url =  vm.serviceUrl ;
            } else {
	            	vm.currentAR.agreementdropzone = new Dropzone("div#agreementpdffiledropzone", {

	                    url: vm.serviceUrl,
	                    autoProcessQueue: false,
	                    addRemoveLinks: true,
	                    acceptedFiles: '.pdf',
	                    uploadMultiple : false,
	                    parallelUploads : 10,
	                    maxFilesize: 512,
	                    maxFiles: 1,
	                    dictInvalidFileType: 'Please upload a pdf file.',
	                });

	            	vm.currentAR.agreementdropzone.on("maxfilesexceeded", function(file) {
	            		vm.currentAR.agreementdropzone.removeFile(file);
	                });


	            	vm.currentAR.agreementdropzone.on('sending', function (file, xhr, formData) {
	                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
	                });

	            	vm.currentAR.agreementdropzone.on('processing', function (file) {
	            		vm.currentAR.agreementdropzone.options.url =  	vm.serviceUrl + "agreementfile/" + vm.currentAR.id + "/" + vm.currentAR.revision.id;
	     	       });


	            	vm.currentAR.agreementdropzone.on('success',function(file, dataset) {
	            			  vm.currentAR.agreementdropzone.removeAllFiles(true);
	            			  vm.currentAR.revision.s3Key =file.name;
	            			  vm.currentAR.revision.pdfFileName =file.name;
	                      $scope.$apply();
	                });


	            var errFunc = function(file,response){
	                if (response.errorMessage) {
	                    $(file.previewElement).find('.dz-error-message').text(response.errorMessage);
	                    GlobalModal.displayError(response.errorMessage);
	                }
	                else if (response.status == 0){
	                    $(file.previewElement).find('.dz-error-message').text('timed out');
	                    GlobalModal.displayError('Timed out uploading file.');
	                }
	                else if(response.status == 500){
	                    $(file.previewElement).find('.dz-error-message').text('server error uploading file');
	                    GlobalModal.displayError('Error uploading file.');
	                }
	                else if(response.status == 400){
	                    var message = 'unsupported file format';
	                    $(file.previewElement).find('.dz-error-message').text(message);
	                    GlobalModal.displayError('Unrecognized file format.');
	                }
	            };

	            vm.currentAR.agreementdropzone.on('error', errFunc);


            }
        }


        // saves revision information after changes have been made
        function saveRevision() {

        		vm.revisionForm.$setSubmitted();

        		if (!vm.revisionForm.$valid) return;



        		var revision = {
        			id: vm.currentAR.revision.id,
        			startDate: '',
        			endDate: '',
        			agreementText: vm.currentAR.revision.agreementText,
        			name: vm.currentAR.revision.name,
        			s3Key: vm.currentAR.revision.s3Key

        		}


        		if (vm.currentAR.revision.startDate && vm.currentAR.revision.startDate != "") {
                    var d = moment(vm.currentAR.revision.startDate, ["M-D-YYYY"], true);
        				revision.startDate = d.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        		}

	        if (vm.currentAR.revision.endDate && vm.currentAR.revision.endDate != "") {
		            var d = moment(vm.currentAR.revision.endDate, ["M-D-YYYY"], true);
					revision.endDate = d.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

			}



        		var ar = {
        			id: $stateParams.agreementId,
        			revision: revision,
        		}

        		if (revision.id > 0) {
        			Agreement.updateRevision({id: $stateParams.agreementId, arId: ar.revision.id}, ar.revision, function(data) {
        				vm.currentAR.revision.id = data.id;
        				processDropzoneQueue();
        				GlobalModal.displaySuccess('Your changes have been saved.');
        				loadRevisions(data.id);
        			}, function(error) {
        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.revisionexists')
        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.datesoverlap')
        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
        				else
        					GlobalModal.displayError('Unable to save revision. Please try again.');
        			});
        		}
        		else {
        			Agreement.createRevision({id: $stateParams.agreementId}, ar.revision, function(data) {
        				vm.currentAR.id = $stateParams.agreementId;
        				vm.currentAR.revision.id = data.id;
        				processDropzoneQueue();
        			    GlobalModal.displaySuccess('Your changes have been saved.');
        			    vm.currentAR = null;
        			    loadRevisions(data.id);
        			}, function(error) {
        				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.revisionexists')
        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
        				else if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.datesoverlap')
        					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
        				else
        					GlobalModal.displayError('Unable to save revision. Please try again.');
        			});
        		}
        }



	    	// invoked when calendar button is pressed
        function openStartDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.startDateOpen = true;
        };
        function openEndDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.endDateOpen = true;
        };

        function selectAgreementTypeId(id) {
            for (var idx=0; idx<vm.agreement.availableTypes.length; idx++) {
                var agreementType = vm.agreement.availableTypes[idx];
                if (agreementType.id === id) {
                    vm.agreement.agreementType = agreementType;
                    break;
                }
            }
            vm.agreementTypeId = id;
        }


     	function processDropzoneQueue() {


	    		if ( vm.currentAR.agreementdropzone.files.length > 0 ) {
	    			vm.currentAR.agreementdropzone.processQueue();
	    	 		return false;
	    		}


     	}


     	function signForAllUsers(agreementRevision) {
     		 GlobalModal.confirm('Do you want to mark this Agreement "' + agreementRevision.name + '" as signed for all external users ?', function() {
     			Agreement.signRevisionForAllUsers({id: $stateParams.agreementId, arId: agreementRevision.id}, agreementRevision, function(data) {
    				vm.currentAR.revision.id = data.id;
    				processDropzoneQueue();
    				GlobalModal.displaySuccess('All external Users are marked as signed .');
    				loadRevisions(data.id);
    			}, function(error) {
    				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.revisionsignerror')
    					GlobalModal.displayError(error.headers('x-squirrelboxapp-errormessage'));
    				else
    					GlobalModal.displayError('Unable to auto sign all users. Please try again.');
    			});
         });


     	}

    }
})();
