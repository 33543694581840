(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('org-setup', {
			parent: 'account',
			url: '/account/org-setup',
			data: {
				authorities: ['ROLE_ORG_ADMIN', 'ROLE_ADMIN'],
				pageTitle: 'Organization SetUp - DataRail'
			},
			views: {
				'content@': {
					templateUrl : 'app/account/org/org-setup.html',
					controller : 'OrgSetUpController',
					controllerAs : 'vm'
				},
				'business-area@org-setup': {
					templateUrl : 'app/account/org/org-setup-businessarea.html',
				},
				'password-rules@org-setup': {
					templateUrl : 'app/account/org/org-setup-passwordrules.html',
				},
				'archive-rules@org-setup': {
					templateUrl : 'app/account/org/org-setup-archiverules.html',
				},
                'dc@org-setup': {
                    templateUrl : 'app/account/org/org-setup-dc.html',
                },
			}
		});


	}
})();
