(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('accept/:activationKey', {
			parent: 'account',
			url: '/accept/{activationKey}',
			data: {
				authorities: []
			},
			views: {
				'content@': {
					templateUrl: 'app/account/accept/accept.html',
					controller: 'AccountAcceptController',
					controllerAs: 'vm'
				}
			}
		})
		.state('acceptError', {
			url: '/acceptError',
			data: {
				pageTitle: 'Error-DataRail'
			},
			views: {
				'content@': {
					templateUrl: '404.html',
				}
			}
		})
		 .state('accept-expired', {
			 url: '/accept-expired',
				data: {
					pageTitle: 'Invitation-Expired'
				},
				views: {
					'content@': {
						templateUrl: 'app/account/accept/accept-expired.html',
					}
				}
        });


	}
})();
