(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('DataCenterAdmin', DataCenterAdmin);

    DataCenterAdmin.$inject = ['$resource', 'DateUtils'];

    function DataCenterAdmin ($resource, DateUtils) {
        var resourceUrl =  'api/datacenter/:id';

        return $resource(resourceUrl, {}, {
            'queryReusuableCifsCredentials': {
                url: '/api/datacenter/queryReusuableCifsCredentials',
                method: 'GET',
                isArray: true
            },
            'queryReusuableCifsCredentialsForAutocomplete': {
                url: '/api/datacenter/queryReusuableCifsCredentials/autocomplete',
                method: 'GET',
                isArray: true
            },
            'createCifsCredentials': {
                url: '/api/datacenter/cifsCredentials',
                method: 'POST'
            },
            'updateCifsCredentials': {
                url: '/api/datacenter/cifsCredentials/:id',
                method: 'PUT'
            },
            'deleteCifsCredentials': {
                url: '/api/datacenter/cifsCredentials/:id',
                method: 'DELETE'
            },
            'queryReusuableSftpClientCredentials': {
                url: '/api/datacenter/queryReusuableSftpClientCredentials',
                method: 'GET',
                isArray: true
            },
            'queryReusuableSftpClientCredentialsForAutocomplete': {
                url: '/api/datacenter/queryReusuableSftpClientCredentials/autocomplete',
                method: 'GET',
                isArray: true
            },
            'createSftpClientCredentials': {
                url: '/api/datacenter/sftpClientCredentials',
                method: 'POST'
            },
            'updateSftpClientCredentials': {
                url: '/api/datacenter/sftpClientCredentials/:id',
                method: 'PUT'
            },
            'deleteSftpClientCredentials': {
                url: '/api/datacenter/sftpClientCredentials/:id',
                method: 'DELETE'
            },
            'queryReusuableSftpHostCredentials': {
                url: '/api/datacenter/queryReusuableSftpHostCredentials',
                method: 'GET',
                isArray: true
            },
            'queryReusuableSftpHostCredentialsForAutocomplete': {
                url: '/api/datacenter/queryReusuableSftpHostCredentials/autocomplete',
                method: 'GET',
                isArray: true
            },
            'createSftpHostCredentials': {
                url: '/api/datacenter/sftpHostCredentials',
                method: 'POST'
            },
            'updateSftpHostCredentials': {
                url: '/api/datacenter/sftpHostCredentials/:id',
                method: 'PUT'
            },
            'deleteSftpHostCredentials': {
                url: '/api/datacenter/sftpHostCredentials/:id',
                method: 'DELETE'
            },
            'queryReusuableS3Credentials': {
                url: '/api/datacenter/queryReusuableS3Credentials',
                method: 'GET',
                isArray: true
            },
            'queryReusuableS3CredentialsForAutocomplete': {
                url: '/api/datacenter/queryReusuableS3Credentials/autocomplete',
                method: 'GET',
                isArray: true
            },
            'createS3Credentials': {
                url: '/api/datacenter/s3Credentials',
                method: 'POST'
            },
            'updateS3Credentials': {
                url: '/api/datacenter/s3Credentials/:id',
                method: 'PUT'
            },
            'deleteS3Credentials': {
                url: '/api/datacenter/s3Credentials/:id',
                method: 'DELETE'
            },
            'queryReusuableFlowPgpDecryptKeys': {
                url: '/api/datacenter/queryReusuableFlowPgpDecryptKeys',
                method: 'GET',
                isArray: true
            },
            'queryReusuableFlowPgpDecryptKeysForAutocomplete': {
                url: '/api/datacenter/queryReusuableFlowPgpDecryptKeys/autocomplete',
                method: 'GET',
                isArray: true
            },
            'createFlowPgpDecryptKeys': {
                url: '/api/datacenter/flowPgpDecryptKeys',
                method: 'POST'
            },
            'updateFlowPgpDecryptKeys': {
                url: '/api/datacenter/flowPgpDecryptKeys/:id',
                method: 'PUT'
            },
            'deleteFlowPgpDecryptKeys': {
                url: '/api/datacenter/flowPgpDecryptKeys/:id',
                method: 'DELETE'
            },
        });
    }
})();
