(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('Notification', Notification);

    Notification.$inject = ['$resource', 'DateUtils'];

    function Notification ($resource, DateUtils) {
        var resourceUrl =  'api/notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': {
            	method: 'GET',
            	isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        var notifications = angular.fromJson(data);
                        if (notifications.error) return [];
                        notifications.map(function(notification) {
                        	notification.creationDate = DateUtils.convertDateTimeFromServer(notification.creationDate);
                        });
                        return notifications;
                    }
                    return data;
                }
            }
        });
    }
})();
