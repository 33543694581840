(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('user-agreement', {
            parent: 'app',
            url: '/user-agreement',
            data: {
                authorities: [],
                loginState: true,
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/user-agreement/user-agreement.html',
                    controller: 'UserAgreementController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('user-agreement-history', {
            parent: 'app',
            url: '/user-agreement-history',
            data: {
                authorities: [],
                loginState: true,
                isHistory: true,
            },
            views: {
                'content@': {
                    templateUrl: 'app/components/user-agreement/user-agreements-history.html',
                    controller: 'UserAgreementController',
                    controllerAs: 'vm'
                }
            },
        });
    }
})();
