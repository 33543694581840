(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('OrgSetUpController', OrgSetUpController);

    OrgSetUpController.$inject = ['$scope','$rootScope', '$state', '$timeout', 'OrgSetUpService', 'GlobalModal', 'clipboard'];

    function OrgSetUpController ($scope,$rootScope, $state, $timeout, OrgSetUpService, GlobalModal, clipboard) {
        var vm = this;

        vm.tab='business-area';

        vm.addBusinessArea = addBusinessArea;
        vm.loadOrganizationContent=loadOrganizationContent;
        vm.saveContents=saveContents;
        vm.generateNewDcAccessKey = generateNewDcAccessKey;
        vm.revokeDcAccessKey = revokeDcAccessKey;
        vm.copyDcSecretKey = copyDcSecretKey;
        vm.orgBusinessAreasTempList = [];
        vm.clipboardSupported = clipboard.supported;

        function addBusinessArea(){
            	 if(vm.newBusinessArea == null || vm.newBusinessArea == ""){return;}

            	 if (vm.orgBusinessAreasTempList.length>0) {
            		 for (var i=0; i<vm.orgBusinessAreasTempList.length; i++) {
            			 if (vm.orgBusinessAreasTempList[i].name === vm.newBusinessArea) {
    	       				 GlobalModal.displayError("Cannot have duplicate business areas");
            				 return false;
            			 }
            		 }
            	 }

            	 var orgBusinessArea = {};
            	 orgBusinessArea.name = vm.newBusinessArea;
            	 orgBusinessArea.active = true;
            	 vm.orgBusinessAreasTempList.unshift(orgBusinessArea);
            	 vm.newBusinessArea = "";
        }

        function loadOrganizationContent(){
            	OrgSetUpService.getOrganization().then(function(response){
            		vm.org = response.data;
            		if(vm.org.businessAreas != undefined && vm.org.businessAreas != null)
            			vm.orgBusinessAreasTempList = vm.org.businessAreas;

                    vm.orgDcAccessKeysTempList = vm.org.dcAccessKeys;

            		if (vm.org.id === 1)
            			vm.tab = 'password-rules';
            	})
            	.catch(function () {

            	});
        }

        function saveContents(){
            	if (!vm.orgForm.$valid) {
        			GlobalModal.displayError("Invalid Data");
            		return false;
            	}

           	  vm.org.businessAreas = vm.orgBusinessAreasTempList;
              vm.org.dcAccessKeys = vm.orgDcAccessKeysTempList;

           	  // only allow business areas once
           	  if (vm.org.businessAreas.length>0) {
           		  var baSet = {};
           		  for (var i=0; i<vm.org.businessAreas.length; i++) {
           			  var businessArea = vm.org.businessAreas[i];
           			  if (businessArea.active) {
           				  if (baSet[businessArea.name]) {
        	       				  GlobalModal.displayError("Cannot have duplicate active business areas");
        	       				  return false;
        	       			  }

           				baSet[businessArea.name] = businessArea;
           			  }
           		  }
           	  }

           	  OrgSetUpService.updateOrganization(vm.org).then(function(response){
           		  		GlobalModal.displaySuccess("Your changes have been saved");
           		  		loadOrganizationContent();
           	        })
       			 .catch(function () {

       			 });

         }

        function generateNewDcAccessKey() {
            OrgSetUpService.generateDcAccessKey().then(function(response) {
                vm.orgDcAccessKeysTempList.push(response.data);
            })
            .catch(function() {
                GlobalModal.displayError("Failed to generate access key");
            });
        }

        function revokeDcAccessKey(key) {
            GlobalModal.confirm('Are you sure you want to revoke this Data Center key pair?', function() {
                OrgSetUpService.revokeDcAccessKey(key.id).then(function(response) {
                    var idx = -1;
                    for (var i=0; i<vm.orgDcAccessKeysTempList.length; i++) {
                        if (key.id === vm.orgDcAccessKeysTempList[i].id) {
                            idx = i;
                            break;
                        }
                    }

                    vm.orgDcAccessKeysTempList.splice(idx, 1);
                })
                .catch(function() {
                    GlobalModal.displayError("Failed to revoke access key");
                });
            });
        }

        function copyDcSecretKey(key) {
            if (vm.clipboardSupported) {
                try {
                    clipboard.copyText(key.secretKey);
                    GlobalModal.displayInfo('API Secret Key has been copied to your clipboard');
                }
                catch (ignored) {}
            }
        }



    }

})();
