(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('dcFlowChosen', DcFlowChosenDirective);

    DcFlowChosenDirective.$inject = ['$timeout'];

    function DcFlowChosenDirective ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);

    	        // initialize chosen select control
    	        $timeout(function() {
    	    	    select.chosen({
    	    	        disable_search_threshold: 10,
    	    	        width: attr.width || "400px"
    	    	    });
    	        });

                select.on('change', function() {
                    if (select[0].getAttribute('direction-change') === 'true') {
                        scope.vm.onDirectionChange(select.val());
                        scope.$apply();
                    }
                    else if (select[0].getAttribute('system-type')) {
                        scope.vm.selectSystemType(select[0].getAttribute('system-type'), select.val());
                    }
                });

    	        scope.$on('dcFlowUpdate', function() {
        	        $timeout(function() {
    	        	    select.trigger('chosen:updated');
        	        })
    	        });
    	    }
    	}
    }
})();
