(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('agreement', {
            parent: 'app',
            url: '/agreement/{agreementId}',
            data: {
                authorities: [
                	'ROLE_ORG_ADMIN'
                ]
            },
            views: {
                'content@': {
					templateUrl: 'app/orgadmin/agreement/agreement.html',
					controller: 'AgreementController',
					controllerAs: 'vm'
                },
                'revisions@agreement': {
					templateUrl: 'app/orgadmin/agreement/agreement-revisions.html',
                },
                'configuration@agreement': {
					templateUrl: 'app/orgadmin/agreement/agreement-configuration.html',
                },
            },
        });
    }
})();
