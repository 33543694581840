(function() {
	'use strict';

	angular.module('squirrelboxApp').controller('ConfirmationController',
			ConfirmationController);

	ConfirmationController.$inject = [ '$rootScope', '$scope', '$state', '$cookies' , 'Logo'];

	function ConfirmationController($rootScope, $scope, $state, $cookies, Logo) {
		var vm = this;
		vm.login = login;
		vm.passwordForgot = passwordForgot;
		vm.logoIconUrl = Logo.logoIconUrl;
        vm.loginImageUrl = Logo.loginImageUrl;

        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });
        $rootScope.$on('loginImageUpdate', function() {
            vm.loginImageUrl = Logo.loginImageUrl;
        });
		
		var email = $cookies.get('email');
		vm.userEmail = email.replace(/(?!^).(?=[^@]+@)/g,"*");
		
		function passwordForgot() {
			$state.go('password-forgot');
		}
        
        function login() {
            $state.go('login');
        }

		

	}
})();
