(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('DownloadService', DownloadService);

    DownloadService.$inject = ['DatasetFileMicroservice', 'Principal', '$location'];

    function DownloadService (DatasetFileMicroservice, Principal, $location) {
        	function downloadDatasetFile(datasetId, downloadId, failure, multipart) {
    			DatasetFileMicroservice.getDownloadToken({id: datasetId, downloadId: downloadId}, function(response) {
    					if (!response.token) {
    						if (failure) failure();
    						return;
    					}
    					
    					var url = 'zuul/squirrelboxfileservice/file/datasets/'+datasetId+'/download/'+downloadId;
                    if (multipart)
                        url += '/multipart';
                    url += '?token='+response.token;
                        
                    if (multipart)
                        url = 'datarailmp://' + $location.host() + ':' + $location.port() + '/' + url + '#' + $location.protocol();
    
    					window.open(url, 'downloadFrame');
    				}, function(error) {
    					if (failure) failure();
    				});
        	}
        	
        	function downloadDatasetBlobFile(datasetId, failure, multipart) {
        		downloadDatasetBlobFileWithId(datasetId, 0, failure, multipart);
        	}
        	
        	function downloadDatasetBlobFileWithId(datasetId, downloadId, failure, multipart) {
    			DatasetFileMicroservice.getDownloadToken({id: datasetId, downloadId: downloadId}, function(response) {
    					if (!response.token) {
    						if (failure) failure();
    						return;
    					}
    					
    					var url = 'zuul/squirrelboxfileservice/file/datasets/'+datasetId+'/downloadBlob';
    					if (multipart)
    					    url += '/multipart';
    					url += '?token='+response.token;
    					if (downloadId > 0) url += '&downloadId='+downloadId;
    					
    					if (multipart)
    					    url = 'datarailmp://' + $location.host() + ':' + $location.port() + '/' + url + '#' + $location.protocol();
    
    					window.open(url, 'downloadFrame');
    				}, function(error) {
    					if (failure) failure();
    				});
        	}
        	
        	function downloadHelpFile(type, failure) {
        	    Principal.identity().then(function(identity) {
        	                var type = identity.authorities.indexOf('ROLE_EXTERNAL') >= 0 ? 'external' : 'internal';
    					var url = 'zuul/squirrelboxfileservice/file/helpfile' + '/' + identity.orgId + '/' + type;
    					window.open(url, 'downloadFrame');
    				}, function(error) {
    					if (failure) failure();
    				});
        	}
        	
        	return {
        		downloadDatasetFile: downloadDatasetFile,
        		downloadDatasetBlobFile: downloadDatasetBlobFile,
        		downloadDatasetBlobFileWithId: downloadDatasetBlobFileWithId,
        		downloadHelpFile: downloadHelpFile
        	};
    }
})();
