(function() {
	'use strict';

	angular.module('squirrelboxApp').controller('PasswordForgotController',
			PasswordForgotController);

	PasswordForgotController.$inject = [ '$rootScope','$scope', '$state', 'Auth', 'User', 'SysAdmin', '$cookies', 'GlobalModal', 'Logo'];

	function PasswordForgotController($rootScope, $scope, $state, Auth, User, SysAdmin, $cookies, GlobalModal, Logo) {
		var vm = this;
		vm.requestPasswordReset = requestPasswordReset;
		vm.login = login;
		vm.linkExpired = $state.params.linkExpired;
        vm.logoIconUrl = Logo.logoIconUrl;
        vm.loginImageUrl = Logo.loginImageUrl;

        Auth.logout(false);

        $rootScope.$on('logoIconUpdate', function() {
            vm.logoIconUrl = Logo.logoIconUrl;
        });
        $rootScope.$on('loginImageUpdate', function() {
            vm.loginImageUrl = Logo.loginImageUrl;
        });


		function requestPasswordReset() {
			if(vm.email=="" || vm.email==null){
				GlobalModal.displayError("Please enter a valid email address");
				return;
			}
			var user={};
			user.email = vm.email;

			User.checkForEmail(user, function(data) {
				$cookies.put('email',data.email);
				$state.go("password-resetlink-confirmation");

			}, function(error) {
				if (error.headers && error.headers('x-squirrelboxapp-error') === 'error.invitenotaccepted') {
					vm.inviteNotAcceptedError= true;
				} else {
					vm.emailError= true;
				}
			});
		}

        function login() {
        	$state.go('login');
        }
	}
})();
