(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('TransferReportController', TransferReportController);

    TransferReportController.$inject = ['$scope', '$state', '$stateParams', '$window', 'Principal', 'Transfer', 'ParseLinks'];

    function TransferReportController ($scope, $state, $stateParams, $window, Principal, Transfer, ParseLinks) {
        var vm = this;

        vm.transfers = null;
        vm.links = null;
        vm.page = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;
        vm.transferStartDateOpen = false;
        vm.transferEndDateOpen = false;
        vm.externalOnly = true;
        
        vm.loadPage = loadPage;
        vm.reload = reload;
        vm.query = query;
        vm.showTransferDetails = showTransferDetails;
        vm.hideTransferDetails = hideTransferDetails;
        vm.openTransferStartDateDateCalendar = openTransferStartDateDateCalendar;
        vm.openTransferEndDateDateCalendar = openTransferEndDateDateCalendar;
        
        $scope.sort = 'transferDate';
        $scope.reverse = true;
        
        // obtain identity for filtering access
        Principal.identity().then(function(identity) {
        	    vm.identity = identity;
        });

        vm.query();

        function query () {
            	// build sort logic based on user's column heading click
            	var sort;
            	if (Array.isArray($scope.sort)) {
            		sort = [];
            		for (var i=0; i<$scope.sort.length; i++) {
                		sort.push($scope.sort[i] + "," + ($scope.reverse ? 'desc' : 'asc'));
            		}
            	}
            	else {
            		sort = $scope.sort + "," + ($scope.reverse ? 'desc' : 'asc');
            	}
            	
            	if (vm.transferStartDate) {
            	    vm.transferStartDate.setHours(0);
            	    vm.transferStartDate.setMinutes(0);
            	    vm.transferStartDate.setSeconds(0);
            	    vm.transferStartDate.setMilliseconds(0);
            	}

            	if (vm.transferEndDate) {
            	    vm.transferEndDate.setHours(23);
                vm.transferEndDate.setMinutes(59);
                vm.transferEndDate.setSeconds(59);
                vm.transferEndDate.setMilliseconds(999);
            	}
        	
            Transfer.query({transferStartDate: vm.transferStartDate, 
                    transferEndDate: vm.transferEndDate, externalOnly: vm.externalOnly,
                    page: vm.page-1, size: vm.itemsPerPage, sort: sort }, 
                    function(result, headers)
                {
                    vm.transfers = result;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                });
        }

        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
        
        function reload() {
            loadPage(vm.page);
        }
        
        function pageChanged(){
            $window.scrollTo(0,0);
        }
        
        
        function showTransferDetails(transfer) {
            vm.selectedTransfer = null;
            
            Transfer.get({id: transfer.id}, function(transfer) {
                vm.selectedTransfer = transfer;
                vm.selectedTransfer.contractKeys = Object.keys(transfer.contractNumbers).sort();
                vm.selectedTransfer.balancingKeys = Object.keys(transfer.balancingInformation).sort();
                $('#transferDetailsPopover').modal('show');
            });
        }
        
        function hideTransferDetails() {
            $('#transferDetailsPopover').modal('hide');
        }

        // invoked when calendar button is pressed
        function openTransferStartDateDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.transferStartDateOpen = true;
        };
        function openTransferEndDateDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.transferEndDateOpen = true;
        };
    }

})();
