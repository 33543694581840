(function() {
    'use strict';

    angular
    .module('squirrelboxApp')
    .factory('LoginService', LoginService);

    LoginService.$inject = ['$state', '$rootScope'];

    function LoginService ($state, $rootScope) {
        var service = {
                open: open
        };

        return service;

        function open () {
            // validate that we are not already in a login state before redirecting
            if (!$state.current.data || !$state.current.data.loginState) {
                goLogin();
            }
        }

        function goLogin() {
            if ($rootScope.transitioning)
                $rootScope.nextState = 'login';
            else
                $state.go("login");
        }
    }
})();
