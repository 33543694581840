(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('signUp/:activationKey', {
			parent: 'account',
			url: '/signUp/{activationKey}',
			data: {
				authorities: [],
				pageTitle: 'Sign Up - DataRail'
			},
			views: {
				'content@': {
					templateUrl: 'app/account/signup/signup.html',
					controller: 'SignUpController',
					controllerAs: 'vm'
				}
            }
		})
		.state('signUpError', {
			url: '/signUpError',
			data: {
				pageTitle: 'Error-DataRail'
			},
			views: {
				'content@': {
					templateUrl: '404.html',
				}
			}
		});


	}
})();
