(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('supplier', {
        	abstract: true,
            parent: 'app',
            data: {
                authorities: [
                	'ROLE_SUPPLIER'
                ]
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('supplier');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
