
(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.controller('SupplierShareController', SupplierShareController);

	SupplierShareController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$window', '$q', '$sce', 'Dataset', 'User', 'GlobalModal', 'AuthServerProvider', 'Principal', 'PreviewService', 'DatasetFileMicroservice', 'DownloadService'];

	function SupplierShareController ($scope, $state, $stateParams, $timeout, $window, $q, $sce, Dataset, User, GlobalModal, AuthServerProvider, Principal, PreviewService, DatasetFileMicroservice, DownloadService) {
		var vm = this;


        var MILLIS_TO_REFRESHING_DISPLAY = 2000;
        var MILLIS_TO_REFRESHING_TIMEOUT = 10000;

        // be sure to stop polling for any downloads when user navigates away from screen
        vm.refreshIntervalPromises = [];

		vm.saving = false;
		vm.sbAuth = false;
		vm.nextAvailableUserQueryId = 0;
		vm.nextAvailableSupplierQueryId = 0;

		vm.shareBlob = shareBlob;
		vm.shareDataset = shareDataset;
		vm.saveView = saveView;
		vm.newView = newView;
		vm.editView = editView;
		vm.copyView = copyView;
		vm.deleteView = deleteView;
		vm.returnToDatasets = returnToDatasets;
		vm.selectUser = selectUser;
		vm.deselectUser = deselectUser;
		vm.selectAllUsers = selectAllUsers;
		vm.deselectAllUsers = deselectAllUsers;
		vm.availableUserFilterChange = availableUserFilterChange;
		vm.selectedUserFilterChange = selectedUserFilterChange;
		vm.selectSupplier = selectSupplier;
		vm.deselectSupplier = deselectSupplier;
		vm.selectAllSuppliers = selectAllSuppliers;
		vm.deselectAllSuppliers = deselectAllSuppliers;
		vm.availableSupplierFilterChange = availableSupplierFilterChange;
		vm.selectedSupplierFilterChange = selectedSupplierFilterChange;
		vm.userAttrFilterChange = userAttrFilterChange;
		vm.allUserAttributeInUse = allUserAttributeInUse;
		vm.buildUserNameOption = buildUserNameOption;
		vm.addUserAttribute = addUserAttribute;
		vm.changeAllColumnSelections = changeAllColumnSelections;
		vm.updateColumnSelection = updateColumnSelection;
		vm.inviteUser = inviteUser;
		vm.changeSort = changeSort;
		vm.changeOwner = changeOwner;
		vm.ownerBlur = ownerBlur;
		vm.previewDataset = previewDataset;
		vm.reuseShareViewDisplay = "";
        vm.previewUser = "";
        vm.changePreviewUser = changePreviewUser;

        vm.openDatasetAuditReport = openDatasetAuditReport;
        vm.openDownloadHistory = openDownloadHistory;
        vm.isEmpty = isEmpty;
        vm.previewAs = "existing";
        vm.downloadPreview = downloadPreview;

		vm.delegatesAdded = [];
		vm.delegatesRemoved = [];

		vm.initiateDownload = initiateDownload;
        vm.grantAccessToPreviewUser = grantAccessToPreviewUser;

        vm.previewEnabled = true;
        vm.showInfo = showInfo;
        vm.hideModalInfo = hideModalInfo;

		vm.showContentFilter = showContentFilter;
        vm.hideContentFilter = hideContentFilter;
		vm.changeFilterContentType = changeFilterContentType;
		vm.addContentFilter = addContentFilter;
		vm.addContentFilterRange = addContentFilterRange;
		vm.addContentFilterBoolean = addContentFilterBoolean;
		vm.openContentFilterStartDate = openContentFilterStartDate;
		vm.openContentFilterEndDate = openContentFilterEndDate;
		vm.addContentFilterStartDate = addContentFilterStartDate;
		vm.addContentFilterEndDate = addContentFilterEndDate;
		vm.addContentFilterDateFormat = addContentFilterDateFormat;
		vm.updateCustomDateFormat = updateCustomDateFormat;
		vm.toggleDateGuide = toggleDateGuide;
		vm.addContentFilterDate = addContentFilterDate;
		vm.removeContentFilter = removeContentFilter;

		// check that SB authentication is allowed to allow creation / modification of users
		AuthServerProvider.getAuthenticationMethod()
		.then(function(method) {
			vm.sbAuth = method.authenticationType == 'SB';
		});

		// create new empty view after retrieving user identity info
		Principal.identity()
		.then(function(identity) {
			vm.identity = identity;
			vm.isExternal = Principal.hasAuthority('ROLE_EXTERNAL', identity);
			vm.isOwner = Principal.hasAuthority('ROLE_OWNER', identity);
			initView();
		});

		//Handles the share/save function depending on file type
		function shareBlob() {
			saveView(true, false);
		}

		function isEmpty(obj) {
		    for(var key in obj) {
		        if (obj.hasOwnProperty(key)) {
		            return false;
		        }
		    }
		    return true;
		}

		function processShareDataset() {
			vm.saving = true;
			Dataset.shareDataset({id: $stateParams.datasetId, viewId: vm.viewId}, {}, function(result) {
				GlobalModal.displaySuccess('Dataset shared successfully.');
				vm.saving = false;
				/* $scope.apply() does not work, since digest cycle is already running*/
				$timeout( function(){
					vm.viewStatus = 'SHARED';
				},100);
			}, function(error) {
				GlobalModal.displayError(error.statusText);
				vm.saving = false;
			});
		}

		// shares the dataset
		function shareDataset() {
			if (!vm.shareForm.$pristine) return;
			var selectedExtUserCount = 0;
			for (var j=0; j<vm.selectedUsers.length; j++) {
				var selectedUser = vm.selectedUsers[j];
				if (selectedUser.isExternal) {
					selectedExtUserCount++
				}
			}
			var message = (selectedExtUserCount > 1 ? 'Users':'User');
			var msg = '';

			
			
				var contentType = '';
				if (vm.dataset != null && vm.dataset.contentType != null ) {
					contentType = vm.dataset.contentType.contentType;
					contentType =contentType.toUpperCase();
				}
				//NOTE: This may not be accurate if an organization has a different name or meaning for PHI
				
				if(contentType === 'PHI' && selectedExtUserCount > 0) {
					msg = 'You are about to share PHI information. Your selected user list for sharing this dataset includes ' + selectedExtUserCount + ' External '  + message + '. Please confirm ? '
	
				} else if (selectedExtUserCount > 0) {
					msg = 'Your selected user list for sharing this dataset includes ' + selectedExtUserCount + ' External '  + message + '. Please confirm ? ';
				} else if (contentType === 'PHI' ) {
					msg = 'You are about to share PHI information. Please confirm ?' ;
				}
			
			
			if (msg.length > 0){
				GlobalModal.confirm(msg, function() {
					processShareDataset();
	    		}, function(error) {
	    		});
			} else {
                processShareDataset();
            }
		}

		// saves a view that is being edit
		function saveView(shareImm, isPreview) {
			vm.shareForm.$setSubmitted();

			// make sure top level fields are filled in
			if (!vm.shareForm.$valid) {
				GlobalModal.displayError('Please ensure all required fields have valid entries before saving.');
				return false;
			}

			// make sure at least one column is selected
			if (vm.dataset.datasetType == 'TABLE') {
				var colSelected = false;
				for (var i=0; i<vm.dataset.columns.length; i++) {
					var column = vm.dataset.columns[i];
					if (column.selected) {
						colSelected = true;
						// make sure only a content filter or user attribute filter is selected, not both
						if (column.filterContent && column.filterContent.length > 0 && column.filterUserAttribute) {
							
							vm.activeTab = 'columns';
							GlobalModal.displayError('Please do not select both content filter and user attribute for the same column.');
							return false;
						}
					}
				}
				if (!colSelected) {
					vm.activeTab = 'columns';
					GlobalModal.displayError('Please select a column to share.');
					return false;
				}
			}

			// make sure all user attributes are filled for all selected users
			for (var i=0; i<vm.selectedUsers.length; i++) {
				var user = vm.selectedUsers[i];
				if (!allUserAttributeInUse(user)) {
					vm.activeTab = 'access';
					GlobalModal.displayError('Every user must have their attribute filter values defined.');
					return false;
				}
			}
			var data = {
					dataset: vm.dataset,
					availableUserAttributes: vm.availableUserAttributes,
					viewName: vm.currentViewName,
					viewDescription: vm.viewDescription,
					viewStatus: vm.viewStatus,
					selectedUsers: vm.selectedUsers,
					selectedSuppliers: vm.selectedSuppliers,
					delegatesAdded: vm.delegatesAdded,
					delegatesRemoved: vm.delegatesRemoved,
                    preview: isPreview,
                    previewUsers: vm.previewUser ? [vm.previewUser] : null
			}
			vm.saving = true;

			if (shareImm && vm.selectedUsers.length == 0){
				GlobalModal.displayError("Please select at least one user to share the dataset with");
				return;
			}

			// creating a new view
			if (vm.viewId <= 0) {
				Dataset.createShareView({id: $stateParams.datasetId}, data, function(result) {
					if(!shareImm) {
						GlobalModal.displaySuccess('View created successfully.');
					}
                    editView({id: result.id, name: vm.currentViewName}, true);
					if(shareImm) {
						vm.shareForm.$setPristine();
						shareDataset();
					}
					vm.saving = false;
				}, function(error) {
					if (error.statusText && error.statusText.length != '')
						GlobalModal.displayError(error.statusText);
					else
						GlobalModal.displayError("An error occurred saving your view.");
					vm.saving = false;
				});
			}

			// editing an existing view
			else {
				Dataset.updateShareView({id: $stateParams.datasetId, viewId: vm.viewId}, data, function() {
					if(shareImm) {
						vm.shareForm.$setPristine();
						shareDataset();
					} else {
						GlobalModal.displaySuccess('View updated successfully.');
					}
                    if (!isPreview) {
                        editView({id: vm.viewId, name: vm.currentViewName}, true);
                    }
					vm.saving = false;
				}, function(error) {
					GlobalModal.displayError(error.statusText);

					vm.saving = false;
				});
			}
            vm.delegatesAdded = [];
            vm.delegatesRemoved = [];
		}

		// called at startup to set initial view
		function initView() {
			newView(function() {
				// start screen off by editing first view in list
				if (vm.availableViews.length > 0)
					editView(vm.availableViews[0]);
			});
		}

		// creates a new empty view for editing
		function newView(callback) {
			if (vm.shareForm)
				vm.shareForm.$setPristine();

			vm.viewId = -1;
			vm.editViewLabel = false;
			vm.dataset = null;
			vm.changingOwner = false;

			if (vm.isExternal)
				vm.activeTab = 'access';
			else
				vm.activeTab = 'description';

			vm.currentViewName = '';
			vm.viewDescription = '';
			vm.viewStatus = 'READY';
			vm.allSelected = true;
			vm.sortColumn = 'columnNum';
			vm.sortReverse = false;

			vm.reuseShareViewDisplay = "";
			Dataset.getShareInfo({id: $stateParams.datasetId}, function(data) {
				vm.dataset = data.dataset;
				vm.owner = data.owner;
				vm.ownerDisplay = buildUserDisplayName(vm.owner);
				vm.availableUserAttributes = data.availableUserAttributes;
				vm.selectedUserAttributes = [];
                vm.datasetHasDownloads = data.hasDownloads;
				// save button should always be visible to allow tags to be changed
				vm.isSaveButtonVisible = true;

				processColumns(data.dataset.columns);

				vm.originalUsers = data.availableUsers;
				vm.selectedUsers = [];
				processUsers(vm.originalUsers);

				// update selected attributes list
				userAttrFilterChange();

				// update supplier delegate lists
				if (!vm.isExternal && vm.owner.id === vm.identity.id) {
					vm.originalSuppliers = data.availableSuppliers.concat(data.selectedSuppliers);
					vm.selectedSuppliers = data.selectedSuppliers;
					vm.originalSelectedSuppliers = data.selectedSuppliers.slice();
					processSuppliers(vm.originalSuppliers);
				}

				vm.availableViews = data.availableViews;

				if (callback) callback();
			});
			Dataset.getDownloadInfo({id: $stateParams.datasetId}, function(data){
				vm.excelOk = data.segment.sourceSize < 5000000 && data.segment.rowCount < 10000;
			});

		}

		// loads a view for editing
		function editView(view, force) {
			if (vm.shareForm)
				vm.shareForm.$setPristine();

			// if clicked on same label more than once, display edit input
			// for changing label
			if (!force && vm.viewId == view.id) {
				vm.editViewLabel = true;
				$timeout(function() {
					$('#viewName').focus();
				})
				return;
			}
			vm.editViewLabel = false;

			vm.viewId = view.id;
			vm.currentViewName = view.name;
			vm.dataset = null;
			vm.viewDescription = '';
			vm.viewStatus = 'READY';
			vm.allSelected = true;
			vm.sortColumn = 'columnNum';
			vm.sortReverse = false;
			vm.changingOwner = false;

			Dataset.getShareView({id: $stateParams.datasetId, viewId: vm.viewId}, function(data) {
				vm.dataset = data.dataset;
				vm.owner = data.owner;
				vm.ownerDisplay = buildUserDisplayName(vm.owner);
				vm.currentViewName = data.viewName;
				vm.viewDescription = data.viewDescription;
				vm.viewStatus = data.viewStatus;
				vm.availableUserAttributes = data.availableUserAttributes;
				vm.selectedUserAttributes = data.selectedUserAttributes;

				processColumns(data.dataset.columns);

				vm.originalUsers = buildOriginalUserList(data.availableUsers, data.selectedUsers);
				vm.selectedUsers = data.selectedUsers;
				var allUsers = vm.originalUsers;
				processUsers(allUsers);

				// update selected attributes list
				userAttrFilterChange();

				// update supplier delegate lists
				if (!vm.isExternal && vm.owner.id === vm.identity.id) {
					vm.originalSuppliers = buildOriginalUserList(data.availableSuppliers, data.selectedSuppliers);
					vm.selectedSuppliers = data.selectedSuppliers;
					vm.originalSelectedSuppliers = data.selectedSuppliers.slice();
					processSuppliers(vm.originalSuppliers);
				}

				vm.availableViews = data.availableViews;
			});
		}

		// build list of original users from available and selected users
		function buildOriginalUserList(availableUsers, selectedUsers) {
			var originalUsers = [].concat(availableUsers);
			for (var i=0; i<selectedUsers.length; i++) {
				var u = selectedUsers[i];
				var exists = false;
				for (var j=0; j<originalUsers.length; j++) {
					if (u.id === originalUsers[j].id) {
						originalUsers[j] = u;
						exists = true;
						break;
					}
				}
				if (!exists)
					originalUsers.push(u);
			}
			return originalUsers;
		}

		// copies current view for editing
		function copyView(view) {
			if (vm.shareForm)
				vm.shareForm.$setPristine();

			vm.viewId = -1;
			vm.currentViewName = null;
		}

		// deletes a view
		function deleteView(view, $event) {
			GlobalModal.confirm('Are you sure you want to delete this dataset share?', function() {
				Dataset.deleteShareView({id: $stateParams.datasetId, viewId: view.id}, function() {
					newView();
				});
			});

			// Prevent bubbling to editView.
			// On recent browsers, only $event.stopPropagation() is needed
			if ($event.stopPropagation) $event.stopPropagation();
			if ($event.preventDefault) $event.preventDefault();
			$event.cancelBubble = true;
			$event.returnValue = false;
		}

		function returnToDatasets() {
			$state.go('datasets');
		}

		// selects a user
		function selectUser(user, quiet) {
			// make sure user endpoint is valid before selected
			if (user.serviceEndpoint && !user.serviceEndpointValid) {
				if (!quiet) {
					GlobalModal.displayError("The user selected cannot accept datasets of containing this type of data.");
				}
				return false;
			}

			if (!user.attributes) user.attributes = {};
			for (var i=0; i<vm.availableUserAttributes; i++) {
				var attr = vm.availableUserAttributes[i];
				if (!user.attributes[attr.id])
					user.attributes[attr.id] = '';
			}

			// add user to selected if not already selected
			var selected = false;
			for (var j=0; j<vm.selectedUsers.length; j++) {
				var selectedUser = vm.selectedUsers[j];
				if (selectedUser.id == user.id) {
					selected = true;
					break;
				}
			}
			if (!selected) vm.selectedUsers.push(user);

			var idx = vm.availableUsers.indexOf(user);
			if (idx >= 0)
				vm.availableUsers.splice(idx, 1);

			vm.shareForm.$setDirty();
		}

		// deselects a user
		function deselectUser(user) {
			vm.availableUsers.push(user);

			var idx = vm.selectedUsers.indexOf(user);
			if (idx >= 0)
				vm.selectedUsers.splice(idx, 1);

			vm.shareForm.$setDirty();
		}

		// selects all users
		function selectAllUsers() {
			var users = vm.availableUsers.slice(0);
			for (var i=0; i<users.length; i++)
				selectUser(users[i], true);
		}

		// deselects all users
		function deselectAllUsers() {
			var users = vm.selectedUsers.slice(0);
			for (var i=0; i<users.length; i++)
				deselectUser(users[i]);
		}

		// invoked when available user filter changes
		function availableUserFilterChange() {
			if (vm.availableUserFilter.length > 1) {
				vm.currentAvailableUserQueryId = vm.nextAvailableUserQueryId++;
				var queryId = vm.currentAvailableUserQueryId;
				Dataset.getShareMatchingAvailableUsers({id: $stateParams.datasetId, name: vm.availableUserFilter}, function(data) {
					if (queryId == vm.currentAvailableUserQueryId) {
						processUsers(data);
					}
				});
			}
			else
				processUsers(vm.originalUsers);
		}

		// invoked when selected user filter changes
		function selectedUserFilterChange() {
			vm.selectedUserFilterApplied = vm.selectedUserFilter.length > 1 ? vm.selectedUserFilter : '';
		}

		// invoked when any user attribute has been changed for a column
		function userAttrFilterChange() {
			vm.selectedUserAttributes = [];
			for (var i=0; i<vm.dataset.columns.length; i++) {
				var column = vm.dataset.columns[i];
				var attr = column.filterUserAttribute;

				if (attr) {
					column.filterUserAttributeId = attr.id;

					if (column.selected && vm.selectedUserAttributes.indexOf(attr) < 0) {
						vm.selectedUserAttributes.push(attr);
					}
				}
				else {
					column.filterUserAttributeId = null;
				}
			}
		}

		// returns true if any column has a user attribute assigned and the user does
		// not have that attribute assigned
		function allUserAttributeInUse(user) {
			for (var i=0; i<vm.selectedUserAttributes.length; i++) {
				var attr = vm.selectedUserAttributes[i];

				var used = false;
				for (var userAttrId in user.attributes) {
					if (attr.id == userAttrId) {
						var userAttr = user.attributes[userAttrId];
						if (userAttr && userAttr.length > 0) {
							used = true;
							break;
						}
					}
				}

				if (!used) return false;
			}

			return true;
		}

		// selects a supplier
		function selectSupplier(user) {
			// add user to selected if not already selected
			var selected = false;
			for (var j=0; j<vm.selectedSuppliers.length; j++) {
				var selectedUser = vm.selectedSuppliers[j];
				if (selectedUser.id == user.id) {
					selected = true;
					break;
				}
			}
			if (!selected) {
				vm.selectedSuppliers.push(user);

				// If the user is not already in delegatesAdded and was not
				// originally a delegate when we loaded the page, add them to the list
				if (!vm.delegatesAdded.includes(user) && !vm.originalSelectedSuppliers.includes(user)){
					vm.delegatesAdded.push(user);
				}
				// A user who was originally selected can't have been added this time
				if (vm.originalSelectedSuppliers.includes(user)){
					var idx = vm.delegatesRemoved.indexOf(user);
					vm.delegatesRemoved.splice(idx, 1);
				}
			}

			var idx = vm.availableSuppliers.indexOf(user);
			if (idx >= 0)
				vm.availableSuppliers.splice(idx, 1);

			vm.shareForm.$setDirty();
		}

		// deselects a supplier
		function deselectSupplier(user) {
			vm.availableSuppliers.push(user);

			// If the user is not already in delegatesRemoved and was
			// originally a delegate when we loaded the page, add them to the list
			if (!vm.delegatesRemoved.includes(user) && vm.originalSelectedSuppliers.includes(user)){
				vm.delegatesRemoved.push(user);
			}
			// A user who wasn't originally selected can't have been removed
			if (!vm.originalSelectedSuppliers.includes(user)){
				var idx = vm.delegatesAdded.indexOf(user);
				vm.delegatesAdded.splice(idx, 1);
			}

			var idx = vm.selectedSuppliers.indexOf(user);
			if (idx >= 0)
				vm.selectedSuppliers.splice(idx, 1);

			vm.shareForm.$setDirty();
		}

		// selects all suppliers
		function selectAllSuppliers() {
			var suppliers = vm.availableSuppliers.slice(0);
			for (var i=0; i<suppliers.length; i++)
				selectSupplier(suppliers[i]);
		}

		// deselects all suppliers
		function deselectAllSuppliers() {
			var suppliers = vm.selectedSuppliers.slice(0);
			for (var i=0; i<suppliers.length; i++)
				deselectSupplier(suppliers[i]);
		}

		// invoked when available supplier filter changes
		function availableSupplierFilterChange() {
			if (vm.availableSupplierFilter.length > 1) {
				vm.currentAvailableSupplierQueryId = vm.nextAvailableSupplierQueryId++;
				var queryId = vm.currentAvailableSupplierQueryId;
				Dataset.getShareMatchingAvailableSuppliers({id: $stateParams.datasetId, name: vm.availableSupplierFilter}, function(data) {
					if (queryId == vm.currentAvailableSupplierQueryId) {
						processSuppliers(data);
					}
				});
			}
			else
				processSuppliers(vm.originalSuppliers);
		}

		// invoked when selected supplier filter changes
		function selectedSupplierFilterChange() {
			vm.selectedSupplierFilterApplied = vm.selectedSupplierFilter.length > 1 ? vm.selectedSupplierFilter : '';
		}

		// processes columns after retrieval from server
		function processColumns(columns) {
			for (var i=0; i<columns.length; i++) {
				var column = columns[i];

				if (!column.filterUserAttribute && column.filterUserAttributeId) {
					for (var j=0; j<vm.availableUserAttributes.length; j++) {
						var attr = vm.availableUserAttributes[j];
						if (attr.id == column.filterUserAttributeId) {
							column.filterUserAttribute = attr;
							break;
						}
					}
				}
			}
		}

		// check columns to apply share view reuse- columns have to match exactly  - name, number and order
		function checkColumns(columns) {
			if (vm.dataset.columns.length == columns.length) {
				for (var i=0; i<columns.length; i++) {
					if (vm.dataset.columns[i].columnName != columns[i].columnName ) {
						return false;
					}
				}

				return true;
			} else {
				return false;
			}

		}

		// processes available user set after retrieval from server
		function processUsers(data) {
			vm.availableUsers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];

				if (user.serviceEndpoint && user.serviceEndpointValid == undefined) {
					var details = user.serviceEndpointDetails;

					var valid = false;
					if (vm.dataset.datasetType == 'BLOB') {
						if (!details || details.allMimeTypes) {
							valid = true;
						}
						else {
							if (!valid && details.docMimeTypes) {
								if (["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].indexOf(vm.dataset.sourceFormat) >= 0)
									valid = true;
							}
							if (!valid && details.pptMimeTypes) {
								if (["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"].indexOf(vm.dataset.sourceFormat) >= 0)
									valid = true;
							}
							if (!valid && details.xlsMimeTypes) {
								if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].indexOf(vm.dataset.sourceFormat) >= 0)
									valid = true;
							}
							if (!valid && details.csvMimeTypes) {
								if ("text/csv" === vm.dataset.sourceFormat)
									valid = true;
							}
							if (!valid && details.textMimeTypes) {
								if (vm.dataset.sourceFormat.indexOf('text/') == 0)
									valid = true;
							}
							if (!valid && details.validMimeTypes.length > 0) {
								for (var vmIdx=0; vmIdx<details.validMimeTypes.length; vmIdx++) {
									try {
										var text = details.validMimeTypes[vmIdx];
										text = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
										text = text.replace("\\*", ".*");

										if (vm.dataset.sourceFormat.match(text))
											valid = true;
									}
									catch (ignored) {}
								}
							}
						}
					}

					user.serviceEndpointValid = valid;
				}

				user.displayName = buildUserDisplayName(user);

				// if duplicates of the same user name are in the list, mark to display email
				if (user.accountType === 'USER' && nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				// add user to available if not already selected
				var selected = false;
				for (var j=0; j<vm.selectedUsers.length; j++) {
					var selectedUser = vm.selectedUsers[j];
					if (selectedUser.id == user.id) {
						selected = true;
						break;
					}
				}
				if (!selected) vm.availableUsers.push(user);
				if (user.userGroup) {
					var value = null;
					Object.keys( user.userGroupUsers).forEach(function(key) {
						value =  user.userGroupUsers[key];

					});
				}

			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}

		}

		// processes available supplier set after retrieval from server
		function processSuppliers(data) {
			vm.availableSuppliers = [];

			var nameSet = {};
			for (var i=0; i<data.length; i++) {
				var user = data[i];

				user.displayName = buildUserDisplayName(user);

				// if duplicates of the same user name are in the list, mark to display email
				if (user.accountType === 'USER' && nameSet[user.displayName]) {
					nameSet[user.displayName].showEmail = true;
					user.showEmail = true;
				}
				else  {
					nameSet[user.displayName] = user;
				}

				// add user to available if not already selected
				var selected = false;
				for (var j=0; j<vm.selectedSuppliers.length; j++) {
					var selectedUser = vm.selectedSuppliers[j];
					if (selectedUser.id == user.id) {
						selected = true;
						break;
					}
				}
				if (!selected) vm.availableSuppliers.push(user);
			}

			// build option text for display to user in select box
			for (var i=0; i<data.length; i++) {
				var user = data[i];
				user.optionText = buildUserNameOption(user);
			}
		}

		// builds the display name key for a given user
		function buildUserDisplayName(user) {
			var name = '';

			// build name portion of string
			if ((user.firstName && user.firstName !== '') || (user.lastName && user.lastName !== '')) {
				name += user.firstName;

				if  (user.lastName && user.lastName !== '') {
					if (name.length>0) name += ' ';
					name += user.lastName;
				}
			}
			else {
				name += user.email;
			}

			return name;
		}

		function buildReuseShareViewDisplayName(reuseSharedViewVM) {
			if (reuseSharedViewVM.sharedViewName != '') {
				return reuseSharedViewVM.sharedViewName + ' - ' + reuseSharedViewVM.datasetName;
			} else {
				return reuseSharedViewVM.datasetName;
			}

		}

		// builds the text used to display in listbox option
		function buildUserNameOption(user) {
			var name = user.displayName;

			if (user.showEmail) {
				name += ' (';
				name += user.email;
				name += ')';
			}

			// append "pending" notice for users that have not accepted yet
			if (!user.inviteAccepted)
				name += ' (pending)';
			if (user.expirationWarning) {
				name += ' (exp ' + moment(user.accountInactiveExpirationDate).format('MM-DD-YYYY') + ')';
			}

			return name;
		}

		// invoked when a user attribute is created
		function addUserAttribute($event) {
			if (vm.newUserAttributeText && vm.newUserAttributeText.length > 0) {
				for (var i=0; i<vm.availableUserAttributes.length; i++) {
					var existingAttr = vm.availableUserAttributes[i];
					if (existingAttr.name === vm.newUserAttributeText) {
						GlobalModal.displayError("Attribute already exists.");
						return;
					}
				}

				vm.availableUserAttributes.push({
					id: -vm.availableUserAttributes.length,
					name: vm.newUserAttributeText
				});

				$scope.$broadcast('newUserAttribute');
				vm.newUserAttributeText = null;
				vm.shareForm.addattribute.$error.required = false;
			}
			else {
				vm.shareForm.addattribute.$error.required = true;

				// don't close user attribute popup on click of save button
				$event.stopPropagation();
			}
		}

		// called when all column selections is checked
		function changeAllColumnSelections() {
			for (var i=0; i<vm.dataset.columns.length; i++) {
				vm.dataset.columns[i].selected = vm.allSelected;
			}

			// check for attribute filter changes
			userAttrFilterChange();
		}

		// called when a column selection changes
		function updateColumnSelection(index) {
			var allSelected = true;
			for (var i=0; i<vm.dataset.columns.length; i++) {
				if (!vm.dataset.columns[i].selected) {
					allSelected = false;
					break;
				}
			}

			vm.allSelected = allSelected;

			// check for attribute filter changes
			userAttrFilterChange();
		}

		// called to invite a user
		function inviteUser() {
			vm.showUserInviteError = false;

			// validate data before sending to server
			if (!vm.inviteUserEmail || vm.inviteUserEmail.length < 3 || !validateEmail(vm.inviteUserEmail)) {
				vm.showUserInviteError = true;
				return false;
			}

			var user = {
					accountType: 'USER',
					login: vm.inviteUserEmail,
					email: vm.inviteUserEmail,
					activated: true,
					authorities: ['ROLE_CONSUMER'],
			}

			var newEmail = vm.inviteUserEmail;
			User.save(user, function() {
				// load the user information that was just saved
				Dataset.getShareMatchingAvailableUsers({id: $stateParams.datasetId, name: newEmail}, function(data) {
					// locate the pending user and add to selected users list
					for (var i=0; i<data.length; i++) {
						var user = data[0];
						if (!user.inviteAccepted) {
							user.displayName = buildUserDisplayName(user);
							user.optionText = buildUserNameOption(user);
							vm.selectedUsers.push(user);
							break;
						}
					}
				});
			}, function(error) {
				console.log(error);
			});

			vm.showUserInvite = false;
			vm.inviteUserEmail = '';
			return true;
		}

		// called to change the sort column
		function changeSort(columnName) {
			if (vm.sortColumn == 'columnNum' || vm.sortColumn != columnName) {
				vm.sortColumn = columnName;
				vm.sortReverse = false;
			}
			else if (!vm.sortReverse) {
				vm.sortReverse = true;
			}
			else {
				vm.sortColumn = 'columnNum';
				vm.sortReverse = false;
			}
		}

		// called when user clicks change owner button
		function changeOwner() {
			vm.changingOwner = true;

			// highlight the owner text for changing
			var ownerEl = $window.document.getElementById('owner');
			ownerEl.setSelectionRange(0, ownerEl.value.length);
			ownerEl.focus();
		}

		// called when owner field loses focus
		function ownerBlur() {
			var hidden = $(".auto-complete-container").hasClass("ng-hide");
			if (hidden)
				vm.ownerDisplay = buildUserDisplayName(vm.owner);
		}

		vm.autoCompleteOwner = {
				minimumChars: 1,
				data: function (term) {
					var deferred = $q.defer();

					Dataset.getShareMatchingAvailableSuppliers({id: $stateParams.datasetId, name: term, usersOnly: true}, function(data) {
						// update display name
						for (var i=0; i<data.length; i++) {
							var user = data[i];
							user.displayName = buildUserDisplayName(user);
							user.optionName = buildUserNameOption(user);
						}

						deferred.resolve(data);
					});

					return deferred.promise;
				},
				renderItem: function (item) {
					return {
						value: item.displayName,
						label: $sce.trustAsHtml(
								"<span class='auto-complete'>"
								+ item.optionName +
						"</span>")
					};
				},
				dropdownHidden: function() {
					if (vm.autoCompleteOwner.length > 0)
						vm.ownerDisplay = buildUserDisplayName(vm.owner);
				},
				itemSelected: function (selection) {
					if (selection) {
						var confirmMsg = selection.item.ownerRole ? "Are you sure you want to reassign ownership of this dataset? You will not be able to undo this action." :
							"Are you sure you want to reassign ownership of this dataset? All supplier delegations will be lost, and you will not be able to undo this action."

							GlobalModal.confirm(confirmMsg, function() {

								// perform owner change
								Dataset.changeDatasetOwner({id: $stateParams.datasetId}, {newOwnerId: selection.item.id}, function(response) {
									if (response.delegated)
										$state.reload();
									else
										$state.go('datasets');
								}, function(error) {
									GlobalModal.displayError("An error occurred changing the owner of this dataset");
								})

							}, function() {
								vm.ownerDisplay = buildUserDisplayName(vm.owner);
							});
					}
				}
		}


		vm.autoCompleteReuseShareView = {
				minimumChars: 1,
				data: function (term) {
					var deferred = $q.defer();

					Dataset.getMatchingAvailableShareViews({id: $stateParams.datasetId, name: term}, function(data) {
						// update display name
						for (var i=0; i<data.length; i++) {
							var shareView = data[i];
							shareView.displayName = buildReuseShareViewDisplayName(shareView);
							shareView.optionName = buildReuseShareViewDisplayName(shareView);
						}

						deferred.resolve(data);
					});

					return deferred.promise;
				},
				renderItem: function (item) {
					return {
						value: item.displayName,
						label: $sce.trustAsHtml(
								"<span class='auto-complete'>"
								+ item.optionName +
						"</span>")
					};
				},
				dropdownHidden: function() {

				},
				itemSelected: function (selection) {
					if (selection) {
						var confirmMsg =  "Are you sure you want to re-use the shared view  of the dataset " + selection.item.displayName + " ? .";

						GlobalModal.confirm(confirmMsg, function() {

							Dataset.getShareView({id: selection.item.datasetId, viewId: selection.item.sharedViewId}, function(data) {


								if (vm.shareForm)
									vm.shareForm.$setPristine();


								vm.editViewLabel = true;
								vm.changingOwner = false;

								if (vm.isExternal)
									vm.activeTab = 'access';
								else
									vm.activeTab = 'description';

								vm.viewStatus = 'READY';
								vm.allSelected = true;
								vm.sortColumn = 'columnNum';
								vm.sortReverse = false;

								vm.currentViewName = data.viewName;
								vm.viewDescription = data.viewDescription;
								vm.availableUserAttributes = data.availableUserAttributes;
								vm.selectedUserAttributes = data.selectedUserAttributes;
								if (checkColumns(data.dataset.columns) ) {
									//copy the column id, as they are different for each dataset
									for (var i=0; i<data.dataset.columns.length; i++) {
										var tmpId = vm.dataset.columns[i].id;
										vm.dataset.columns[i] = data.dataset.columns[i];
										vm.dataset.columns[i].id  = tmpId;
									}

									for (var i=0; i<data.dataset.columns.length; i++) {
										var column = data.dataset.columns[i];

										if (!column.filterUserAttribute && column.filterUserAttributeId) {
											for (var j=0; j<vm.availableUserAttributes.length; j++) {
												var attr = vm.availableUserAttributes[j];
												if (attr.id == column.filterUserAttributeId) {
													column.filterUserAttribute = attr;
													break;
												}
											}
										}
									}


								}

								//we need to select only active users. The previous shared view might have users who are no longer active now, so
								//we should filter them out and retain only those that are active
								vm.selectedUsers = [];
								for (var k = 0; k < data.selectedUsers.length; k++  ) {
									if (data.selectedUsers[k].activated) {
										//Dont reuser user groups
										if (data.datasetType !="BLOB" && data.selectedUsers[k].userGroup ) {
											continue;
										} else if (data.selectedUsers[k].serviceEndpoint && !data.selectedUsers[k].serviceEndpointValid){ //dont add invalid service end points
											continue;
										}  else {
											vm.selectedUsers.push(data.selectedUsers[k]);
										}
									}
								}
								vm.originalUsers = buildOriginalUserList(data.availableUsers,  vm.selectedUsers);
								var allUsers = vm.originalUsers;
								processUsers(allUsers);

								// update selected attributes list
								userAttrFilterChange();

								//we need to select only active supplier delegates. The previous shared view might have users who are no longer active now, so
								//we should filter them out and retain only those that are active
								vm.selectedSuppliers = [];
								for (var k = 0; k < data.selectedSuppliers.length; k++  ) {
									if (data.selectedSuppliers[k].activated) {
										vm.selectedSuppliers.push(data.selectedSuppliers[k]);
									}
								}
								// update supplier delegate lists
								if (!vm.isExternal && vm.owner.id === vm.identity.id) {
									vm.originalSuppliers = buildOriginalUserList(data.availableSuppliers, vm.selectedSuppliers);
									processSuppliers(vm.originalSuppliers);
								}

								vm.reuseShareViewDisplay  = selection.item.displayName;
							}, function(error) {
								GlobalModal.displayError("An error occurred while retrieving shared view");
							});

						}, function() {
							vm.reuseShareViewDisplay  = '';
						});
					}
				}
		}

		function validateEmail(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		}

		// preview dataset popoup
		function previewDataset(dataset) {
			if (dataset.datasetType == "BLOB") {
				PreviewService.previewDatasetBlobFile(dataset.id, function() {
					GlobalModal.displayError('There was a problem with your preview. Please try again.');
				});
			}
		}
        // Goes to the dataset audit report page
        function openDatasetAuditReport(dataset) {
    		$state.go('activity-report', {
    			datasetId: dataset.id,
    		});
        }

        // Opens the download history for the dataset
        function openDownloadHistory(dataset) {
    		// don't pass allUsers flag at all if not owner (true or false)
    		// to keep out of url
    		var options = {
	    			datasetId: dataset.id,
	    		};
    		if (vm.identity.id === dataset.ownerId || dataset.delegate) options.allUsers = true;

    		$state.go('downloadHistory', options);
        }

     // monitors a download request to perform actual download
    	function pollDownloadProgress(downloadId) {
    		var refreshPromise = null;
    		var downloadStart = new Date().getTime();
    		var notifiedRefreshing = false;
    		var pollingTimeOut = 1000;
    		var pollingBackOff = 1000;

    		function pollingFunc () {
            	DatasetFileMicroservice.getDownloadInfo({id: $stateParams.datasetId, downloadId: downloadId},
    		    		function(result) {
            				var elapsed = new Date().getTime() - downloadStart;

			            	// success, ready to download
            				if (result.status == 'READY') {
            					stopDownloadPolling(refreshPromise);
            					GlobalModal.hideModal();

            					// start actual file download
            					DownloadService.downloadDatasetFile($stateParams.datasetId, downloadId, function() {
            							GlobalModal.displayError('There was a problem with your download. Please try again.');
            						});

            					return;
            				}

            				// failure stop polling
            				else if (result.status == 'ERROR') {
            					GlobalModal.displayError('There was a problem with your download. Please try again.');
            					stopDownloadPolling(refreshPromise);
            					return;
            				}

            				// download has taken longer than 2 sec
            				else if (elapsed > MILLIS_TO_REFRESHING_DISPLAY && elapsed < MILLIS_TO_REFRESHING_TIMEOUT) {
            					if (!notifiedRefreshing) {
            						GlobalModal.displayRefresh('Your download is in process.');
            						notifiedRefreshing = true;
            					}
            				}

            				// download has taken longer than 5 sec
            				else if (elapsed >= MILLIS_TO_REFRESHING_TIMEOUT) {
            			        GlobalModal.displayRefreshHtml('Your download is in process. It will be available on the <a ng-click="vm.navigateToDownloadHistory()" class="white bold">Datasets</a> page when processing is complete.',
            			        		$scope);
            					stopDownloadPolling(refreshPromise);
            					return;
            				}

            				// reschedule polling function
        					stopDownloadPolling(refreshPromise);

        					// increase polling time to back off by 1sec for up to 10sec
        					if (pollingTimeOut < 10000)
        						pollingTimeOut += pollingBackOff;
        		    		refreshPromise = $timeout(pollingFunc, pollingTimeOut);

        		            vm.refreshIntervalPromises.push(refreshPromise);
    		    		},
    		    		function(error) {
    		    			GlobalModal.displayError('There was a problem with your download. Please try again.');
        					stopDownloadPolling(refreshPromise);
    		    		});
            }

    		// poll for completion
    		var refreshPromise = $timeout(pollingFunc, pollingTimeOut);
            vm.refreshIntervalPromises.push(refreshPromise);
    	}

    	// stops polling for a particular download
    	function stopDownloadPolling(refreshPromise) {
			vm.refreshIntervalPromises = vm.refreshIntervalPromises.filter(function(promise) { return promise != refreshPromise; });
			$timeout.cancel(refreshPromise);
    	}


        // initiates a download request
    	function initiateDownload(type) {
    		if (vm.noneSelected) return;
    		// build list of column ids
    		var columnIds = vm.dataset.columns.reduce(function(result, column){
    				if (column.selected) result.push(column.id);
    				return result;
    			},[]);

    		// build options object to send to server
    		var options = {
    			resultMimeType: type == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv',
    		    columnIds: columnIds,
    			name: vm.downloadName,
    			description: vm.downloadDescription,
                previewUserId: vm.previewUser.id,
                viewId: vm.viewId
    		};
    		// send request to server
    		DatasetFileMicroservice.requestPreviewDownload({id: $stateParams.datasetId}, options, function(download) {
    				pollDownloadProgress(download.id);
    			}, function(error) {
    			    if (error.status === 412)
                    GlobalModal.displayError('Please wait for previous download to generate before requesting another');
    			    else
    			        GlobalModal.displayError('Your download request failed');
    			});
                vm.hasPreviewed = true;
    	}

        function downloadPreview(type, user){
            if (allUserAttributeInUse(user)){
                vm.saveView(false, true);
                vm.hasPreviewed = true;
                vm.initiateDownload(type);
            } else {
                GlobalModal.displayError('A user must have their attribute filter values defined to preview a dataset.');
            }
        }

        function changePreviewUser(user){
            vm.previewUser = user;
            vm.hasPreviewed = false;
        }

        function grantAccessToPreviewUser(user){
            if (allUserAttributeInUse(user)){
                selectUser(user);
                GlobalModal.displaySuccess("Added " + user.firstName + " " + user.lastName + " to the access list. " +
                "Please remember to save your changes to share this dataset with " + user.firstName + " " + user.lastName + ".");
            } else {
                GlobalModal.displayError('A user must have their attribute filter values defined in order to grant access to a dataset.');
            }
        }

		function showInfo(){
            $('#showInfoModal').modal('show');
       	};

       function hideModalInfo() {
           $('#showInfoModal').modal('hide');
       	};

		// Functions for advanced column filtering
		function parseIfJson(input) {
			try {
				input = JSON.parse(input);
				input = Array.isArray(input) ? input : [input];
			} catch (e) {
				return [input];
			}
			return input;
		} 

		function clearValues(column) {
			if(column) {
				if(column.filterContentText) delete column.filterContentText;

				if(column.filterContentRangeStart) delete column.filterContentRangeStart;
				if(column.filterContentRangeEnd) delete column.filterContentRangeEnd;

				if(column.filterContentStartDate) delete column.filterContentStartDate;
				if(column.filterContentEndDate) delete column.filterContentEndDate;

				if(column.dateFormatType) delete column.dateFormatType;
				if(column.dateFormatOrder) delete column.dateFormatOrder;
				if(column.dateFormatSeparator) delete column.dateFormatSeparator;
				if(column.dateFormatComma) delete column.dateFormatComma;
				if(column.dateFormatWeek) delete column.dateFormatWeek;

				if(column.timeFormatType) delete column.timeFormatType;
				if(column.timeFormatSeconds) delete column.timeFormatSeconds;
				if(column.timeFormatZone) delete column.timeFormatZone;

				if(column.filterContentType) delete column.filterContentType;
			}	
		}

		function showContentFilter(column) {
            vm.selectedColumn = column;
			clearValues(column);
			var columnFilterValue = vm.selectedColumn.filterContent ? parseIfJson(vm.selectedColumn.filterContent) : [];
			if (columnFilterValue.length > 0) {
				var columnFilterValueSplit = columnFilterValue[0].split(":");
				switch(columnFilterValueSplit[0]) {
					case "rangeStart":
						vm.selectedColumn.columnFilters = [ columnFilterValue[0].split(":")[1] + " - " + columnFilterValue[1].split(":")[1] ];
						vm.selectedColumn.filterContentRangeStart = columnFilterValue[0].split(":")[1];
						vm.selectedColumn.filterContentRangeEnd = columnFilterValue[1].split(":")[1];
						break;
					case "startDate":
						vm.selectedColumn.columnFilters = [ formatDatePreview(columnFilterValue[2].split("::")[1], columnFilterValue[0].split("::")[1]) + " - " + formatDatePreview(columnFilterValue[2].split("::")[1], columnFilterValue[1].split("::")[1]) ];
						vm.selectedColumn.filterStartDate = formatDate(columnFilterValue[0].split("::")[1]);
						vm.selectedColumn.filterEndDate = formatDate(columnFilterValue[1].split("::")[1]);
						vm.selectedColumn.filterDateFormat = columnFilterValue[2].split("::")[1];
						vm.selectedColumn.filterDateFormatPreview = formatDatePreview(vm.selectedColumn.filterDateFormat);
						break;
					default:
						vm.selectedColumn.columnFilters = columnFilterValue;
				}
			} else {
				vm.selectedColumn.columnFilters = columnFilterValue;
			}			
            $('#contentFilterPopover').modal('show');
        };
        
        function hideContentFilter(column) {
			clearValues(column);
			vm.selectedColumn = null;
            $('#contentFilterPopover').modal('hide');
        };

		function changeFilterContentType(column) {
			clearValues(column);
		};

		function addContentFilter(target) {
			var filterValue = target.trim();
			var columnFiltersArray = parseIfJson(vm.selectedColumn.filterContent);
			if (filterValue && !vm.selectedColumn.columnFilters.includes(filterValue)){
				if (Array.isArray(columnFiltersArray) && columnFiltersArray.length >= 2 && (columnFiltersArray[0].includes("rangeStart") || columnFiltersArray[0].includes("startDate"))) {
					vm.selectedColumn.columnFilters = [filterValue];
				} else {
					vm.selectedColumn.columnFilters.push(filterValue);
				}
				vm.selectedColumn.filterContent = JSON.stringify(vm.selectedColumn.columnFilters);
				vm.selectedColumn.filterContentText = "";
			}
		}

		function addContentFilterRange(targetStart, targetEnd) {
			var filterRangeStart = targetStart.trim();
			var filterRangeEnd = targetEnd.trim();
			if (filterRangeStart && filterRangeStart){
				vm.selectedColumn.columnFilters = [filterRangeStart + " - " + filterRangeEnd]
				vm.selectedColumn.filterContent = JSON.stringify(["rangeStart:" + filterRangeStart, "rangeEnd:" + filterRangeEnd]);
				vm.selectedColumn.filterContentRangeStart = "";
				vm.selectedColumn.filterContentRangeEnd = "";
			}
		}

		function addContentFilterBoolean(value) {
			vm.selectedColumn.columnFilters = [value];
			vm.selectedColumn.filterContent = JSON.stringify([value]);
		}

		function convertDate(date) {
			date = new Date(date).toJSON()
			return date
		}

		function formatDate(date) {
			date = new Date(date)
			date = date.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) + " " + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit'});
			return date;
		}

		function openContentFilterStartDate(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.addStartDateOpen = true;
            vm.addEndDateOpen = false;
        };

		function openContentFilterEndDate(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.addStartDateOpen = false;
            vm.addEndDateOpen = true;
        };

		function addContentFilterStartDate(startDate) {
			if (startDate) vm.selectedColumn.filterStartDate = formatDate(startDate);
		}

		function addContentFilterEndDate(endDate) {
			if (endDate) vm.selectedColumn.filterEndDate = formatDate(endDate);
		}

		function applySeparator(formatString, separator) {
			return separator ? formatString.replace(/-/g, separator) : formatString;
		};

		function appendWeek(formatString, type, comma) {
			return type === "text2" ? (comma ? "%W, " + formatString : "%W " + formatString) : (comma ? "%a, " + formatString : "%a " + formatString);
		};

		function formatDatePreview(formatString, dateString) { 
			var previewDate = dateString ? new Date(dateString) : new Date();
			var weekDayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			var monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			var datePreviewDic = {
				'%a': weekDayList[previewDate.getDay()].substring(0, 3), // 'Sat',
				'%W': weekDayList[previewDate.getDay()], // 'Saturday',
				'%b': monthList[previewDate.getMonth()].substring(0, 3), // 'Jan',
				'%M': monthList[previewDate.getMonth()], // 'January',
				'%m': previewDate.toISOString().split('-')[1], // '01',
				'%c': previewDate.getMonth() + 1, // '1',
				'%d': previewDate.toDateString().split(' ')[2], // '02',
				'%e': previewDate.getDate(), // '2',
				'%Y': previewDate.getFullYear(), // '2021',
				'%y': previewDate.getFullYear().toString().substring(2, 4), // '21',
				'%r': previewDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second:'2-digit' }), // '01:00:00 PM', 
				'%T': previewDate.toTimeString().split(' ')[0], // '13:00:00',
				'%p': previewDate.toLocaleTimeString('en-US').split(' ')[1], // 'PM', 
				'%H': previewDate.toTimeString().split(':')[0], // '13', // 24-hour, 2-digit
				'%h': previewDate.toLocaleTimeString('en-US', { hour: '2-digit' }).split(' ')[0], // '01', // 12 hour, 2-digit
				'%k': previewDate.getHours(), // '1', // 24 hour, 1-digit
				'%l': previewDate.toLocaleTimeString('en-US').split(':')[0], // '1', // 12 hour, 1-digit
				'%i': previewDate.toTimeString().split(':')[1], // '00', // minutes
				'%s': previewDate.toTimeString().split(':')[2].split(' ')[0], // '00', // seconds
				'%S': previewDate.toTimeString().split(':')[2].split(' ')[0], // '00', // seconds
				'%f': '.000000'
			}
			return formatString.replace(/%[a-z]/gi, function (m) { return datePreviewDic[m] } );
		};

		function addContentFilterDateFormat(type, order, separator, comma, week, time, seconds, timezone) {
			var filterContentDateFormat;

			if (type === 'iso') {
				filterContentDateFormat = "%Y-%m-%dT%H:%i:%s.000Z";
			} else {
				if (order === 'year') {
					switch(type) {
						case "num2":
							filterContentDateFormat = applySeparator("%Y-%m-%d", separator);
							break;
						case "num1":
							filterContentDateFormat = applySeparator("%Y-%c-%e", separator);
							break;
						case "text2":
							filterContentDateFormat = comma ? "%Y, %M %d" : "%Y %M %d";
							break;
						case "text1":
							filterContentDateFormat = comma ? "%Y, %b %d" : "%Y %b %d";
							break;
					}
				} else {
					switch(type) {
						case "num2":
							filterContentDateFormat = applySeparator("%m-%d-%Y", separator);
							break;
						case "num1":
							filterContentDateFormat = applySeparator("%c-%e-%Y", separator);
							break;
						case "text2":
							filterContentDateFormat = comma ? "%M %d, %Y" : "%M %d %Y";
							if (week) filterContentDateFormat = appendWeek(filterContentDateFormat, type, comma);
							break;
						case "text1":
							filterContentDateFormat = comma ? "%b %d, %Y" : "%b %d %Y";
							if (week) filterContentDateFormat = appendWeek(filterContentDateFormat, type, comma);
							break;
						default:
							filterContentDateFormat = "";
					}
				}
				switch(time) {
					case 'time1':
						filterContentDateFormat = filterContentDateFormat + (comma ? ', ' : ' ') + (seconds ? '%h:%i:%s %p' : '%h:%i %p');
						break;
					case 'time2':
						filterContentDateFormat = filterContentDateFormat + (comma ? ', ' : ' ') + (seconds ? '%H:%i:%s' : '%H:%i');
						break;
					case 'time3':
						filterContentDateFormat = filterContentDateFormat + (comma ? ', ' : ' ') + (seconds ? '%l:%i:%s %p' : '%l:%i %p');
						break;
					case 'time4':
						filterContentDateFormat = filterContentDateFormat + (comma ? ', ' : ' ') + (seconds ? '%k:%i:%s' : '%k:%i');
						break;
				}

				switch(timezone) {
					case 'full':
						filterContentDateFormat = filterContentDateFormat + ' Coordinated Universal Time';
						break;
					case 'short':
						filterContentDateFormat = filterContentDateFormat + ' UTC';
						break;
					case 'num':
						filterContentDateFormat = filterContentDateFormat + ' GMT+0000';
						break;
					case 'num2':
						filterContentDateFormat = filterContentDateFormat + ' GMT+00:00';
						break;
					case 'long':
						filterContentDateFormat = filterContentDateFormat + ' GMT+0000 (Coordinated Universal Time)';
						break;
				}
			}
			vm.selectedColumn.filterDateFormat = filterContentDateFormat;
			vm.selectedColumn.filterDateFormatPreview = formatDatePreview(filterContentDateFormat);
		};

		function updateCustomDateFormat(dateFormat) {
			vm.selectedColumn.filterDateFormat = dateFormat;
			vm.selectedColumn.filterDateFormatPreview = formatDatePreview(dateFormat);
		};

		function toggleDateGuide() {
			vm.showDateGuide = !vm.showDateGuide;
		};

		function addContentFilterDate(column) {
			if ((vm.selectedColumn.filterStartDate && vm.selectedColumn.filterEndDate) && (new Date(vm.selectedColumn.filterStartDate) > new Date(vm.selectedColumn.filterEndDate))) {
				GlobalModal.displayError("Start date must occur before end date");
				return;
			} else if (vm.selectedColumn.filterDateFormat && vm.selectedColumn.filterStartDate && vm.selectedColumn.filterEndDate) {
				var filterStartDate = convertDate(vm.selectedColumn.filterStartDate);
				var filterEndDate = convertDate(vm.selectedColumn.filterEndDate);
				var dateTimeFormat = vm.selectedColumn.filterDateFormat;
				vm.selectedColumn.columnFilters = [formatDatePreview(dateTimeFormat, filterStartDate) + " - " + formatDatePreview(dateTimeFormat, filterEndDate)];
				vm.selectedColumn.filterContent = JSON.stringify(["startDate::" + filterStartDate, "endDate::" + filterEndDate, "dateFormat::" + dateTimeFormat]);
			} else {
				GlobalModal.displayError("Please enter start date, end date, and date format");
				return;
			}
		};

		function removeContentFilter(value) {
			if (vm.selectedColumn.columnFilters.length > 1) {
				vm.selectedColumn.columnFilters = vm.selectedColumn.columnFilters.filter(function(filterValue) { return filterValue !== value });
				vm.selectedColumn.filterContent = JSON.stringify(vm.selectedColumn.columnFilters);
			} else {
				vm.selectedColumn.columnFilters = [];
				vm.selectedColumn.filterContent = "";
			}
		};
    };
})();
