(function() {
'use strict';

angular
.module('squirrelboxApp')
.controller('NavbarController', NavbarController);

NavbarController.$inject = ['$scope', '$state', '$window', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$rootScope', 'AuthServerProvider', 'DownloadService', 'Logo', 'UserAgreement', 'ExternalEntity'];

function NavbarController ( $scope, $state, $window, Auth, Principal, ProfileService, LoginService, $rootScope, AuthServerProvider, DownloadService, Logo, UserAgreement, ExternalEntity) {
    var vm = this;

    vm.isAuthenticated = Principal.isAuthenticated;
    vm.isAdmin = false;
    vm.identity = null;
    vm.unsignedAgreements = true;


    $rootScope.$on('authenticationSuccess', checkAuthentication);
    checkAuthentication();



    ProfileService.getProfileInfo().then(function(response) {
        vm.inProduction = response.inProduction;
    });

    vm.help = help;
    vm.dashboard = dashboard;
    vm.upload = upload;
    vm.userAdmin = userAdmin;
    vm.setupSysAdmin = setupSysAdmin;
    vm.setupSysAdminSamlIdp = setupSysAdminSamlIdp;
    vm.organizationSetup = organizationSetup;
    vm.reports = reports;
    vm.orgReports = orgReports;
    vm.datasets = datasets;
    vm.userAccount = userAccount;
    vm.userGroups = userGroups;
    vm.notifications = notifications;
    vm.transfers = transfers;
    vm.logout = logout;
    vm.userAttributes = userAttributes;
    vm.externalEntities = externalEntities;
    vm.tags = tags;
    vm.contentTypes = contentTypes;
    vm.entityTypes = entityTypes;
    vm.logoIconUrl = Logo.logoIconUrl;
    vm.loginImage = Logo.loginImage;
    vm.externalAssociations = externalAssociations;
    vm.announcements = announcements;

    vm.entityReport = entityReport;

    vm.dcFlows = dcFlows;
    vm.dcCifsCredentials = dcCifsCredentials;
    vm.dcSftpClientCredentials = dcSftpClientCredentials;
    vm.dcSftpHostCredentials = dcSftpHostCredentials;
    vm.dcS3Credentials = dcS3Credentials;
    vm.dcPgpDecriptionKeys = dcPgpDecriptionKeys;

    vm.attestationReport = attestationReport;
    vm.attestations = attestations;
    vm.userImport = userImport;

    $rootScope.$on('loginImageUpdate', function() {
        vm.loginImage = Logo.loginImage;
    });
    $rootScope.$on('logoIconUpdate', function() {
        vm.logoIconUrl = Logo.logoIconUrl;
    });
    vm.agreementTypes = agreementTypes;
    vm.agreements = agreements;


    function help() {
        DownloadService.downloadHelpFile();
    }


    function dashboard() {

        if (vm.isAdmin)
            $state.go('sysadmin-home');
        else
            $state.go('home');
    }
    function upload() {

        $state.go('upload');
    }
    function userAdmin() {

        $state.go('accountAdmin');
    }
    function userGroups() {

        $state.go('user-groups');
    }
    function setupSysAdmin() {

        $state.go('sysadmin-setup');
    }
    function setupSysAdminSamlIdp() {
        $state.go('sysadmin-saml');
    }
    function organizationSetup(){

        $state.go('org-setup');
    }
    function reports() {

        $state.go('sysadmin-reports');
    }
    function orgReports() {
        $state.go('activity-report', {
            datasetId: undefined,
        });
    }
    function datasets() {
        $state.go('datasets');
    }
    function userAccount() {
        $state.go('userAccount');
    }
    function notifications() {
        $state.go('consumer-reports');
    }
    function transfers() {
        $state.go('transfer-report');
    }
    function tags() {
        $state.go('tag-admin');
    }
    function contentTypes() {
        $state.go('contentTypesAdmin');
    }
    function entityTypes() {
        $state.go('entityTypesAdmin');
    }
    function agreementTypes() {
        $state.go('agreementTypesAdmin');
    }
    function agreements() {
        $state.go('agreement-list');
    }
    function entityReport() {
    	$state.go('entity-report');
    }

    function dcFlows() {
        $state.go('dc-flows');
    }
    function dcCifsCredentials() {
        $state.go('cifs-credentials');
    }
    function dcSftpClientCredentials() {
        $state.go('sftp-client-credentials');
    }
    function dcSftpHostCredentials() {
        $state.go('sftp-host-credentials');
    }
    function dcS3Credentials() {
        $state.go('s3-credentials');
    }
    function dcPgpDecriptionKeys() {
        $state.go('pgp-decrypt-keys');
    }
    function userImport() {
        $state.go('userImportAdmin');
    }


    function attestationReport() {

		$state.go('attestation-report', {
			attestationId: vm.attestationId,
		});

    }

    function attestations() {
		$state.go('attestation');
    }

    function logout() {
        Auth.logout();
        vm.unsignedAgreements = true;
        UserAgreement.reset();
    }
    function userAttributes() {

        $state.go('user-attributes');
    }
    function externalEntities() {

        $state.go('external-entities');
    }
    function externalAssociations() {

        $state.go('external-associations');
    }

    function announcements() {
        $state.go('announcements');
    }


    $scope.isActive = function(location) {
        var locsplit = $window.location.href.split("/");
        var curlocation = locsplit.pop()
        if (!isNaN(parseInt(curlocation)))
            curlocation = locsplit.pop()

        if (Array.isArray(location)) {
            for (var i=0; i<location.length; i++)
                if (location[i] === curlocation)
                    return true;
        }
        else if (location === curlocation) {
            return true;
        }

        return false;
    }

    $scope.isANumber = function(){
        var num = $window.location.href.split("/").pop();
        return  !isNaN(parseInt(num));
    }

    function checkAuthentication() {
        Principal.identity()
        .then(function(identity) {
            vm.identity = identity;
            vm.isAdmin = Principal.hasAuthority('ROLE_ADMIN', identity);
            vm.isOrgAdmin = Principal.hasAuthority('ROLE_ORG_ADMIN', identity);
            vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
            vm.isConsumer = Principal.hasAuthority('ROLE_CONSUMER', identity);
            vm.isInternal = Principal.hasAuthority('ROLE_INTERNAL', identity);
            vm.isExternal = Principal.hasAuthority('ROLE_EXTERNAL', identity);

            if (identity) {
                UserAgreement.checkForUnsignedAgreements(function (response) {
                    vm.unsignedAgreements = response.count > 0;
                })

                vm.dcSupport = identity.dcSupport;
            }
            else {
                vm.dcSupprt = false;
            }
        });
    }


}
})();
