(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('sftp-client-credentials', {
			parent: 'datacenter',
			url: '/sftp-client-credentials',
			data: {
				authorities: [
					'ROLE_ORG_ADMIN'
				]
			},
			views: {
				'content@': {
					templateUrl: 'app/datacenter/sftp-client-credentials/sftp-client-credentials.html',
					controller: 'SftpClientCredentialsController',
					controllerAs: 'vm'
				}
			}
		});
	}
})();
