(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SquirrelBoxLoginLandingController', SquirrelBoxLoginLandingController);

    SquirrelBoxLoginLandingController.$inject = ['$rootScope', '$state', '$stateParams', '$timeout', 'Auth', 'AuthServerProvider'];

    function SquirrelBoxLoginLandingController ($rootScope, $state, $stateParams, $timeout, Auth, AuthServerProvider) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.spInitiatedLogin = false;

        Auth.logout(false);

        if ($stateParams.error)
        		vm.errorMsg = "An error occurred attempting to sign in via SAML authentication: " + $stateParams.error;
        else
        		vm.errorMsg = "Your Organization uses SAML for authentication.  Please login through the appropriate channel.";

		// redirect to proper login for current organization
		AuthServerProvider.getAuthenticationMethod()
			.then(function(method) {
				// check if auth is disabled
				if (method.authenticationType == 'SB') {
		        		$timeout(function() {
		            		$state.go('sb-login');
		        		});
				}

				// IDP login is supplied
				else if (method.orgIdpSigninUrl && method.orgIdpSigninUrl.length > 0 && method.orgEmailToRedirect && method.orgEmailToRedirect.length > 0) {
					vm.orgIdpSigninUrl = method.orgIdpSigninUrl;
				}

				// check if SP login is allowed
				else if (method.authenticationType == 'SAML' && method.spInitiatedLoginAllowed) {
					vm.spInitiatedLogin = true;
				}
			});
    }
})();
