(function() {
	'use strict';

	angular
	.module('squirrelboxApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('userAccount', {
			parent: 'account',
			url: '/myAccount',
			data: {
				authorities: [
					'ROLE_USER'
				]
			},
			views: {
				'content@': {
					templateUrl: 'app/account/user/user-account.html',
					controller: 'UserAccountController',
					controllerAs: 'vm'
				}
			}
		});
	}
})();
