(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('Attestation', Attestation);

    Attestation.$inject = ['$resource', 'DateUtils'];

    function Attestation($resource, DateUtils) {
        var resourceUrl =  'api/extuserattest';

        return $resource(resourceUrl, {}, {
        	
            'getAll': { 
            	url: resourceUrl,
            	method: 'GET' , 
            	isArray: true },
           
            'getExtUserAttestById': {
	        		url: resourceUrl + '/:attestationId',
	        		method: 'GET' 
	        	},
	        
            'updateExtUserAttest': {
            		url: resourceUrl + '/update/:attestationId',
            		method: 'PUT' 
            	},
            	
            	'createExtUserAttest': {
             		url: resourceUrl + '/create',
             		method: 'POST' 
            },
            
            'startExtUserAttest': {
		        		url: resourceUrl + '/start/:attestationId',
		        		method: 'PUT' 
             }
           
        });
    }
})();
