(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('datasets', {
            parent: 'app',
            url: '/datasets',
            data: {
                authorities: [
                	'ROLE_USER',
                	'ROLE_CONSUMER',
                	'ROLE_SUPPLIER',
                ]
            },
            params: {
                confirmSuccess: false,
                query: null,
            },
            views: {
                'content@': {
                    templateUrl: 'app/datasets/datasets.html',
                    controller: 'DatasetsController',
                    controllerAs: 'vm'
                },
                'grid@datasets': {
                    templateUrl: 'app/datasets/datasets-grid.html',
                },
                'list@datasets': {
                    templateUrl: 'app/datasets/datasets-list.html',
                },
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('dataset');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
