(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('Dataset', Dataset);

    Dataset.$inject = ['$resource', 'DateUtils'];

    function Dataset ($resource, DateUtils) {
        var resourceUrl =  'api/datasets/:id';

        return $resource(resourceUrl, {}, {
            'delete': { method: 'DELETE' },
            'query': { method: 'GET', isArray: true },
            'refresh': { 
                url: 'api/datasets/refresh',
                method: 'GET', 
                isArray: true,
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationTimeStamp = DateUtils.convertDateTimeFromServer(data.creationTimeStamp);
                    }
                    return data;
                }
            },
            'getShareInfo': {
                url: resourceUrl + '/share',
                method: 'GET' 
            },
            'shareDataset': {
                url: resourceUrl + '/share/:viewId/share',
                method: 'PUT' 
            },
            'getShareMatchingAvailableUsers': {
                url: resourceUrl + '/share/users',
                method: 'GET' ,
                isArray: true
            },
            'getMatchingAvailableShareViews': {
                url: resourceUrl + '/share/views',
                method: 'GET' ,
                isArray: true
            },            
            'getShareMatchingAvailableSuppliers': {
                url: resourceUrl + '/share/suppliers',
                method: 'GET' ,
                isArray: true
            },
            'getShareView': {
                url: resourceUrl + '/share/:viewId',
                method: 'GET' 
            },
            'createShareView': {
                url: resourceUrl + '/share',
                method: 'POST',
            },
            'updateShareView': {
                url: resourceUrl + '/share/:viewId',
                method: 'PUT' 
            },
            'deleteShareView': {
                url: resourceUrl + '/share/:viewId',
                method: 'DELETE' 
            },
            'getDownloadInfo': {
                url: resourceUrl + '/download',
                method: 'GET' 
            },
            'getDownloadView': {
                url: resourceUrl + '/download/:viewId',
                method: 'GET' 
            },
            'createDownloadView': {
                url: resourceUrl + '/download',
                method: 'POST',
            },
            'updateDownloadView': {
                url: resourceUrl + '/download/:viewId',
                method: 'PUT',
            },
            'deleteDownloadView': {
                url: resourceUrl + '/download/:viewId',
                method: 'DELETE' 
            },
            'getDownloadHistory': {
                url: resourceUrl + '/downloadHistory',
                method: 'GET' 
            },
            'deleteDownloadHistoryEntry': {
                url: resourceUrl + '/downloadHistory/:downloadId',
                method: 'DELETE' 
            },
            'changeDatasetOwner': {
                url: resourceUrl + '/changeOwner',
                method: 'PUT' 
            },
            'queryAutoSuggest': {
                url: 'api/datasets/autosuggest',
                method: 'GET' 
            },
            'starAutoSuggestQuery': {
                url: 'api/datasets/autosuggest/:id/star',
                method: 'PUT' 
            },
        });
    }
})();
