(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$localStorage', '$state', '$rootScope', 'Principal', 'ExternalEntity', 'Announcement'];

    function HomeController($localStorage, $state, $rootScope, Principal, ExternalEntity, Announcement) {
        var vm = this;

        vm.isOrgAdmin = false;
        vm.isSupplier = false;
        vm.isConsumer = false;
        vm.attestationPending = false;
        vm.attestationId = 0;
        vm.attestationReport = attestationReport;
        vm.isInternal = false;
        vm.showInfo = showInfo;
        vm.hideModalInfo = hideModalInfo;

        vm.closeBanner = closeBanner;

        $rootScope.notificationExpanded = $localStorage.notificationExpanded || false;
        $rootScope.uploadExpanded = $localStorage.uploadExpanded || true;

        $rootScope.$on('authenticationSuccess', checkAuthentication);
        checkAuthentication();
        getPendingAttestation();
        getAnnouncements();

        function checkAuthentication() {
            Principal.identity()
                .then(function(identity) {
                    if (Principal.hasAuthority('ROLE_ADMIN', identity)) {
                        $state.go('sysadmin-home');
                    }

                    vm.isOrgAdmin = Principal.hasAuthority('ROLE_ORG_ADMIN', identity);
                    vm.isSupplier = Principal.hasAuthority('ROLE_SUPPLIER', identity);
                    vm.isConsumer = Principal.hasAuthority('ROLE_CONSUMER', identity);

                });
        }

        function closeBanner(announcement) {
            Announcement.dismiss(announcement);
            let index = vm.announcements.indexOf(announcement);
            vm.announcements.splice(index, 1);
        }

        function getAnnouncements() {
            Announcement.getActive({id: 0}, function(announcements) {
                for (var idx=0; idx<announcements.length; idx++) {
                    var announcement = announcements[idx];
                    announcement.text = announcement.text.replaceAll("\n", "<br />");
                }
                vm.announcements = announcements;
            });
        }

        function attestationReport() {

            $state.go('attestation-report', {
                attestationId: vm.attestationId,
            });

        }

        //check if ext user attestation is pending for this user
        function getPendingAttestation() {

            ExternalEntity.getPendingAttestation(function(response) {
                vm.attestationPending = response.attestationPending;
                vm.attestationId = response.attestationId;


            })

        }

        function showInfo() {
            $('#showInfoModal').modal('show');
        }

        function hideModalInfo() {
            $('#showInfoModal').modal('hide');
        }
    }
})();
