(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ActivityReportController', ActivityReportController);

    ActivityReportController.$inject = ['$scope', '$state', '$stateParams', '$window', 'Principal', 'AuditsService', 'ParseLinks', 'Dataset', 'GlobalModal', 'DownloadService'];

    function ActivityReportController ($scope, $state, $stateParams, $window, Principal, AuditsService, ParseLinks, Dataset, GlobalModal, DownloadService) {
        var vm = this;

        vm.audits = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.reload = reload;
        vm.query = query;
        vm.pageChanged = pageChanged;
        vm.openDataset = openDataset;
        vm.openDownloadHistory = openDownloadHistory;
        vm.page = 1;
        vm.totalItems = null;
        vm.itemsPerPage = 20;
        vm.showAuditDetails = showAuditDetails;
        vm.hideAuditDetails = hideAuditDetails;
        
        vm.openSearchStartDateCalendar = openSearchStartDateCalendar;
        vm.openSearchEndDateCalendar = openSearchEndDateCalendar;
        vm.toggleFilterOptions = toggleFilterOptions;
        vm.filter = filter;
        vm.clearFilter = clearFilter;
        
        vm.searchStartDateOpen = false;
        vm.searchEndDateOpen = false;
        // Show/hide filter toggle
        vm.filterToggle = false;
        // Should the filter ever be visible at all?
        vm.filterHidden = $stateParams.datasetId != null;
        
        vm.filterParams = {
        		user: "",
        		activity: "",
        		startDate: null,
        		endDate: null,
        };
        
        vm.userChanged = userChanged;
        vm.activityChanged = activityChanged;
        vm.startDateChanged = startDateChanged;
        vm.endDateChanged = endDateChanged;
        
        vm.activityReportSearch = {};
        
        
        $scope.sort = 'auditEventDate';
        $scope.reverse = true;
        
        // obtain identity for filtering access
        Principal.identity().then(function(identity) {
        	vm.identity = identity;
        });

        vm.query();

        function query () {
        	// build sort logic based on user's column heading click
        	var sort;
        	if (Array.isArray($scope.sort)) {
        		sort = [];
        		for (var i=0; i<$scope.sort.length; i++) {
            		sort.push($scope.sort[i] + "," + ($scope.reverse ? 'desc' : 'asc'));
        		}
        	}
        	else {
        		sort = $scope.sort + "," + ($scope.reverse ? 'desc' : 'asc');
        	}
        	        	
    		AuditsService.query({
        			datasetId: $stateParams.datasetId,
        			page: vm.page-1,
        			size: vm.itemsPerPage,
        			user: vm.filterParams.user,
        			activity: vm.filterParams.activity,
        			startDate: vm.filterParams.startDate,
        			endDate: vm.filterParams.endDate,
        			sort: sort
				},
				function(result, headers){
	    			vm.audits = result;
	    			vm.links = ParseLinks.parse(headers('link'));
	    			vm.totalItems = headers('X-Total-Count');
				}
			);
        }

        function loadPage (page) {
            vm.page = page;
            vm.query();
        }
        
        function reload() {
        	loadPage(vm.page);
        }
        
        function pageChanged(){
        	$window.scrollTo(0,0);
        }
        
        // open dataset based on ownership
        function openDataset(datasetId) {
        	Dataset.get({ id: datasetId }, function(dataset) {
        		
        		//if the dataset is purged, show a messaage, else continue
        		if ( dataset.purgeTimeStamp || dataset.status === 'PURGED') {
        			GlobalModal.displayError('Unable to open. This Dataset was purged on '  + moment(dataset.purgeTimeStamp).format( " YYYY-MM-DD")) ;
        		} else {
	        		// only open for owner
	        		if (dataset.ownerId === vm.identity.id) {
	                	if (dataset.status === 'NEW') {
	                		$state.go('upload-configure', {
	                			datasetId: dataset.id,
	                		});
	                	}
	                	else if (dataset.status === 'READY' ||dataset.status === 'SHARED') {
	                		$state.go('share', {
	                			datasetId: dataset.id,
	                		});
	                	}
	        		}
	        		
	        		// not owner
	        		else {
	        			
	        			// check if download is assigned to user
	                	Dataset.getDownloadInfo({id: datasetId}, function(data) {
	                		
	                		// if blob, download file
	                    	if (dataset.datasetType == "BLOB") {
	                			DownloadService.downloadDatasetBlobFile(dataset.id, function() {
	            					GlobalModal.displayError('There was a problem with your download. Please try again.');
	            				});
	                    	}
	                    	
	                    	// if table, go to download screen
	                    	else {
	            	    		$state.go('download', {
	            	    			datasetId: dataset.id,
	            	    		});
	                    	}
	                	}, function() {
	                		GlobalModal.displayError('Unable to open. You are not the supplier or a consumer of this dataset.');
	                	});
	        		}
        		}
        		
        	}, function (response) {
        		if (response.status == 404)
        			GlobalModal.displayError('Dataset was not found.');
        		else
        			GlobalModal.displayError('Unable to open the requested dataset. ');
        	});
        }
        
        function openDownloadHistory(datasetId) {
        	Dataset.get({ id: datasetId }, function(dataset) {
        		
        		// check dataset status
            	if (dataset.status === 'NEW') {
            		GlobalModal.displayError('Dataset is not ready for downloading yet.')
            	}
        		
        		else {
            		$state.go('downloadHistory', {
            			datasetId: datasetId,
            			allUsers: true
            		});
        		}
        		
        	}, function (response) {
        		if (response.status == 404)
        			GlobalModal.displayError('Dataset was not found.');
        		else
        			GlobalModal.displayError('Unable to open the requested dataset. ');
        	});
        }
        
        function showAuditDetails(audit) {
            vm.selectedAudit = null;
            
            AuditsService.get({id: audit.id}, function(audit) {
                vm.selectedAudit = audit;
                vm.selectedAudit.dataKeys = Object.keys(audit.data).sort();
                $('#auditDetailsPopover').modal('show');
            });
        }
        
        function hideAuditDetails() {
            $('#auditDetailsPopover').modal('hide');
        }
        
     	// invoked when calendar button is pressed
        function openSearchStartDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.searchStartDateOpen = true;
            vm.searchEndDateOpen = false;
        }
        
        function openSearchEndDateCalendar(e) {
            e.preventDefault();
            e.stopPropagation();

            vm.searchEndDateOpen = true;
            vm.searchStartDateOpen = false;
        }
        
        function toggleFilterOptions(){
        	vm.filterToggle = !vm.filterToggle;
        }
        
        function filter(skipValidation){
        	if (!skipValidation){
	        	if (!vm.activityReportSearchForm.$valid){
	        		GlobalModal.displayError("Invalid data");
	        		return;
	        	}
	        	
	        	if (vm.filterParams.user.trim() != "" || vm.filterParams.activity.trim() != ""  ) {
	        		
	        	 	if ( !vm.filterParams.startDate || !vm.filterParams.endDate) {
	        	 		GlobalModal.displayError("Start Date & End Date are required.");
		        		return;
	        	 	}
	        	}
	        	
	        	if ((vm.filterParams.startDate && vm.filterParams.endDate) && vm.filterParams.startDate > vm.filterParams.endDate){
	        		GlobalModal.displayError("Start date must occur before end date");
	        		return;
	        	}
        	}
        	vm.page = 1;
        	vm.reload();
        }
        
        function clearFilter(){
        	
        	// Reset view
        	vm.activityReportSearch.userSearch = "";
        	vm.activityReportSearch.activitySearch = "";
        	vm.activityReportSearch.searchStartDate = "";
        	vm.activityReportSearch.searchEndDate = "";
        	
        	// Reset model
        	vm.filterParams.user = "";
        	vm.filterParams.activity = "";
        	vm.filterParams.startDate = null;
        	vm.filterParams.endDate = null;
        	
        	// Rerun filter
        	filter(true)	
        }
        
        function userChanged(id){
        	vm.filterParams.user = id;
        }
        
        function activityChanged(id){
        	vm.filterParams.activity = id;
        }
        
        function startDateChanged(id){
			var idStartDate = new Date(Date.UTC(id.getFullYear(), id.getMonth(), id.getDate(), id.getHours(), id.getMinutes()));
        	if (id != null){
        		vm.filterParams.startDate = idStartDate;
        	}
        }
        
        function endDateChanged(id){
			var idEndDate = new Date(Date.UTC(id.getFullYear(), id.getMonth(), id.getDate(), id.getHours(), id.getMinutes() + 1));
        	if (id != null){
        		vm.filterParams.endDate = idEndDate;
        	}
        }
    }

})();
