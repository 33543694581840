(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .directive('datasetPreviewChosen', datasetPreviewChosen);

    datasetPreviewChosen.$inject = ['$timeout'];

    function datasetPreviewChosen ($timeout) {
    	return {
    	    link: function(scope, element, attr) {
    	        var select = $(element);

    	        // initialize chosen select control
    	        $timeout(function() {
    	    	    select.chosen({
    	    	        disable_search_threshold: 10,
    	    	        width: attr.width || "400px"
    	    	    });
    	        });

     	       // notify control if a new attribute is added
    	        scope.$on('datasetPreviewChosen', function() {
        	        $timeout(function() {
        	        	select.trigger('chosen:updated');
        	        })
    	        });

    	    }
    	}
    }
})();
