(function() {
    'use strict';
    angular
        .module('squirrelboxApp')
        .factory('DataCenterFlow', DataCenterFlow);

    DataCenterFlow.$inject = ['$resource', 'DateUtils'];

    function DataCenterFlow ($resource, DateUtils) {
        var resourceUrl =  'api/datacenter/flow/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                url: 'api/datacenter/flow/query',
                method: 'GET',
                isArray: true
            },
            'get': { method: 'GET' },
            'create': { method: 'POST' },
            'update': { method: 'PUT' },
        });
    }
})();
