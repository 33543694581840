(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('SysAdminSetupController', SysAdminSetupController);

    SysAdminSetupController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'SysAdmin', 'GlobalModal', 'orderByFilter', 'AuthServerProvider'];

    function SysAdminSetupController ($scope, $rootScope, $state, $timeout, SysAdmin, GlobalModal, orderBy, AuthServerProvider) {
        var vm = this;
        vm.addSuccess = false;
        vm.addOrganization = addOrganization;
        vm.loadOrganizations = loadOrganizations;
        vm.changeOrgStatus = changeOrgStatus;
        vm.resendInvitation = resendInvitation ;
        vm.setAddForm = setAddForm ;
        vm.deleteOrg = deleteOrg ;
        vm.editNewOrg = editNewOrg;
        vm.showForms = showForms;
        vm.editOrg = editOrg;
        vm.resetDropzone = resetDropzone;
        vm.removeHelpFile = removeHelpFile;
        vm.removeLogoImageFile = removeLogoImageFile;
        vm.orgDetails = null;
        vm.organizationList = null;

        vm.activeForm = false;
    	vm.newForm = false;
    	vm.reverse = false;
    	vm.selectedindex=0;
    	vm.sort = sort;

    	$scope.$on('$viewContentLoaded', function() {
    		resetDropzone();
    	});

    	SysAdmin.loadOrganizations().then(function(response){
			var orgList = response.data;
			vm.organizationList = orderBy(orgList, 'orgName',vm.reverse);
			showForms(vm.organizationList[0],0);
			 })

    	// fetch available saml idps for administration
    	SysAdmin.getSamlIdps()
    		.then(function(response) {
    			vm.samlIdps = response.data;
    			notifyIdpChange();
    		});

    	function notifyIdpChange() {
    		$timeout(function() {
    			$scope.$broadcast('samlIdpsChange');
    		});
    	}


        function addOrganization() {
        	if(vm.orgname==null || vm.orgname=="" || vm.orgemail==null || vm.orgemail==""||vm.orgdomain==null || vm.orgdomain==""){
        		vm.emptyField=true;
        		return;
        		}
            vm.orgDetails = {
            		orgName: vm.orgname,
                	orgEmail: vm.orgemail,
                	orgDomain: vm.orgdomain,
            }
            SysAdmin.registerOrganization(vm.orgDetails).then(function (response) {
            	//reset the form
            	var addedOrg = response.data;
            	vm.orgname="";
                vm.orgemail="";
                vm.orgdomain="";
                vm.orgSetUpForm.$setPristine();
                vm.orgSetUpForm.orgname.$setValidity();
                vm.orgSetUpForm.orgemail.$setValidity();
                vm.orgSetUpForm.orgdomain.$setValidity();
                vm.emptyField=false;

                //Show the newly added organization
                SysAdmin.loadOrganizations().then(function(response){
                	var orderedOrgList = response.data;
                	vm.organizationList= orderBy(orderedOrgList, 'orgName');
    				var index = vm.organizationList.map(function(e) { return e.id; }).indexOf(addedOrg.id);
    				vm.showForms(addedOrg,index);

        			 });
            	}).catch(function (response) {

            	});
        }

        function sort(){
        	vm.organizationList = orderBy(vm.organizationList,'orgName',vm.reverse);
        }

        function showForms(org,$index){
    		vm.selectedIndex=$index;
    		vm.org = org;

    		vm.org.samlIdpConfiguration = null;
    		if (vm.samlIdps) {
	    		for (var i=0; i<vm.samlIdps.length; i++) {
	    			var samlIdp = vm.samlIdps[i];
	    			if (samlIdp.id === vm.org.samlIdpConfigurationId) {
	    				vm.org.samlIdpConfiguration = samlIdp;
	    				break;
	    			}
	    		}
    		}

			notifyIdpChange();

			if(org.status == 'NEW'){
				setNewForm();
			}else if (org.status == 'ACTIVE' || org.status == 'INACTIVE'){
				setActiveForm();
			}

			resetDropzone();
        }

        function setNewForm(){
        	vm.addForm=false;
   		   	vm.newForm=true;
   		   	vm.activeForm = false;
        }

        function setAddForm(){
        	vm.addForm=true;
  		   	vm.newForm=false;
  		   	vm.activeForm = false;
        }

        function setActiveForm(){
        	vm.addForm=false;
   		   	vm.newForm=false;
   		   	vm.activeForm = true;
            vm.tab = 'main';
        }

         function loadOrganizations(){
        	SysAdmin.loadOrganizations().then(function(response){
    				var orgList = response.data;
    				vm.organizationList = orderBy(orgList, 'orgName',vm.reverse);
        			 })
        			 .catch(function () {

        			 });
        	}

        function editNewOrg(){
        	if(vm.org.orgName==null || vm.org.orgName=="" || vm.org.orgEmail==null || vm.org.orgEmail==""||vm.org.orgDomain==null || vm.org.orgDomain==""){
        		vm.emptyField=true;
        		return;
        		}
       	 	SysAdmin.editNewOrg(
       	 	    {
       	 		orgName : vm.org.orgName,
       	 		orgEmail: vm.org.orgEmail,
       	 		orgDomain: vm.org.orgDomain,
       	 		id : vm.org.id,
       	 		authenticationType: 'SB',
       	 		status: 'NEW'
       	 		}
       	 	).then(function (response) {
       	 			GlobalModal.displaySuccess('Your Changes Have been saved');
                	loadOrganizations();

                	}).catch(function (response) {

                	});
        }

        function resendInvitation (org){
          	 SysAdmin.resendInvitation(org).then(function(response){
          		   GlobalModal.displaySuccess('Invitation Successfully resent');
          		   loadOrganizations();
   			 })
   			 .catch(function () {

              });

           }

        function deleteOrg (org){

        	GlobalModal.confirm('Are you sure you want to delete this organization?', function() {
            	SysAdmin.deleteOrg(org).then(function(response){
            		SysAdmin.loadOrganizations().then(function(response){
            			 var orderByOrgNameList= response.data;
        				 vm.organizationList= orderBy(orderByOrgNameList, 'orgName');
              		     vm.showForms(vm.organizationList[0],0);
            			 });

          		   }).catch(function () {});
            });
          }

        function changeOrgStatus(org){
        	SysAdmin.changeOrgStatus(org).then(function(response){
        		loadOrganizations();
  			 })
  			 .catch(function () {

             });

          }


        function editOrg(org){

        	if (vm.org.authenticationType === 'SAML' && !vm.org.samlIdpConfiguration) {
        		GlobalModal.displayError('Please pick a SAML IDP configuration if using SAML Authentication');
        		return;
        	}

        	var orgdata = {
       	 		orgName : vm.org.orgName,
       	 		orgEmail: vm.org.orgEmail,
       	 		orgDomain: vm.org.orgDomain,
       	 		status: vm.org.status,
       	 		id : vm.org.id,
       	 		authenticationType: vm.org.authenticationType,
       	 		samlIdpConfigurationId: vm.org.samlIdpConfiguration ? vm.org.samlIdpConfiguration.id : 0,
       	 		disableSquirrelboxAuthentication: vm.org.disableSquirrelboxAuthentication,
       	 		samlOrgEmailToRedirect: vm.org.samlOrgEmailToRedirect,
                dcSupport: vm.org.dcSupport,
   	 		};

       	 	SysAdmin.editNewOrg(orgdata).then(function (response) {
   	 			GlobalModal.displaySuccess('Your Changes Have been saved');
                	loadOrganizations();

                	}).catch(function (response) {

                	});

          }


        function removeHelpFile(org, type) {
       	 	SysAdmin.deleteOrganizationHelpFile(org.id, type).then(function (response) {
       	 	    var idx = vm.org.helpFileSet.indexOf(type);
       	 	    if (idx >= 0)
       	 	        vm.org.helpFileSet.splice(idx, 1);
            	});
        }

        function removeLogoImageFile(org, type) {
       	 	SysAdmin.deleteOrganizationLogoImageFile(org.id, type).then(function (response) {
	       	 	if ( type === 'logoicon') {
		       	 	vm.org.logoIconEtag ='';
		   	 		vm.org.logoIconLastUpdate ='';
	       	 	} else if (type === 'loginimage') {
		       	 	vm.org.loginImageEtag ='';
		   	 		vm.org.loginImageLastUpdate ='';
	       	 	} else if (type === 'emaillogo') {
		       	 	vm.org.emailLogoEtag ='';
		   	 		vm.org.emailLogoLastUpdate ='';
	       	 	}
       	 	});
        }



        function resetDropzone() {
            if (!document.getElementById("internalhelpfiledropzone")) {
                if (vm.internaldropzone) {
                    delete vm.internaldropzone;
                }
                if (vm.externaldropzone) {
                    delete vm.externaldropzone;
                }
                if (vm.logoicondropzone) {
                    delete vm.logoicondropzone;
                }
                if (vm.loginimagedropzone) {
                    delete vm.loginimagedropzone;
                }
                if (vm.emaillogodropzone) {
                    delete vm.emaillogodropzone;
                }
                return;
            }

            if (!vm.org) return;

            else if (vm.internaldropzone) {
                vm.internaldropzone.options.url = "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/helpfile/internal";
                vm.externaldropzone.options.url = "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/helpfile/external";
                vm.logoicondropzone.options.url = "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/logoicon";
                vm.loginimagedropzone.options.url = "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/loginimage";
                vm.emaillogodropzone.options.url = "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/emaillogo";
            }
            else {
                vm.internaldropzone = new Dropzone("div#internalhelpfiledropzone", {
                    url: "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/helpfile/internal",
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    uploadMultiple : false,
                    parallelUploads : 10,
                    maxFilesize: 512,
                    maxFiles: 1,
                });
                vm.externaldropzone = new Dropzone("div#externalhelpfiledropzone", {
                    url: "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/helpfile/external",
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    uploadMultiple : false,
                    parallelUploads : 10,
                    maxFilesize: 512,
                    maxFiles: 1,
                });
                vm.logoicondropzone = new Dropzone("div#logoiconfiledropzone", {
                    url: "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/logoicon",
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    uploadMultiple : false,
                    parallelUploads : 10,
                    maxFilesize: 512,
                    maxFiles: 1,
                });
                vm.loginimagedropzone = new Dropzone("div#loginimagefiledropzone", {
                    url: "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/loginimage",
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    uploadMultiple : false,
                    parallelUploads : 10,
                    maxFilesize: 512,
                    maxFiles: 1,
                });

                vm.emaillogodropzone = new Dropzone("div#emaillogofiledropzone", {
                    url: "zuul/squirrelboxfileservice/api/org/" + vm.org.id + "/logofile/emaillogo",
                    autoProcessQueue: true,
                    addRemoveLinks: true,
                    uploadMultiple : false,
                    parallelUploads : 10,
                    maxFilesize: 512,
                    maxFiles: 1,
                });

                vm.internaldropzone.on("maxfilesexceeded", function(file) {
                    vm.dropzone.removeFile(file);
                });
                vm.externaldropzone.on("maxfilesexceeded", function(file) {
                    vm.dropzone.removeFile(file);
                });
                vm.logoicondropzone.on("maxfilesexceeded", function(file) {
                    vm.dropzone.removeFile(file);
                });
                vm.loginimagedropzone.on("maxfilesexceeded", function(file) {
                    vm.dropzone.removeFile(file);
                });
                vm.emaillogodropzone.on("maxfilesexceeded", function(file) {
                    vm.dropzone.removeFile(file);
                });

                vm.internaldropzone.on('sending', function (file, xhr, formData) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
                });
                vm.externaldropzone.on('sending', function (file, xhr, formData) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
                });

                vm.logoicondropzone.on('sending', function (file, xhr, formData) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
                });
                vm.loginimagedropzone.on('sending', function (file, xhr, formData) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
                });
                vm.emaillogodropzone.on('sending', function (file, xhr, formData) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthServerProvider.getToken());
                });

                vm.internaldropzone.on('success',function(file, dataset) {
                    vm.internaldropzone.removeAllFiles(true);
                    vm.org.helpFileSet.push('internal');
                    console.log(vm.org);
                    $scope.$apply();
                });
                vm.externaldropzone.on('success',function(file, dataset) {
                    vm.externaldropzone.removeAllFiles(true);
                    vm.org.helpFileSet.push('external');
                    $scope.$apply();
                });
                vm.logoicondropzone.on('success',function(file, dataset) {
                    vm.logoicondropzone.removeAllFiles(true);
                    vm.org.logoIconEtag= 'true';//put some value to show the remove link
                    $scope.$apply();
                });
                vm.loginimagedropzone.on('success',function(file, dataset) {
                    vm.loginimagedropzone.removeAllFiles(true);
                    vm.org.loginImageEtag= 'true';//put some value to show the remove link
                    $scope.$apply();

                });
                vm.emaillogodropzone.on('success',function(file, dataset) {
                    vm.emaillogodropzone.removeAllFiles(true);
                    vm.org.emailLogoEtag= 'true';//put some value to show the remove link
                    $scope.$apply();

                });

                var errFunc = function(file,response){
                    if (response.errorMessage) {
                        $(file.previewElement).find('.dz-error-message').text(response.errorMessage);
                        GlobalModal.displayError(response.errorMessage);
                    }
                    else if (response.status == 0){
                        $(file.previewElement).find('.dz-error-message').text('timed out');
                        GlobalModal.displayError('Timed out uploading file.');
                    }
                    else if(response.status == 500){
                        $(file.previewElement).find('.dz-error-message').text('server error uploading file');
                        GlobalModal.displayError('Error uploading file.');
                    }
                    else if(response.status == 400){
                        var message = 'unsupported file format';
                        $(file.previewElement).find('.dz-error-message').text(message);
                        GlobalModal.displayError('Unrecognized file format.');
                    }
                };

                vm.internaldropzone.on('error', errFunc);
                vm.externaldropzone.on('error', errFunc);
                vm.logoicondropzone.on('error', errFunc);
                vm.loginimagedropzone.on('error', errFunc);
                vm.emaillogodropzone.on('error', errFunc);

            }
        }
    }
})();
