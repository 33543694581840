(function() {
    'use strict';

    angular
        .module('squirrelboxApp')
        .controller('ExternalEntitiesController', ExternalEntitiesController);

    ExternalEntitiesController.$inject = ['$state', 'ExternalEntity'];

    function ExternalEntitiesController ($state, ExternalEntity) {
        var vm = this;

        vm.entityFilter = '';
        vm.itemsPerPage = 20;
        vm.page = 1;
        vm.sort = ['name'];
        vm.nextAvailableQueryId = 0;

        vm.addEntity = addEntity;
        vm.editEntity = editEntity;
        vm.query = query;
        vm.sortByName = sortByName;
        vm.sortByStatus = sortByStatus;
        vm.loadPage = loadPage;

        query();

        function addEntity() {
	    		$state.go('external-entity', {
                    entityId: 0
                });
	    }

        function editEntity(entity) {
	    		$state.go('external-entity', {
	    			entityId: entity.id,
	    		});
        }

        function query() {
            vm.currentAvailableQueryId = vm.nextAvailableQueryId++;
            var queryId = vm.currentAvailableQueryId;
	        ExternalEntity.query({name: vm.entityFilter, type: "", status: "", page: vm.page-1, size: vm.itemsPerPage, sort: vm.sort},
		    		function(result, headers) {
                    if (queryId == vm.currentAvailableQueryId) {
        		        		vm.externalEntities = result;
        		        		vm.totalItems = headers('X-Total-Count');
                    }
	        		}
	    		);

	        return false;
        }

        function sortByName(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['name,' + sortDir];
	    		query();
	    }

        function sortByStatus(){
	    		var sortDir = vm.reverse ? 'desc' : 'asc';
	    		vm.sort = ['active,' + sortDir];
	    		query();
	    }

        function loadPage(page) {
            vm.page = page;
            query();
        }
    }
})();
