(function() {
    'use strict';
    angular
    .module('squirrelboxApp')
    .factory('Transfer', Transfer);

    Transfer.$inject = ['$resource'];

    function Transfer ($resource) {
        var resourceUrl =  'api/transfers/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
            },
        });
    }
})();
