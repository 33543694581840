(function () {
    'use strict';

    angular
        .module('squirrelboxApp')
        .factory('OrgSetUpService', OrgSetUpService);

    OrgSetUpService.$inject = ['$rootScope', '$state', '$sessionStorage', '$q','$http', '$resource'];

    function OrgSetUpService($rootScope, $state, $sessionStorage, $q, $http, $resource) {
    		var service = {
    			getOrganization : getOrganization,
    			updateOrganization : updateOrganization,
                generateDcAccessKey: generateDcAccessKey,
                revokeDcAccessKey: revokeDcAccessKey,
            };

            return service;

            function getOrganization(){
            	return $http.get('api/organization'

            	).then(function(response){

            		return response;
            	}

            	);
            }

            function updateOrganization(org){
            	return $http.put('api/organizations/'+org.id,org

                	).then(function(response){

                		return response;
                	}

                	);

                }

        function generateDcAccessKey(){
            return $http.get('api/organization/newDcAccessKey').then(function(response){
                return response;
            });
        }

        function revokeDcAccessKey(keyId){
            return $http.get('api/organization/revokeDcAccessKey/' + keyId).then(function(response){
                return response;
            });
        }
    }
})();
